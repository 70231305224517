import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spin } from "antd";
import { TableWrapper } from "../../styled";
import { Button } from "../../../components/buttons/buttons";
import {
  getAllOrderList,
  accetOrders,
} from "../../../redux/onlineOrder/actionCreator";
import { DownOutlined, UpOutlined, LoadingOutlined } from "@ant-design/icons";
import "../sell.css";
import {
  createOnlineTakeAway,
  setOrderTickets,
  setItem,
  getItem,
  acceptOnlineOrderList,
} from "../../../utility/localStorageControl";
import moment from "moment";
import OrderTicketPrint from "../Current/OrderTicketPrint";
import ReactDOMServer from "react-dom/server";
import { getReceiptNumber } from "../../../utility/utility";
import { getAllSetUpPrinterList } from "../../../redux/printer/actionCreator";

const New = (props) => {
  const { currentRegisterData, changeTab } = props;
  const dispatch = useDispatch();
  const [totalOrders, setTotalOrderList] = useState([]);
  const [loader, setLoader] = useState({
    recordId: "",
    check: false,
  });
  const { onlineOrderCount } = useSelector((state) => {
    let acceptList = acceptOnlineOrderList();
    return {
      onlineOrderCount: state.kitchenUser.kitchenUserList?.filter(
        (val) => acceptList.find((j) => j.onlineOrder.id == val.id) == undefined
      )?.length,
    };
  });
  let [setupList, setsetupPrinterList] = useState([]);


  async function fetchAllOrders(first) {
    if (
      currentRegisterData?.onlineOrder &&
      currentRegisterData?.onlineOrder?.length
    ) {
      const getAllOrderDetailsList = await dispatch(
        getAllOrderList(currentRegisterData.onlineOrder)
      );

      let acceptList = acceptOnlineOrderList();
      let filterList = getAllOrderDetailsList?.kitchenUserList?.filter(
        (val) => acceptList.find((j) => j.onlineOrder.id == val.id) == undefined
      );
      if (getAllOrderDetailsList) {
        setItem("totalOnlineOrder", filterList);
        setTotalOrderList(filterList);
      }
      return filterList;
    }
  }
  useEffect(() => {
    fetchAllOrders();
  }, [onlineOrderCount]);

  useEffect(async () => {
    let getSetupPrintList = await dispatch(getAllSetUpPrinterList("sell"));
    if (getSetupPrintList) {
      setsetupPrinterList(getSetupPrintList);
    }
  }, []);

  function dateCompare(time1, time2) {
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0], parts[1], parts[2], 0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0], parts[1], parts[2], 0);

    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime() > t2.getTime()) return 1;
    if (t1.getTime() < t2.getTime()) return -1;
    return 0;
  }
  function checkCategory(val) {
    let Add = [];
    let remove = [];
    val.data.map((j) => {
      if (j.add_or_remove == "Removed Items") {
        remove.push(j);
      } else {
        Add.push(j);
      }
    });
    return Add.length > 0 && remove.length > 0
      ? "both"
      : Add.length > 0 && remove.length == 0
        ? "Added Items"
        : "Removed Items";
  }

  const createOrderTikets = (localCartInfo, items) => {
    let PreviousTikets = [];
    let receiptNumber =
      localCartInfo && localCartInfo.receipt_Number
        ? localCartInfo.receipt_Number
        : getReceiptNumber(currentRegisterData, []);
    let listOfProducts = [
      { categoryName: "Main Kitchen", data: localCartInfo.data },
    ];
    listOfProducts.map((val) => {
      let OrderTicketNumber;
      if (getItem("previousOrderTicketNumber") != null) {
        let Details = getItem("previousOrderTicketNumber");
        if (moment(moment(Details.date).format("L")).isSame(moment().format("L"))) {
          if (dateCompare(moment(Details.date).format("HH:mm:ss"), "06:00:00") == -1 &&
            dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0
          ) {
            OrderTicketNumber = 1;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1,
            });
          } else {
            OrderTicketNumber = 1 + Details.number;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1 + Details.number,
            });
          }
        } else {
          if (dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0) {
            OrderTicketNumber = 1;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1,
            });
          } else {
            OrderTicketNumber = 1 + Details.number;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1 + Details.number,
            });
          }
        }
      } else {
        OrderTicketNumber = 1;
        setItem("previousOrderTicketNumber", {
          date: new Date(),
          number: 1,
        });
      }

      let object = {
        orderNotes: val?.order_tickets_notes,
        tiketNumber: OrderTicketNumber,
        categoryName: val.categoryName,
        add_remove: checkCategory(val),
        itemList: val.data,
        enterDate: new Date(),
        table_name: localCartInfo.tableName,
        receiptNumber: receiptNumber,
        receiptNumberDetails: {
          type: "receipt",
          number: receiptNumber,
        },
      };
      window.frames["print_frame"].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <OrderTicketPrint
          customerComment={
            localCartInfo?.onlineOrder?.comment
              ? localCartInfo?.onlineOrder?.comment
              : undefined
          }
          onlineOrder={{
            source: localCartInfo?.onlineOrder?.Source
              ? localCartInfo?.onlineOrder?.Source
              : "",
            orderId: localCartInfo?.onlineOrder?.order_id
              ? localCartInfo?.onlineOrder?.order_id
              : "",
          }}
          categoryDetails={object}
          PreviousTikets={PreviousTikets}
          ReceiptNumber={receiptNumber}
          TableName={localCartInfo?.tableName ? localCartInfo?.tableName : ""}
        />
      );
      window.frames["print_frame"].window.focus();
      window.frames["print_frame"].window.print();
      if (localCartInfo?.cartKey) {
        setOrderTickets(localCartInfo?.cartKey, val.data, object);
      }
    });
    return true;
  };
  const acceptOrder = async (record) => {
    if (record.order_id) {
      setLoader({
        recordId: record.order_id,
        check: true,
      });
      const response = await dispatch(
        accetOrders(
          currentRegisterData.onlineOrder,
          record.order_id,
          record.Source ? record.Source : []
        )
      );

      if (response) {
        let items = [];
        record.Items.map((val) => {
          let obj = {
            AddonOptions: [],
            add_or_remove: "Added Items",
            calculatedprice: val.calculatedprice,
            display_name: val.name,
            id: val.item_id,
            isAddon: false,
            isAddon1st: false,
            isAddon2nd: false,
            isAddon3rd: false,
            isVarience: false,
            item: val.name,
            key: val.item_id,
            key_price: 0,
            new_item: true,
            newqty: val.quantity,
            option_addon_group: [],
            option_item_group: [],
            option_variant_group: [],
            order_ticket_group: {},
            price: val.price,
            productTaxes: 0,
            product_category: val.category,
            quantity: val.quantity,
            taxGroup: {},
            variance_object: {},
            variance_price: 0,
          };
          items.push(obj);
        });

        let localCartInfo = createOnlineTakeAway(
          items,
          currentRegisterData,
          record
        );

        if (localCartInfo) {
          let response1 = createOrderTikets(localCartInfo, items);
          if (response1) {
            let allOrderList = await fetchAllOrders();
            if (allOrderList) {
              setLoader({
                recordId: "",
                check: false,
              });
            }
          }
        }
      }
    }
  };

  const columns = [
    {
      title: "Order Time",
      dataIndex: "Time",
      key: "Time",
    },
    {
      title: "Source",
      dataIndex: "Source",
      key: "source",
      filters: [
        {
          text: "Zomato",
          value: "Zomato",
        },
        {
          text: "Swiggy",
          value: "Swiggy",
        },
      ],
      onFilter: (value, record) => record.Source.includes(value),
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: "15%",
    },
    {
      title: "Items",
      dataIndex: "Items",
      key: "Items",
      width: "25%",
      render(value, record, index1) {
        return {
          children: (
            <>
              {record.clickRow ? (
                value.map((val, index) =>
                  index == value.length - 1 ? (
                    <p
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                      onClick={() => {
                        totalOrders.map((val, index) => {
                          if (index1 == index) {
                            val.clickRow = !val.clickRow;
                          }
                          return val;
                        });
                        setTotalOrderList([...totalOrders]);
                      }}
                    >
                      {val.quantity} x {val.name}{" "}
                      <UpOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "10px",
                        }}
                      />{" "}
                    </p>
                  ) : (
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                      {val.quantity} x {val.name}{" "}
                    </p>
                  ))
              ) : (
                <p
                  onClick={() => {
                    totalOrders.map((val, index) => {
                      if (index1 == index) {
                        val.clickRow = !val.clickRow;
                      }
                      return val;
                    });
                    setTotalOrderList([...totalOrders]);
                  }}
                >
                  {value[0].quantity} x {value[0].name}{" "}
                  <DownOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: "10px",
                    }}
                  />
                </p>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
    },
    {
      title: "Customer",
      dataIndex: "Customer",
      key: "Customer",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render(value, record) {
        return {
          children: (
            <Button
              size="medium"
              className="confirmQr btn-custom"
              type="primary"
              raised
              onClick={() => acceptOrder(record)}
            >
              <span className="whiteloader">
                {loader.check && loader.recordId == record.id ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 16,
                          margin: "0px 14px",
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  "Accept"
                )}
              </span>
            </Button>
          ),
        };
      },
    },
  ];

  return (
    <TableWrapper className="table-responsive">
      <Table
        rowKey="id"
        size="small"
        dataSource={totalOrders}
        columns={columns}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          total: totalOrders.length,
        }}
        scroll={{
          x: 900,
        }}
      />
    </TableWrapper>
  );
};

export { New };
