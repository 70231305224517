import React from "react";
import { Row, Col, Form, Input } from "antd";
import commonFunction from "../../utility/commonFunctions";
import "./style.css";
import moment from "moment";
import { getItem } from "../../utility/localStorageControl";
function CustomerPrint({ roomDetails, currentRegisterData }) {
  return (
    <>
      <Row>
        <Col span={24}>
          <div
            className="ReceiptHeading"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="left_receipt_heading">
              <h4
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  textAlign: "left",
                  marginBottom: "0px",
                }}
              >
                {getItem("setupCache")?.shopDetails?.shop_name}
              </h4>
              <p
                style={{
                  fontSize: "12px",
                  // fontWeight: 700,
                  fontFamily: "Arial, Helvetica, sans-serif",
                  textAlign: "left",
                  margin: 0,
                }}
              >
                {currentRegisterData?.bill_header}
              </p>
            </div>

            <div
              className="right_receipt_heading"
              style={{ textAlign: "right" }}
            >
              <h4
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  padding: "0",
                  textAlign: "right",
                  marginBottom: "0px",
                }}
              >
                Registration
              </h4>
              <p
                style={{
                  fontSize: "12px",
                  fontFamily: "Arial, Helvetica, sans-serif",
                  textAlign: "right",
                  margin: 0,
                }}
              >
                {commonFunction.convertToDate(new Date(), "MMM DD,Y, h:mm A")}
              </p>
            </div>
          </div>

          <table style={{ width: "100%" }}>
            <tr>
              <hr
                style={{ margin: "5px 0px", borderTop: "2px dotted black" }}
              />
            </tr>
          </table>

          <Form colon={true} style={{ padding: "24px 5px" }}>
            <div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: 500,
                  fontFamily: "Arial, Helvetica, sans-serif",
                  marginBottom: 0,
                }}
              >
                Name
              </div>
              <p
                style={{
                  border: "none",
                  marginBottom: "10px",
                  fontSize: "15px",

                  fontFamily: "Arial, Helvetica, sans-serif",
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                {roomDetails?.customerInfo?.name}
              </p>
            </div>
            <div style={{ borderBottom: "1px solid black" }}></div>

            <div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: 500,
                  fontFamily: "Arial, Helvetica, sans-serif",
                  marginTop: 15,
                }}
              >
                Reservation ID
              </div>
              <p
                style={{
                  border: "none",
                  marginBottom: "10px",
                  fontSize: "15px",

                  fontFamily: "Arial, Helvetica, sans-serif",
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                {roomDetails?.reservationStringId}
              </p>
            </div>
            <div style={{ borderBottom: "1px solid black" }}></div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                marginTop: 25,
                columnGap: "50px",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Check In
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {commonFunction.convertToDate(
                      roomDetails?.reservationInDateAndTime,
                      "ddd DD MMM YYYY"
                    )}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Check Out
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {commonFunction.convertToDate(
                      roomDetails?.reservationOutDateAndTime,
                      "ddd DD MMM YYYY"
                    )}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Stay Duration
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {moment(roomDetails?.reservationOutDateAndTime).diff(
                      moment(roomDetails?.reservationInDateAndTime),
                      "days"
                    )}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                marginTop: 25,
                columnGap: "50px",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Room Type
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails?.room_type?.name}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Room Number
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails?.room_name ? roomDetails?.room_name : "-"}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Number of guest/s
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails.guest.adults +
                      roomDetails.guest.children +
                      roomDetails.guest.infants}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                marginTop: 25,
                columnGap: "50px",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Rate Plan
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails?.rate_plan?.rate_name}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Total Amount
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails?.calculatedPriceWithTax}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                marginTop: 25,
                columnGap: "50px",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Email Address
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails?.customerInfo?.email
                      ? roomDetails?.customerInfo?.email
                      : "-"}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Mobile Number
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails?.customerInfo?.mobile}
                  </p>
                </div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      marginBottom: 0,
                    }}
                  >
                    Address
                  </div>
                  <p
                    style={{
                      border: "none",
                      marginBottom: "10px",
                      fontSize: "15px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "100%",
                      marginBottom: 10,
                    }}
                  >
                    {roomDetails?.customerInfo?.shipping_address
                      ? roomDetails?.customerInfo?.shipping_address
                      : "-"}
                  </p>
                </div>
                {/* <div style={{ borderBottom: "1px solid black" }}></div> */}
              </div>
            </div>
          </Form>

          {currentRegisterData.registration_card_footer &&
            currentRegisterData.registration_card_footer != "" && (
              <>
                {currentRegisterData?.registration_card_footer
                  .split("\n")
                  .map((val) => {
                    return (
                      <p
                        style={{
                          fontSize: "16px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          marginTop: "1px",
                          marginBottom: "0px",
                        }}
                      >
                        {val}
                      </p>
                    );
                  })}
              </>
            )}
          <table style={{ width: "100%", marginTop: "5%" }}>
            <tr>
              <hr
                style={{ margin: "5px 0px", borderTop: "2px dotted black" }}
              />
            </tr>
          </table>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              marginBottom: 10,
              columnGap: "50px",
            }}
          >
            <div
              style={{
                textAlign: "left",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: 500,
                    fontFamily: "Arial, Helvetica, sans-serif",
                    marginBottom: 0,
                  }}
                >
                  Front Office Signature
                </div>
                <p
                  style={{
                    border: "none",
                    marginBottom: "10px",
                    fontSize: "15px",

                    fontFamily: "Arial, Helvetica, sans-serif",
                    width: "100%",
                    marginBottom: 40,
                  }}
                ></p>
              </div>
              <div style={{ borderBottom: "1px solid black" }}></div>
            </div>
            <div
              style={{
                textAlign: "left",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: 500,
                    fontFamily: "Arial, Helvetica, sans-serif",
                    marginBottom: 0,
                  }}
                >
                  Guest Signature
                </div>
                <p
                  style={{
                    border: "none",
                    marginBottom: "10px",
                    fontSize: "15px",

                    fontFamily: "Arial, Helvetica, sans-serif",
                    width: "100%",
                    marginBottom: 40,
                  }}
                ></p>
              </div>
              <div style={{ borderBottom: "1px solid black" }}></div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CustomerPrint;
