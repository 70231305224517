import React, { useState, useEffect } from "react";

import { PerformanceChartWrapper, Pstates, CardBarChart } from "../../style";
import CashFlow from "./CashFlow";
import { Cards } from "../../../../components/cards/frame/cards-frame";
import Heading from "../../../../components/heading/heading";
import { ChartjsAreaChart } from "../../../../components/charts/chartjs";
import {
  chartLinearGradient,
  customTooltips,
} from "../../../../components/utilities/utilities";
import {
  performanceFilterData,
  performanceGetData,
  setIsLoading,
} from "../../../../redux/chartContent/actionCreator";

const WebsitePerformance = (props) => {
  let { dashBoardData } = props;

  return (
    <PerformanceChartWrapper style={{ height: "100%" }}>
      <div className="performance-lineChart sale_mobtitle">
        <CashFlow dashBoardData={dashBoardData} />
      </div>
    </PerformanceChartWrapper>
  );
};

export { WebsitePerformance };
