const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import moment from "moment";

const getMasterList = (registerId, page, filterObj) => {
  return async (dispatch) => {
    try {
      let startDate = filterObj?.startDate
        ? filterObj?.startDate
        : moment()
          .startOf("month")
          .format("YYYY-MM-DD");
      let endDate = filterObj?.endDate
        ? filterObj?.endDate
        : moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      let url = API.hotelReport.masterList;
      if (registerId) {
        url = `${API.hotelReport.masterList
          }?registerId=${registerId}&startDate=${startDate}&endDate=${endDate}&page=${page}&reservationStringId=${filterObj.reservationStringId
          }&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
      }
      const UsersListData = await DataService.get(url);
      if (!UsersListData.data.error) {
        return UsersListData.data;
      } else {
        return UsersListData.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const exportMasters = (payloads) => {
  return async () => {
    try {
      let url = ""

      let startDate;
      let endDate;

      if (payloads.dateRange == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (payloads.dateRange == "yesterday") {
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "custom") {
        startDate = payloads.startDate;
        endDate = payloads.endDate;
      }

      url = `${API.hotelReport.masterExport}?registerId=${payloads.register_id}&startDate=${startDate}&endDate=${endDate}&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone}&reportType=${payloads.type}&email=${payloads.email}`;
      const resp = await DataService.get(url);
      if (resp && !resp.data.error) {
        return resp.data;
      } else {
        return resp.data;
      }
    } catch (error) {
      return false;
    }
  };
};
const getRoomReservation = (registerId, page, filterObj) => {
  return async (dispatch) => {
    try {
      let startDate = filterObj?.startDate
        ? filterObj?.startDate
        : moment()
          .startOf("month")
          .format("YYYY-MM-DD");
      let endDate = filterObj?.endDate
        ? filterObj?.endDate
        : moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      let url = API.hotelReport.roomReservation;
      if (registerId) {
        url = `${API.hotelReport.roomReservation
          }?registerId=${registerId}&startDate=${startDate}&status=${filterObj.status}&endDate=${endDate}&isCancelled=${filterObj.isCancelled ? true : false}&page=${page}&reservationStringId=${filterObj.reservationStringId
          }&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
      }
      const UsersListData = await DataService.get(url);
      if (!UsersListData.data.error) {
        return UsersListData.data;
      } else {
        return UsersListData.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const exportRooms = (payloads) => {
  return async () => {
    try {
      let url = ""

      let startDate;
      let endDate;

      if (payloads.dateRange == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (payloads.dateRange == "yesterday") {
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "custom") {
        startDate = payloads.startDate;
        endDate = payloads.endDate;
      }

      url = `${API.hotelReport.roomReservationExport}?registerId=${payloads.register_id}&isCancelled=${payloads.isCancelled ? true : false}&startDate=${startDate}&endDate=${endDate}&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone}&reportType=${payloads.type}&email=${payloads.email}`;
      const resp = await DataService.get(url);
      if (resp && !resp.data.error) {
        return resp.data;
      } else {
        return resp.data;
      }
    } catch (error) {
      return false;
    }
  };
};
const getRoomOcccupancy = (registerId, start, end) => {
  return async (dispatch) => {
    try {
      let startDate = start ? start : moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      let endDate = end ? end : moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      let url = API.hotelReport.roomOccupancy;
      if (registerId) {
        url = `${API.hotelReport.roomOccupancy
          }?registerId=${registerId}&startDate=${startDate}&endDate=${endDate}&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone
          }`;
      }
      const UsersListData = await DataService.get(url);
      if (!UsersListData.data.error) {
        return UsersListData.data;
      } else {
        return UsersListData.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getSaleReport = (registerId) => {
  return async (dispatch) => {
    try {
      let startDate = moment()
        .startOf("year")
        .format("YYYY-MM-DD");
      let endDate = moment()
        .endOf("year")
        .format("YYYY-MM-DD");
      let url = API.hotelReport.saleReoprt;
      if (registerId) {
        url = `${API.hotelReport.saleReoprt
          }?registerId=${registerId}&startDate=${startDate}&endDate=${endDate}&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone
          }`;
      }
      const UsersListData = await DataService.get(url);
      if (!UsersListData.data.error) {
        return UsersListData.data;
      } else {
        return UsersListData.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export { getMasterList, getRoomReservation, getSaleReport, getRoomOcccupancy, exportMasters, exportRooms };
