import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  Space,
  Button,
  Form,
  Spin,
  message,
  Tooltip,
  Select,
  Card,
} from "antd";
import {
  SearchOutlined,
  ImportOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { UserTableStyleWrapper } from "../../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../../Customer/Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Popover } from "../../../components/popup/popup";
import { ExportCustomer } from "../../../redux/customer/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../../Customer/customer.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import moment from "moment";

import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import { getPaymnetMode } from "../../../redux/dashboard/actionCreator";
import { CardBarChart } from "../../dashboard/style";
import { handlePaymnetModeDate } from "../../../redux/receipts/actionCreator";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./texes.css";
import * as XLSX from "xlsx";
function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const TaxesReport = () => {
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const { startDate, endDate, dateChange, registerList } = useSelector(
    (state) => {
      return {
        startDate: state.receipts.paymnetModeStartDate
          ? state.receipts.paymnetModeStartDate
          : moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
        endDate: state.receipts.paymnetModeEndDate
          ? state.receipts.paymnetModeEndDate
          : moment()
              .endOf("month")
              .format("YYYY-MM-DD"),
        dateChange: state.receipts.paymnetModeDateChange,
        registerList: state.register.RegisterList,
      };
    }
  );
  const dispatch = useDispatch();
  const [dateWisePatmnetList, setDateWisePatmnetList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [customObjtype, setCustomObjType] = useState([]);
  const csvLinkRef = useRef();
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [currentRegisterData, setCurrentRegisterData] = useState("allRegister");
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);

  async function fetchPaymnetData(startDate, endDate) {
    setLoader(true);
    const response = await dispatch(
      getPaymnetMode(startDate, endDate, currentRegisterData, "taxes")
    );
    setLoader(false);
    if (response && !response.error) {
      let { saleSummaruydashboardDateWiseDetails } = response;
      setDateWisePatmnetList(
        saleSummaruydashboardDateWiseDetails
          .filter((b) => b.amount > 0)
          .map((val) => ({
            ...val,
            amount: Number(val.amount).toFixed(2),
          }))
      );
    } else if (response) {
      message.error(response.message);
    }
  }
  async function setStartDateAndDate(startDate, endDate) {
    dispatch(handlePaymnetModeDate(startDate, endDate, false));
  }
  useEffect(() => {
    let startDate = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    let endDate = moment()
      .endOf("month")
      .format("YYYY-MM-DD");
    fetchPaymnetData(startDate, endDate);
    setStartDateAndDate(startDate, endDate);
  }, []);

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      if (dateChange) {
        fetchPaymnetData(startDate, endDate);
      }
    } else {
      didMount.current = true;
    }
  }, [dateChange, startDate, endDate]);
  const didMountRegister = useRef(false);
  useEffect(() => {
    if (didMountRegister.current) {
      if (currentRegisterData) {
        fetchPaymnetData(startDate, endDate);
      }
    } else {
      didMountRegister.current = true;
    }
  }, [currentRegisterData]);

  const columns = [
    {
      title: "NAME",
      dataIndex: "label",
      key: "label",
      fixed: "left",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "TOTAL",
      dataIndex: "amount",
      key: "amount",
      align: "left",
      render(text, record) {
        return {
          children: (
            <div>
              {rsSymbol}
              {Number(text).toFixed(2)}
            </div>
          ),
        };
      },
    },
  ];

  const handleDownloadCSV = () => {
    csvLinkRef.current.link.click();
  };
  const handleDownloadPdf = () => {
    let col = [...columns, ...customObjtype].filter(
      (val) => val.dataIndex != "action"
    );
    const doc = new jsPDF();
    doc.text("TaxesReport", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [col.map((column) => column.title)],
      body: dateWisePatmnetList.map((row) =>
        col.map((column) => row[column.dataIndex])
      ),
    });
    doc.save("TaxesReport.pdf");
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      dateWisePatmnetList.map((k) => ({
        NAME: k.label,
        TOTAL: k.amount,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "TaxesReport.xlsx");
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadPdf();
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadExcel();
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadCSV();
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div
                  className="table_titles"
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  <h2>Taxes</h2>
                </div>

                <Select
                  className="customBorderRadius"
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 10,
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "3px",
                  }}
                  value={currentRegisterData}
                  onChange={(val) => setCurrentRegisterData(val)}
                >
                  <Option value={"allRegister"} style={{ textAlign: "center" }}>
                    All Register
                  </Option>
                  {registerList?.reverse()?.map((val) => {
                    return (
                      <Option value={val._id} style={{ textAlign: "center" }}>
                        {val.register_name}
                      </Option>
                    );
                  })}
                </Select>
              </>
            }
            buttons={[
              <div
                key="2"
                className="page-header-actions custom_action"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                <div className="actionBtn">
                  <Tooltip
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Showing master from{" "}
                        {startDate
                          ? moment(startDate).format("MMMM Do YYYY")
                          : moment()
                              .startOf("month")
                              .format("MMMM Do YYYY")}
                        {" to"}{" "}
                        {endDate
                          ? moment(endDate).format("MMMM Do YYYY")
                          : moment()
                              .endOf("month")
                              .format("MMMM Do YYYY")}
                      </p>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    />
                  </Tooltip>
                  <CalendarButtonPageHeader key="7" type="payment-mode" />
                </div>
              </div>,
              <div
                key="1"
                className="page-header-actions"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                <Popover
                  placement="bottomLeft"
                  content={content}
                  trigger="hover"
                >
                  <Button size="small" type="white">
                    <FeatherIcon icon="download" size={14} />
                    Export
                  </Button>
                </Popover>
              </div>,
            ]}
          />
        </CardToolbox>
        <CSVLink
          style={{ display: "none" }}
          data={dateWisePatmnetList}
          headers={[...columns]
            .filter((val) => val.dataIndex != "action")
            .map((column) => ({
              label: column.title,
              key: column.dataIndex,
            }))}
          filename={"TaxesReport.csv"}
          ref={csvLinkRef}
        >
          Hidden Download CSV Link
        </CSVLink>
        <div className="container">
          <Row>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="_id"
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        pagination={false}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        size="small"
                        dataSource={dateWisePatmnetList}
                        columns={[...columns]}
                        fixed={true}
                        pagination={{
                          total: dateWisePatmnetList.length,
                          showSizeChanger:
                            dateWisePatmnetList.length > 10 ? true : false,
                        }}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Cards>
          </Row>
          {/* <Row gutter={[16, 16]}>
            {loader ? (
              <Spin
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  margin: "auto",
                }}
              />
            ) : (
              dateWisePatmnetList.map((item, index) => (
                <Col key={index} xs={24} sm={12} md={8}>
                  <Card
                    title={item.title}
                    bordered={false}
                    className="custom-card"
                  >
                    {item.charges.map((charge, idx) => (
                      <div key={idx} className="charge-row">
                        <span>{charge.label}</span>
                        <span>
                          {rsSymbol}
                          {Number(charge.amount).toFixed(2)}
                        </span>
                      </div>
                    ))}
                  </Card>
                </Col>
              ))
            )}
          </Row> */}
        </div>
      </Main>
    </>
  );
};

export default TaxesReport;
