import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams, NavLink, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Input,
  Checkbox,
  InputNumber,
  Spin,
  Popover,
  Button,
  Modal,
  Form,
} from "antd";
import {
  FolderViewOutlined,
  DeleteOutlined,
  SearchOutlined,
  ImportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "../../product.css";
import {
  getAllPricebookProductList,
  exportPricebookProduct,
  UpdateProductPriceBook,
} from "../../../../redux/pricebook/actionCreator";
import FeatherIcon from "feather-icons-react";
import { getAllProductList } from "../../../../redux/products/actionCreator";

const ItemsList = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  let { passFun, updatedList } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [editPrice, setEditPrice] = useState(false);
  // console.log("🚀 ~ file: Items.js:19 ~ ItemsList ~ editPrice:", editPrice)
  let [ProductListData, setProductListData] = useState([]);
  let [editItemList, setEditItemList] = useState([]);
  let isMounted = useRef(true);
  const location = useLocation();
  const queryParams = useParams();
  const [loading, setloading] = useState(true);
  const [editPriceValue, setEditPriceValue] = useState(false);
  let [search, setsearch] = useState("");
  const [modalVisible1, setModelVisible1] = useState(false);
  const [ExportType, SetExportType] = useState("");
  const [modalVisible, setModelVisible] = useState(false);
  let email = localStorage.getItem("email_id");
  const deviceWidth = localStorage.getItem("windowWidth");
  const register_id = location.state.register_id;

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );

        return resizeObserverErr
          ? resizeObserverErr.setAttribute("style", "display: none")
          : resizeObserverErrDiv
            ? resizeObserverErrDiv.setAttribute("style", "display: none")
            : " ";
      }
    });
  }, []);
  useEffect(() => {
    setloading(true);
    fetchProductList();
    if (isMounted.current) {
      fetchProductList();
      // setloading(false);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchProductList = async () => {
    const getProductList = await dispatch(
      getAllPricebookProductList(queryParams.pricebook_id, 1)
    );
    if (
      isMounted.current &&
      getProductList &&
      getProductList.PriceBookProductList
    )
      setEditItemList([]);
    const Products_list = getProductList.PriceBookProductList.filter(
      (data, i) => {
        if (data?.limit_to_register.length > 0) {
          return data?.limit_to_register.includes(register_id) ? data : "";
        } else {
          return data;
        }
      }
    );
    setProductListData(Products_list);
    setloading(false);
    setEditPriceValue(false);
  };

  const [state, setState] = useState({
    item: "",
  });

  // useImperativeHandle(ref, () => ({
  //   async Productlist() {
  //     const getProductList = await dispatch(
  //       getAllPricebookProductList(queryParams.pricebook_id)
  //     );
  //     if (getProductList && getProductList.PriceBookProductList) {
  //       setProductListData(getProductList.PriceBookProductList);
  //       setEditItemList([]);
  //       return getProductList.PriceBookProductList;
  //     }
  //   },
  // }));
  let { selectedRowKeys, item } = state;
  useEffect(() => {
    if (ProductListData) {
      setState({
        item: ProductListData,
        selectedRowKeys,
      });
    }
  }, [ProductListData, selectedRowKeys]);

  passFun(editItemList);

  function pushToArray(editItemList, obj) {
    const index = editItemList.findIndex(
      (e) => e.product_id === obj.product_id
    );
    if (index === -1) {
      setEditItemList([...editItemList, obj]);
    } else {
      editItemList[index].product_id = obj.product_id;
      obj.price && (editItemList[index].price = obj.price);
      obj.disable && (editItemList[index].disable = obj.disable);
    }
  }

  let changePageData = async (value) => {
    const getProductList = await dispatch(
      getAllPricebookProductList(queryParams.pricebook_id, value)
    );

    if (getProductList && getProductList.PriceBookProductList)
      setProductListData(getProductList.PriceBookProductList);
  };
  function onChange(value, editPrice) {
    const findIndex = item.findIndex((product) => product._id === editPrice);

    pushToArray(editItemList, {
      product_id: editPrice,
      price: value,
      disable: item[findIndex].is_disabled ? true : false,
    });
    item[findIndex].price = value;
  }
  function checkboxChange(e, id) {
    const findIndex = item.findIndex((product) => product._id === id);
    item[findIndex].is_disabled = e.target.checked;

    pushToArray(editItemList, {
      product_id: id,
      disable: e.target.checked,
      price: item[findIndex].price,
    });
  }
  const dataSource = [];

  // console.log("sagasgcvahdascdghasdacdadadas", ProductListData);
  if (ProductListData.length)
    item.map((value, inde) => {
      const {
        _id,
        product_name,
        product_category,
        price,
        is_disabled,
        is_price_overridden,
      } = value;
      return dataSource.push({
        id: _id,
        product_name: product_name,
        category: product_category.category_name,
        price: price,
        is_price_overridden: is_price_overridden ? is_price_overridden : "-",
        is_disabled: is_disabled ? is_disabled : false,
      });
    });

  const searchDataSource = [];
  let searchArrTaxes = dataSource.filter((value) =>
    value.product_name.toLowerCase().includes(search.toLowerCase())
  );

  if (searchArrTaxes.length)
    searchArrTaxes.map((value) => {
      const {
        _id,
        product_name,
        category,
        price,
        is_disabled,
        is_price_overridden,
      } = value;
      return searchDataSource.push({
        id: _id,
        product_name: product_name,
        category: category,
        price: price,
        is_price_overridden: is_price_overridden ? is_price_overridden : "-",
        is_disabled: is_disabled ? is_disabled : false,
      });
    });

  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      className: "center-col-padding",
      render(text, record) {
        return {
          children: (
            <div
              style={{ color: "#008cba" }}
              onClick={() => setEditPrice(false)}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "Product Category",
      dataIndex: "category",
      key: "category",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: <div onClick={() => setEditPrice(false)}>{text}</div>,
        };
      },
    },
    {
      title: "Product Price",
      dataIndex: "price",
      key: "price",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: (
            <div onClick={() => setEditPriceValue(true)}>
              {editPrice === record.id && editPriceValue == true ? (
                <InputNumber
                  onPressEnter={() => setEditPrice(false)}
                  defaultValue={text}
                  onChange={(value) => onChange(value, editPrice)}
                  size="small"
                  className="product_price_input"
                />
              ) : (
                <div
                  style={{ borderBottom: "1px dashed #a8b9ce", width: "100px" }}
                  onClick={() => setEditPrice(record.id)}
                >
                  {text}
                </div>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: "Is Price Overridden?",
      dataIndex: "is_price_overridden",
      key: "is_price_overridden",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: <div onClick={() => setEditPrice(false)}>{text}</div>,
        };
      },
    },
    {
      title: "Is Disabled?",
      dataIndex: "is_disabled",
      key: "is_disabled",
      render(text, record) {
        return {
          // props: {
          //   style: { textAlign: "center" },
          // },
          children: (
            <div onClick={() => setEditPrice(false)}>
              <Checkbox
                defaultChecked={text}
                onChange={(value) => checkboxChange(value, record.id)}
              />
            </div>
          ),
        };
      },
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const handleUpdateProductList = async () => {
    setloading(true);
    if (editItemList.length > 0) {
      let obj;
      editItemList[0].product_id && (obj = { products: editItemList });
      const getAllUpdateData = await dispatch(
        UpdateProductPriceBook(obj, queryParams.pricebook_id)
      );

      if (getAllUpdateData && getAllUpdateData.UpdateProductData) {
        if (obj.products) {
          fetchProductList();
          await dispatch(getAllProductList());
        }
      }
    }
    setModelVisible(false);
  };

  const submitExport = (values) => {
    values.type = ExportType;
    if (ExportType) {
      dispatch(exportPricebookProduct(values, queryParams.pricebook_id));
      setModelVisible1(false);
    }
  };

  const handleCancel = (e) => {
    setModelVisible1(false);
    setModelVisible(false);
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  return (
    <div>
      <Row gutter={15} className="list-row">
        <Col span={24} className="pricebookTableBox">
          <div className="priceBook_titles_content">
            <div className="productCount">
              <h2>Product</h2>
              <span className="title-counter" style={{ marginTop: "-17px" }}>
                {ProductListData.length} products{" "}
              </span>
            </div>
            <div
              style={{
                boxShadow: "none",
                marginBottom: "16px",
                marginLeft: "20px",
              }}
              className="table_search"
            >
              <Input
                suffix={<SearchOutlined />}
                className="set_serbt"
                autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                placeholder="Search by product name"
                style={{
                  borderRadius: "30px",
                  width: "250px",
                }}
                onChange={(e) => setsearch(e.target.value)}
                value={search}
              />
            </div>
          </div>
          <div className="pricebookTables-toolbox-menu">
            <div key="1" className="page-header-actions">
              {/* <NavLink
                to={`pricebook-items/produt/import/${queryParams.pricebook_id}`}
                className="ant-btn ant-btn-white ant-btn-md"
              >
                <ImportOutlined /> Import
              </NavLink>
              <Popover placement="bottomLeft" content={content} trigger="hover">
                <Button size="small" type="white">
                  <FeatherIcon icon="download" size={14} />
                  Export
                </Button>
              </Popover> */}
              <Button
                size="middle"
                type="primary"
                Style={{ padding: 10 }}
                onClick={() => history.push("/products/pricebook")}
              >
                Go back
              </Button>
              <Button
                size="middle"
                type="primary"
                Style={{ padding: 10 }}
                onClick={() => setModelVisible(true)}
              >
                Save Product
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      {loading ? (
        <>
          <Table
            rowKey="id"
            size="small"
            locale={locale}
            dataSource={[]}
            columns={columns}
            fixed={true}
            scroll={{ x: 800 }}
          />
        </>
      ) : (
        <Table
          rowKey="id"
          size="small"
          locale={loading ? locale : false}
          dataSource={dataSource.filter((value) =>
            value.product_name.toLowerCase().includes(search.toLowerCase())
          )}
          columns={columns}
          fixed={true}
          scroll={{ x: 800 }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
            total: dataSource.filter((value) =>
              value.product_name.toLowerCase().includes(search.toLowerCase())
            ).length,
          }}
        />
      )}

      <Modal
        title="Products"
        visible={modalVisible1}
        width={600}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form name="export" form={form} onFinish={submitExport}>
          <div className="add-product-block">
            <div className="add-product-content">
              <Form.Item
                name="email"
                initialValue={email ? email : ""}
                label="Send to Email Address"
                rules={[
                  {
                    message: "Email address is required",
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Save Products"
        visible={modalVisible}
        onOk={handleUpdateProductList}
        onCancel={handleCancel}
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                  spin
                />
              }
            />
          ) : (
            "Save Products"
          )
        }
        width={600}
      >
        <p>Are you sure you want to save products</p>
      </Modal>
    </div>
  );
});

export { ItemsList };
