import React, { useState, useContext } from "react";
import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import { openDB } from "idb";
const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

const dbPromise = openDB("myDatabase", 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains("productDetails")) {
      db.createObjectStore("productDetails", { keyPath: "id" });
    }
  },
});
const openDatabase = (databaseName, version) => {
  return new Promise((resolve, reject) => {
    const dbPromise = idb.open(databaseName, version);

    dbPromise.onsuccess = () => {
      resolve(dbPromise.result);
    };

    dbPromise.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const getItem = (key) => {
  const data = typeof window !== "undefined" ? localStorage.getItem(key) : "";

  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const addProductListInIndexdb = async (list) => {
  const db = await dbPromise;
  const tx = db.transaction("productDetails", "readwrite");
  const store = tx.objectStore("productDetails");
  store.put({ id: 1, productList: list });
  // let response = await tx.done;

  // const dbPromise = idb.open("productList", 1);
  // dbPromise.onsuccess = () => {
  //   const db = dbPromise.result;
  //   if (!db.objectStoreNames.contains("userData")) {
  //     const objectStore = db.createObjectStore("userData", {
  //       keyPath: "id",
  //     });
  //   }
  //   var tx = db.transaction("userData", "readwrite");
  //   var userData = tx.objectStore("userData");
  //   const users = userData.put({
  //     id: 1,
  //     productList: list,
  //   });
  //   users.onsuccess = (query) => {
  //     tx.oncomplete = function() {
  //       db.close();
  //     };
  //   };
  // };
};
const getIndexdbProductList = async () => {
  try {
    // const db = await openDatabase("productList", 1);
    // const tx = db.transaction("userData", "readonly");
    // const userData = tx.objectStore("userData");
    // const users = await new Promise((resolve, reject) => {
    //   const request = userData.getAll();
    //   request.onsuccess = (event) => {
    //     resolve(JSON.parse(event.target.result[0].productList));
    //   };
    //   request.onerror = (event) => {
    //     reject(event.target.error);
    //   };
    // });
    const db = await dbPromise;
    let response = await db.get("productDetails", 1);

    if (response && response.productList) {
      return JSON.parse(response.productList);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
const addVariantListInIndexdb = async (list) => {
  const db = await dbPromise;
  const tx = db.transaction("productDetails", "readwrite");
  const store = tx.objectStore("productDetails");
  store.put({ id: 2, productVariants: list });
  // const dbPromise = idb.open("productList", 1);
  // dbPromise.onsuccess = () => {
  //   const db = dbPromise.result;
  //   var tx = db.transaction("userData", "readwrite");
  //   var userData = tx.objectStore("userData");
  //   const users = userData.put({
  //     id: 2,
  //     productVariants: list,
  //   });
  //   users.onsuccess = (query) => {
  //     tx.oncomplete = function() {
  //       db.close();
  //     };
  //   };
  // };
};
const getIndexdbVarintList = async () => {
  try {
    // const db = await openDatabase("productList", 1);
    // const tx = db.transaction("userData", "readonly");
    // const userData = tx.objectStore("userData");
    // const users = await new Promise((resolve, reject) => {
    //   const request = userData.getAll();
    //   request.onsuccess = (event) => {
    //     resolve(JSON.parse(event.target.result[1].productVariants));
    //   };
    //   request.onerror = (event) => {
    //     reject(event.target.error);
    //   };
    // });

    // return users;
    const db = await dbPromise;
    let response = await db.get("productDetails", 2);

    if (response && response.productVariants) {
      return JSON.parse(response.productVariants);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
const addVariantGroupListInIndexdb = async (list) => {
  const db = await dbPromise;
  const tx = db.transaction("productDetails", "readwrite");
  const store = tx.objectStore("productDetails");
  store.put({ id: 3, productVariantGroups: list });
  // const dbPromise = idb.open("productList", 1);
  // dbPromise.onsuccess = () => {
  //   const db = dbPromise.result;
  //   var tx = db.transaction("userData", "readwrite");
  //   var userData = tx.objectStore("userData");
  //   const users = userData.put({
  //     id: 3,
  //     productVariantGroups: list,
  //   });
  //   users.onsuccess = (query) => {
  //     tx.oncomplete = function() {
  //       db.close();
  //     };
  //   };
  // };
};
const getIndexdbVarintGroupList = async () => {
  try {
    // const db = await openDatabase("productList", 1);
    // const tx = db.transaction("userData", "readonly");
    // const userData = tx.objectStore("userData");
    // const users = await new Promise((resolve, reject) => {
    //   const request = userData.getAll();
    //   request.onsuccess = (event) => {
    //     resolve(JSON.parse(event.target.result[2].productVariantGroups));
    //   };
    //   request.onerror = (event) => {
    //     reject(event.target.error);
    //   };
    // });

    // return users;
    const db = await dbPromise;
    let response = await db.get("productDetails", 3);

    if (response && response.productVariantGroups) {
      return JSON.parse(response.productVariantGroups);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const addProductAddonListInIndexdb = async (list) => {
  const db = await dbPromise;
  const tx = db.transaction("productDetails", "readwrite");
  const store = tx.objectStore("productDetails");
  store.put({ id: 4, productAddon: list });
  // const dbPromise = idb.open("productList", 1);
  // dbPromise.onsuccess = () => {
  //   const db = dbPromise.result;
  //   var tx = db.transaction("userData", "readwrite");
  //   var userData = tx.objectStore("userData");
  //   const users = userData.put({
  //     id: 4,
  //     productAddon: list,
  //   });
  //   users.onsuccess = (query) => {
  //     tx.oncomplete = function() {
  //       db.close();
  //     };
  //   };
  // };
};

const getIndexdbAddonList = async () => {
  try {
    // const db = await openDatabase("productList", 1);
    // const tx = db.transaction("userData", "readonly");
    // const userData = tx.objectStore("userData");
    // const users = await new Promise((resolve, reject) => {
    //   const request = userData.getAll();
    //   request.onsuccess = (event) => {
    //     resolve(JSON.parse(event.target.result[3].productAddon));
    //   };
    //   request.onerror = (event) => {
    //     reject(event.target.error);
    //   };
    // });

    // return users;
    const db = await dbPromise;
    let response = await db.get("productDetails", 4);

    if (response && response.productAddon) {
      return JSON.parse(response.productAddon);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
const addProductAddonGroupListInIndexdb = async (list) => {
  const db = await dbPromise;
  const tx = db.transaction("productDetails", "readwrite");
  const store = tx.objectStore("productDetails");
  store.put({ id: 5, productAddonGroups: list });
  // const dbPromise = idb.open("productList", 1);
  // dbPromise.onsuccess = () => {
  //   const db = dbPromise.result;
  //   var tx = db.transaction("userData", "readwrite");
  //   var userData = tx.objectStore("userData");
  //   const users = userData.put({
  //     id: 5,
  //     productAddonGroups: list,
  //   });
  //   users.onsuccess = (query) => {
  //     tx.oncomplete = function() {
  //       db.close();
  //     };
  //   };
  // };
};
const getIndexdbAddonGroupList = async () => {
  try {
    // const db = await openDatabase("productList", 1);
    // const tx = db.transaction("userData", "readonly");
    // const userData = tx.objectStore("userData");
    // const users = await new Promise((resolve, reject) => {
    //   const request = userData.getAll();
    //   request.onsuccess = (event) => {
    //     resolve(JSON.parse(event.target.result[4].productAddonGroups));
    //   };
    //   request.onerror = (event) => {
    //     reject(event.target.error);
    //   };
    // });

    // return users;
    const db = await dbPromise;
    let response = await db.get("productDetails", 5);

    if (response && response.productAddonGroups) {
      return JSON.parse(response.productAddonGroups);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
const setItem = (key, value) => {
  if (key == "productList") {
    const stringify = typeof value !== "string" ? JSON.stringify(value) : value;
    localStorage.setItem(key, JSON.stringify([]));
    addProductListInIndexdb(stringify);
    return value;
  }
  const stringify = typeof value !== "string" ? JSON.stringify(value) : value;
  return localStorage.setItem(key, stringify);
};

const removeItem = (key) => {
  localStorage.removeItem(key);
};

const localStorageCartKeyName = "LOCAL_STORAGE_CART_KEY_NAME";

const getLocalCartDataByType = (type, registerId) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return [];
  } else {
    return JSON.parse(local_cart_data).filter(
      (data) =>
        data.type == type && registerId && data.register_id == registerId
    );
  }
};

const getLocalCartCount = (registerDetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return [].length;
  } else {
    return JSON.parse(local_cart_data).filter(
      (data) =>
        data.Status == "In Progress" &&
        registerDetails &&
        data.register_id == registerDetails._id &&
        !data.tableName?.includes("-BK-")
    ).length;
  }
};

const getAllTakeAwayDataInLocal = (registerDetails) => {
  if (registerDetails) {
    let local_cart_data = getLocalCartDataByType(
      "take-away-local",
      registerDetails._id
    );
    return local_cart_data;
  }
};
const getallCustomSwapList = (registerDetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return [];
  } else {
    return JSON.parse(local_cart_data).filter(
      (data) =>
        data.type == "custom-table-local" &&
        registerDetails &&
        data.register_id == registerDetails._id &&
        data.swapTableCustum
    );
  }
};
const getallCustomSplitList = (registerDetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return [];
  } else {
    return JSON.parse(local_cart_data).filter(
      (data) =>
        (data.type == "custom-table-local" &&
          registerDetails &&
          data.register_id == registerDetails._id &&
          data.customSplit) ||
        data.swapTableCustum
    );
  }
};

const getAllDeliveryDataInLocal = (registerId) => {
  let local_cart_data = getLocalCartDataByType(
    "delivery-local",
    registerId?._id
  );
  return local_cart_data;
};

// const tableStatus = {
//   Empty: "Empty",
//   Serving: "Serving",
//   Unpaid: "Unpaid",
//   Paid: "Paid",
//   Occupied: "Occupied",
// };

const createNewCartwithKeyandPush = (
  type,
  data,
  registerDetails,
  formData,
  splitName,
  indexOfSplit
) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  const userName = getItem("userDetails")?.username
    ? getItem("userDetails")?.username
    : "";
  let isCartAlreadyExists = true;
  if (local_cart_data == null) {
    local_cart_data = [];
  } else {
    local_cart_data = JSON.parse(local_cart_data);
  }

  let cartKey =
    Math.floor(Math.random() * 1000000000) + "_time_" + new Date().getTime();
  let RegisterId = registerDetails?._id;

  let default_cart_object = {
    type: type,
    data: [],
    Status: "In Progress",
    created_at: new Date(),
    cartKey: cartKey,
    register_id: RegisterId,
    waiterName: userName,
  };
  if (data.tableName == "New Reservation") {
    default_cart_object["reservation"] = true;
  }
  if (
    getItem("hotel_enable") &&
    registerDetails &&
    registerDetails.hotelDetalis &&
    registerDetails.hotelDetalis.rooms &&
    data.tableName
  ) {
    let findTableIsHotelRoom = registerDetails.hotelDetalis.rooms.find(
      (j) => data.tableName == `${j.roomType} ${j.number}`
    );
    if (findTableIsHotelRoom && (typeof splitName == "string") == false) {
      default_cart_object["RoomId"] = findTableIsHotelRoom.uuid;
      default_cart_object["hotelID"] = findTableIsHotelRoom.hotelID;
      default_cart_object["reservation_id"] = splitName.reservation_id;
    }
  } else if ((typeof splitName == "string") == false && splitName) {
    default_cart_object["RoomId"] = splitName.uuid;
    default_cart_object["hotelID"] = splitName.hotelID;
    default_cart_object["reservation_id"] = splitName.reservation_id;
  }

  splitName &&
    typeof splitName == "string" &&
    (default_cart_object.customSplit = true);
  indexOfSplit && (default_cart_object.index = indexOfSplit);
  if (type == "DRAFT_CART") {
    default_cart_object.data = data;
    formData?.tableName &&
      (default_cart_object.tableName = formData?.tableName);

    default_cart_object = { ...default_cart_object };
  } else {
    default_cart_object = { ...default_cart_object, ...data };
  }

  if (type == "custom-table-local") {
    let cartData = local_cart_data.filter(function (itm) {
      return (
        itm.tablekey == data.tablekey && itm.register_id == registerDetails?._id
      );
    });

    if (cartData.length > 0) {
      isCartAlreadyExists = false;
      localStorage.setItem("active_cart", cartData[0].cartKey);
      return cartData[0];
    }
  }
  if (data.tableName) {
    let cartData = local_cart_data.filter(function (itm) {
      return (
        itm.tableName == data.tableName &&
        itm.register_id == registerDetails?._id
      );
    });

    if (cartData.length > 0) {
      isCartAlreadyExists = false;
      localStorage.setItem("active_cart", cartData[0].cartKey);
      return cartData[0];
    }
  }

  localStorage.setItem("active_cart", cartKey);
  local_cart_data.push(default_cart_object);
  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return default_cart_object;
};

const bookingAddInLocalData = (localcartInfo, bookingData) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);

  if (local_cart_data == null) {
    local_cart_data = [];
  } else {
    local_cart_data = JSON.parse(local_cart_data);
  }

  let default_cart_object = {
    ...localcartInfo,
    cartKey:
      Math.floor(Math.random() * 1000000000) + "_time_" + new Date().getTime(),
    Status: "In Progress",
    type: "booking_cart",
    tableName: bookingData.ReceiptNumber,
    tablekey: bookingData.ReceiptNumber,
    bookingDetails: bookingData,
    otherDetails: {
      ...bookingData?.details?.localCartInfo?.otherDetails,
      customer: bookingData.customer,
    },
  };

  let findAllredayBooking = local_cart_data.find(
    (val) =>
      val &&
      val.bookingDetails &&
      val.bookingDetails.ReceiptNumber &&
      val.bookingDetails.ReceiptNumber == bookingData.ReceiptNumber
  );
  if (findAllredayBooking) {
    default_cart_object = findAllredayBooking;
  } else {
    local_cart_data.push(default_cart_object);
  }
  localStorage.setItem("active_cart", default_cart_object.cartKey);
  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );

  return default_cart_object;
};

const getCartInfoFromLocalKey = (key, registerDetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  local_cart_data = JSON.parse(local_cart_data);
  if (local_cart_data) {
    let cartData = local_cart_data.filter(function (itm) {
      return itm.cartKey == key && itm.register_id === registerDetails?._id;
    })[0];
    if (cartData != null && Object.keys(cartData).length > 0) {
    }
    // localStorage.setItem("active_cart", key);
    return cartData;
  }
};

const getCartInfoLocalListsData = (currentRegisterData) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data != null) {
    local_cart_data = JSON.parse(local_cart_data).filter(
      (data) => data.register_id == currentRegisterData?._id
    );
    return local_cart_data;
  } else {
    return [];
  }
};

const setCartInfoFromLocalKey = (
  key,
  data,
  darftUpdate,
  formData,
  splitName
) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return {};
  }
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data?.findIndex((item) => item.cartKey === key);
  if (findIndex !== -1) {
    local_cart_data[findIndex].Status = "In Progress";
    local_cart_data[findIndex].bingagePrintFirstTrueClick = false;
    local_cart_data[findIndex].data = data;
    darftUpdate == "darftupdate" &&
      (local_cart_data[findIndex].created_at = new Date());

    formData?.tableName &&
      (local_cart_data[findIndex].tableName = formData?.tableName);

    darftUpdate == "darftupdate" &&
      (local_cart_data[findIndex].darftDetalisUpdate = true);
  }

  localStorage.setItem("active_cart", key);

  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  let obj = {
    default_cart_object: local_cart_data[findIndex],
    allLocalData: local_cart_data,
  };
  return obj;
};

const getTableStatusFromId = (id, registerdetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data != null) {
    local_cart_data = JSON.parse(local_cart_data);
    let cartData = local_cart_data.filter(function (itm) {
      return (
        itm.tablekey == id &&
        registerdetails &&
        itm.register_id === registerdetails._id
      );
    });

    if (cartData.length > 0) {
      return cartData[0].Status;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const getTotalOfUnpaid = (id, registerdetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data != null) {
    local_cart_data = JSON.parse(local_cart_data);
    let cartData = local_cart_data.filter(function (itm) {
      return (
        itm.tablekey == id &&
        registerdetails &&
        itm.register_id === registerdetails._id
      );
    });

    if (
      cartData.length > 0 &&
      cartData[0].otherDetails?.finalCharge &&
      cartData[0].otherDetails?.finalCharge
    ) {
      return cartData[0].otherDetails?.finalCharge;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

const tableStatusChange = (
  key,
  status,
  receipt_Number,
  bingageDetails,
  bingaePrintFristTrue,
  printFirstNumber
) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return {};
  }
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data.findIndex((item) => item.cartKey === key);
  if (findIndex !== -1 && status == "Delete") {
    // local_cart_data[findIndex].Status = "Delete";
    // local_cart_data[findIndex].data = [];
    // localStorage.removeItem("active_cart");
  } else {
    local_cart_data[findIndex].Status = status;
    if (bingaePrintFristTrue) {
      local_cart_data[findIndex].bingagePrintFirstTrueClick = true;
    }
    if (bingageDetails) {
      local_cart_data[findIndex].bingageDetails = bingageDetails;
    }
    if (getItem("orderTicketButton") == true && receipt_Number != "") {
      local_cart_data[findIndex].printFirstReceiptNumber = receipt_Number;
    }
    if (printFirstNumber == undefined) {
      local_cart_data[findIndex].printFirstReceiptNumber = receipt_Number;
    }
  }
  let obj = {
    default_cart_object: local_cart_data[findIndex],
    allLocalData: local_cart_data,
  };
  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return obj;
};

const checkIfTableIsSelected = (id, registerDetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data != null) {
    local_cart_data = JSON.parse(local_cart_data);
    let cartData = local_cart_data.filter(function (itm) {
      return (
        itm.tablekey == id &&
        registerDetails &&
        itm.register_id == registerDetails._id
      );
    });
    if (cartData.length > 0) {
      if (cartData[0].cartKey == localStorage.getItem("active_cart")) {
        return <span className="active-dots" />;
      } else {
        return "";
      }
      //return cartData[0].Status;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const checkIfTableIsSelectedByCartkey = (id) => {
  if (id == localStorage.getItem("active_cart")) {
    return <span className="active-dots" />;
  } else {
    return "";
  }
};

const updateTableNameFromCartId = (tableName, cartKey) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data.findIndex(
    (item) => item.cartKey === cartKey
  );
  local_cart_data[findIndex].tableName = tableName;
  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return local_cart_data[findIndex];
};

const removeCartFromLocalStorage = (cartKey) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName)
    ? localStorage.getItem(localStorageCartKeyName)
    : [];
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data.findIndex(
    (item) => item.cartKey === cartKey
  );

  local_cart_data.splice(findIndex, 1);

  localStorage.removeItem("product_Details");
  localStorage.removeItem("active_cart");
  let obj = {
    default_cart_object: local_cart_data[findIndex],
    allLocalData: local_cart_data,
  };
  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return obj;
};

const storeOtherData = (key, data) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return {};
  }
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data?.findIndex((item) => item.cartKey === key);
  if (findIndex !== -1) {
    local_cart_data[findIndex].otherDetails = data;
    local_cart_data[findIndex].details = data.details;
  }

  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return local_cart_data[findIndex];
};

const setOrderTickets = (
  key,
  data,
  object,
  selectedAllProduct,
  mobileWaiterName,
  customer
) => {
  const userName = getItem("userDetails")?.username
    ? getItem("userDetails")?.username
    : "";
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return {};
  }
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data?.findIndex((item) => item.cartKey === key);

  findIndex !== -1
    ? (local_cart_data[findIndex].receipt_Number = object.receiptNumber)
    : null;

  if (local_cart_data[findIndex]?.orderTicketsData) {
    if (findIndex !== -1) {
      local_cart_data[findIndex].orderTicketsData = [
        ...local_cart_data[findIndex].orderTicketsData,
        {
          enterDate: new Date(),
          itemList: data,
          orderNotes: object.orderNotes,
          tiketNumber: object.tiketNumber,
          categoryName: object.categoryName,
          add_remove: object.add_remove,
          receiptNumberDetails: object.receiptNumberDetails,
          table_name: object.table_name,
        },
      ];
      if (selectedAllProduct) {
        local_cart_data[findIndex].data = selectedAllProduct;
      }
      local_cart_data[findIndex].waiterName = mobileWaiterName
        ? mobileWaiterName
        : userName;
    }
  } else {
    if (findIndex !== -1) {
      local_cart_data[findIndex].orderTicketsData = [
        {
          enterDate: new Date(),
          itemList: data,
          orderNotes: object.orderNotes,
          tiketNumber: object.tiketNumber,
          categoryName: object.categoryName,
          add_remove: object.add_remove,
          table_name: object.table_name,
          receiptNumberDetails: object.receiptNumberDetails,
        },
      ];
      local_cart_data[findIndex].waiterName = mobileWaiterName
        ? mobileWaiterName
        : userName;
      if (selectedAllProduct) {
        local_cart_data[findIndex].data = selectedAllProduct;
      }
    }
  }

  if (customer && findIndex !== -1) {
    local_cart_data[findIndex].otherDetails = { customer: customer };
  }

  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return {
    default_cart_object: local_cart_data[findIndex],
    allLocalData: local_cart_data,
  };
};

const darftCount = (currentRegisterData) => {
  return getCartInfoLocalListsData().filter(
    (d) => d.type == "DRAFT_CART" && d.register_id == currentRegisterData._id
  ).length;
};

const getTableNameTo = (id, registerdetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data != null) {
    local_cart_data = JSON.parse(local_cart_data);
    let cartData = local_cart_data.filter(function (itm) {
      return (
        itm.tableName == id &&
        registerdetails &&
        itm.register_id === registerdetails._id
      );
    });

    if (cartData.length > 0) {
      return cartData[0];
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const AddLastSplitName = (key, alldata, splitName, registerDetails, index) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return {};
  }
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data?.findIndex(
    (item) => item.cartKey === key && item.register_id === registerDetails._id
  );
  if (findIndex !== -1) {
    local_cart_data[findIndex] = {
      ...alldata,
      lastSplitName: splitName,
      splitIndex: index,
    };
  }

  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return local_cart_data[findIndex];
};

const checkTakeAwayTodayYaNot = (allTakeAwayData) => {
  if (
    allTakeAwayData?.length > 0 &&
    allTakeAwayData[allTakeAwayData.length - 1]?.created_at
  ) {
    if (
      moment(
        moment(allTakeAwayData[allTakeAwayData.length - 1].created_at).format(
          "MM/DD/YYYY"
        )
      ).isSame(moment().format("MM/DD/YYYY"))
    ) {
      return allTakeAwayData.length + 1;
    } else {
      return 1;

      // let local_cart_data = localStorage.getItem(localStorageCartKeyName);
      // if (local_cart_data == null) {
      //   return 1;
      // } else {
      //   local_cart_data = JSON.parse(local_cart_data);
      //   let removeTakeAwayData = local_cart_data.filter(
      //     (val) => val.type !== "take-away-local"
      //   );
      //   localStorage.setItem(
      //     localStorageCartKeyName,
      //     JSON.stringify(removeTakeAwayData)
      //   );
      //   return 1;
      // }
    }
  } else {
    return 1;
  }
};

const checkDeliveryTodayYaNot = (allDeliverydata) => {
  if (
    allDeliverydata?.length > 0 &&
    allDeliverydata[allDeliverydata.length - 1]?.created_at
  ) {
    if (
      moment(
        moment(allDeliverydata[allDeliverydata.length - 1].created_at).format(
          "MM/DD/YYYY"
        )
      ).isSame(moment().format("MM/DD/YYYY"))
    ) {
      return allDeliverydata.length + 1;
    } else {
      // let local_cart_data = localStorage.getItem(localStorageCartKeyName);
      // if (local_cart_data == null) {
      //   return 1;
      // } else {
      //   local_cart_data = JSON.parse(local_cart_data);
      //   let removeDeliverydata = local_cart_data.filter(
      //     (val) => val.type !== "delivery-local"
      //   );

      //   localStorage.setItem(
      //     localStorageCartKeyName,
      //     JSON.stringify(removeDeliverydata)
      //   );
      //   return 1;
      // }
      return 1;
    }
  } else {
    return 1;
  }
};

const swaptable = (key, data, registerDetails, roomDetails) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    return {};
  }
  local_cart_data = JSON.parse(local_cart_data);

  const findIndex = local_cart_data?.findIndex(
    (item) => item.cartKey === key && item.register_id === registerDetails._id
  );
  if (findIndex !== -1) {
    if (
      getItem("hotel_enable") &&
      registerDetails &&
      registerDetails.hotelDetalis &&
      registerDetails.hotelDetalis.rooms &&
      data.tableName
    ) {
      let findTableIsHotelRoom = registerDetails.hotelDetalis.rooms.find(
        (j) => data.tableName == `${j.roomType} ${j.number}`
      );
      console.log("Sagartamnkaknksnakdadadasd", roomDetails)
      if (findTableIsHotelRoom && roomDetails) {
        data["RoomId"] = findTableIsHotelRoom.uuid;
        data["hotelID"] = findTableIsHotelRoom.hotelID;
        data["reservation_id"] = findTableIsHotelRoom.reservation_id;
      } else if (roomDetails) {
        data["RoomId"] = roomDetails.uuid;
        data["hotelID"] = roomDetails.hotelID;
        data["reservation_id"] = roomDetails.reservation_id;
      } else {
        if (data.RoomId) {
          data["RoomId"] = false;
        }
      }
    }
    if ((getItem('userDetails')?.business_type == "hotel_management" &&
      registerDetails?.allow_hotel) && roomDetails) {
      data["RoomId"] = roomDetails.uuid;
      data["hotelID"] = roomDetails.hotelID;
      data["reservation_id"] = roomDetails.reservation_id;

    }
    local_cart_data[findIndex] = data;
  }

  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  let obj = {
    default_cart_object: local_cart_data[findIndex],
    allLocalData: local_cart_data,
  };
  return obj;
};

const getDeleveryNumber = () => {
  let deleveryNumber;
  if (getItem("previousDeliveryNumber") != null) {
    let Details = getItem("previousDeliveryNumber");
    if (moment(moment(Details.date).format("L")).isSame(moment().format("L"))) {
      if (
        dateCompare(moment(Details.date).format("HH:mm:ss"), "06:00:00") ==
        -1 &&
        dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0
      ) {
        deleveryNumber = 1;
        setItem("previousDeliveryNumber", {
          date: new Date(),
          number: 1,
        });
      } else {
        deleveryNumber = 1 + Details.number;
        setItem("previousDeliveryNumber", {
          date: new Date(),
          number: 1 + Details.number,
        });
      }
    } else {
      if (dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0) {
        deleveryNumber = 1;
        setItem("previousDeliveryNumber", {
          date: new Date(),
          number: 1,
        });
      } else {
        deleveryNumber = 1 + Details.number;
        setItem("previousDeliveryNumber", {
          date: new Date(),
          number: 1 + Details.number,
        });
      }
    }
  } else {
    deleveryNumber = 1;
    setItem("previousDeliveryNumber", {
      date: new Date(),
      number: 1,
    });
  }
  return deleveryNumber;
};

function dateCompare(time1, time2) {
  var t1 = new Date();
  var parts = time1.split(":");
  t1.setHours(parts[0], parts[1], parts[2], 0);
  var t2 = new Date();
  parts = time2.split(":");
  t2.setHours(parts[0], parts[1], parts[2], 0);

  // returns 1 if greater, -1 if less and 0 if the same
  if (t1.getTime() > t2.getTime()) return 1;
  if (t1.getTime() < t2.getTime()) return -1;
  return 0;
}

const getTakeAwayNumber = () => {
  let takeAwayNumber;
  if (getItem("previousTakeAwayNumber") != null) {
    let Details = getItem("previousTakeAwayNumber");
    if (moment(moment(Details.date).format("L")).isSame(moment().format("L"))) {
      if (
        dateCompare(moment(Details.date).format("HH:mm:ss"), "06:00:00") ==
        -1 &&
        dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0
      ) {
        takeAwayNumber = 1;
        setItem("previousTakeAwayNumber", {
          date: new Date(),
          number: 1,
        });
      } else {
        takeAwayNumber = 1 + Details.number;
        setItem("previousTakeAwayNumber", {
          date: new Date(),
          number: 1 + Details.number,
        });
      }
    } else {
      if (dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0) {
        takeAwayNumber = 1;
        setItem("previousTakeAwayNumber", {
          date: new Date(),
          number: 1,
        });
      } else {
        takeAwayNumber = 1 + Details.number;
        setItem("previousTakeAwayNumber", {
          date: new Date(),
          number: 1 + Details.number,
        });
      }
    }
  } else {
    takeAwayNumber = 1;
    setItem("previousTakeAwayNumber", {
      date: new Date(),
      number: 1,
    });
  }
  return takeAwayNumber;
};

const createOnlineTakeAway = (data, registerDetails, record) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    local_cart_data = [];
  } else {
    local_cart_data = JSON.parse(local_cart_data);
  }
  let cartKey =
    Math.floor(Math.random() * 1000000000) + "_time_" + new Date().getTime();
  let RegisterId = registerDetails?._id;
  let takwAwayNumber = getTakeAwayNumber();

  let default_cart_object = {
    Status: "In Progress",
    cartKey: cartKey,
    created_at: new Date(),
    data: data,
    register_id: RegisterId,
    onlineOrder: record,
    tableName: "TakeAway " + takwAwayNumber,
    tablekey: "TakeAway " + takwAwayNumber,
    type: "take-away-local",
    onlineOrderType: "Accept",
    otherDetails: {
      bulkDiscountDetails: {
        type: "FLAT",
        value: record.disconut,
        bulkValue: record.disconut,
      },
    },
  };

  if (registerDetails?.table_numbers != "") {
    localStorage.setItem("active_cart", cartKey);
  }
  local_cart_data.push(default_cart_object);
  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return default_cart_object;
};

const acceptOnlineOrderList = () => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    local_cart_data = [];
  } else {
    local_cart_data = JSON.parse(local_cart_data);
  }
  let filterAccept = local_cart_data.filter(
    (val) => val.onlineOrderType && val.onlineOrderType == "Accept"
  );
  return filterAccept.reverse();
};

// online Qrcode
const createOnlineQRorder = (
  data,
  registerDetails,
  tableName,
  orderType,
  hotelRoomdetails
) => {
  let local_cart_data = localStorage.getItem(localStorageCartKeyName);
  if (local_cart_data == null) {
    local_cart_data = [];
  } else {
    local_cart_data = JSON.parse(local_cart_data);
  }
  let cartKey =
    Math.floor(Math.random() * 1000000000) + "_time_" + new Date().getTime();
  let RegisterId = registerDetails?._id;
  let table_name = tableName;
  let table_key = tableName.replace(/\s+/g, "-").toLowerCase();
  let order_type = "custom-table-local";
  if (orderType == "delivery") {
    order_type = "delivery-local";
    table_name = "Delivery " + getDeleveryNumber();
    table_key = table_name;
  } else if (orderType == "takeaway") {
    order_type = "take-away-local";
    table_name = "TakeAway " + getTakeAwayNumber();
    table_key = table_name;
  }
  let default_cart_object = {
    Status: "In Progress",
    cartKey: cartKey,
    created_at: new Date(),
    data: data,
    register_id: RegisterId,
    tableName: table_name,
    tablekey: table_key,
    type: order_type,
  };
  if (hotelRoomdetails) {
    default_cart_object["RoomId"] = hotelRoomdetails.uuid;
    default_cart_object["hotelID"] = hotelRoomdetails.hotelID;
    default_cart_object["reservation_id"] = hotelRoomdetails.reservation_id;
  }
  if (registerDetails?.table_numbers != "") {
    localStorage.setItem("active_cart", cartKey);
  }
  local_cart_data.push(default_cart_object);
  localStorage.setItem(
    localStorageCartKeyName,
    JSON.stringify(local_cart_data)
  );
  return default_cart_object;
};

export {
  getItem,
  setItem,
  removeItem,
  createNewCartwithKeyandPush,
  getAllTakeAwayDataInLocal,
  getLocalCartCount,
  getCartInfoFromLocalKey,
  setCartInfoFromLocalKey,
  getTableStatusFromId,
  getCartInfoLocalListsData,
  updateTableNameFromCartId,
  removeCartFromLocalStorage,
  checkIfTableIsSelected,
  checkIfTableIsSelectedByCartkey,
  getAllDeliveryDataInLocal,
  storeOtherData,
  setOrderTickets,
  tableStatusChange,
  getallCustomSwapList,
  darftCount,
  getTableNameTo,
  AddLastSplitName,
  getallCustomSplitList,
  getTotalOfUnpaid,
  bookingAddInLocalData,
  checkTakeAwayTodayYaNot,
  checkDeliveryTodayYaNot,
  swaptable,
  createOnlineTakeAway,
  acceptOnlineOrderList,
  createOnlineQRorder,
  getIndexdbProductList,
  getIndexdbAddonGroupList,
  getIndexdbAddonList,
  getIndexdbVarintGroupList,
  getIndexdbVarintList,
  addVariantListInIndexdb,
  addVariantGroupListInIndexdb,
  addProductAddonListInIndexdb,
  addProductAddonGroupListInIndexdb,
};
