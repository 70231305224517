import React, { useState, useRef, useEffect } from "react";
import { Table, Input, Modal, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import { Popover } from "../../../../../components/popup/popup";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../../../../components/page-headers/page-headers";
import "../../setting.css";
import { UserTableStyleWrapper } from "../../../../pages/style";
import {
  SearchOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

import {
  getAllInventoryList,
  deleteInventory,
  getAllPosProductsList,
} from "../../../../../redux/inventory/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import { getAllRegisterList } from "../../../../../redux/register/actionCreator";
import { Spin } from "antd";
import { TableWrapper, Main } from "../../../../styled";
import { CardToolbox } from "../../../../Customer/Style";
import { getItem, setItem } from "../../../../../utility/localStorageControl";
import { getAllProductList } from "../../../../../redux/products/actionCreator";
const Inventory = () => {
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let [search, setsearch] = useState("");
  let isMounted = useRef(true);
  const [DynoList, setDynoList] = useState([]);
  const [RegisterList, setRegisterList] = useState([]);

  const [selectionType] = useState("checkbox");
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  const offLineMode = useSelector((state) => state.auth.offlineMode);

  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  // console.log("windowWidth:::---inventory---->>>>>>>>>>::", getItem("windowWidth"))
  const createPosItems = (list) => {
    let finalList = [];
    list.map((val) => {
      if (val.option_variant_group.length > 0) {
        val.option_variant_group.map((j) => {
          j.product_variants.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          );
        });
        val.option_variant_group.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        );
        let newArray = [];
        let n = val.option_variant_group.length;
        let indices = new Array(n);

        for (let i = 0; i < n; i++) {
          indices[i] = 0;
        }

        let loop = true;

        while (loop == true) {
          let variantArray = [];
          let title = val.product_name;
          let id = val._id;
          let variantTitle = "";
          let variantIds = "";
          for (let i = 0; i < n; i++) {
            variantTitle =
              variantTitle +
              " / " +
              val.option_variant_group[i]["product_variants"][indices[i]]
                .variant_name;
            variantIds =
              variantIds +
              "--varient-" +
              val.option_variant_group[i]["product_variants"][indices[i]]._id;
            variantArray.push(
              val.option_variant_group[i]["product_variants"][indices[i]]
            );
          }
          title = title + variantTitle;
          id = id + variantIds;

          finalList.push({
            category: val.product_category.category_name,
            product_id: id,
            isNotTracked: true,
            isSellable: true,
            product_name: title,
            limit_to_register: val.limit_to_register,
            unit_of_measure: val.unit_of_measure,
          });

          let next = n - 1;
          while (
            next >= 0 &&
            indices[next] + 1 >=
              val.option_variant_group[next]["product_variants"].length
          ) {
            next--;
          }

          if (next < 0) {
            loop = false;
          }

          indices[next]++;

          for (let i = next + 1; i < n; i++) {
            indices[i] = 0;
          }
        }
      } else {
        finalList.push({
          category: val.product_category.category_name,
          product_id: val._id,
          isNotTracked: true,
          isSellable: true,
          product_name: val.product_name,
          limit_to_register: val.limit_to_register,
          unit_of_measure: val.unit_of_measure,
        });
      }
    });
    return finalList;
  };
  async function fetchRegisterList() {
    const getRegisterList = await dispatch(getAllRegisterList("sell"));
    if (isMounted.current && getRegisterList && getRegisterList.RegisterList)
      setRegisterList(getRegisterList.RegisterList);
  }

  async function fetchInventoryList(val) {
    setLoader(true);
    const getInventoryList = await dispatch(getAllInventoryList(val));
    // console.log("checkadadadad", getInventoryList);

    if (isMounted.current && getInventoryList && getInventoryList.taxesList) {
      setLoader(false);
      let userDetails = getItem("userDetails");
      if (userDetails?.role == "cashier") {
        setDynoList(
          getInventoryList.taxesList.filter(
            (k) =>
              k.user_access &&
              k.user_access.includes(userDetails?.restaurant_user_id)
          )
        );
      } else {
        setDynoList(getInventoryList.taxesList);
      }
    }
  }
  const [posProductList, setPosProductList] = useState([]);
  async function fetchPosProductList() {
    let Productresponse = await dispatch(getAllProductList("sell"));
    let posItems = createPosItems(Productresponse.productList);
    if (posItems && posItems.length) {
      setPosProductList(posItems);
    }
  }
  useEffect(() => {
    if (isMounted.current) {
      fetchPosProductList();
      fetchInventoryList();
      fetchRegisterList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [state, setState] = useState({
    item: DynoList,
  });
  const { selectedRowKeys, item } = state;
  useEffect(() => {
    if (DynoList) {
      setState({
        item: DynoList,
        selectedRowKeys,
      });
    }
  }, [DynoList, selectedRowKeys]);

  const deleteSelectedRegister = async () => {
    setLoading(true);
    const { allSelectedRowsForDelete } = state;
    let allRegisterIdsForDelete = [];
    allSelectedRowsForDelete.map((item) => {
      allRegisterIdsForDelete.push(item.id);
    });
    const getDeletedBingages = await dispatch(
      deleteInventory({ ids: allRegisterIdsForDelete })
    );
    if (getDeletedBingages && !getDeletedBingages.error) {
      fetchInventoryList(undefined);
      setModelDeleteVisible(false);
    }
  };
  const [offLineModeCheck, setOfflineModeCheck] = useState(false);

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setModelDeleteVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Delete Selected item</span>
      </NavLink>
    </>
  );

  const dataSource = [];
  // console.log("DynoList2323232232323232", DynoList);
  let searchArrTaxes = DynoList?.filter((value) =>
    value.inventory_name?.toLowerCase().includes(search.toLowerCase())
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForDelete: selectedRows,
      });
    },
  };

  const handleCancel = (e) => {
    setModelDeleteVisible(false);
  };
  // console.log("searchArrTaxessearchArrTaxes", searchArrTaxes);
  if (DynoList.length)
    searchArrTaxes.map((value) => {
      const { _id, inventory_name, description, linked_registers } = value;
      return dataSource.push({
        id: _id,
        inventory_name: inventory_name,
        description: description,
        linked_registersName: linked_registers.map((j) => {
          return RegisterList.find((val) => val._id == j)?.register_name;
        }),
        ...value,
      });
    });

  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined style={{ marginLeft: "12px" }} />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      width: "2%",
    },
    {
      title: "Name",
      dataIndex: "inventory_name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Linked Registers",
      dataIndex: "linked_registersName",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: <div>{text.toString()}</div>,
        };
      },
    },

    {
      title: "View Inventory",
      dataIndex: "Items",
      key: "Items",
      align: "left",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: (
            <div>
              <ArrowRightOutlined
                onClick={(e) => {
                  if (offLineMode) {
                    setOfflineModeCheck(true);
                  } else {
                    e.stopPropagation();

                    let Products_list = [];
                    if (record?.linked_registers?.length > 0) {
                      Products_list = posProductList.filter((data, i) => {
                        if (data?.limit_to_register?.length > 0) {
                          if (
                            data?.limit_to_register.includes(
                              record?.linked_registers[0]
                            )
                          ) {
                            return data;
                          }
                        } else {
                          return data;
                        }
                      });
                    } else {
                      Products_list = posProductList.filter((data, i) => {
                        if (data?.limit_to_register?.length > 0) {
                          if (
                            data?.limit_to_register.includes(
                              currentRegisterData?._id
                            )
                          ) {
                            return data;
                          }
                        } else {
                          return data;
                        }
                      });
                    }

                    history.push(`/inventory/itemList`, {
                      inventoryData: {
                        ...record,
                        posProductsList: Products_list,
                      },
                    });
                  }
                }}
              />
            </div>
          ),
        };
      },
    },
  ];
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  return (
    <div>
      <CardToolbox>
        <PageHeader
          ghost
          className="comman-other-custom-pageheader receipts-top0"
          subTitle={
            <>
              <div className="table_titles">
                <h2>Inventory</h2>
                <span className="title-counter">
                  {DynoList?.length} Inventory
                </span>
              </div>
              <div
                style={{ boxShadow: "none", marginLeft: "10px" }}
                className="search_lrm"
              >
                <Input
                  suffix={<SearchOutlined />}
                  className="set_serbt"
                  autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                  placeholder="Search by inventory name"
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                  }}
                  onChange={(e) => setsearch(e.target.value)}
                  value={search}
                />
              </div>
            </>
          }
          buttons={[
            <>
              {/* <NavLink
                to={offLineMode ? "#" : "inventory/POSitems"}
                className="ant-btn ant-btn-primary ant-btn-md"
                style={{ marginRight: "8px" }}
                onClick={() =>
                  offLineMode
                    ? setOfflineModeCheck(true)
                    : setOfflineModeCheck(false)
                }
              >
                Manage Items
              </NavLink> */}
              <NavLink
                to={offLineMode ? "#" : "inventory/add"}
                className="ant-btn ant-btn-primary ant-btn-md"
                style={{ color: "#FFF", marginBottom: "10px" }}
                onClick={() =>
                  offLineMode
                    ? setOfflineModeCheck(true)
                    : setOfflineModeCheck(false)
                }
              >
                <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                Add Inventory
              </NavLink>
            </>,
          ]}
        />
      </CardToolbox>
      <Row gutter={15}>
        <Col span={24}>
          <Cards headless>
            <UserTableStyleWrapper>
              <div className="contact-table">
                <TableWrapper className="table-responsive">
                  {loader ? (
                    <Table
                      locale={locale}
                      rowKey="id"
                      dataSource={[]}
                      columns={columns}
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                      onRow={(row) => ({
                        onClick: () =>
                          offLineMode
                            ? setOfflineModeCheck(true)
                            : history.push(`inventory/add`, {
                                data: row,
                              }),
                      })}
                      size="small"
                      style={{ marginTop: "8px" }}
                    />
                  ) : (
                    <Table
                      rowKey="id"
                      dataSource={dataSource}
                      columns={columns}
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                      onRow={(row) => ({
                        onClick: () =>
                          offLineMode
                            ? setOfflineModeCheck(true)
                            : history.push(`inventory/add`, {
                                data: row,
                              }),
                      })}
                      size="small"
                      style={{ marginTop: "8px" }}
                      scroll={{ x: 600 }}
                    />
                  )}
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </Cards>
        </Col>
      </Row>
      <Modal
        title="Confirm Delete"
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 16,
                    color: "white",
                    margin: "0px 14px",
                  }}
                  spin
                />
              }
            />
          ) : (
            "Delete"
          )
        }
        visible={modalDeleteVisible}
        onOk={deleteSelectedRegister}
        onCancel={handleCancel}
        width={600}
      >
        <p>Are you sure you want to delete Inventory ?</p>
      </Modal>
      <Modal
        title="You are Offline"
        visible={offLineModeCheck}
        onOk={() => setOfflineModeCheck(false)}
        onCancel={() => setOfflineModeCheck(false)}
        width={600}
      >
        <p>You are offline not add and update </p>
      </Modal>
    </div>
  );
};

export default Inventory;
