import React from "react";
import { Bar, HorizontalBar, Line, Pie, Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";
import useChartData from "../../hooks/useChartData";
import { customTooltips } from "../utilities/utilities";
import { ChartContainer } from "../../container/dashboard/style";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const ChartjsBarChart = (props) => {
  const { labels, datasets, options, height } = props;
  const data = {
    datasets,
    labels,
  };
  return <Bar data={data} height={height} options={options} />;
};

ChartjsBarChart.defaultProps = {
  height: 200,
  labels: ["11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00"],

  datasets: [
    {
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: "#001737",
    },
  ],

  options: {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "#e5e9f2",
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            fontColor: "#182b49",
            max: 80,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          barPercentage: 0.6,
          ticks: {
            beginAtZero: true,
            fontSize: 11,
            fontColor: "#182b49",
          },
        },
      ],
    },
  },
};

ChartjsBarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number,
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
};

const ChartjsHorizontalChart = (props) => {
  const { labels, datasets, options, height } = props;
  const data = {
    datasets,
    labels,
  };
  return <HorizontalBar data={data} height={height} options={options} />;
};

ChartjsHorizontalChart.defaultProps = {
  height: 200,
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: "#001737",
    },
    {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: "#1ce1ac",
    },
  ],

  options: {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            fontColor: "#182b49",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "#e5e9f2",
          },
          barPercentage: 0.6,
          ticks: {
            beginAtZero: true,
            fontSize: 11,
            fontColor: "#182b49",
            max: 100,
          },
        },
      ],
    },
  },
};

ChartjsHorizontalChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
};

const ChartjsStackedChart = (props) => {
  const { labels, datasets, options, height } = props;
  const data = {
    datasets,
    labels,
  };
  return <Bar data={data} height={height} options={options} />;
};

ChartjsStackedChart.defaultProps = {
  height: 200,
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  datasets: [
    {
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: "#001737",
    },
  ],

  options: {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            color: "#e5e9f2",
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            fontColor: "#182b49",
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          barPercentage: 0.6,
          ticks: {
            beginAtZero: true,
            fontSize: 11,
            fontColor: "#182b49",
          },
        },
      ],
    },
  },
};

ChartjsStackedChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
};

const ChartjsLineChart = (props) => {
  const { labels, datasets, options, height, layout, width, id } = props;
  const data = {
    labels,
    datasets,
  };
  return (
    <ChartContainer className="parentContainer">
      <Line
        id={id && id}
        width={width}
        data={data}
        height={height}
        options={{
          ...options,
          ...layout,
        }}
      />
    </ChartContainer>
  );
};

ChartjsLineChart.defaultProps = {
  height: 479,
  width: null,
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      borderColor: "#001737",
      borderWidth: 1,
      fill: false,
    },
    {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      borderColor: "#1ce1ac",
      borderWidth: 1,
      fill: false,
    },
  ],

  options: {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: "-10",
        right: 0,
        top: 0,
        bottom: "-10",
      },
    },
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
  },
};

ChartjsLineChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  layout: PropTypes.object,
  width: PropTypes.number,
  options: PropTypes.object,
  id: PropTypes.string,
};

const ChartjsAreaChart = (props) => {
  const { labels, datasets, options, height, layout, id } = props;

  const data = {
    labels,
    datasets,
  };
  return (
    <div>
      <ChartContainer className="parentContainer">
        <Line
          id={id}
          data={data}
          height={height}
          options={{
            tooltips: {
              mode: "nearest",
              intersect: false,
              enabled: false,
              custom: customTooltips,
              callbacks: {
                labelColor(tooltipItem, chart) {
                  return {
                    backgroundColor: datasets.map((item) => item.borderColor),
                    borderColor: "transparent",
                  };
                },
              },
            },
            ...options,
            ...layout,
          }}
        />
      </ChartContainer>
    </div>
  );
};

ChartjsAreaChart.defaultProps = {
  height: 250,
  labels: [
    "11:00",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      borderColor: "#001737",
      borderWidth: 1,
      fill: true,
      backgroundColor: "#00173750",
      pointHoverBorderColor: "transparent",
    },
    {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      borderColor: "#1ce1ac",
      borderWidth: 1,
      fill: true,
      backgroundColor: "#1ce1ac50",
      pointHoverBorderColor: "transparent",
    },
  ],
  options: {
    maintainAspectRatio: true,
    hover: {
      mode: "nearest",
      intersect: false,
    },

    layout: {
      padding: {
        left: -10,
        right: 0,
        top: 2,
        bottom: -10,
      },
    },
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
            color: "#e5e9f2",
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            display: false,
            stepSize: 20,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },

          ticks: {
            beginAtZero: true,
            fontSize: 11,
            display: false,
          },
        },
      ],
    },
  },
};

ChartjsAreaChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  layout: PropTypes.object,
  options: PropTypes.object,
  id: PropTypes.string,
};

const ChartjsBarChartTransparent = (props) => {
  let {
    labels,
    datasets,
    chartData,
    scale,
    options,
    height,
    layout,
    module,
  } = props;
  const modifiedScale = {
    ...scale,
    xAxes: [
      {
        ticks: {
          autoSkip: false,
          maxRotation: module == "Product" ? 25 : 50,
          minRotation: module == "Product" ? 25 : 50,
          callback: function(value) {
            const screenWidth = window.innerWidth;
            return screenWidth < 600 ? value : value;
          },
          fontSize: window.innerWidth < 600 ? 8 : 12,
        },
      },
    ],
  };
  let options1 = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: props.displayLegend,
      position: props.legendPosition,
      labels: {
        fontColor: "#000",
      },
    },
    scales: modifiedScale,
    tooltips: {
      backgroundColor: "#f5f5f5",
      bodyFontColor: "black",
      titleColor: "black",
      fontColor: "black",
      displayColors: 0,
      titleAlign: "center",
      bodyAlign: "center",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      callbacks: {
        title: (toolTipItem, data) => {
          const { yLabel, xLabel } = toolTipItem[0].y; // uses the x value of this point as the title
          return xLabel;
        },
        label(t, d) {
          const { yLabel, xLabel } = t;
          const sales =
            module == "Payment"
              ? `Payment = ${Number(yLabel).toFixed(2)}`
              : `Sales = ${Number(yLabel).toFixed(2)}`;
          const time = xLabel;
          return [sales, time];
        },
      },
    },
  };
  if (module == "Product") {
    options1 = {
      ...options1,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      animation: {
        onComplete: function() {
          const chart = this.chart;
          const ctx = chart.ctx;
          ctx.font =
            window.innerWidth < 600 ? "bold 8px Arial" : "bold 12px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";

          chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i);
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              const position = bar.tooltipPosition();
              ctx.fillText(data, position.x, position.y - 5);
            });
          });
        },
      },
      events: [],
    };
  }
  return (
    <ChartContainer className="parentContainer">
      <Bar
        type="bar"
        data={chartData}
        height={height}
        width={"70%"}
        options={options1}
      />
    </ChartContainer>
  );
};

ChartjsBarChartTransparent.defaultProps = {
  height: 176,
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: "rgba(0,23,55, .5)",
      label: "Profit",
    },
    {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: "rgba(28,225,172, .5)",
      label: "Lose",
    },
  ],

  options: {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: true,
      position: "bottom",
      align: "start",
      labels: {
        boxWidth: 6,
        display: true,
        usePointStyle: true,
      },
    },
    layout: {
      padding: {
        left: "0",
        right: 0,
        top: 0,
        bottom: "0",
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "#e5e9f2",
          },
          ticks: {
            beginAtZero: true,
            fontSize: 13,
            fontColor: "#182b49",
            max: 80,
            stepSize: 20,
            callback(value, index, values) {
              return `${value}k`;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          barPercentage: 0.6,
          ticks: {
            beginAtZero: true,
            fontSize: 13,
            fontColor: "#182b49",
          },
        },
      ],
    },
  },
};

ChartjsBarChartTransparent.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
  layout: PropTypes.object,
};

const ChartjsBarChartGrad = (props) => {
  const { labels, datasets, options, height, layout } = props;
  const data = {
    labels,
    datasets,
  };
  return (
    <>
      <Bar data={data} options={{ ...options, ...layout }} height={height} />
    </>
  );
};

ChartjsBarChartGrad.defaultProps = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: "rgba(0,23,55, .5)",
    },
    {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: "rgba(28,225,172, .5)",
    },
  ],

  options: {
    maintainAspectRatio: true,
    responsive: true,
    layout: {
      padding: {
        left: "0",
        right: 0,
        top: 0,
        bottom: "0",
      },
    },
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "#e5e9f2",
          },
          ticks: {
            beginAtZero: true,
            fontSize: 10,
            fontColor: "#182b49",
            max: 80,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          barPercentage: 0.6,
          ticks: {
            beginAtZero: true,
            fontSize: 11,
            fontColor: "#182b49",
          },
        },
      ],
    },
  },
};

ChartjsBarChartGrad.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
  layout: PropTypes.object,
};

const ChartjsPieChart = (props) => {
  const { labels, datasets, options, height } = props;
  const data = {
    labels,
    datasets,
  };
  return <Pie data={data} height={height} options={options} />;
};

ChartjsPieChart.defaultProps = {
  height: 200,
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      data: [20, 20, 30, 5, 25],
      backgroundColor: ["#560bd0", "#007bff", "#00cccc", "#cbe0e3", "#74de00"],
    },
  ],

  options: {
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          const currentValue = dataset.data[tooltipItem.index];
          const percentage = Math.round((currentValue / total) * 100);
          return percentage + "%";
        },
      },
    },
    legend: {
      display: true,
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  },
};

ChartjsPieChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
};
/**
 *
 *  {const labels = chart.data.labels.reduce((prev, curent, i) => {
        return `${prev}<li><span class="doughnutLabelColor" style="background-color:${chart.data.datasets[0].backgroundColor[i]}"></span><span class="doughnutLabe">${curent}</span></li>`;
      }, '');
      const generatedLegend = `<ul class="${chart.id}-legend">${labels}</ul>`;

      return generatedLegend;} props
 */

const ChartjsDonutChart = (props) => {
  const { labels, datasets, options, height, totalRs, module } = props;
  const { ref } = useChartData();
  const data = {
    labels,
    datasets,
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>{totalRs}</span>
        <br />
        {module == "Payment" ? "Total Revenue" : "Total Sales"}
      </div>
      <Doughnut ref={ref} data={data} options={options} height={height} />
    </div>
  );
};

ChartjsDonutChart.defaultProps = {
  height: 200,
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      data: [20, 20, 30, 5, 25],
      backgroundColor: ["#560bd0", "#007bff", "#00cccc", "#cbe0e3", "#74de00"],
    },
  ],

  options: {
    cutoutPercentage: 70,
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      display: false,
      position: "bottom",
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          );
          const currentValue = dataset.data[tooltipItem.index];
          const percentage = ((currentValue / total) * 100).toFixed(2); // Round to two decimal places
          return `${data.labels[tooltipItem.index]}: ${percentage}%`;
        },
      },
    },
    plugins: {
      datalabels: {
        color: "#fff", // Set the color of the labels, make sure it's visible against the chart background
        font: {
          weight: "bold", // Makes the labels bold
        },
        formatter: (value, ctx) => {
          return value; // This will display the raw value; you can format it as needed
        },
        anchor: "center", // Anchors the labels in the center of each piece of the doughnut
        align: "center", // Aligns text centrally relative to 'anchor' point
        display: "auto", // You can set specific conditions for displaying labels
      },
    },
  },
};

ChartjsDonutChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
};

const ChartjsDonutChart2 = (props) => {
  const { labels, datasets, options, height } = props;
  const { ref } = useChartData();
  const dataInfo = {
    labels,
    datasets,
  };

  return (
    <div>
      <Doughnut
        ref={ref}
        data={dataInfo}
        height={height}
        options={options}
        width={200}
      />

      <div className="align-center-v justify-content-between rd-labels">
        <div className="revenue-chat-label">
          {labels.map((label, key) => {
            return (
              <div key={key + 1} className="chart-label">
                <span className={`label-dot dot-${label}`} />
                {label}
              </div>
            );
          })}
        </div>
        <div className="revenue-chart-data">
          {datasets.map((item, key) => {
            const { data } = item;
            return (
              <div key={key + 1}>
                {data.map((value) => {
                  return (
                    <p key={value}>
                      <strong>${value}</strong>
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="revenue-chat-percentage">
          <span>45%</span>
          <span>45%</span>
          <span>45%</span>
        </div>
      </div>
    </div>
  );
};

ChartjsDonutChart2.defaultProps = {
  height: 220,
  // width: 220,
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      data: [20, 20, 30, 5, 25],
      backgroundColor: ["#560bd0", "#007bff", "#00cccc", "#cbe0e3", "#74de00"],
    },
  ],

  options: {
    cutoutPercentage: 60,
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      display: false,
      position: "bottom",
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  },
};

ChartjsDonutChart2.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
};

export {
  ChartjsDonutChart,
  ChartjsDonutChart2,
  ChartjsPieChart,
  ChartjsBarChartGrad,
  ChartjsBarChartTransparent,
  ChartjsAreaChart,
  ChartjsLineChart,
  ChartjsStackedChart,
  ChartjsHorizontalChart,
  ChartjsBarChart,
};
