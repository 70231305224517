import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Input,
  Form,
  Card,
  Button,
  Modal,
  Tooltip,
  Spin,
  Table,
  Divider,
  message,
  Select,
} from "antd";
import "./style.css";
import { Main } from "../styled";
import { PricingCard, ListGroup, Badge } from "../Sell/Style";
import { getItem } from "../../utility/localStorageControl";
import { List } from "./subscriptionCardList/List";
import Heading from "./heading/heading";
import {
  getLastDevice,
  getLastReceipt,
  createCheckout,
  saveCurrentDevice,
  transactionList,
} from "../../redux/sell/actionCreator";
import { useDispatch } from "react-redux";
import { logOutTransaction } from "../../redux/authentication/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";

function SubscriptionCards(props) {
  const { dataSource, currentRegisterData } = props;
  const loader = props?.loading;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let localData = getItem("setupCache");
  let registerData = currentRegisterData;
  const [clickCurrentPlanName, setClickCurrentPlanNAme] = useState();
  const [rsSymbol, setRsSymbol] = useState(
    localData?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)?.length > 0
        ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)[1]
        : localData.shopDetails.rs_symbol
      : "₹"
  );
  const [planList, setPlanList] = useState([
    {
      _id: "price_1PV50ESGLUrWaacTU80aLVSR",
      name: "RESTAURANT STARTER",
      isActive: false,
      type: "pos",
    },
    {
      _id: "price_1PV52DSGLUrWaacTX2GxPVcc",
      name: "RESTAURANT PRO",
      isActive: false,
      type: "pos",
    },
    {
      _id: "price_1PV54bSGLUrWaacTZMOz5Qqf",
      name: "HOTEL PRO",
      isActive: false,
      type: "pms",
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [planName, setPlanName] = useState();
  // const [planName,setPlanName]=useState()

  let currentPlan = "No Plan";
  let find = planList.find(
    (val) => val._id == registerData.subscriptionPriceId
  );
  if (find) {
    currentPlan = find.name;
  } else if (
    registerData.subscriptionPriceId == "price_1NqJEFSGLUrWaacTwnAeFj1T"
  ) {
    currentPlan = "RESTAURANT PRO";
  }
  const [restaurantProBuutonText, setbuttonText] = useState(
    rsSymbol != "₹"
      ? "Contact Support"
      : registerData.subscription_status == "subscribed" &&
        (registerData?.subscriptionPriceId ==
          "price_1PV52DSGLUrWaacTX2GxPVcc" ||
          registerData?.subscriptionPriceId == "price_1NqJEFSGLUrWaacTwnAeFj1T")
      ? "Cancel Subscription"
      : registerData.subscription_status == "unsubscribed" &&
        (registerData?.subscriptionPriceId ==
          "price_1PV52DSGLUrWaacTX2GxPVcc" ||
          registerData?.subscriptionPriceId ==
            "price_1NqJEFSGLUrWaacTwnAeFj1T") &&
        registerData.subscription_renew_link &&
        registerData.subscription_renew_link != "" &&
        registerData.paymentType == "stripe"
      ? "Subscribe"
      : "Subscribe"
  );
  const [
    restaurantStarterBuutonText,
    setRestaurantStarterBuutonText,
  ] = useState(
    rsSymbol != "₹"
      ? "Contact Support"
      : registerData.subscription_status == "subscribed" &&
        registerData?.subscriptionPriceId == "price_1PV50ESGLUrWaacTU80aLVSR"
      ? "Cancel Subscription"
      : registerData.subscription_status == "unsubscribed" &&
        registerData?.subscriptionPriceId == "price_1PV50ESGLUrWaacTU80aLVSR" &&
        registerData.subscription_renew_link &&
        registerData.subscription_renew_link != "" &&
        registerData.paymentType == "stripe"
      ? "Subscribe"
      : "Subscribe"
  );
  const [hotelProButtonText, setHotelProButtonText] = useState(
    rsSymbol != "₹"
      ? "Contact Support"
      : registerData.subscription_status == "subscribed" &&
        registerData?.subscriptionPriceId == "price_1PV54bSGLUrWaacTZMOz5Qqf"
      ? "Cancel Subscription"
      : registerData.subscription_status == "unsubscribed" &&
        registerData?.subscriptionPriceId == "price_1PV54bSGLUrWaacTZMOz5Qqf" &&
        registerData.subscription_renew_link &&
        registerData.subscription_renew_link != "" &&
        registerData.paymentType == "stripe"
      ? "Subscribe"
      : "Subscribe"
  );
  // subscriptionPriceId;
  useEffect(() => {
    if (registerData) {
      setHotelProButtonText(
        rsSymbol != "₹"
          ? "Contact Support"
          : registerData.subscription_status == "subscribed" &&
            registerData?.subscriptionPriceId ==
              "price_1PV54bSGLUrWaacTZMOz5Qqf"
          ? "Cancel Subscription"
          : registerData.subscription_status == "unsubscribed" &&
            registerData?.subscriptionPriceId ==
              "price_1PV54bSGLUrWaacTZMOz5Qqf" &&
            registerData.subscription_renew_link &&
            registerData.subscription_renew_link != "" &&
            registerData.paymentType == "stripe"
          ? "Subscribe"
          : "Subscribe"
      );
      setRestaurantStarterBuutonText(
        rsSymbol != "₹"
          ? "Contact Support"
          : registerData.subscription_status == "subscribed" &&
            registerData?.subscriptionPriceId ==
              "price_1PV50ESGLUrWaacTU80aLVSR"
          ? "Cancel Subscription"
          : registerData.subscription_status == "unsubscribed" &&
            registerData?.subscriptionPriceId ==
              "price_1PV50ESGLUrWaacTU80aLVSR" &&
            registerData.subscription_renew_link &&
            registerData.subscription_renew_link != "" &&
            registerData.paymentType == "stripe"
          ? "Subscribe"
          : "Subscribe"
      );
      setbuttonText(
        rsSymbol != "₹"
          ? "Contact Support"
          : registerData.subscription_status == "subscribed" &&
            (registerData?.subscriptionPriceId ==
              "price_1PV52DSGLUrWaacTX2GxPVcc" ||
              registerData?.subscriptionPriceId ==
                "price_1NqJEFSGLUrWaacTwnAeFj1T")
          ? "Cancel Subscription"
          : registerData.subscription_status == "unsubscribed" &&
            (registerData?.subscriptionPriceId ==
              "price_1PV52DSGLUrWaacTX2GxPVcc" ||
              registerData?.subscriptionPriceId ==
                "price_1NqJEFSGLUrWaacTwnAeFj1T") &&
            registerData.subscription_renew_link &&
            registerData.subscription_renew_link != "" &&
            registerData.paymentType == "stripe"
          ? "Subscribe"
          : "Subscribe"
      );
    }
  }, [registerData?._id]);
  const handleSubscription = async (nameOfPlan) => {
    setClickCurrentPlanNAme(nameOfPlan);
    setLoading(true);
    if (
      restaurantProBuutonText == "Renew" ||
      restaurantStarterBuutonText == "Renew" ||
      hotelProButtonText == "Renew"
    ) {
      var newWindow = window.open();
      newWindow.location.href = registerData.subscription_renew_link;
      setLoading(false);
      return true;
    }
    const checkout = await dispatch(createCheckout(registerData, nameOfPlan));
    if (checkout && checkout.url) {
      var newWindow = window.open();
      newWindow.location.href = checkout.url;
      setLoading(false);
    } else if (checkout && checkout.cancellation_details) {
      isModalOpen === true ? setIsModalOpen(false) : "";
      message.success({
        content: "Cancelled successfully",
        style: {
          float: "right",
          marginTop: "2vh",
        },
      });
      setLoading(false);
    }
  };

  const filterlist = dataSource.filter((val) => val && val.status == "paid");
  let date = "-";
  if (
    registerData &&
    registerData.subscription_status == "subscribed" &&
    filterlist &&
    filterlist.length
  ) {
    date = filterlist[0].expires_at;
  }

  const handleOk = () => {
    if (restaurantProBuutonText != "Contact Support") {
      if (planName) {
        handleSubscription(planName);
      }
    }
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };

  return (
    <div>
      <div className="current_planCard">
        <Card>
          <div className="planCardHeader">
            <h1>{currentPlan}</h1>
            <p>Current Plan</p>
          </div>

          <div className="detailBox">
            <div className="plan_detail">
              <p>Register</p>
              <h4>{registerData?.register_name}</h4>
            </div>
            <div className="plan_detail">
              <p>Next Invoice Date</p>
              <h4>{loader ? "Loading.." : date}</h4>
            </div>
          </div>
        </Card>
      </div>
      <div className="subscriptionCardBox">
        <Row gutter={25} justify="center">
          {/* <Col xxl={6} xl={9} lg={11} md={12} sm={18} xs={24}>
              <PricingCard
                style={{
                  marginBottom: 30,
                  minHeight: "665px",
                  maxHeight: "665px",
                  height: "auto",
                }}
              >
                <Badge className="pricing-badge" type="success">
                  POSEASE FREE FOREVER
                </Badge>
                <Heading className="price-amount" as="h3">
                  Free{" "}
                </Heading>
                <span className="package-user-type">
                  Our Promise For Forever Free
                </span>
                <ListGroup>
                  <List text="Unlimited Billing" />
                  <List text="Menu Management" />
                  <List text="Floor Plan & Table Management" />
                  <List text="Customer Relationship Management (CRM)" />
                  <List text="KOT Management (Multiple Printers)" />
                  <List text="Cashier & Managers" />
                  <List text="Dashboard" />
                </ListGroup>
              </PricingCard>
            </Col> */}
          {registerData?.type != "hotel" && (
            <Col xxl={6} xl={9} lg={11} md={12} sm={18} xs={24}>
              <PricingCard
                style={{
                  marginBottom: 30,
                  minHeight: "635px",
                  height: "auto",
                }}
              >
                <Badge className="pricing-badge" type="secondary">
                  RESTAURANT STARTER
                </Badge>
                <Heading className="price-amount" as="h3">
                  <sup className="currency">{rsSymbol == "₹" ? "₹" : ""}</sup>
                  {rsSymbol == "₹" ? "3600" : "120"}
                  <sub className="pricing-validity">
                    {rsSymbol == "₹"
                      ? " /year per outlet"
                      : " $/year per outlet"}
                  </sub>
                </Heading>
                <span className="package-user-type">Billed Annually</span>
                <br />
                <Button
                  size="default"
                  type="secondary"
                  onClick={() => {
                    if (restaurantStarterBuutonText == "Subscribe") {
                      handleSubscription("RESTAURANT STARTER");
                    } else if (
                      restaurantStarterBuutonText != "Contact Support"
                    ) {
                      setPlanName("RESTAURANT STARTER");
                      setIsModalOpen(true);
                    }
                  }}
                >
                  {loading && clickCurrentPlanName == "RESTAURANT STARTER" ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            color: "white",
                            margin: "0px 23px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <div>{restaurantStarterBuutonText}</div>
                  )}
                </Button>
                <Divider />
                <ListGroup>
                  {/* <p className="includesFree">Includes everything in Free</p> */}
                  <List text="Unlimited Billing" />
                  <List text="Menu Management" />
                  <List text="Floor Plan & Table Management" />
                  <List text="Customer Relationship Management (CRM)" />
                  <List text="KOT Management (Multiple Printers)" />
                  <List text="Cashier & Managers" />
                  <List text="Dashboard" />
                  <List text="Reporting & Analytics" />
                  <List text="Priority Support" />
                </ListGroup>
              </PricingCard>
            </Col>
          )}
          {registerData?.type != "hotel" && (
            <Col xxl={6} xl={9} lg={11} md={12} sm={18} xs={24}>
              <PricingCard
                style={{
                  marginBottom: 30,
                  minHeight: "635px",
                  height: "auto",
                }}
              >
                <Badge className="pricing-badge" type="secondary">
                  RESTAURANT PRO
                </Badge>
                <Heading className="price-amount" as="h3">
                  <sup className="currency">{rsSymbol == "₹" ? "₹" : ""}</sup>
                  {rsSymbol == "₹" ? "5000" : "200"}
                  <sub className="pricing-validity">
                    {rsSymbol == "₹"
                      ? " /year per outlet"
                      : " $/year per outlet"}
                  </sub>
                </Heading>
                <span className="package-user-type">Billed Annually</span>
                <br />
                <Button
                  size="default"
                  type="secondary"
                  onClick={() => {
                    if (restaurantProBuutonText == "Subscribe") {
                      handleSubscription("RESTAURANT PRO");
                    } else if (restaurantProBuutonText != "Contact Support") {
                      setPlanName("RESTAURANT PRO");
                      setIsModalOpen(true);
                    }
                  }}
                >
                  {loading && clickCurrentPlanName == "RESTAURANT PRO" ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            color: "white",
                            margin: "0px 23px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <div>{restaurantProBuutonText}</div>
                  )}
                </Button>
                <Divider />
                <ListGroup>
                  <p className="includesFree">
                    Includes everything in restaurant starter
                  </p>

                  <List text="Reservation" />
                  <List text="PriceBook" />
                  <List text="Petty Cash" />
                  <List text="Inventory & Recipe Management" />
                  <List text="QR Ordering" />
                  <List text="Waiter/Captain App" />
                  <List text="Vendor management" />
                  <List text="Expense management" />
                  <List text="Priority Support" />
                </ListGroup>
              </PricingCard>
            </Col>
          )}
          {registerData?.type == "hotel" && (
            <Col xxl={6} xl={9} lg={11} md={12} sm={18} xs={24}>
              <PricingCard
                style={{
                  marginBottom: 30,
                  minHeight: "635px",
                  height: "auto",
                }}
              >
                <Badge className="pricing-badge" type="secondary">
                  HOTEL PRO
                </Badge>
                <Heading className="price-amount" as="h3">
                  <sup className="currency">{rsSymbol == "₹" ? "₹" : ""}</sup>
                  {rsSymbol == "₹" ? "10,000" : "200"}
                  <sub className="pricing-validity">
                    {rsSymbol == "₹"
                      ? " /year per outlet"
                      : " $/year per outlet"}
                  </sub>
                </Heading>
                <span className="package-user-type">Billed Annually</span>
                <br />
                <Button
                  size="default"
                  type="secondary"
                  onClick={() => {
                    if (hotelProButtonText == "Subscribe") {
                      handleSubscription("HOTEL PRO");
                    } else if (hotelProButtonText != "Contact Support") {
                      setPlanName("HOTEL PRO");
                      setIsModalOpen(true);
                    }
                  }}
                >
                  {loading && clickCurrentPlanName == "HOTEL PRO" ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            color: "white",
                            margin: "0px 23px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <div>{hotelProButtonText}</div>
                  )}
                </Button>
                <Divider />
                <ListGroup>
                  {/* <p className="includesFree">Includes everything in Free</p> */}

                  <List text="Front-office management" />
                  <List text="Handle arrivals and departures" />
                  <List text="Group management" />
                  <List text="Walk-ins and reservation" />
                  <List text="Flexible rate management" />
                  <List text="Housekeeping" />
                  <List text="Tax management" />
                  <List text="Multiple payment method" />
                  <List text="User roles" />
                  <List text="Reporting and analytics" />
                  <List text="Priority Support" />
                </ListGroup>
              </PricingCard>
            </Col>
          )}
        </Row>
      </div>

      <Modal
        title="Subscription"
        open={isModalOpen}
        onOk={handleOk}
        centered={true}
        onCancel={handleCancel}
        cancelText="No"
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 16,
                    color: "white",
                    margin: "0px 23px",
                  }}
                  spin
                />
              }
            />
          ) : (
            "Yes"
          )
        }
      >
        {restaurantProBuutonText == "Cancel Subscription" ? (
          <p style={{ fontSize: "15px", fontWeight: 500 }}>
            Are you sure you want to cancel subscription for next year ?
          </p>
        ) : restaurantProBuutonText == "Renew" ? (
          <p style={{ fontSize: "15px", fontWeight: 500 }}>
            Are you sure you want to renew subscription for next year ?
          </p>
        ) : (
          restaurantProBuutonText == "Subscribe" && (
            <p style={{ fontSize: "15px", fontWeight: 500 }}>
              Are you sure you want to subscribe ?
            </p>
          )
        )}
      </Modal>
    </div>
  );
}

export default SubscriptionCards;
