import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { Row, Col, Form, Input, Select, Button, message } from "antd";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../styled";
import Heading from "../../../components/heading/heading";
import "../option.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  addOrUpdateVendorItem,
  getVendorItemCategoryList,
} from "../../../redux/vendor/actionCreator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
const Additems = (props) => {
  const [form] = Form.useForm();
  let [isDisabled, setDisabled] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [vendorId, setVendorId] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const getVendorListData = async () => {
    setFetchLoader(true);
    let response = await dispatch(getVendorItemCategoryList());
    if (response && !response.error) {
      setCategoryList(response.data);
      setFetchLoader(false);
    } else {
      setFetchLoader(false);
      message.error(response.message);
    }
  };

  useEffect(() => {
    getVendorListData();
  }, []);

  const handleSubmit = async (value) => {
    if (loading == false) {
      let dataSource = [
        {
          name: value.name,
          category: value.category,
          measure: value.measure,
          cost_price: value.cost_price,
          inventory_item_id:
            Date.now().toString(36) +
            Math.random()
              .toString(36)
              .substr(2),
        },
      ];
      if (value.items && value.items.length > 0) {
        dataSource = dataSource.concat(
          value.items.map((k) => {
            k["inventory_item_id"] =
              Date.now().toString(36) +
              Math.random()
                .toString(36)
                .substr(2);
            return k;
          })
        );
      }
      setLoading(true);
      let reponse = await dispatch(
        addOrUpdateVendorItem({ items: dataSource }, vendorId)
      );
      if (reponse && !reponse.error) {
        setLoading(false);
        history.push("/vendor/items");
      } else if (reponse && reponse.messageCode != "ALREADY_EXIST") {
        setLoading(false);
        message.error(reponse.message);
      } else {
        setLoading(false);
      }
    }
  };

  const handleFormChange = (item, allFileds) => {
    allFileds.splice(-1);
    if (
      allFileds
        .filter((k) => !k.name.includes("cost_price"))
        .find((val, index) => val.value == undefined || val.value == "")
    ) {
      setDisabled(true);
      return true;
    } else {
      setDisabled(false);
      return true;
    }
  };
  return (
    <>
      <Main className="padding-top-form">
        <br></br>
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Item Details</Heading>
            </div>
          }
        >
          {fetchLoader ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          ) : (
            <Form
              autoComplete="off"
              size="large"
              onFinish={handleSubmit}
              form={form}
              onFieldsChange={(val, allFileds) =>
                handleFormChange(val, allFileds)
              }
            >
              <Row>
                <Col xs={24} xl={6} className="gutter-box">
                  <Form.Item
                    label="Item Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Item name is Required.",
                      },
                    ]}
                  >
                    <Input
                      className="input-text"
                      type="text"
                      style={{ margin: "8px 0 0" }}
                      placeholder="Item Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={6} className="gutter-box">
                  <Form.Item
                    label="Category"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Category is Required.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      className="custom_select4545"
                      placeholder={
                        <span style={{ fontSize: "15px" }}>
                          Search Category
                        </span>
                      }
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {categoryList?.map((val) => {
                        return (
                          <Select.Option key={val._id} value={val._id}>
                            {val.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={6} className="gutter-box">
                  <Form.Item label="Cost Price" name="cost_price">
                    <Input
                      type="number"
                      min={0}
                      style={{ margin: "8px 0 0" }}
                      step="any"
                      initialValue={0}
                      className="input-text"
                      placeholder="Cost Price"
                      onKeyPress={(event) => {
                        if (event.key.match("[0-9,.]+")) {
                          return true;
                        } else {
                          return event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={4} className="gutter-box">
                  <Form.Item
                    label="Unit Of Measure"
                    name="measure"
                    rules={[
                      {
                        required: true,
                        message: "Unit Of Measure is Required.",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      className="input-text"
                      style={{ margin: "8px 0 0" }}
                      placeholder="Unit Of Measure"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={2} className="gutter-box">
                  <Form.Item
                    label="Action"
                    style={{ marginLeft: "10px" }}
                    className="action-class"
                  ></Form.Item>
                </Col>
              </Row>
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row
                        className="sub_prod"
                        key={field.key}
                        // style={{ margin: "8px 0 0" }}
                      >
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.area !== curValues.area ||
                            prevValues.sights !== curValues.sights
                          }
                        >
                          {() => (
                            <>
                              <Col xs={24} xl={6} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "name"]}
                                  fieldKey={[field.fieldKey, "name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Product name is Required.",
                                    },
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    className="input-text"
                                    placeholder="Item Name"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={6} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "category"]}
                                  fieldKey={[field.fieldKey, "category"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Category name is Required.",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    className="custom_select4545"
                                    placeholder={
                                      <span style={{ fontSize: "15px" }}>
                                        Search Category
                                      </span>
                                    }
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                      return (
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      );
                                    }}
                                  >
                                    {categoryList?.map((val) => {
                                      return (
                                        <Select.Option
                                          key={val._id}
                                          value={val._id}
                                        >
                                          {val.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={6} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "cost_price"]}
                                  fieldKey={[field.fieldKey, "cost_price"]}
                                >
                                  <Input
                                    type="number"
                                    min={0}
                                    style={{ margin: "8px 0 0" }}
                                    step="any"
                                    initialValue={0}
                                    className="input-text"
                                    placeholder="Cost Price"
                                    onKeyPress={(event) => {
                                      if (event.key.match("[0-9,.]+")) {
                                        return true;
                                      } else {
                                        return event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={4} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "measure"]}
                                  fieldKey={[field.fieldKey, "measure"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Unit Of Measure is Required.",
                                    },
                                  ]}
                                >
                                  <Input
                                    type="Text"
                                    className="input-text"
                                    placeholder="Unit Of Measure"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={2} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  className="action-class"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <DeleteOutlined
                                    onClick={() => {
                                      remove(field.name);
                                      setDisabled(false);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Form.Item>
                      </Row>
                    ))}
                    <div style={{ marginLeft: 9 }}>
                      <Form.Item>
                        <Button
                          type="primary"
                          info
                          disabled={isDisabled}
                          style={{ marginBottom: 10 }}
                          size="medium"
                          onClick={() => {
                            add();
                            setDisabled(true);
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add Product
                        </Button>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          size="medium"
                          onClick={() => history.push("/vendor/items")}
                          style={{ marginRight: 10 }}
                        >
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          info
                          htmlType="submit"
                          size="medium"
                        >
                          {loading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: 16,
                                    color: "white",
                                    margin: "0px 15px",
                                  }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form.List>
            </Form>
          )}
        </Cards>
      </Main>
    </>
  );
};

export default Additems;
