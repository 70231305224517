import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  TreeSelect,
  Popover,
  Button,
  message,
  Select,
} from "antd";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../styled";
import Heading from "../../../components/heading/heading";
import "../option.css";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  addOrUpdateVendorItem,
  getVendorItemCategoryList,
} from "../../../redux/vendor/actionCreator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  addProductInInvenory,
  addUpdateInevntoryItems,
  getAllInventoryList,
  trackUpdate,
} from "../../../redux/inventory/actionCreator";

const EditItem = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const location = useLocation();
  const [vendorId, setVendorId] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [trackInventory, setTrackInventory] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  const { Option } = Select;
  const getVendorListData = async () => {
    setFetchLoader(true);
    let response = await dispatch(getVendorItemCategoryList());
    const getInventoryList = await dispatch(getAllInventoryList("sell"));
    console.log("getInventoryListtaxesList", getInventoryList.taxesList);
    if (
      response &&
      !response.error &&
      getInventoryList &&
      getInventoryList.taxesList
    ) {
      setInventoryList(getInventoryList.taxesList);
      setFetchLoader(false);
      setCategoryList(response.data);
    } else {
      message.error(response.message);
    }
  };

  useEffect(async () => {
    await getVendorListData();
    if (location && location.state?.vendorDetails) {
      setVendorId(location.state?.vendorDetails?._id);
      if (location.state?.vendorDetails?.track_inventory) {
        setTrackInventory(true);
      }
      form.setFieldsValue({
        ...location.state?.vendorDetails,
        category: location.state?.vendorDetails?.category?._id,
      });
    } else {
      setVendorId();
    }
  }, []);

  const handleSubmit = async (value) => {
    if (loading == false) {
      setLoading(true);
      const Obj = {};
      value["inventory_item_id"] = location?.state?.vendorDetails
        ?.inventoryItemData
        ? location.state.vendorDetails.inventoryItemData._id
        : Date.now().toString(36) +
          Math.random()
            .toString(36)
            .substr(2);

      if (location.state?.vendorDetails?.product_id) {
        value["inventory_item_id"] = location.state?.vendorDetails.product_id;
        if (value.track_inventory) {
          Obj.product_name = value?.name;
          Obj.unit_of_measure = value.measure;
          Obj.product_id = location.state?.vendorDetails.product_id;
          Obj._id = location.state?.vendorDetails.product_id;
          Obj.isTracked = true;
          Obj.cost_price = value.cost_price;
          Obj.product_category = categoryList.find(
            (p) => p._id == value.category
          )?.name;
          const dataSource = [Obj];
          const getDeletedTaxes = await dispatch(
            trackUpdate(value.inventory_Id, dataSource)
          );
        } else if (
          location.state?.vendorDetails.inventoryItemData &&
          value.track_inventory == false
        ) {
          Obj.product_name = value?.name;
          Obj.unit_of_measure = value.measure;
          Obj.product_id = location.state.vendorDetails.inventoryItemData._id;
          Obj._id = location.state?.vendorDetails.product_id;
          Obj.isTracked = value.track_inventory;
          Obj.cost_price = value.cost_price;
          Obj.product_category = categoryList.find(
            (p) => p._id == value.category
          )?.name;
          const dataSource = [Obj];
          const getDeletedTaxes = await dispatch(
            trackUpdate(
              location.state?.vendorDetails.inventoryItemData.inventory_id,
              dataSource
            )
          );
        }
      } else {
        if (value.track_inventory) {
          Obj.inventory_item_name = value?.name;
          Obj.unit_of_measure = value.measure;
          Obj._id = value.inventory_item_id;
          Obj.isTracked = true;
          Obj.cost_price = value.cost_price;
          Obj.vendorCategory = categoryList.find(
            (p) => p._id == value.category
          )?.name;
          const dataSource = [Obj];
          let dataSource1 = [
            {
              product_name: Obj.inventory_item_name,
              product_category: Obj.vendorCategory,
              quantity: Number(0),
              product_id: Obj._id,
              unit_of_measure: Obj.unit_of_measure,
            },
          ];
          let payload = {
            inventory_items: dataSource,
          };
          let payload1 = {
            type: "Inward",
            products: dataSource1,
          };
          if (location.state?.vendorDetails.inventoryItemData) {
            Obj._id = location.state.vendorDetails.inventoryItemData._id;
            payload = Obj;
          }
          const getAddedProduct = await dispatch(
            addUpdateInevntoryItems(value.inventory_Id, payload)
          );

          const getAddedProduct1 = await dispatch(
            addProductInInvenory(value.inventory_Id, payload1)
          );
        } else if (
          location.state?.vendorDetails.inventoryItemData &&
          value.track_inventory == false
        ) {
          Obj.inventory_item_name = value?.name;
          Obj.unit_of_measure = value.measure;
          Obj._id = value.inventory_item_id;
          Obj.isTracked = value.track_inventory;
          Obj.cost_price = value.cost_price;
          Obj.vendorCategory = categoryList.find(
            (p) => p._id == value.category
          )?.name;
          const dataSource = [Obj];
          let payload = {
            inventory_items: dataSource,
          };
          if (location.state?.vendorDetails.inventoryItemData) {
            Obj._id = location.state.vendorDetails.inventoryItemData._id;
            payload = Obj;
          }
          const getAddedProduct = await dispatch(
            addUpdateInevntoryItems(
              location.state?.vendorDetails.inventoryItemData.inventory_id,
              payload
            )
          );
        }
      }

      if (value.track_inventory) {
        value["inventoryItemData"] = Obj;
        value["inventoryItemData"]["inventory_id"] = value.inventory_Id;
      }

      let payload = {
        items: [value],
      };
      let reponse = await dispatch(
        addOrUpdateVendorItem(vendorId ? value : payload, vendorId)
      );
      if (reponse && !reponse.error) {
        setLoading(false);
        history.push("/vendor/items");
      } else if (reponse && reponse.messageCode != "ALREADY_EXIST") {
        setLoading(false);
        message.error(reponse.message);
      } else {
        setLoading(false);
      }
    }
  };
  const customStyle = {
    height: "48px",
    lineHeight: "48px",
  };
  return (
    <>
      <Main className="padding-top-form">
        <br></br>
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Item Details</Heading>
              <span>Edit your Item details here </span>
            </div>
          }
        >
          {fetchLoader ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          ) : (
            <div>
              <Row gutter={25} justify="center">
                <Col xxl={12} md={14} sm={18} xs={24}>
                  <div className="formDiv">
                    <div className="mainFormdiv">
                      {" "}
                      <Form
                        form={form}
                        autoComplete="off"
                        size="large"
                        onFinish={handleSubmit}
                      >
                        <Form.Item
                          name="name"
                          label="Item Name"
                          rules={[
                            {
                              min: 3,
                              message:
                                "Item Name must be at least 3 characters long.",
                            },
                            {
                              required: true,
                              message: "Item Name is required",
                            },
                            {
                              max: 60,
                              message:
                                "Item Name cannot be more than 60 characters long.",
                            },
                          ]}
                        >
                          <Input
                            style={{ marginBottom: 10 }}
                            placeholder="Name"
                            disabled={
                              location.state?.vendorDetails?.product_id
                                ? true
                                : false
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Category"
                          name="category"
                          className="customSelectHeight"
                          rules={[
                            { required: true, message: "Category is required" },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ marginBottom: 10 }}
                            placeholder="Search Category"
                            disabled={
                              location.state?.vendorDetails?.product_id
                                ? true
                                : false
                            }
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {categoryList?.map((val) => {
                              return (
                                <Select.Option key={val._id} value={val._id}>
                                  {val.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label="Cost Price"
                          name="cost_price"
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                          }}
                        >
                          <Input
                            type="number"
                            min={0}
                            style={{ marginBottom: 10 }}
                            step="any"
                            placeholder="Cost Price"
                            onKeyPress={(event) => {
                              if (event.key.match("[0-9,.]+")) {
                                return true;
                              } else {
                                return event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                        <span
                          style={{
                            display: "inline-block",
                            width: "24px",
                            lineHeight: "32px",
                            textAlign: "center",
                          }}
                        ></span>
                        <Form.Item
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                          }}
                          label="Unit Of Measure"
                          name="measure"
                          rules={[
                            {
                              required: true,
                              message: "Unit Of Measure is Required.",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            style={{ marginBottom: 10 }}
                            placeholder="Unit Of Measure"
                          />
                        </Form.Item>

                        <Form.Item
                          name="track_inventory"
                          valuePropName="checked"
                          style={{ marginTop: "10px" }}
                        >
                          <Checkbox
                            className="add-form-check"
                            onChange={(e) =>
                              setTrackInventory(e.target.checked)
                            }
                          >
                            Track purchase Inventory for this item
                          </Checkbox>
                        </Form.Item>
                        {trackInventory && (
                          <Form.Item
                            name="inventory_Id"
                            label="Inventory"
                            className="customSelectHeight"
                            rules={[
                              {
                                required: true,
                                message:
                                  "A inventory must be selected for the track.",
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select a Inventory"
                            >
                              {inventoryList.map((groupedData) => {
                                if (
                                  groupedData.linked_registers?.length > 0 &&
                                  groupedData.linked_registers.includes(
                                    currentRegisterData?._id
                                  )
                                ) {
                                  return (
                                    <Option
                                      key={groupedData._id}
                                      value={groupedData._id}
                                    >
                                      {groupedData.inventory_name}
                                    </Option>
                                  );
                                } else if (
                                  groupedData.linked_registers?.length == 0
                                ) {
                                  return (
                                    <Option
                                      key={groupedData._id}
                                      value={groupedData._id}
                                    >
                                      {groupedData.inventory_name}
                                    </Option>
                                  );
                                }
                              })}
                            </Select>
                          </Form.Item>
                        )}
                        <Form.Item style={{ float: "right" }}>
                          <NavLink to="/vendor/items">
                            <Button size="medium" style={{ marginRight: 10 }}>
                              Go Back
                            </Button>
                          </NavLink>
                          <Button
                            size="medium"
                            type="primary"
                            htmlType="submit"
                          >
                            {loading ? (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{
                                      fontSize: 16,
                                      color: "white",
                                      margin: "0px 8px",
                                    }}
                                  />
                                }
                              />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Cards>
      </Main>
    </>
  );
};

export default EditItem;
