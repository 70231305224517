import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { Row, Col, Form, Input, Button, message } from "antd";
import { Cards } from "../../components/cards/frame/cards-frame";
import { TableWrapper, Main } from "../styled";
import Heading from "../../components/heading/heading";
import "../VendorItems/option.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { addOrUpdateExpenseCategory } from "../../redux/vendor/actionCreator";

const AddExpenseCategory = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [categoryId, setCategoryId] = useState();

  useEffect(() => {
    if (location && location.state?.categoryDetails) {
      setCategoryId(location.state?.categoryDetails?.id);
      form.setFieldsValue(location.state?.categoryDetails);
    } else {
      setCategoryId();
    }
  }, []);

  const handleSubmit = async (value) => {
    if (loading == false) {
      setLoading(true);
      let response = await dispatch(
        addOrUpdateExpenseCategory(value, categoryId)
      );
      if (response && !response.error) {
        setLoading(false);
        history.push("/vendor/expense/category");
      } else if (response && response.messageCode !== "ALREADY_EXIST") {
        setLoading(false);
        message.error(response.message);
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Main className="padding-top-form">
        <br />
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Expense Category Details</Heading>
              <span>
                {location.state?.categoryDetails ? "Edit" : "Add"} your expense
                category details here
              </span>
            </div>
          }
        >
          <Row gutter={25} justify="center">
            <Col xxl={12} md={14} sm={18} xs={24}>
              <div className="formDiv">
                <div className="mainFormdiv">
                  <Form
                    form={form}
                    autoComplete="off"
                    size="large"
                    onFinish={handleSubmit}
                  >
                    <Form.Item
                      name="name"
                      label="Category Name"
                      rules={[
                        {
                          min: 3,
                          message:
                            "Category name must be at least 3 characters long.",
                        },
                        {
                          required: true,
                          message: "Category Name is required",
                        },
                        {
                          max: 60,
                          message:
                            "Category name cannot be more than 60 characters long.",
                        },
                      ]}
                    >
                      <Input style={{ marginBottom: 10 }} placeholder="Name" />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                      <TextArea
                        style={{ marginBottom: 20 }}
                        placeholder="Description (optional)"
                      />
                    </Form.Item>
                    <Form.Item style={{ float: "right" }}>
                      <NavLink to="/vendor/expense/category">
                        <Button size="medium" style={{ marginRight: 10 }}>
                          Go Back
                        </Button>
                      </NavLink>
                      <Button size="medium" type="primary" htmlType="submit">
                        {loading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 16,
                                  color: "white",
                                  margin: "0px 8px",
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default AddExpenseCategory;
