import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useLocation, useParams, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Input,
  Checkbox,
  InputNumber,
  Spin,
  Button,
  Popover,
  Form,
  Col,
  Row,
  Modal,
} from "antd";
import {
  FolderViewOutlined,
  DeleteOutlined,
  SearchOutlined,
  ImportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  getAllPricebookVariantList,
  exportPricebookVarints,
  UpdateProductPriceBook,
} from "../../../../redux/pricebook/actionCreator";
import "../../product.css";
import FeatherIcon from "feather-icons-react";
import { getAllProductList } from "../../../../redux/products/actionCreator";

const VariantPrice = forwardRef((props, ref) => {
  let { passFun } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [editPrice, setEditPrice] = useState(false);
  let [editItemList, setEditItemList] = useState([]);
  const [VariantListData, setVariantListData] = useState([]);
  let isMounted = useRef(true);
  const location = useLocation();
  let [search, setsearch] = useState("");
  const queryParams = useParams();
  const [loading, setloading] = useState(true);
  const [editPriceValue, setEditPriceValue] = useState(false);
  const [modalVisible1, setModelVisible1] = useState(false);
  const [ExportType, SetExportType] = useState("");
  const [modalVisible, setModelVisible] = useState(false);
  let email = localStorage.getItem("email_id");
  const deviceWidth = localStorage.getItem("windowWidth");
  useEffect(() => {
    setloading(true);

    if (isMounted.current) {
      fetchVariantList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchVariantList = async () => {
    const getVariantList = await dispatch(
      getAllPricebookVariantList(queryParams.pricebook_id)
    );
    if (
      isMounted.current &&
      getVariantList &&
      getVariantList.PricebookVariantList
    ) {
      setEditItemList([]);
      setVariantListData(getVariantList.PricebookVariantList);
      setEditPriceValue(false);
    }
    setloading(false);
  };

  const [state, setState] = useState({
    item: "",
  });

  let { selectedRowKeys, item } = state;
  useEffect(() => {
    if (VariantListData) {
      setState({
        item: VariantListData,
        selectedRowKeys,
      });
    }
  }, [VariantListData, selectedRowKeys]);

  // useImperativeHandle(ref, () => ({
  //   async Variantlist() {
  //     const getVariantList = await dispatch(
  //       getAllPricebookVariantList(queryParams.pricebook_id)
  //     );
  //     if (getVariantList && getVariantList.PricebookVariantList)
  //       setVariantListData(getVariantList.PricebookVariantList);
  //     setEditItemList([]);
  //     return getVariantList.PricebookVariantList;
  //   },
  // }));

  passFun(editItemList);

  function pushToArray(editItemList, obj) {
    const index = editItemList.findIndex(
      (e) => e.variant_id === obj.variant_id
    );
    if (index === -1) {
      setEditItemList([...editItemList, obj]);
    } else {
      editItemList[index].variant_id = obj.variant_id;
      obj.price && (editItemList[index].price = obj.price);
      obj.disable && (editItemList[index].disable = obj.disable);
    }
  }

  function onChange(value, editPrice) {
    const findIndex = item.findIndex((product) => product._id === editPrice);
    pushToArray(editItemList, {
      variant_id: editPrice,
      price: value,
      disable: item[findIndex].is_disabled ? true : false,
    });
    item[findIndex].price = value;
  }
  function checkboxChange(e, id) {
    const findIndex = item.findIndex((product) => product._id === id);
    pushToArray(editItemList, {
      variant_id: id,
      disable: e.target.checked,
      price: item[findIndex].price,
    });
    item[findIndex].disabled = e.target.checked;
  }

  const dataSource = [];
  if (VariantListData.length)
    item.map((value) => {
      const {
        _id,
        variant_name,
        variant_groups,
        price,
        is_disabled,
        is_price_overridden,
      } = value;
      return dataSource.push({
        id: _id,
        variant_name: variant_name,
        varian_group: variant_groups,
        varian_price: price,
        is_price_overridden: is_price_overridden ? is_price_overridden : "-",
        is_disabled: is_disabled ? is_disabled : false,
      });
    });

  const searchDataSource = [];
  let searchArrTaxes = VariantListData.filter((value) =>
    value.variant_name.toLowerCase().includes(search.toLowerCase())
  );

  if (searchArrTaxes.length)
    searchArrTaxes.map((value) => {
      const {
        _id,
        variant_name,
        variant_groups,
        price,
        is_disabled,
        is_price_overridden,
      } = value;
      return searchDataSource.push({
        id: _id,
        variant_name: variant_name,
        varian_group: variant_groups,
        varian_price: price,
        is_price_overridden: is_price_overridden ? is_price_overridden : "-",
        is_disabled: is_disabled ? is_disabled : false,
      });
    });
  const columns = [
    {
      title: "Name",
      dataIndex: "variant_name",
      key: "variant_name",
      render(text, record) {
        return {
          children: (
            <div
              style={{ color: "#008cba" }}
              onClick={() => setEditPrice(false)}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "Variant Groups",
      dataIndex: "varian_group",
      key: "varian_group",
      render(text, record) {
        return {
          children: (
            <div
              style={{ color: "#008cba" }}
              onClick={() => setEditPrice(false)}
            >
              {text.map((value, index) => {
                return (
                  <>
                    {index > 0 ? (
                      <span>{value + " , "}</span>
                    ) : (
                      <span>{value}</span>
                    )}
                  </>
                );
              })}
            </div>
          ),
        };
      },
    },
    {
      title: "Price",
      dataIndex: "varian_price",
      key: "varian_price",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: (
            <div onClick={() => setEditPriceValue(true)}>
              {editPrice === record.id && editPriceValue == true ? (
                <InputNumber
                  size="small"
                  defaultValue={text}
                  onPressEnter={() => setEditPrice(false)}
                  onChange={(value) => onChange(value, editPrice)}
                  className="product_price_input"
                />
              ) : (
                <div
                  style={{ borderBottom: "1px dashed #a8b9ce", width: "100px" }}
                  onClick={() => setEditPrice(record.id)}
                >
                  {text}
                </div>
              )}
            </div>
          ),
        };
      },
    },

    {
      title: "Is price Overridden?",
      dataIndex: "is_price_overridden",
      key: "is_price_overridden",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: <div onClick={() => setEditPrice(false)}>{text}</div>,
        };
      },
    },
    {
      title: "Is Disabled?",
      dataIndex: "is_disabled",
      key: "is_disabled",
      render(text, record) {
        return {
          // props: {
          //   style: { textAlign: "center" },
          // },
          children: (
            <div onClick={() => setEditPrice(false)}>
              <Checkbox
                defaultChecked={text}
                onChange={(value) => checkboxChange(value, record.id)}
              />
            </div>
          ),
        };
      },
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const handleUpdateProductList = async () => {
    setloading(true);
    if (editItemList.length > 0) {
      let obj;
      editItemList[0].variant_id && (obj = { variants: editItemList });
      const getAllUpdateData = await dispatch(
        UpdateProductPriceBook(obj, queryParams.pricebook_id)
      );

      if (getAllUpdateData && getAllUpdateData.UpdateProductData) {
        if (obj.variants) {
          setModelVisible(false);
          fetchVariantList();
          await dispatch(getAllProductList());
        }
      }
    }
  };

  const submitExport = (values) => {
    values.type = ExportType;
    if (ExportType) {
      dispatch(exportPricebookVarints(values, queryParams.pricebook_id));
      setModelVisible1(false);
    }
  };

  const handleCancel = (e) => {
    setModelVisible1(false);
    setModelVisible(false);
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  return (
    <div>
      <Row gutter={15} className="list-row">
        <Col span={24} className="pricebookTableBox">
          <div className="priceBook_titles_content">
            <div className="productCount">
              <h2>Variant</h2>
              <span className="title-counter" style={{ marginTop: "-17px" }}>
                {VariantListData.length} Variants{" "}
              </span>
            </div>
            <div
              style={{
                boxShadow: "none",
                marginBottom: "16px",
                marginLeft: "20px",
              }}
              className="table_search"
            >
              <Input
                suffix={<SearchOutlined />}
                className="set_serbt"
                autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                placeholder="Search by variant name"
                style={{
                  borderRadius: "30px",
                  width: "250px",
                }}
                onChange={(e) => setsearch(e.target.value)}
                value={search}
              />
            </div>
          </div>

          <div className="pricebookTables-toolbox-menu">
            <div key="1" className="page-header-actions">
              <Button
                size="middle"
                type="primary"
                Style={{ padding: 10 }}
                onClick={() => history.push("/products/pricebook")}
              >
                Go back
              </Button>
              <Button
                size="middle"
                type="primary"
                Style={{ padding: 10 }}
                onClick={() => setModelVisible(true)}
              >
                Save Variants
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      {loading ? (
        <>
          <Table
            rowKey="id"
            size="small"
            locale={locale}
            dataSource={[]}
            columns={columns}
            fixed={true}
            scroll={{ x: 800 }}
          />
        </>
      ) : (
        <Table
          rowKey="id"
          size="small"
          dataSource={dataSource.filter((value) =>
            value.variant_name.toLowerCase().includes(search.toLowerCase())
          )}
          columns={columns}
          fixed={true}
          scroll={{ x: 800 }}
          pagination={{
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
            showSizeChanger: true,
            total: dataSource.filter((value) =>
              value.variant_name.toLowerCase().includes(search.toLowerCase())
            ).length,
          }}
        />
      )}

      <Modal
        title="Variants"
        visible={modalVisible1}
        width={600}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form name="export" form={form} onFinish={submitExport}>
          <div className="add-product-block">
            <div className="add-product-content">
              <Form.Item
                name="email"
                initialValue={email ? email : ""}
                label="Send to Email Address"
                rules={[
                  {
                    message: "Email address is required",
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Save variants"
        visible={modalVisible}
        onOk={handleUpdateProductList}
        onCancel={handleCancel}
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                  spin
                />
              }
            />
          ) : (
            "Save variants"
          )
        }
        width={600}
      >
        <p>Are you sure you want to save variants"</p>
      </Modal>
    </div>
  );
});

export { VariantPrice };
