import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import "../sell.css";
import {
  getCartInfoLocalListsData,
} from "../../../utility/localStorageControl";
import { getItem } from "../../../utility/localStorageControl";
const Unpaid = (props) => {
  let {
    setCustomTableOrderInLocalStorageHandler,
    searchText,
    handleSplit,
  } = props;

  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
        ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );

  const currentRegisterData = useSelector((state) =>
    state.register.RegisterList.find((val) => val.active)
  );

  function setTableStatusUnpaid(tableName) {
    let tableNameStr = tableName.replace(/\s+/g, "-").toLowerCase();
    setCustomTableOrderInLocalStorageHandler(tableName, tableNameStr);
  }

  let [localAllTableData, setLocalTableData] = useState([
    ...getCartInfoLocalListsData(currentRegisterData),
  ]);

  return (
    <div className="sell-table-parent">
      <Row gutter={[2, 2]} className="occupied-row list-box-row">
        {localAllTableData?.filter((val) =>
          val.tableName.toLowerCase().includes(searchText.toLowerCase())
        ).map((tableData, index) => {
          const status = tableData?.Status
            ? tableData.Status
            : "sell-main-order";
          if (status && status == "Unpaid") {
            return (
              <Col
                xxl={4}
                lg={4}
                xl={4}
                sm={12}
                xs={24}
                className="sell-table-col"
                key={index}
              >
                <div className={
                  status == "In Progress"
                    ? "sell-main-order"
                    : status == "Unpaid"
                      ? "sell-unpaid"
                      : "sell-empty"
                }
                  onClick={() => setTableStatusUnpaid(tableData.tableName)}
                >
                  <div className="sell-table-counter">
                    <div className="counter_served">
                      {tableData.tableName}
                    </div>
                    {(tableData?.type === 'delivery-local' || tableData?.type === 'take-away-local') && tableData?.otherDetails?.customer?.name !== '' &&
                      tableData?.otherDetails?.customer?.mobile !== '' &&
                      tableData?.otherDetails?.customer?.mobile !== 'Add Customer' ?
                      <div className="takeAway_customerInfo">
                        <p>{tableData?.otherDetails?.customer?.name}</p>
                        <p>{tableData?.otherDetails?.customer?.mobile}</p>
                      </div>
                      : ''
                    }
                    <div className="postion">
                      <div className="product-price inlineDIv">
                        {tableData &&
                          tableData.cartKey == getItem("active_cart") && (
                            <span className="active-dots" />
                          )}
                        {status != "sell-main-order" && status}
                        {status == "Unpaid" && (
                          <span>{` ${rsSymbol}${Number(
                            (tableData.tableName
                              .replace(/\s+/g, "-")
                              .toLowerCase(),
                              tableData?.otherDetails?.finalCharge)
                          ).toFixed(2)}`}</span>
                        )}
                      </div>
                    </div>

                  </div>
                </div>

                {(status == "In Progress" || status == "Unpaid") &&
                  tableData.customSplit == undefined &&
                  tableData.swapTableCustum == undefined &&
                  tableData.RoomId == undefined && (
                    <div
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        color: "#008cba",
                      }}
                      onClick={() =>
                        handleSplit(tableData.tableName, index - 2)
                      }
                    >
                      Split
                    </div>
                  )}
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
};

export { Unpaid };
