import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { Row, Col, Form, Input, Button, message } from "antd";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../styled";
import Heading from "../../../components/heading/heading";
import "../option.css";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { addOrUpdateVendor } from "../../../redux/vendor/actionCreator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddVendor = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [vendorId, setVendorId] = useState();
  useEffect(() => {
    if (location && location.state?.vendorDetails) {
      setVendorId(location.state?.vendorDetails?._id);
      form.setFieldsValue(location.state?.vendorDetails);
    } else {
      setVendorId();
    }
  }, []);
  const handleSubmit = async (value) => {
    if (loading == false) {
      setLoading(true);
      let reponse = await dispatch(addOrUpdateVendor(value, vendorId));
      if (reponse && !reponse.error) {
        setLoading(false);
        history.push("/vendors");
      } else if (reponse && reponse.messageCode != "ALREADY_EXIST") {
        setLoading(false);
        message.error(reponse.message);
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Main className="padding-top-form">
        <br></br>
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Vendor Details</Heading>
              <span>
                {location.state?.vendorDetails ? "Edit" : "Add"} your vendor
                details here{" "}
              </span>
            </div>
          }
        >
          <Row gutter={25} justify="center">
            <Col xxl={12} md={14} sm={18} xs={24}>
              <div className="formDiv">
                <div className="mainFormdiv">
                  {" "}
                  <Form
                    form={form}
                    autoComplete="off"
                    size="large"
                    onFinish={handleSubmit}
                  >
                    <Form.Item
                      name="name"
                      label="Vendor Name"
                      rules={[
                        {
                          min: 3,
                          message:
                            "Vendor name must be at least 3 characters long.",
                        },
                        { required: true, message: "Vendor Name" },
                        {
                          max: 60,
                          message:
                            "Vendor name cannot be more than 60 characters long.",
                        },
                      ]}
                    >
                      <Input style={{ marginBottom: 10 }} placeholder="Name" />
                    </Form.Item>
                    <Form.Item name="mobile" label="Vendor mobile">
                      <Input
                        style={{ marginBottom: 10 }}
                        placeholder="Vendor Mobile (optional)"
                        type="number"
                      />
                    </Form.Item>
                    <Form.Item name="taxNumber" label="Tax Number">
                      <Input
                        style={{ marginBottom: 10 }}
                        placeholder="Tax Number (optional)"
                      />
                    </Form.Item>
                    <Form.Item name="address" label="Address">
                      <TextArea
                        style={{ marginBottom: 10 }}
                        placeholder="Address (optional)"
                      />
                    </Form.Item>
                    <Form.Item name="addtional" label="Addtional Information">
                      <TextArea
                        style={{ marginBottom: 20 }}
                        placeholder="Addtional Information (optional)"
                      />
                    </Form.Item>
                    <Form.Item style={{ float: "right" }}>
                      <NavLink to="/vendors">
                        <Button size="medium" style={{ marginRight: 10 }}>
                          Go Back
                        </Button>
                      </NavLink>
                      <Button size="medium" type="primary" htmlType="submit">
                        {loading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 16,
                                  color: "white",
                                  margin: "0px 8px",
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default AddVendor;
