import React, { useState } from "react";
import { Tabs } from "antd";
import { QRNew } from "../QrOrder/QRNew";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { useEffect } from "react";
import {
  acceptOnlineOrderList,
} from "../../../utility/localStorageControl";
import { useSelector } from "react-redux";
const QrOrderBuilder = (props) => {
  const { currentRegisterData, orderTabChange } = props;

  const [activeTab, changeTab] = useState();
  const { onlineOrderCount } = useSelector((state) => {
    let acceptList = acceptOnlineOrderList();
    return {
      onlineOrderCount: state.kitchenUser.kitchenUserList.filter(
        (val) => acceptList.find((j) => j.onlineOrder.id == val.id) == undefined
      )?.length,
    };
  });

  const [acceptOrdersCount, setAcceptOrderCount] = useState(0);
  useEffect(() => {
    setAcceptOrderCount(acceptOnlineOrderList()?.length);
  }, [acceptOnlineOrderList()?.length]);

  return (
    <Cards headless>
      <QRNew
        currentRegisterData={currentRegisterData}
        changeTab={changeTab}
      />
    </Cards>
  );
};

export { QrOrderBuilder };
