import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, message } from "antd";
import { Main, TableWrapper } from "../../styled";
import { Button } from "../../../components/buttons/buttons";
import {
  getAllQROrderList,
  qrOrderAcceptOrReject,
} from "../../../redux/onlineOrder/actionCreator";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import "../sell.css";
import {
  setOrderTickets,
  setItem,
  getItem,
  createOnlineQRorder,
  getTableNameTo,
  getLocalCartCount,
} from "../../../utility/localStorageControl";
import moment from "moment";
import OrderTicketPrint from "../Current/OrderTicketPrint";
import ReactDOMServer from "react-dom/server";
import { Spin } from "antd";
import { getAllRoomList } from "../../../redux/sell/actionCreator";
import { getReceiptNumber } from "../../../utility/utility";
import { getAllSetUpPrinterList } from "../../../redux/printer/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";

const QRNew = (props) => {
  const { currentRegisterData, changeTab, setLocalCartCount } = props;
  const dispatch = useDispatch();

  const { qrAllOrders } = useSelector((state) => {
    return { qrAllOrders: state.kitchenUser.kitchenUserIdData };
  });
  const [totalOrders, setTotalOrderList] = useState(qrAllOrders);
  const [loader, setLoader] = useState({
    recordId: "",
    check: false,
    type: "",
  });
  let [setupList, setsetupPrinterList] = useState([]);

  useEffect(() => {
    setTotalOrderList(qrAllOrders);
  }, [qrAllOrders]);

  async function fetchAllOrders() {
    if (currentRegisterData) {
      const getAllOrderDetailsList = await dispatch(
        getAllQROrderList(currentRegisterData._id)
      );
    }
  }
  useEffect(() => {
    fetchAllOrders();
  }, [totalOrders.length]);

  useEffect(async () => {
    let getSetupPrintList = await dispatch(getAllSetUpPrinterList("sell"));
    if (getSetupPrintList) {
      setsetupPrinterList(getSetupPrintList);
    }
  }, []);

  function dateCompare(time1, time2) {
    var t1 = new Date();
    var parts = time1.split(":");
    t1.setHours(parts[0], parts[1], parts[2], 0);
    var t2 = new Date();
    parts = time2.split(":");
    t2.setHours(parts[0], parts[1], parts[2], 0);

    // returns 1 if greater, -1 if less and 0 if the same
    if (t1.getTime() > t2.getTime()) return 1;
    if (t1.getTime() < t2.getTime()) return -1;
    return 0;
  }
  function checkCategory(val) {
    let Add = [];
    let remove = [];
    val.data.map((j) => {
      return j.add_or_remove == "Removed Items" ? remove.push(j) : Add.push(j);
    });
    return Add.length > 0 && remove.length > 0
      ? "both"
      : Add.length > 0 && remove.length == 0
      ? "Added Items"
      : "Removed Items";
  }

  const createOrderTikets = (
    localCartInfo,
    updateProduct,
    selectedAllProduct,
    customer
  ) => {
    let PreviousTikets = [];
    let receiptNumber =
      localCartInfo && localCartInfo.receipt_Number
        ? localCartInfo.receipt_Number
        : getReceiptNumber(currentRegisterData, []);
    let listOfProducts = updateProduct;
    listOfProducts.map((val) => {
      let OrderTicketNumber;
      if (getItem("previousOrderTicketNumber") != null) {
        let Details = getItem("previousOrderTicketNumber");
        if (
          moment(moment(Details.date).format("L")).isSame(moment().format("L"))
        ) {
          if (
            dateCompare(moment(Details.date).format("HH:mm:ss"), "06:00:00") ==
              -1 &&
            dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0
          ) {
            OrderTicketNumber = 1;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1,
            });
          } else {
            OrderTicketNumber = 1 + Details.number;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1 + Details.number,
            });
          }
        } else {
          if (
            dateCompare(moment(new Date()).format("HH:mm:ss"), "06:00:00") >= 0
          ) {
            OrderTicketNumber = 1;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1,
            });
          } else {
            OrderTicketNumber = 1 + Details.number;
            setItem("previousOrderTicketNumber", {
              date: new Date(),
              number: 1 + Details.number,
            });
          }
        }
      } else {
        OrderTicketNumber = 1;
        setItem("previousOrderTicketNumber", {
          date: new Date(),
          number: 1,
        });
      }
      let object = {
        orderNotes:
          customer && customer.instruction ? customer.instruction : undefined,
        tiketNumber: OrderTicketNumber,
        categoryName: val.categoryName,
        add_remove: checkCategory(val),
        itemList: val.data,
        enterDate: new Date(),
        table_name: localCartInfo.tableName,
        receiptNumber: receiptNumber,
        receiptNumberDetails: {
          type: "receipt",
          number: receiptNumber,
        },
      };
      window.frames[
        "print_frame"
      ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <OrderTicketPrint
          customerComment={
            localCartInfo?.onlineOrder?.comment
              ? localCartInfo?.onlineOrder?.comment
              : undefined
          }
          categoryDetails={object}
          PreviousTikets={PreviousTikets}
          ReceiptNumber={receiptNumber}
          TableName={localCartInfo?.tableName ? localCartInfo?.tableName : ""}
        />
      );
      window.frames["print_frame"].window.focus();
      window.frames["print_frame"].window.print();
      if (localCartInfo?.cartKey) {
        setOrderTickets(
          localCartInfo?.cartKey,
          val.data,
          object,
          selectedAllProduct,
          undefined,
          customer
        );
      }
    });
    return true;
  };
  const acceptOrder = async (record) => {
    if (record._id) {
      setLoader({
        recordId: record._id,
        check: true,
        type: "confirm",
      });
      console.log("recordrecordrecordrecord", record);
      const allTableList = record.hotel_room
        ? await dispatch(
            getAllRoomList(
              currentRegisterData._id,
              record.hotelRegisterId
                ? record.hotelRegisterId
                : currentRegisterData.hotelDetalis.hotelId,
              record.table_id
            )
          )
        : false;
      if (
        allTableList &&
        allTableList.data &&
        allTableList.data.length &&
        allTableList.data[0].checkIn == false
      ) {
        message.error({
          content: "This room is not in-house",
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
        setLoader({
          recordId: "",
          check: false,
          type: "",
        });
        return true;
      }
      let localCartInfo = getTableNameTo(
        record.table_name,
        currentRegisterData
      );
      if (localCartInfo && localCartInfo.Status == "Unpaid") {
        setLoader({
          recordId: "",
          check: false,
          type: "",
        });
        message.error({
          content: "Please wait this table is occupied.",
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
        return true;
      }
      const response = await dispatch(qrOrderAcceptOrReject(record._id));
      if (response) {
        if (localCartInfo != "") {
          let addItemList = record.Items;
          let privewsTikets = [];
          let selectedProduct = localCartInfo?.data ? localCartInfo.data : [];
          let allUpdatedProduct = [];
          if (selectedProduct && addItemList && addItemList.length) {
            addItemList.reverse().map((val) => {
              allUpdatedProduct.push(val.key);
              let findIndex = selectedProduct.findIndex(
                (j) => j.key == val.key
              );
              if (findIndex != -1) {
                selectedProduct[
                  findIndex
                ].orderTiketsNotes = val.orderTiketsNotes
                  ? val.orderTiketsNotes
                  : "";
                selectedProduct[findIndex].updatedTime = new Date().getTime();
                selectedProduct[findIndex].quantity =
                  selectedProduct[findIndex].quantity + val.quantity;
                selectedProduct[findIndex].calculatedprice =
                  selectedProduct[findIndex].quantity *
                  (selectedProduct[findIndex].key_price
                    ? selectedProduct[findIndex].key_price
                    : selectedProduct[findIndex].price);
                if (
                  selectedProduct[findIndex].productInclusivePrice != undefined
                ) {
                  selectedProduct[
                    findIndex
                  ].productInclusivePricecalculatedprice =
                    selectedProduct[findIndex].quantity *
                    (selectedProduct[findIndex].productInclusivePriceKeyPrice
                      ? selectedProduct[findIndex].productInclusivePriceKeyPrice
                      : selectedProduct[findIndex].productInclusivePrice);
                }
              } else {
                val.updatedTime = new Date().getTime();
                val.calculatedprice =
                  val.quantity * (val.key_price ? val.key_price : val.price);
                if (val.productInclusivePrice != undefined) {
                  val.productInclusivePricecalculatedprice =
                    val.quantity *
                    (val.productInclusivePriceKeyPrice
                      ? val.productInclusivePriceKeyPrice
                      : val.productInclusivePrice);
                }
                selectedProduct.push(val);
              }
            });
          }
          selectedProduct = selectedProduct.sort(
            (a, b) => parseFloat(b.updatedTime) - parseFloat(a.updatedTime)
          );
          let finalData = [];
          if (localCartInfo && localCartInfo.orderTicketsData) {
            let totalOrderTikets = [];
            let checkdata = [];
            localCartInfo.orderTicketsData.map((val) => {
              checkdata.push(val.tiketNumber);
              val.itemList.map((i) => {
                totalOrderTikets.push(i);
              });
            });
            privewsTikets = checkdata;
            var holder = {};
            totalOrderTikets.forEach(function(d) {
              d.newqty = d.newqty ? d.newqty : d.quantity;
              if (d.add_or_remove == "Added Items") {
                return holder.hasOwnProperty(d.key)
                  ? (holder[d.key] = holder[d.key] + d.newqty)
                  : (holder[d.key] = d.newqty);
              } else if (d.add_or_remove == "Removed Items") {
                return holder.hasOwnProperty(d.key)
                  ? (holder[d.key] = holder[d.key] - d.newqty)
                  : (holder[d.key] = d.newqty);
              }
            });

            var obj2 = [];
            for (var prop in holder) {
              obj2.push({
                key: prop,
                newqty: holder[prop],
              });
            }
            obj2.map((i) => {
              selectedProduct.reverse().map((data) => {
                if (i.key === data.key) {
                  if (data.quantity > i.newqty) {
                    data.add_or_remove = "Added Items";
                    data.newqty = data.quantity - i.newqty;
                    finalData.push(data);
                  } else if (data.quantity < i.newqty) {
                    data.add_or_remove = "Removed Items";
                    data.newqty = i.newqty - data.quantity;
                    finalData.push(data);
                  }
                }
              });
            });

            var result = selectedProduct.reverse().filter(function(o1) {
              return !obj2.some(function(o2) {
                return o1.key === o2.key;
              });
            });

            if (result.length > 0) {
              result.map((val) => {
                finalData.push(val);
              });
            }
            var result2 = obj2.filter(function(o1) {
              return !selectedProduct.reverse().some(function(o2) {
                return o1.key === o2.key;
              });
            });

            if (result2.length > 0) {
              result2.map((i) => {
                let findData = totalOrderTikets.find((j) => j.key === i.key);
                if (i.newqty > 0) {
                  findData.add_or_remove = "Removed Items";
                  finalData.push({
                    ...findData,
                    newqty: i.newqty,
                  });
                }
              });
            }
          } else {
            selectedProduct.reverse().map((val) => {
              val.newqty = val.quantity;
              finalData.push(val);
            });
          }

          let arrayData = Object.values(
            finalData.reduce(function(res, value) {
              if (!res[value?.order_ticket_group?._id]) {
                res[value?.order_ticket_group?._id] = {
                  categoryName:
                    value?.order_ticket_group?.order_ticket_group_name,
                  data: [value],
                };
              } else {
                res[value?.order_ticket_group?._id].data.push(value);
              }
              return res;
            }, {})
          );

          arrayData = arrayData.filter((val) => {
            if (val.categoryName && val.data && val.data.length) {
              val["data"] = val.data.filter((z) => {
                let find = allUpdatedProduct.find((y) => y == z.key);
                if (find) {
                  return z;
                }
              });
              if (val.data.length) {
                return val;
              }
            }
          });
          let response1 = createOrderTikets(
            localCartInfo,
            arrayData,
            selectedProduct,
            record.Customer
          );
          if (response1) {
            let allOrderList = await fetchAllOrders();
            if (allOrderList) {
              setLoader({
                recordId: "",
                check: false,
                type: "",
              });
            }
          }
        } else {
          let localCartInfoDetails = createOnlineQRorder(
            record.Items,
            currentRegisterData,
            record.table_name,
            record.table_type,
            record.hotel_room ? allTableList.data[0] : false
          );

          if (localCartInfoDetails) {
            let arrayData = Object.values(
              record.Items.reduce(function(res, value) {
                !res[value?.order_ticket_group?._id]
                  ? (res[value?.order_ticket_group?._id] = {
                      categoryName:
                        value?.order_ticket_group?.order_ticket_group_name,
                      data: [value],
                    })
                  : res[value?.order_ticket_group?._id].data.push(value);

                return res;
              }, {})
            );

            arrayData = arrayData.filter((val) => val.categoryName);
            let response1 = createOrderTikets(
              localCartInfoDetails,
              arrayData,
              record.Items,
              record.Customer
            );
            if (response1) {
              let allOrderList = await fetchAllOrders();
              if (allOrderList) {
                setLoader({
                  recordId: "",
                  check: false,
                  type: "",
                });
              }
            }
          }
        }
        setLocalCartCount(getLocalCartCount(currentRegisterData));
      }
    }
  };

  const rejectOrder = async (record) => {
    if (record._id) {
      setLoader({
        recordId: record._id,
        check: true,
        type: "reject",
      });
      const response = await dispatch(qrOrderAcceptOrReject(record._id));
      if (response) {
        let allOrderList = await fetchAllOrders();
        if (allOrderList) {
          setLoader({
            recordId: "",
            check: false,
            type: "",
          });
        }
      }
    }
  };
  const dataSource = [];
  if (totalOrders.length)
    totalOrders.map((value) => {
      const {
        _id,
        details,
        created_at,
        table_name,
        clickRow,
        added_items,
        table_type,
        customer,
        hotel_room,
        table_id,
        isHotelPMSOrder,
        hotelRegisterId,
      } = value;
      return dataSource.push({
        _id: _id,
        Value: details ? details.priceSummery?.total : 100,
        Time: moment(created_at).format("MMM DD, Y, h:mm A"),
        Items: details ? details.added_items : added_items,
        Customer: customer,
        table_name: table_name,
        clickRow: clickRow ? true : false,
        table_type: table_type,
        hotel_room: hotel_room,
        table_id: table_id,
        isHotelPMSOrder,
        hotelRegisterId,
      });
    });
  const columns = [
    {
      title: "Order Time",
      dataIndex: "Time",
      key: "Time",
    },
    {
      title: "Table Name",
      dataIndex: "table_name",
      key: "table_name",
    },
    {
      title: "Items",
      dataIndex: "Items",
      key: "Items",
      width: "25%",
      render(value, record, index1) {
        return {
          children: (
            <>
              {record.clickRow ? (
                value.map((val, index) =>
                  index == value.length - 1 ? (
                    <p
                      className="incomingQrOrderItem"
                      style={{ marginBottom: "0px" }}
                      onClick={() => {
                        totalOrders.map((val, index) => {
                          if (index1 == index) {
                            val.clickRow = val.clickRow ? false : true;
                          }
                          return val;
                        });
                        setTotalOrderList([...totalOrders]);
                      }}
                    >
                      {val.quantity} x {val.display_name}{" "}
                      <UpOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: "10px",
                        }}
                      />{" "}
                    </p>
                  ) : (
                    <p
                      className="incomingQrOrderItem"
                      style={{ marginBottom: "0px" }}
                    >
                      {val.quantity} x {val.display_name}{" "}
                    </p>
                  )
                )
              ) : (
                <p
                  className="incomingQrOrderItem"
                  style={{ marginBottom: "0px" }}
                  onClick={() => {
                    totalOrders.map((val, index) => {
                      if (index1 == index) {
                        val.clickRow = val.clickRow ? false : true;
                      }
                      return val;
                    });
                    setTotalOrderList([...totalOrders]);
                  }}
                >
                  {value[0].quantity} x {value[0].display_name}{" "}
                  <DownOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: "10px",
                    }}
                  />
                </p>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
    },
    {
      title: "Customer",
      dataIndex: "Customer",
      key: "Customer",
      render(value, record) {
        return {
          children: (
            <>
              {value && value.mobile && <div>{value.mobile}</div>}
              {value && value.name && <div>{value.name}</div>}
            </>
          ),
        };
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render(value, record) {
        return {
          children: (
            <>
              <Button
                size="medium"
                className="confirmQr btn-custom"
                type="primary"
                raised
                style={{ marginRight: "10px" }}
                onClick={() => acceptOrder(record)}
              >
                <span className="whiteloader">
                  {loader.check &&
                  loader.recordId == record._id &&
                  loader.type == "confirm" ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            margin: "0px 14px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Confirm"
                  )}
                </span>
              </Button>
              <Button
                size="medium"
                className="btn-custom "
                type="primary"
                raised
                onClick={() => rejectOrder(record)}
              >
                <span className="whiteloader">
                  {loader.check &&
                  loader.recordId == record._id &&
                  loader.type == "reject" ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            margin: "0px 14px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Reject"
                  )}
                </span>
              </Button>
            </>
          ),
        };
      },
    },
  ];

  return (
    <TableWrapper className="table-responsive">
      <Table
        rowKey="id"
        size="small"
        dataSource={dataSource}
        columns={columns}
        pagination={{
          pageSizeOptions: ["10", "50", "100", "500", "1000"],
          showSizeChanger: true,
          total: totalOrders.length,
        }}
        scroll={{
          x: 1000,
        }}
      />
    </TableWrapper>
  );
};

export { QRNew };
