import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { getAlldashboradDatwiseChangeData } from "../../../../redux/dashboard/actionCreator";
import { Cards } from "../../../../components/cards/frame/cards-frame";
import moment from "moment";
import "./ecomstyle.css";
const ReservationType = ({ dashBoardDataDetails }) => {
  const dispatch = useDispatch();
  const [reservationTypeList, setReservationTypeList] = useState([]);

  useEffect(() => {
    if (dashBoardDataDetails?.reservationtype) {
      setReservationTypeList([
        { _id: "Direct", count: dashBoardDataDetails?.reservationtype.direct },
        { _id: "OTA", count: dashBoardDataDetails?.reservationtype.ota },
        { _id: "Agent", count: dashBoardDataDetails?.reservationtype.agent },
        {
          _id: "Corporate",
          count: dashBoardDataDetails?.reservationtype.corporate,
        },
      ]);
    }
  }, [dashBoardDataDetails]);

  const sellingColumns = [
    {
      className: "ProductClass",
      title: "Type",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: "30%",
    },
    {
      width: "40%",
    },
    {
      className: "ProductClass",
      title: "Count",
      dataIndex: "count",
      align: "center",
      key: "count",
      width: "30%",
    },
  ];

  return (
    <div className="full-width-table to-sel-pad top_selltab">
      <Cards title={"Reservation"} size="large">
        <div className="table-responsive">
          <Table
            scroll={false}
            columns={sellingColumns}
            dataSource={reservationTypeList}
            pagination={false}
            fixed={true}
            size="small"
          />
        </div>
      </Cards>
    </div>
  );
};

export { ReservationType };
