import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  Space,
  Button,
  Form,
  Spin,
  Tooltip,
  message,
} from "antd";
import {
  SearchOutlined,
  ImportOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { UserTableStyleWrapper } from "../../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../../Customer/Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "../../../components/popup/popup";
import {
  getCustomerList,
  filterListData,
  ExportCustomer,
} from "../../../redux/customer/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../../Customer/customer.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import {
  getMasterList,
  getRoomOcccupancy,
} from "../../../redux/HotelReports/actionCreator";
import moment from "moment";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import { handlePaymnetModeDate } from "../../../redux/receipts/actionCreator";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Occupancy = () => {
  const { path } = useRouteMatch();
  let location = useLocation();
  const dispatch = useDispatch();
  const [sizeOfData, setSize] = useState(10);
  let searchInput = useRef(null);
  const history = useHistory();
  const [form] = Form.useForm();
  const [changePage, setChangePage] = useState(1);
  const [totalCustomer, setTotalCustomer] = useState();
  const [CustomerListData, setCustomerListData] = useState([]);
  const [roomReservation, setRoomReservation] = useState([]);
  const [exportType, setExportType] = useState();
  const [loading, setLoading] = useState(false);
  let isMounted = useRef(true);
  const [modalVisible, setModelVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const csvLinkRef = useRef();
  const { startDate, endDate, dateChange, currentRegisterData } = useSelector(
    (state) => {
      return {
        startDate: state.receipts.paymnetModeStartDate
          ? state.receipts.paymnetModeStartDate
          : moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
        endDate: state.receipts.paymnetModeEndDate
          ? state.receipts.paymnetModeEndDate
          : moment()
              .endOf("month")
              .format("YYYY-MM-DD"),
        dateChange: state.receipts.paymnetModeDateChange,
        currentRegisterData:
          state.register.RegisterList?.length > 0 &&
          state.register.RegisterList?.find((val) => val.activeHotel),
      };
    }
  );
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  let searchText = "";
  async function fetchCustomerList(start, end) {
    setLoader(true);
    const response = await dispatch(
      getRoomOcccupancy(currentRegisterData?._id, start, end)
    );
    setLoader(false);
    if (response && !response.error) {
      let { bookingRoomList, noRoom } = response.data;
      let obj = {};
      for (let val of bookingRoomList) {
        let startDate = val.reservationInDateAndTime;
        let endDate = val.reservationOutDateAndTime;
        while (moment(startDate).isBefore(endDate)) {
          obj[moment(startDate).format("YYYY-MM-DD")];
          if (!obj[moment(startDate).format("YYYY-MM-DD")]) {
            let object = {
              perDayRevenue: Number(val.calculatedPriceWithoutTax),
              blocked: 0,
              checkin: val.isAssignIn ? 1 : 0,
              perOccupancyBlocked: 0,
              perOccupancyCheckIn: 0,
              perOccupancyReserved: 0,
              reserved: 1,
              total: noRoom,
              date: moment(startDate).format("YYYY-MM-DD"),
            };
            object["perOccupancyReserved"] =
              (object.reserved / object.total) * 100;
            if (object.checkin) {
              object["perOccupancyCheckIn"] =
                (object.checkin / object.total) * 100;
            }
            obj[moment(startDate).format("YYYY-MM-DD")] = object;
          } else {
            let object = obj[moment(startDate).format("YYYY-MM-DD")];
            object["reserved"] = object.reserved + 1;
            object["perOccupancyReserved"] =
              (object.reserved / object.total) * 100;
            object["checkin"] = val.isAssignIn
              ? object.checkin + 1
              : object.checkin;
            if (object.checkin) {
              object["perOccupancyCheckIn"] =
                (object.checkin / object.total) * 100;
            }
            if (object.perDayRevenue) {
              object["perDayRevenue"] =
                object.perDayRevenue + Number(val.calculatedPriceWithoutTax);
            }
            obj[moment(startDate).format("YYYY-MM-DD")] = {
              ...object,
            };
          }
          startDate = moment(startDate).add(1, "days");
        }
      }

      let object = {};
      let startDate = moment(start);
      let endDate = moment(end);
      while (moment(startDate).isBefore(endDate) || startDate.isSame(endDate)) {
        object[moment(startDate).format("YYYY-MM-DD")];
        if (!obj[moment(startDate).format("YYYY-MM-DD")]) {
          let object1 = {
            perDayRevenue: 0,
            blocked: 0,
            checkin: 0,
            perOccupancyBlocked: 0,
            perOccupancyCheckIn: 0,
            perOccupancyReserved: 0,
            reserved: 0,
            total: noRoom,
            date: moment(startDate).format("YYYY-MM-DD"),
          };

          object[moment(startDate).format("YYYY-MM-DD")] = object1;
        } else {
          let object1 = obj[moment(startDate).format("YYYY-MM-DD")];
          object[moment(startDate).format("YYYY-MM-DD")] = {
            ...object1,
          };
        }
        startDate = moment(startDate).add(1, "days");
      }
      console.log("SagarTanksasasaasasasasaasasas", object);
      let latestArray = Object.values(object);
      setRoomReservation(latestArray);
    } else if (response) {
      message.error(response.message);
    }
  }
  const didMountRegister = useRef(false);
  useEffect(() => {
    if (didMountRegister.current) {
      if (currentRegisterData) {
        fetchCustomerList(startDate, endDate);
      }
    } else {
      didMountRegister.current = true;
    }
  }, [currentRegisterData]);
  async function setStartDateAndDate(startDate, endDate) {
    dispatch(handlePaymnetModeDate(startDate, endDate, false));
  }
  useEffect(() => {
    let startDate = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    let endDate = moment()
      .endOf("month")
      .format("YYYY-MM-DD");
    fetchCustomerList(startDate, endDate);
    setStartDateAndDate(startDate, endDate);
  }, []);

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      if (dateChange) {
        fetchCustomerList(startDate, endDate);
      }
    } else {
      didMount.current = true;
    }
  }, [dateChange, startDate, endDate]);

  let email = localStorage.getItem("email_id");

  const onSubmit = async (values) => {
    setLoading(true);
    if (loading) {
      setModelVisible(false);
      setLoading(false);
    }
    values.type = exportType;
    let ExportCustomerAPI = await dispatch(ExportCustomer(values));
    // if (!ExportCustomerAPI.error) {
    //   setLoading(false);
    //   setModelVisible(false);
    // }
  };

  const handleCancel = (e) => {
    setModelVisible(false);
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadPdf();
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadExcel();
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadCSV();
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      render(text, record) {
        return {
          children: (
            <div>{commonFunction.convertToDate(text, "MMM DD, YYYY")}</div>
          ),
        };
      },
    },
    {
      title: "RESERVED",
      dataIndex: "reserved",
      key: "reserved",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "OCCUPIED",
      dataIndex: "checkin",
      key: "checkin",
      align: "left",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "AVAILABLE",
      dataIndex: "available",
      key: "available",
      align: "left",
      render(text, record) {
        return {
          children: (
            <div>
              {record.total - record.reserved > 0
                ? record.total - record.reserved
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: "TOTAL",
      dataIndex: "total",
      key: "total",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "OCCUPANCY",
      dataIndex: "perOccupancyCheckIn",
      key: "perOccupancyCheckIn",
      align: "left",
      render: (text) => <span>{Number(text).toFixed(2)}%</span>,
    },
    {
      title: "OCCUPANCY (INC. ARRIVALS)",
      dataIndex: "perOccupancyReserved",
      key: "perOccupancyReserved",
      align: "left",
      render: (text) => <span>{Number(text).toFixed(2)}%</span>,
    },
  ];

  const handleDownloadCSV = () => {
    csvLinkRef.current.link.click();
  };

  const handleDownloadPdf = () => {
    let col = [...columns].filter((val) => val.dataIndex != "action");
    const doc = new jsPDF();
    doc.text("Occupancy Report", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [col.map((column) => column.title)],
      body: roomReservation.map((row) =>
        col.map((column) =>
          column.dataIndex == "perOccupancyReserved"
            ? `${Number(row["perOccupancyReserved"]).toFixed(2)}%`
            : column.dataIndex == "perOccupancyCheckIn"
            ? `${Number(row["perOccupancyCheckIn"]).toFixed(2)}%`
            : column.dataIndex == "available"
            ? `${
                row["total"] - row["reserved"] > 0
                  ? row["total"] - row["reserved"]
                  : 0
              }`
            : column.dataIndex == "date"
            ? commonFunction.convertToDate(
                row[column.dataIndex],
                "MMM DD, YYYY"
              )
            : row[column.dataIndex]
        )
      ),
    });
    doc.save("Occupancy.pdf");
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      roomReservation.map((k) => ({
        DATE: commonFunction.convertToDate(k.date, "MMM DD, YYYY"),
        RESERVED: k.reserved,
        OCCUPIED: k.checkin,
        AVAILABLE: k.total - k.reserved > 0 ? k.total - k.reserved : 0,
        TOTAL: k.total,
        OCCUPANCY: `${Number(k.perOccupancyCheckIn).toFixed(2)}%`,
        "OCCUPANCY (INC. ARRIVALS)": `${Number(k.perOccupancyReserved).toFixed(
          2
        )}%`,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Occupancy.xlsx");
  };

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div className="table_titles">
                  <h2>Occupancy</h2>
                  {/* <span className="title-counter">
                    {roomReservation?.length}
                  </span> */}
                </div>
                {/* <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Input
                    suffix={<SearchOutlined />}
                    autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                    placeholder="Search by Mobile no"
                    style={{
                      borderRadius: "30px",
                      width: "250px",
                    }}
                    onChange={(e) => onSearch(e)}
                    onKeyPress={(event) => {
                      if (event.key.match("[0-9]+")) {
                        return true;
                      } else {
                        return event.preventDefault();
                      }
                    }}
                  />
                </div> */}
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions custom_action">
                <div className="actionBtn">
                  <Tooltip
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Showing master from{" "}
                        {startDate
                          ? moment(startDate).format("MMMM Do YYYY")
                          : moment()
                              .startOf("month")
                              .format("MMMM Do YYYY")}
                        {" to"}{" "}
                        {endDate
                          ? moment(endDate).format("MMMM Do YYYY")
                          : moment()
                              .endOf("month")
                              .format("MMMM Do YYYY")}
                      </p>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    />
                  </Tooltip>
                  <CalendarButtonPageHeader key="7" type="payment-mode" />
                </div>
                <div className="actionBtn">
                  <Popover
                    placement="bottomLeft"
                    content={content}
                    trigger="click"
                  >
                    <Button size="middle" type="white">
                      <FeatherIcon icon="download" size={14} />
                      Export
                    </Button>
                  </Popover>
                </div>
              </div>,
            ]}
          />
        </CardToolbox>
        <CSVLink
          style={{ display: "none" }}
          data={roomReservation.map((k) => ({
            date: commonFunction.convertToDate(k.date, "MMM DD, YYYY"),
            reserved: k.reserved,
            checkin: k.checkin,
            available: k.total - k.reserved > 0 ? k.total - k.reserved : 0,
            total: k.total,
            perOccupancyCheckIn: `${Number(k.perOccupancyCheckIn).toFixed(2)}%`,
            perOccupancyReserved: `${Number(k.perOccupancyReserved).toFixed(
              2
            )}%`,
          }))}
          headers={[...columns]
            .filter((val) => val.dataIndex != "action")
            .map((column) => ({
              label: column.title,
              key: column.dataIndex,
            }))}
          filename={"Occupancy.csv"}
          ref={csvLinkRef}
        >
          Hidden Download CSV Link
        </CSVLink>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="_id"
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                        pagination={{
                          pageSizeOptions: ["10", "50", "100", "500", "1000"],
                          showSizeChanger: true,
                          total: roomReservation.length,
                        }}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        size="small"
                        dataSource={roomReservation}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 1200 }}
                        pagination={{
                          pageSizeOptions: ["10", "50", "100", "500", "1000"],
                          showSizeChanger: true,
                          total: roomReservation.length,
                        }}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
        <Modal
          title="Export Occupancy"
          visible={modalVisible}
          onOk={form.submit}
          okText={"OK"}
          onCancel={handleCancel}
          width={600}
        >
          <Form form={form} name="export_customer" onFinish={onSubmit}>
            <div className="add-product-block">
              <div className="add-product-content">
                {loading ? (
                  <p>
                    It would take about 5 to 10 minutes to export and you will
                    be send by email.
                  </p>
                ) : (
                  <Form.Item
                    name="email"
                    label="Send to Email Address"
                    initialValue={email}
                    rules={[
                      { message: "Email address is required", required: true },
                      { type: "email", message: "A valid semail is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
              </div>
            </div>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Occupancy;
