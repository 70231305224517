import React, { useState, useEffect, useRef, useContext } from "react";
import { Row, Col, Tag, Spin, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "../sell.css";
import { getAllRoomList } from "../../../redux/sell/actionCreator";
import { getItem } from "../../../utility/localStorageControl";
import {
  setItem,
  getCartInfoLocalListsData,
} from "../../../utility/localStorageControl";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { SocketProvider, SocketContext } from "../../../socket/socketContext";
import { getReservationList } from "../../../redux/reservation/actionCreator";
const Room = (props) => {
  const qrSocket = useContext(SocketContext);
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const [loading, setLoading] = useState(true);
  let {
    CustomTableData,
    setCustomTableOrderInLocalStorageHandler,
    handleSplit,
  } = props;
  const dispatch = useDispatch();
  let { createNewTakeawayInLocalStorageHandler, searchText } = props;
  let [takeAwayData, settakeAwayData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  let {
    createNewDeliveryInLocalStorageHandler,
    getAllDeliveryDataInLocalFn,
    getDeliveryInLocalStorageHandler,
  } = props;
  let [allTableList, setAllTableList] = useState([]);
  let [checkInRoomList, setCheckInRoomList] = useState([]);
  let [availableRoomList, setAvailbleRoomList] = useState([]);
  let isMounted = useRef(true);
  const currentRegisterData = useSelector((state) =>
    state.register.RegisterList.find((val) => val.active)
  );
  const fetchPoseaseRoom = async () => {
    setLoading(true);
    let getReservationListData = await dispatch(
      getReservationList(currentRegisterData.hotel_register, "room")
    );

    if (getReservationListData && getReservationListData.error == false) {
      let { data } = getReservationListData;
      let roomObj = {};
      data.reverse().map((k) => {
        k.roomInfo.map((o) => {
          if (o.status != "checkout") {
            if (roomObj[o.roomId]) {
              if (roomObj[o.roomId].status == "checkin") {
                roomObj[o.roomId] = { ...roomObj[o.roomId] };
              } else {
                roomObj[o.roomId] = o;
              }
            } else {
              roomObj[o.roomId] = o;
            }
          }
        });
      });
      const allTableList = await dispatch(
        getAllRoomList(currentRegisterData.hotel_register)
      );
      if (isMounted.current && allTableList && !allTableList.error) {
        let checkIn = [];
        let available = [];
        allTableList.data.map((val) => {
          if (val.table_name != "Takeaway/Delivery") {
            if (roomObj[val.uuid] && roomObj[val.uuid].status == "checkin") {
              let updateRoomInfo = roomObj[val.uuid];
              let body = {
                checkIn: true,
                reservation_id: updateRoomInfo?.reservationId,
                roomInfo: {
                  guests: [
                    {
                      name: updateRoomInfo?.customerInfo?.name,
                      customerId: updateRoomInfo?.customerInfo?._id,
                    },
                  ],
                  occupancy: updateRoomInfo?.guest,
                  ratePlanName: updateRoomInfo?.rate_plan?.rate_name,
                },
              };
              checkIn.push({
                ...val,
                register_id: currentRegisterData?._id,
                ...body,
              });
            } else {
              available.push({
                ...val,
                register_id: currentRegisterData?._id,
              });
            }
          }
        });
        setCheckInRoomList(checkIn);
        setAvailbleRoomList(available);
        setAllTableList(allTableList.data);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (currentRegisterData.hotel_register) {
      fetchPoseaseRoom();
    } else if (currentRegisterData && currentRegisterData.hotelDetalis) {
      fetchAllTableList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (
  //       isMounted.current == false &&
  //       currentRegisterData &&
  //       currentRegisterData.hotelDetalis
  //     ) {
  //       fetchAllTableList();
  //     } else if (currentRegisterData.hotel_register) {
  //       fetchPoseaseRoom();
  //     }
  //   }, 700);
  // }, [loading]);

  const fetchAllTableList = async () => {
    setLoading(true);
    const allTableList = await dispatch(
      getAllRoomList(
        currentRegisterData && currentRegisterData._id,
        currentRegisterData &&
          currentRegisterData?.hotelDetalis &&
          currentRegisterData?.hotelDetalis?.hotelId
      )
    );
    // let body = {
    //   checkIn: true,
    //   reservation_id: reservationData?._id,
    //   roomInfo: {
    //     guests: [
    //       {
    //         name: updateRoomInfo?.customerInfo?.name,
    //         customerId: updateRoomInfo?.customerInfo?._id,
    //       },
    //     ],
    //     occupancy: updateRoomInfo?.guest,
    //     ratePlanName: updateRoomInfo?.rate_plan?.rate_name,
    //   },
    // };
    console.log("hkhjfkahhkfsahlfhsafhafhasfsafsafa", allTableList);
    if (isMounted.current && allTableList && !allTableList.error) {
      let checkIn = [];
      let available = [];
      allTableList.data.map((val) => {
        return val.checkIn ? checkIn.push(val) : available.push(val);
      });
      setCheckInRoomList(checkIn);
      setAvailbleRoomList(available);
      setAllTableList(allTableList.data);
      setLoading(false);
    }
  };
  function setTableStatusOccupied(tableName, table) {
    setItem("bookingDetails", false);
    let tableNameStr = tableName.replace(/\s+/g, "-").toLowerCase();
    setCustomTableOrderInLocalStorageHandler(tableName, tableNameStr, table);
  }

  let filterArray = allTableList.filter((value) => {
    return value.table_type.toLowerCase().indexOf("take-away") !== -1;
  });

  //Delivery
  let filterDeliveryArray = allTableList.filter((value) => {
    return value.table_type.toLowerCase().indexOf("delivery") !== -1;
  });

  let AllTableList = [];
  filterArray.map((value) => {
    AllTableList.push({
      prefix: "take-away",
      table_name: value.table_prefix,
    });
  });
  takeAwayData.map((values) => {
    if (values.Status != "Delete") {
      AllTableList.push({
        tablekey: values.tablekey,
        table_name: values.tableName,
        cartKey: values.cartKey,
      });
    }
  });

  filterDeliveryArray.map((value) => {
    AllTableList.push({
      prefix: "Delivery",
      table_name: value.table_prefix,
    });
  });
  deliveryData.map((values) => {
    if (values.Status != "Delete") {
      AllTableList.push({
        tablekey: values.tablekey,
        table_name: values.tableName,
        cartKey: values.cartKey,
      });
    }
  });
  CustomTableData.map((table) => {
    table.rows.map((value) => {
      AllTableList.push({
        table_name: value,
      });
    });
  });
  availableRoomList = availableRoomList.filter((value) =>
    value?.table_name?.toLowerCase().includes(searchText.toLowerCase())
  );
  checkInRoomList = checkInRoomList.filter((value) =>
    value?.table_name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const [localAllTableData, setLocalTableData] = useState([
    ...getCartInfoLocalListsData(currentRegisterData),
  ]);

  console.log("sdashdjgdjasgdjsagdjagdgajgas", availableRoomList);
  const hanldeRefrsesh = () => {
    if (currentRegisterData.hotel_register) {
      fetchPoseaseRoom();
    } else if (currentRegisterData && currentRegisterData.hotelDetalis) {
      fetchAllTableList();
    }
  };

  useEffect(() => {
    if (currentRegisterData && currentRegisterData.hotel_register) {
      qrSocket?.on(
        `send-checkin-table/${currentRegisterData.hotel_register}`,
        async (tableResponse) => {
          if (tableResponse) {
            hanldeRefrsesh();
          }
        }
      );
    }
  }, [currentRegisterData, qrSocket]);
  return (
    <div
      className="sell-table-parent all-parent list-boxmain"
      // style={{ alignContent: "center" }}
    >
      {loading ? (
        <Row>
          {Array.from({ length: 35 }).map((val, index) => (
            <>
              <Col
                xxl={4}
                lg={4}
                xl={4}
                sm={12}
                xs={24}
                className="sell-table-col"
                key={index}
              >
                <div className={"loader-empty"}>
                  <div>
                    <Skeleton.Input
                      active={true}
                      size={"default"}
                      block={false}
                      style={{ height: "100px" }}
                    />
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
      ) : (
        <div>
          <div className="roomBtnBox">
            <Tag
              color="#43ac6a"
              style={{
                marginLeft: "5px",
                marginTop: "8px",
                padding: "4px",
                fontSize: "12px",
              }}
            >
              In-house
            </Tag>

            <p onClick={() => hanldeRefrsesh()}>
              <ReloadOutlined /> Refresh
            </p>
          </div>

          <Row gutter={[2, 2]} className="all-row list-box-row">
            {checkInRoomList.length > 0 ? (
              checkInRoomList.map((values, index) => {
                let tableData = localAllTableData?.find(
                  (itm) =>
                    itm.tablekey ==
                      values.table_name.replace(/\s+/g, "-").toLowerCase() &&
                    itm.register_id === currentRegisterData._id
                );
                const status = tableData?.Status
                  ? tableData.Status
                  : "sell-main-order";
                return (
                  <>
                    <Col
                      xxl={4}
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={24}
                      className="sell-table-col"
                      key={index}
                    >
                      <div
                        className={
                          status == "In Progress"
                            ? "sell-main-order"
                            : status == "Unpaid"
                            ? "sell-unpaid"
                            : "sell-empty"
                        }
                        onClick={() =>
                          setTableStatusOccupied(values.table_name, values)
                        }
                      >
                        <div>
                          <div className="sell-table-counter">
                            <div className="counter_served">
                              {values.table_name}
                            </div>
                            {values.roomInfo &&
                              values.roomInfo.guests &&
                              values.roomInfo.guests.length && (
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                  {values.roomInfo.guests[0].name}
                                </p>
                              )}

                            {values.roomInfo &&
                              values.roomInfo.guests &&
                              values.roomInfo.guests.length && (
                                <p style={{ margin: 0, fontSize: "12px" }}>
                                  {`${values.roomInfo.occupancy.adults +
                                    values.roomInfo.occupancy.children +
                                    values.roomInfo.occupancy.infants} `}
                                  guests - {values.roomInfo.ratePlanName}
                                </p>
                              )}

                            <div className="postion">
                              <div className="product-price inlineDIv">
                                {tableData &&
                                  tableData.cartKey ==
                                    getItem("active_cart") && (
                                    <span className="active-dots" />
                                  )}
                                {status != "sell-main-order" && status}
                                {status == "Unpaid" && (
                                  <span>{` ${rsSymbol}${Number(
                                    tableData?.otherDetails?.finalCharge
                                  ).toFixed(2)}`}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })
            ) : (
              <Col
                xxl={4}
                lg={4}
                xl={4}
                sm={12}
                xs={24}
                className="sell-table-col"
              >
                <div className={"sell-empty"}>
                  <div>
                    <div className="sell-table-counter">
                      <div className="counter_served">No rooms found</div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Tag
            color="#008cba"
            style={{
              marginLeft: "5px",
              marginTop: "8px",
              padding: "4px",
              fontSize: "12px",
            }}
          >
            Available
          </Tag>
          <Row gutter={[2, 2]} className="all-row list-box-row">
            {availableRoomList.length > 0 ? (
              availableRoomList.map((values, index) => {
                return (
                  <>
                    <Col
                      xxl={4}
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={24}
                      className="sell-table-col"
                      key={index}
                      style={{ cursor: "not-allowed" }}
                    >
                      <div
                        className={"sell-empty"}
                        style={{ cursor: "not-allowed" }}
                      >
                        <div style={{ cursor: "not-allowed" }}>
                          <div
                            className="sell-table-counter"
                            style={{ cursor: "not-allowed" }}
                          >
                            <div
                              className="counter_served"
                              style={{ cursor: "not-allowed" }}
                            >
                              {values.table_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })
            ) : (
              <Col
                xxl={4}
                lg={4}
                xl={4}
                sm={12}
                xs={24}
                className="sell-table-col"
              >
                <div className={"sell-empty"}>
                  <div className="sell-table-counter">
                    <div className="counter_served">No rooms found</div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export { Room };
