import React, { useState, useRef, useEffect } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual, Switch } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Radio,
  Button,
  Modal,
  Form,
  Pagination,
  Spin,
  message,
  Select,
} from "antd";
import { adminSubscripe } from "../../redux/users/actionCreator";
import {
  ClockCircleFilled,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "./Style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import "./UserManagement.css";
import { LoadingOutlined, CrownOutlined } from "@ant-design/icons";
import { getItem, setItem } from "../../utility/localStorageControl";
import {
  addOrUpdateApplication,
  getApplicationById,
} from "../../redux/applicatons/actionCreator";
import { connectChannel } from "../../redux/register/actionCreator";
function SubscriptionDetail() {
  let location = useLocation();
  const history = useHistory();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const dispatch = useDispatch();
  let isMounted = useRef(true);
  const [registerList, setRegisterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModelVisible] = useState(false);
  const [modalVisible1, setModelVisible1] = useState({
    visible: false,
    registerID: "",
    connect_channel: false,
  });
  let [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [planList, setPlanList] = useState([
    {
      _id: "price_1PV50ESGLUrWaacTU80aLVSR",
      name: "RESTAURANT STARTER",
      isActive: false,
      type: "pos",
      rs: 3600,
    },
    {
      _id: "price_1PV52DSGLUrWaacTX2GxPVcc",
      name: "RESTAURANT PRO",
      isActive: false,
      type: "pos",
      rs: 5000,
    },
    {
      _id: "price_1PV54bSGLUrWaacTZMOz5Qqf",
      name: "HOTEL PRO",
      isActive: false,
      type: "pms",
      rs: 10000,
    },
  ]);
  const [hotelDetails, setHotelDetails] = useState({
    id: "",
    visible: false,
    checkEnable: false,
  });
  const [registerDetails, setRegisterDetails] = useState(false);

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");
  const [userId, setUserId] = useState();
  const getData = async () => {
    setLoading(true);
    if (location?.state?.registerList.length > 0) {
      setRegisterList(location?.state?.registerList);
      setLoading(false);
    } else {
      setRegisterList([]);
      setLoading(false);
    }
    if (location?.state?.user_id) {
      setUserId(location?.state?.user_id);
    }
  };
  useEffect(() => {
    if (isMounted.current) {
      getData();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handlePagination = (page, pageNumber) => {
    // console.log("🚀 ~ file: UserManagement.js:72 ~ handlePagination ~ page:", page)
    getData(page, pageNumber);
    setCurrentPage(page);
    setDataPerPage(pageNumber);
  };

  let searchArr =
    registerList &&
    registerList.filter((value) =>
      value.register_name.toLowerCase().includes(search.toLowerCase())
    );

  const dataSource = [];
  if (registerList && registerList.length > 0) {
    searchArr.map((value, i) => {
      const {
        _id,
        register_name,
        subscription_status,
        subscriptionPriceId,
        type,
        connect_channel,
        channel_key,
        ota_hotel_code,
        ota_token,
      } = value;
      return dataSource.push({
        id: _id,
        register_name: register_name,
        key: i,
        subscription_status: subscription_status,
        subscriptionPriceId: subscriptionPriceId,
        type,
        connect_channel,
        channel_key,
        ota_hotel_code,
        ota_token,
      });
    });
  }
  const showChannel = (register, connect) => {
    form2.setFieldsValue({
      channel_key: register.channel_key ? register?.channel_key : "",
      ota_hotel_code: register.ota_hotel_code ? register?.ota_hotel_code : "",
      ota_token: register?.ota_token ? register?.ota_token : "",
    });
    setModelVisible1({
      visible: true,
      registerID: register?.id,
      connect_channel: connect,
    });
  };

  const columns = [
    {
      title: "Register Name",
      dataIndex: "register_name",
      key: "register_name",
    },
    {
      title: "Subscription Status",
      dataIndex: "subscription_status",
      key: "subscription_status",
      render(text, record) {
        return record?.subscription_status ? record?.subscription_status : "-";
      },
    },
    {
      title: "Plan Name",
      dataIndex: "subscriptionPriceId",
      key: "subscriptionPriceId",
      render(text, record) {
        let name = "";
        let find = planList.find((val) => val._id == text);
        return find ? find?.name : "-";
      },
    },
    {
      title: "Channel manager",
      dataIndex: "abc",
      key: "abc",
      render(text, record) {
        console.log("record", record);
        if (record.type == "hotel") {
          return (
            <div className="subscribeBtn">
              <CrownOutlined
                onClick={() => showChannel(record, true)}
                style={{ marginRight: "10px" }}
              />
              <CloseOutlined
                onClick={() => showChannel(record, false)}
                style={{ marginRight: "10px" }}
              />
            </div>
          );
        }
      },
    },
    {
      title: "Channel Connected",
      dataIndex: "connect_channel",
      key: "connect_channel",
      render(text, record) {
        console.log("record", record);
        if (record.type == "hotel") {
          return <div>{text ? "Yes" : "No"}</div>;
        }
      },
    },
    {
      title: "Subscribe",
      width: "10%",
      render(value, record) {
        return {
          props: { style: { textAlign: "center", display: "flex" } },
          children: (
            <>
              <div
                onClick={showModal.bind("", record)}
                className="subscribeBtn"
              >
                <CrownOutlined />
              </div>
            </>
          ),
        };
      },
    },

    {
      title: "Hotel management",
      render(value, record) {
        return {
          children: (
            <>
              <Button onClick={() => handleHotel(record)}>Hotel</Button>
            </>
          ),
        };
      },
    },
  ];

  const showModal = (id) => {
    if (id && id !== "") {
      console.log("bdhfjdsfgjkfdsgfkfsdfdfsadfs", id);
      setRegisterDetails(id);
      setModelVisible(true);
      // setUserId(id);
    }
  };

  const confirmSubscription = async (value) => {
    if (registerDetails) {
      console.log("sdgahjdgasjdasdasdghjk", value, registerDetails);
      let findPlan = planList.find((h) => h._id == value.plan_id);
      const response = await dispatch(
        adminSubscripe({
          userId: userId,
          register_id: registerDetails.id,
          action:
            registerDetails.subscription_status == "subscribed"
              ? "unsubscribe"
              : "subscribe",
          price: findPlan?.rs,
          plan_id: value?.plan_id,
        })
      );
      console.log("hdkjasddasdas", response);
      if (response.data) {
        message.success({
          content: response.data,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      }

      history.push("/admin/user-management");
      setModelVisible(false);
    }
  };
  const confirmChannel = async (formData) => {
    if (modalVisible1.visible && modalVisible1.registerID != "") {
      formData["connect_channel"] = modalVisible1.connect_channel;
      const response = await dispatch(
        connectChannel(formData, modalVisible1.registerID)
      );
      if (response) {
        setModelVisible1({ visible: false, registerID: "" });
        history.push("/admin/user-management");
      } else {
        history.push("/admin/user-management");
      }
    }
  };
  const confirmationHotel = async () => {
    if (hotelDetails && hotelDetails.id != "") {
      const response = await dispatch(
        addOrUpdateApplication(
          {
            hotel_enable: { enable: hotelDetails.checkEnable ? false : true },
          },
          { _id: hotelDetails.id }
        )
      );

      if (response.data) {
        message.success({
          content: response.data,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      }
      setHotelDetails({ id: "", visible: false, checkEnable: false });
      history.push("/admin/user-management");
    }
  };
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const handleHotel = async (record) => {
    if (record) {
      let response = await dispatch(getApplicationById({ _id: record.id }));
      console.log("dhjsdgadjsgdgajsdgdas", response);
      if (response) {
        setHotelDetails({
          id: record.id,
          visible: true,
          checkEnable: response.hotel_enable.enable ? true : false,
        });
      } else {
        setHotelDetails({
          id: record.id,
          visible: true,
          checkEnable: false,
        });
      }
    }
  };

  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            className="admin-custom-pageheader"
            ghost
            title="Register List"
            subTitle={
              <>
                <span className="title-counter">
                  {registerList && registerList.length} registers
                </span>
                <Input
                  suffix={<SearchOutlined />}
                  autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  placeholder="Search by register name"
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                    marginLeft: "10px",
                  }}
                />
              </>
            }
            buttons={
              <Button
                className="ant-btn ant-btn-primary ant-btn-md"
                style={{ color: "#FFF" }}
                onClick={() => history.push(`/admin/user-management`)}
              >
                <FeatherIcon
                  icon="arrow-left"
                  size={16}
                  className="pls_iconcs"
                />
                Go Back
              </Button>
            }
          />
        </CardToolbox>
        <Cards headless>
          <Row gutter={15}>
            <Col span={24}>
              <TableWrapper className="table-order table-responsive">
                {loading ? (
                  <Table
                    rowKey="id"
                    locale={locale}
                    dataSource={[]}
                    columns={columns}
                    size="small"
                    style={{ marginTop: "8px" }}
                    scroll={{ x: "100px" }}
                  />
                ) : (
                  <>
                    <Table
                      rowKey="id"
                      size="small"
                      dataSource={dataSource}
                      columns={columns}
                      fixed={true}
                      scroll={{ x: 800 }}
                      pagination={false}
                    />
                    <Pagination
                      className="userManagement_Pagination"
                      total={dataSource && dataSource.length}
                      current={currentPage}
                      onChange={handlePagination}
                      pageSizeOptions={[10, 20, 50, 100]}
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`
                      }
                      pageSize={dataPerPage}
                      showSizeChanger
                    />
                  </>
                )}
              </TableWrapper>
            </Col>
          </Row>
          <Modal
            title="Confirm Subscription"
            okText="Confirm"
            open={modalVisible}
            onOk={form1.submit}
            onCancel={() => setModelVisible(false)}
            width={600}
          >
            <Form
              style={{ width: "100%" }}
              name="Export"
              form={form1}
              onFinish={confirmSubscription}
            >
              {registerDetails.subscription_status == "subscribed" ? (
                <p>Are you sure want to unsubscribe this users?</p>
              ) : (
                <div className="add-product-block">
                  <div className="add-product-content">
                    <Form.Item
                      name="plan_id"
                      label="Choose a plan"
                      rules={[
                        {
                          required: true,
                          message: "Please select plan",
                        },
                      ]}
                    >
                      <Select style={{ width: "100%" }}>
                        {planList
                          .filter((data) => {
                            if (
                              registerDetails.type == "restaurant" &&
                              data.type == "pos"
                            ) {
                              return true;
                            } else if (
                              registerDetails.type == "hotel" &&
                              data.type == "pms"
                            ) {
                              return true;
                            }
                          })
                          .map((data) => {
                            return (
                              <Option value={data._id}>{data.name}</Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              )}
            </Form>
          </Modal>
          <Modal
            title="Confirm Channel"
            okText="Confirm"
            open={modalVisible1.visible}
            onOk={form2.submit}
            onCancel={() =>
              setModelVisible1({
                visible: false,
                registerID: "",
              })
            }
            width={600}
          >
            <Form
              style={{ width: "100%" }}
              name="Export1"
              form={form2}
              onFinish={confirmChannel}
            >
              <div className="add-product-block">
                {modalVisible1?.connect_channel ? (
                  <div className="add-product-content">
                    <Form.Item
                      name="channel_key"
                      label="Channel key"
                      rules={[
                        {
                          required: true,
                          message: "Please enter channel key",
                        },
                      ]}
                    >
                      <Input placeholder="Enter key"></Input>
                    </Form.Item>
                    <Form.Item
                      name="ota_hotel_code"
                      label="Hotel Code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter hotel Code",
                        },
                      ]}
                    >
                      <Input placeholder="Enter hotel Code"></Input>
                    </Form.Item>
                    <Form.Item
                      name="ota_token"
                      label="Token"
                      rules={[
                        {
                          required: true,
                          message: "Please enter hotel token",
                        },
                      ]}
                    >
                      <Input placeholder="Enter hotel token"></Input>
                    </Form.Item>
                  </div>
                ) : (
                  <div>
                    <p>Are you sure want to close channel this register?</p>
                  </div>
                )}
              </div>
            </Form>
          </Modal>
          <Modal
            title="Confirm Hotel"
            okText="Confirm"
            open={hotelDetails.visible}
            onOk={confirmationHotel}
            onCancel={() =>
              setHotelDetails({ id: "", visible: false, checkEnable: false })
            }
            width={600}
          >
            are you sure to hotel{" "}
            {hotelDetails.checkEnable ? "Disable ?" : "Enable ?"}
          </Modal>
        </Cards>
      </Main>
    </>
  );
}

export default SubscriptionDetail;
