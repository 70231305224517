import React, { Suspense, useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Skeleton,
  Empty,
  Divider,
  Button,
  message,
  Spin,
} from "antd";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../styled";
import { useDispatch, useSelector } from "react-redux";
import { getSaleReport } from "../../../redux/HotelReports/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const saleReport = () => {
  const dispatch = useDispatch();
  const [saleLoader, setSaleLoader] = useState(true);
  const [saleData, setSaleData] = useState();
  let currentDate = moment();
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  async function fetchSaleReport() {
    setSaleLoader(true);
    const response = await dispatch(getSaleReport(currentRegisterData?._id));
    setSaleLoader(false);
    if (response && !response.error) {
      setSaleData(response.data);
    } else if (response) {
      message.error(response.message);
    }
  }
  useEffect(() => {
    fetchSaleReport();
  }, []);
  return (
    <>
      <Main
        className="padding-top-form mobilepad_frm"
        style={{
          margin: "95px 0 0 0",
        }}
      >
        <div>
          <h1 style={{ textAlign: "center", fontWeight: "700", fontSize: 25 }}>
            Sales Report{" "}
          </h1>
          <Row justify="center" gutter={25} type="flex">
            <Col
              xxl={8}
              xl={8}
              lg={8}
              xs={24}
              style={{ paddingBottom: "25px" }}
            >
              <Cards title={currentDate.format("ddd, DD MMM YYYY")}>
                {saleLoader ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          margin: "0px 14px",
                          textAlign: "center",
                        }}
                      />
                    }
                  />
                ) : (
                  <div>
                    <p>
                      Confirmed bookings :{" "}
                      {saleData?.thisDayConfirmBookingCount}
                    </p>
                    <p>
                      Confirmed room nights : {saleData?.thisDayRoomNightsCount}
                    </p>
                    <p>On hold bookings : {0}</p>
                    <p>On hold room nights : {0}</p>
                    <p>No show bookings : {0}</p>
                    <p>No show room nights : {0}</p>
                    <p>Cancelled bookings : {0}</p>
                    <p>Cancelled room nights : {0}</p>
                  </div>
                )}
              </Cards>
            </Col>
            <Col
              xxl={8}
              xl={8}
              lg={8}
              xs={24}
              style={{ paddingBottom: "25px" }}
            >
              <Cards
                title={`${currentDate.format(
                  "MMM YYYY"
                )} (until ${currentDate
                  .endOf("month")
                  .format("ddd, DD MMM YYYY")})`}
              >
                {saleLoader ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          margin: "0px 14px",
                          textAlign: "center",
                        }}
                      />
                    }
                  />
                ) : (
                  <div>
                    <p>
                      Confirmed bookings :{" "}
                      {saleData?.thisMonthConfirmBookingCount}
                    </p>
                    <p>
                      Confirmed room nights :{" "}
                      {saleData?.thisMonthRoomNightsCount}
                    </p>
                    <p>On hold bookings : {0}</p>
                    <p>On hold room nights : {0}</p>
                    <p>No show bookings : {0}</p>
                    <p>No show room nights : {0}</p>
                    <p>Cancelled bookings : {0}</p>
                    <p>Cancelled room nights : {0}</p>
                  </div>
                )}
              </Cards>
            </Col>

            <Col
              xxl={8}
              xl={8}
              lg={8}
              xs={24}
              style={{ paddingBottom: "25px" }}
            >
              <Cards title={`For the year ${currentDate.format("YYYY")}`}>
                {saleLoader ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          margin: "0px 14px",
                          textAlign: "center",
                        }}
                      />
                    }
                  />
                ) : (
                  <div>
                    <p>
                      Confirmed bookings :{" "}
                      {saleData?.thisYearConfirmBookingCount}
                    </p>
                    <p>
                      Confirmed room nights :{" "}
                      {saleData?.thisYearRoomNightsCount}
                    </p>
                    <p>On hold bookings : {0}</p>
                    <p>On hold room nights : {0}</p>
                    <p>No show bookings : {0}</p>
                    <p>No show room nights : {0}</p>
                    <p>Cancelled bookings : {0}</p>
                    <p>Cancelled room nights : {0}</p>
                  </div>
                )}
              </Cards>
            </Col>
          </Row>
        </div>
        <div>
          <h1 style={{ textAlign: "center", fontWeight: "700", fontSize: 25 }}>
            Revenue Report{" "}
          </h1>
          <Row justify="center" gutter={25} type="flex">
            <Col
              xxl={8}
              xl={8}
              lg={8}
              xs={24}
              style={{ paddingBottom: "25px" }}
            >
              <Cards title={currentDate.format("ddd, DD MMM YYYY")}>
                {saleLoader ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          margin: "0px 14px",
                          textAlign: "center",
                        }}
                      />
                    }
                  />
                ) : (
                  <div>
                    <p>Room charges : {saleData?.thisDayRoomCharges}</p>
                    <p>Room charges tax : {saleData?.thisDayRoomChargesTax}</p>
                    <p>Revenue : {saleData?.thisDayGrandTotal}</p>
                  </div>
                )}
              </Cards>
            </Col>
            <Col
              xxl={8}
              xl={8}
              lg={8}
              xs={24}
              style={{ paddingBottom: "25px" }}
            >
              <Cards
                title={`${currentDate.format(
                  "MMM YYYY"
                )} (until ${currentDate
                  .endOf("month")
                  .format("ddd, DD MMM YYYY")})`}
              >
                {saleLoader ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          margin: "0px 14px",
                          textAlign: "center",
                        }}
                      />
                    }
                  />
                ) : (
                  <div>
                    <p>Room charges : {saleData?.thisMonthRoomCharges}</p>
                    <p>
                      Room charges tax : {saleData?.thisMonthRoomChargesTax}
                    </p>
                    <p>Revenue : {saleData?.thisMonthGrandTotal}</p>
                  </div>
                )}
              </Cards>
            </Col>

            <Col
              xxl={8}
              xl={8}
              lg={8}
              xs={24}
              style={{ paddingBottom: "25px" }}
            >
              <Cards title={`For the year ${currentDate.format("YYYY")}`}>
                {saleLoader ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          margin: "0px 14px",
                          textAlign: "center",
                        }}
                      />
                    }
                  />
                ) : (
                  <div>
                    <p>Room charges : {saleData?.thisYearRoomCharges}</p>
                    <p>Room charges tax : {saleData?.thisYearRoomChargesTax}</p>
                    <p>Revenue : {saleData?.thisYearGrandTotal}</p>
                  </div>
                )}
              </Cards>
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
};

export default saleReport;
