import React, { useEffect, useState } from "react";
import "./productEditModal.css";
import { Modal, Form, Select } from "antd";
import { getAllRoomList } from "../../../redux/sell/actionCreator";
import {
  getTableStatusFromId,
  swaptable,
} from "../../../utility/localStorageControl";
import { getItem } from "../../../utility/localStorageControl";
import { useDispatch, useSelector } from "react-redux";
import { getReservationList } from "../../../redux/reservation/actionCreator";
const SwapTableModal = (props, ref) => {
  const userDetails = getItem("userDetails");
  let {
    table_name,
    swapTableNameList,
    setlocalCartInfo,
    setTableName,
    localCartInfo,
    registerData,
    setSwapModalVisible,
    swapModalVisible,
  } = props;
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [swapTableList, setSwapTableList] = useState(swapTableNameList);
  const handleCancel = () => {
    setSwapModalVisible(false);
  };

  useEffect(() => {
    if (registerData) {
      if (registerData.table_numbers != "") {
        let customTablesInfo = [];
        let tableNosArray = registerData.table_numbers.split("),");
        let finalTableArray = [];
        let tableNosName;
        let tableNosRange;
        let splitedTbs;
        let roomArray = [];
        let i;
        if (getItem("create_receipt_while_fullfilling_booking")) {
          finalTableArray.push({
            name: "Reservation",
            rows: [`New Reservation`],
            status: "Empty",
          });
        }
        tableNosArray.forEach((items) => {
          let inputNumberItem = items[0];
          if (items[0] == 1) {
            if (items.indexOf("-") > -1) {
              tableNosRange = items.split("-");
              tableNosRange[0] = parseInt(tableNosRange[0]);
              tableNosRange[1] = parseInt(tableNosRange[1]);

              if (tableNosRange[0] > tableNosRange[1]) {
                for (i = tableNosRange[1]; i <= tableNosRange[0]; i++) {
                  roomArray.push("Table" + " " + i);
                }
              } else {
                for (i = tableNosRange[0]; i <= tableNosRange[1]; i++) {
                  roomArray.push("Table" + " " + i);
                }
              }
            } else {
              tableNosRange = items.split(",");
              tableNosRange.forEach((items) => {
                roomArray.push("Table" + " " + items);
              });
            }

            i = 1;
            finalTableArray.forEach((item) => {
              if (item.name == "Table") {
                i = 2;
                item.rows = roomArray;
              }
            });
            if (i == 1) {
              finalTableArray.push({
                name: "Table",
                status: "Empty",
                rows: roomArray,
              });
            }
          } else if (
            isNaN(inputNumberItem) &&
            items &&
            items.indexOf("-") > -1
          ) {
            splitedTbs = items.split("(");
            tableNosName = splitedTbs[0];
            tableNosRange = splitedTbs[1];
            let roomCharArray = [];
            tableNosRange = tableNosRange.replace(")", "");
            tableNosRange = tableNosRange.split("-");
            tableNosRange[0] = parseInt(tableNosRange[0]);
            tableNosRange[1] = parseInt(tableNosRange[1]);
            if (tableNosRange[0] > tableNosRange[1]) {
              for (i = tableNosRange[1]; i <= tableNosRange[0]; i++) {
                roomCharArray.push("Table" + " " + i);
              }
            } else {
              for (i = tableNosRange[0]; i <= tableNosRange[1]; i++) {
                roomCharArray.push(tableNosName + " " + i);
              }
            }
            finalTableArray.push({
              name: tableNosName,
              status: "Empty",
              rows: roomCharArray,
            });
          } else if (items && items.indexOf(",") > -1) {
            let tempTables = items.split("(");
            tableNosName = tempTables[0];
            tableNosRange = tempTables[1];
            tableNosRange = tableNosRange.replace(")", "");
            tableNosRange = tableNosRange.split(",");
            let roomCharArray = [];
            tableNosRange.forEach((items) => {
              roomCharArray.push(tableNosName + " " + items);
            });
            finalTableArray.push({
              name: tableNosName,
              status: "Empty",
              rows: roomCharArray,
            });
          } else {
            if (items.indexOf("-") > -1) {
              tableNosRange = items.split("-");
              tableNosRange[0] = parseInt(tableNosRange[0]);
              tableNosRange[1] = parseInt(tableNosRange[1]);

              if (tableNosRange[0] > tableNosRange[1]) {
                for (i = tableNosRange[1]; i <= tableNosRange[0]; i++) {
                  roomArray.push("Table" + " " + i);
                }
              } else {
                for (i = tableNosRange[0]; i <= tableNosRange[1]; i++) {
                  roomArray.push("Table" + " " + i);
                }
              }
            } else {
              let tempTables = items.split("(");
              tableNosName = tempTables[0];
              tableNosRange = items.split(",");

              tableNosRange.forEach((items) => {
                tempTables[1].indexOf(")") > -1
                  ? finalTableArray.push({
                    name: tableNosName,
                    status: "Empty",
                    rows: [tableNosName + tempTables[1].slice(0, -1)],
                  })
                  : finalTableArray.push({
                    name: tableNosName,
                    status: "Empty",
                    rows: [tableNosName + tempTables[1]],
                  });
              });
            }

            i = 1;
            finalTableArray.forEach((item) => {
              if (item.name == "Table") {
                i = 2;
                item.rows = roomArray;
              }
            });
          }
        });
        if (
          getItem("hotel_enable") &&
          registerData &&
          registerData.hotelDetalis &&
          registerData.hotelDetalis.rooms
        ) {
          async function fetchAllTableList(registerData) {
            const allTableList = await dispatch(
              getAllRoomList(
                registerData._id,
                registerData.hotelDetalis.hotelId
              )
            );

            if (allTableList && !allTableList.error) {
              let checkInList = allTableList.data.filter((val) => val.checkIn);
              if (checkInList) {
                checkInList.map((j) => {
                  let findIndex = finalTableArray.findIndex(
                    (h) => h.name == j.roomType
                  );
                  if (findIndex > -1) {
                    finalTableArray[findIndex].rows.push(
                      `${j.roomType} ${j.number}`
                    );
                  } else {
                    finalTableArray.push({
                      name: j.roomType,
                      rows: [`${j.roomType} ${j.number}`],
                      status: "Empty",
                      type: "hotel",
                    });
                  }
                });
                customTablesInfo = finalTableArray;
                let SwipList = [];

                customTablesInfo.map((table) => {
                  table.rows.map((value, index) => {
                    const status = getTableStatusFromId(
                      value.replace(/\s+/g, "-").toLowerCase(),
                      registerData
                    );

                    if (status == "In Progress" && table_name != value) {
                      let table_Name_Arr = value.split("-");
                      let val;

                      if (table_Name_Arr.length > 1) {
                        val = `${table_Name_Arr[0]}-${Number(
                          table_Name_Arr[1]
                        ) + 1}`;
                      } else if (table_Name_Arr.length == 1) {
                        val = value.concat("-1");
                      }

                      let findRoom = checkInList.find(
                        (h) => h.table_name == value
                      );

                      if (findRoom) {
                        SwipList.push({
                          swapTableName: val,
                          this_index: index,
                          swapCustum: true,
                          roomDetails: findRoom,
                        });
                      } else {
                        SwipList.push({
                          swapTableName: val,
                          this_index: index,
                          swapCustum: true,
                        });
                      }
                    } else if (status != "In Progress" && table_name != value) {
                      let findRoom = checkInList.find(
                        (h) => h.table_name == value
                      );
                      console.log("fdmcmkxmdfkmvkmdkvmdvd", findRoom);
                      if (findRoom) {
                        SwipList.push({
                          swapTableName: value,
                          this_index: index,
                          swapCustum: false,
                          roomDetails: findRoom,
                        });
                      } else {
                        SwipList.push({
                          swapTableName: value,
                          this_index: index,
                          swapCustum: false,
                        });
                      }
                    }
                  });
                });

                setSwapTableList(SwipList);
              }
            }
          }
          fetchAllTableList(registerData);
        } else if (
          (userDetails?.business_type == "hotel_management" &&
            registerData?.allow_hotel)
        ) {
          async function fetchAllTableList(registerData) {
            let getReservationListData = await dispatch(
              getReservationList(registerData.hotel_register, "room")
            );
            let roomObj = {};
            if (getReservationListData && getReservationListData.error == false) {
              let { data } = getReservationListData;
              data.reverse().map((k) => {
                k.roomInfo.map((o) => {
                  if (o.status != "checkout") {
                    if (roomObj[o.roomId]) {
                      if (roomObj[o.roomId].status == "checkin") {
                        roomObj[o.roomId] = { ...roomObj[o.roomId] };
                      } else {
                        roomObj[o.roomId] = o;
                      }
                    } else {
                      roomObj[o.roomId] = o;
                    }
                  }
                });
              });
            }
            const allTableList = await dispatch(
              getAllRoomList(
                registerData.hotel_register
              )
            );

            if (allTableList && !allTableList.error) {

              let checkInList = allTableList.data.filter((val) => {
                if (val.table_name != "Takeaway/Delivery") {
                  if (roomObj[val.uuid] && roomObj[val.uuid].status == "checkin") {
                    let updateRoomInfo = roomObj[val.uuid];
                    let body = {
                      checkIn: true,
                      reservation_id: updateRoomInfo?.reservationId,
                      roomInfo: {
                        guests: [
                          {
                            name: updateRoomInfo?.customerInfo?.name,
                            customerId: updateRoomInfo?.customerInfo?._id,
                          },
                        ],
                        occupancy: updateRoomInfo?.guest,
                        ratePlanName: updateRoomInfo?.rate_plan?.rate_name,
                      },
                    };
                    return {
                      ...val,
                      register_id: registerData?._id,
                      ...body
                    }
                  }
                }
              });
              if (checkInList) {
                console.log("dnadasdsadadadaddad", checkInList)
                checkInList.map((j) => {
                  let findIndex = finalTableArray.findIndex(
                    (h) => h.name == j.roomType
                  );
                  console.log("dsadasdadadadada", j)
                  if (findIndex > -1) {
                    finalTableArray[findIndex].rows.push(
                      `${j.table_name}`
                    );
                  } else {
                    finalTableArray.push({
                      name: j.roomType,
                      rows: [`${j.table_name}`],
                      status: "Empty",
                      type: "hotel",
                    });
                  }
                });
                customTablesInfo = finalTableArray;
                let SwipList = [];

                customTablesInfo.map((table) => {
                  table.rows.map((value, index) => {
                    const status = getTableStatusFromId(
                      value.replace(/\s+/g, "-").toLowerCase(),
                      registerData
                    );

                    if (status == "In Progress" && table_name != value) {
                      let table_Name_Arr = value.split("-");
                      let val;

                      if (table_Name_Arr.length > 1) {
                        val = `${table_Name_Arr[0]}-${Number(
                          table_Name_Arr[1]
                        ) + 1}`;
                      } else if (table_Name_Arr.length == 1) {
                        val = value.concat("-1");
                      }

                      let findRoom = checkInList.find(
                        (h) => h.table_name == value
                      );

                      if (findRoom) {
                        SwipList.push({
                          swapTableName: val,
                          this_index: index,
                          swapCustum: true,
                          roomDetails: findRoom,
                        });
                      } else {
                        SwipList.push({
                          swapTableName: val,
                          this_index: index,
                          swapCustum: true,
                        });
                      }
                    } else if (status != "In Progress" && table_name != value) {
                      let findRoom = checkInList.find(
                        (h) => h.table_name == value
                      );
                      console.log("fdmcmkxmdfkmvkmdkvmdvd", findRoom);
                      if (findRoom) {
                        SwipList.push({
                          swapTableName: value,
                          this_index: index,
                          swapCustum: false,
                          roomDetails: findRoom,
                        });
                      } else {
                        SwipList.push({
                          swapTableName: value,
                          this_index: index,
                          swapCustum: false,
                        });
                      }
                    }
                  });
                });

                setSwapTableList(SwipList);
              }
            }
          }
          fetchAllTableList(registerData);
        } else {
          customTablesInfo = finalTableArray;
          let SwipList = [];
          customTablesInfo.map((table) => {
            table.rows.map((value, index) => {
              const status = getTableStatusFromId(
                value.replace(/\s+/g, "-").toLowerCase(),
                registerData
              );

              if (status == "In Progress" && table_name != value) {
                let table_Name_Arr = value.split("-");
                let val;

                if (table_Name_Arr.length > 1) {
                  val = `${table_Name_Arr[0]}-${Number(table_Name_Arr[1]) + 1}`;
                } else if (table_Name_Arr.length == 1) {
                  val = value.concat("-1");
                }

                SwipList.push({
                  swapTableName: val,
                  this_index: index,
                  swapCustum: true,
                });
              } else if (status != "In Progress" && table_name != value) {
                SwipList.push({
                  swapTableName: value,
                  this_index: index,
                  swapCustum: false,
                });
              }
            });
          });
          setSwapTableList(SwipList);
        }
      }
    }
  }, []);

  const onSubmit = (values) => {
    setSwapModalVisible(false);

    let tableDetails = swapTableList.find(
      (i) => i.swapTableName == values.table_name
    );
    console.log(
      "tableDetailstableDetailstableDetails",
      tableDetails?.roomDetails
    );
    let obj = {
      ...localCartInfo,
      tableName: values.table_name,
      tablekey: values.table_name.replace(/\s+/g, "-").toLowerCase(),
      swapTableCustum: tableDetails.swapCustum,
      index: tableDetails.this_index,
    };
    let { default_cart_object, allLocalData } = swaptable(
      localCartInfo.cartKey,
      obj,
      registerData,
      tableDetails?.roomDetails
    );
    setlocalCartInfo(default_cart_object);
    setTableName(values.table_name);
  };

  return (
    <>
      <Modal
        title={`Swap ${table_name}`}
        visible={swapModalVisible}
        bodyStyle={{ paddingTop: 0 }}
        onOk={form.submit}
        onCancel={handleCancel}
        okText="Swap Table"
      >
        <Form
          autoComplete="off"
          style={{ width: "100%" }}
          form={form}
          onFinish={onSubmit}
          name="editProduct"
        >
          <Form.Item name="table_name" label="Swap to">
            <Select placeholder="Select a table to swap">
              {swapTableList != undefined &&
                swapTableList.map((val) => {
                  return (
                    <Option value={val.swapTableName}>
                      {val.swapTableName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(SwapTableModal);
