import React, { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  Form,
  DatePicker,
  Radio,
  Select,
  Progress,
  Button,
  Popover,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  CheckOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { SearchOutlined } from "@ant-design/icons";
import { getItem, setItem } from "../../../utility/localStorageControl";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "../../PattyCash/petty.css";
import { Main, UserTableStyleWrapper, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import {
  addOrUpdateHouseKeeper,
  deleteHousekeeper,
  getAllhousekeeperList,
} from "../../../redux/roomType/actionCreator";

const Housekeeper = (props) => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  const [selectionType] = useState("checkbox");
  const [form] = Form.useForm();
  let [search, setsearch] = useState("");
  const dispatch = useDispatch();
  const [modalVisibleAdd, setModelVisibleAdd] = useState(false);
  const [houseKeeperId, setHouseKeeperId] = useState();
  const [loading, setLoading] = useState(false);
  const [housekeeperList, setHouseKeeperList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  async function fetchHousekeeperList() {
    const getPattyCashList = await dispatch(
      getAllhousekeeperList(currentRegisterData._id, "sell")
    );
    if (getPattyCashList && !getPattyCashList.error) {
      setHouseKeeperList(getPattyCashList.data);
    }
  }
  useEffect(() => {
    if (currentRegisterData && currentRegisterData._id) {
      fetchHousekeeperList();
    }
  }, [currentRegisterData._id]);

  const onSubmit = async (formData) => {
    setLoading(true);
    formData["register_id"] = currentRegisterData?._id;
    const getAddedPatty = await dispatch(
      addOrUpdateHouseKeeper(formData, houseKeeperId)
    );
    if (getAddedPatty && getAddedPatty.data && !getAddedPatty.error) {
      fetchHousekeeperList();
      form.resetFields();
      setLoading(false);
      setModelVisibleAdd(false);
    }
  };
  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelDeleteVisible(true);
          setLoading(false);
        }}
      >
        <FeatherIcon
          size={16}
          icon="book-open"
          style={{ marginRight: "6px" }}
        />
        <span>Delete Selected item</span>
      </NavLink>
    </>
  );
  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      width: "5px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Mobile number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const handleEdit = (row) => {
    setHouseKeeperId(row._id);
    setModelVisibleAdd(true);
    form.setFieldsValue({
      ...row,
    });
  };
  const [selectedRows, setSelectRows] = useState([]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectRows([...selectedRows]);
    },
  };
  const deleteSelectedHousekeepers = async () => {
    setLoading(true);
    let allTaxesIdsForDelete = [];
    selectedRows.map((item) => {
      allTaxesIdsForDelete.push(item._id);
    });
    const getDeletedTaxes = await dispatch(
      deleteHousekeeper({ ids: allTaxesIdsForDelete }, currentRegisterData?._id)
    );
    console.log("gjfgasjfgasfgafafgafaffaffafasfsafa", getDeletedTaxes);
    if (getDeletedTaxes && !getDeletedTaxes.error) {
      await fetchHousekeeperList();
      setModelDeleteVisible(false);
      setSelectRows([]);
    }
  };
  return (
    <>
      <Row gutter={15}>
        <Col md={24}>
          <Cards headless>
            <Row
              gutter={15}
              className="list-row"
              style={{ marginBottom: "6px" }}
            >
              <Col span={24} className="pricebookTableBox">
                <div className="priceBook_titles_content">
                  <div className="productCount">
                    <h2>Housekeeper</h2>
                    <span
                      className="title-counter"
                      style={{ marginTop: "-17px" }}
                    >
                      {housekeeperList?.length} Housekeepers{" "}
                    </span>
                  </div>
                  <div
                    style={{
                      boxShadow: "none",
                      marginBottom: "16px",
                      marginLeft: "20px",
                    }}
                    className="table_search"
                  >
                    <Input
                      suffix={<SearchOutlined />}
                      className="set_serbt"
                      autoFocus={
                        deviceWidth && deviceWidth <= 992 ? false : true
                      }
                      placeholder="Search by name"
                      style={{
                        borderRadius: "30px",
                        width: "250px",
                      }}
                      onChange={(e) => setsearch(e.target.value)}
                      value={search}
                    />
                  </div>
                </div>

                <div className="pricebookTables-toolbox-menu">
                  <div key="1" className="page-header-actions">
                    <Button
                      size="small"
                      type="primary"
                      className="ant-btn ant-btn-primary ant-btn-md"
                      onClick={() => {
                        setModelVisibleAdd(true);
                        setHouseKeeperId();
                        form.resetFields();
                      }}
                    >
                      <FeatherIcon
                        icon="plus"
                        size={14}
                        className="pls_iconcs"
                      />
                      Add Housekeeper
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <UserTableStyleWrapper>
              <div className="contact-table">
                <TableWrapper className="table-responsive">
                  <Table
                    rowKey="_id"
                    size="small"
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    dataSource={housekeeperList.filter((val) =>
                      val?.name?.toLowerCase().includes(search.toLowerCase())
                    )}
                    columns={columns}
                    onRow={(row) => ({
                      onClick: () => {
                        handleEdit(row);
                      },
                    })}
                    fixed={true}
                    scroll={{ x: 1100 }}
                    className="text-inherit"
                  />
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </Cards>
        </Col>
      </Row>
      <p style={{ display: "none" }}>{loading}</p>
      <Modal
        title="Confirm Delete"
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 16,
                    color: "white",
                    margin: "0px 23px",
                  }}
                  spin
                />
              }
            />
          ) : (
            "Delete"
          )
        }
        visible={modalDeleteVisible}
        onOk={deleteSelectedHousekeepers}
        onCancel={() => {
          setLoading(false);
          setModelDeleteVisible(false);
        }}
        width={600}
      >
        <p>Are you sure you want to delete selected housekeeper ?</p>
      </Modal>

      <Modal
        title="Add Housekepper"
        visible={modalVisibleAdd}
        onOk={form.submit}
        bodyStyle={{ paddingTop: 0 }}
        onCancel={() => {
          setModelVisibleAdd(false);
          form.resetFields();
        }}
        width={600}
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 16,
                    color: "white",
                    margin: "0px 37px",
                  }}
                  spin
                />
              }
            />
          ) : houseKeeperId ? (
            "Update"
          ) : (
            "Add"
          )
        }
      >
        <div>
          <Form
            style={{ width: "100%" }}
            name="addPatty"
            form={form}
            onFinish={onSubmit}
          >
            <div className="add-product-block">
              <div className="add-product-content">
                <Form.Item
                  style={{ marginTop: "15px" }}
                  name="name"
                  label="Name"
                  rules={[
                    {
                      min: 3,
                      message: "Name must be at least 3 characters long",
                    },
                    {
                      max: 40,
                      message: "Name cannot be more than 40 characters long.",
                    },
                    {
                      required: true,
                      message: "Name is required.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Name"
                    autoComplete="off"
                    style={{ marginBottom: 10 }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ type: "email" }]}
                >
                  <Input
                    placeholder="Email"
                    autoComplete="off"
                    style={{ marginBottom: 10 }}
                  />
                </Form.Item>
                <Form.Item name="mobile_number" label="Mobile Number">
                  <Input type="number" placeholder="Mobile Number" />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default Housekeeper;
