import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col, Table, Input, Modal, Button, Form, message } from "antd";
import {
  SearchOutlined,
  ImportOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { TableWrapper } from "../../styled";
import { Popover } from "../../../components/popup/popup";
import { Cards } from "../../../components/cards/frame/cards-frame";
import "../option.css";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { UserTableStyleWrapper } from "../../pages/style";
import {
  addOrFindVendorCategory,
  addOrUpdateVendorItem,
  deleteVendorItem,
  getVendorItemList,
} from "../../../redux/vendor/actionCreator";
import { getItem } from "../../../utility/localStorageControl";
import { getAllProductList } from "../../../redux/products/actionCreator";

const PosItems = (props) => {
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  const dispatch = useDispatch();
  let [search, setSearch] = useState("");
  const history = useHistory();
  const [selectionType] = useState("checkbox");
  const [loader, setLoader] = useState(true);
  const deviceWidth = localStorage.getItem("windowWidth");
  const [vendorList, setVendorList] = useState([]);
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  let [productCategoryList, setProductCategoryList] = useState([]);
  const [state, setState] = useState({});

  const createPosItems = (list) => {
    let finalList = [];
    list.map((val) => {
      if (
        val.limit_to_register?.length == 0 ||
        val?.limit_to_register.includes(currentRegisterData._id)
      ) {
        if (val.option_variant_group.length > 0) {
          val.option_variant_group.map((j) => {
            j.product_variants.sort((a, b) =>
              a.sort_order > b.sort_order ? 1 : -1
            );
          });
          val.option_variant_group.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          );
          let newArray = [];
          let n = val.option_variant_group.length;
          let indices = new Array(n);

          for (let i = 0; i < n; i++) {
            indices[i] = 0;
          }

          let loop = true;

          while (loop == true) {
            let variantArray = [];
            let title = val.product_name;
            let id = val._id;
            let variantTitle = "";
            let variantIds = "";
            for (let i = 0; i < n; i++) {
              variantTitle =
                variantTitle +
                " / " +
                val.option_variant_group[i]["product_variants"][indices[i]]
                  .variant_name;
              variantIds =
                variantIds +
                "--varient-" +
                val.option_variant_group[i]["product_variants"][indices[i]]._id;
              variantArray.push(
                val.option_variant_group[i]["product_variants"][indices[i]]
              );
            }
            title = title + variantTitle;
            id = id + variantIds;

            finalList.push({
              category: val.product_category.category_name,
              product_id: id,
              isNotTracked: true,
              isSellable: true,
              product_name: title,
              limit_to_register: val.limit_to_register,
              unit_of_measure: val.unit_of_measure,
            });

            let next = n - 1;
            while (
              next >= 0 &&
              indices[next] + 1 >=
                val.option_variant_group[next]["product_variants"].length
            ) {
              next--;
            }

            if (next < 0) {
              loop = false;
            }

            indices[next]++;

            for (let i = next + 1; i < n; i++) {
              indices[i] = 0;
            }
          }
        } else {
          finalList.push({
            category: val.product_category.category_name,
            product_id: val._id,
            isNotTracked: true,
            isSellable: true,
            product_name: val.product_name,
            limit_to_register: val.limit_to_register,
            unit_of_measure: val.unit_of_measure,
          });
        }
      }
    });
    return finalList;
  };

  const getVendorListData = async () => {
    setLoader(true);
    let response = await dispatch(getAllProductList("sell"));
    if (response && !response.error && response.productList) {
      setVendorList(createPosItems(response.productList));
      setLoader(false);
    } else {
      setLoader(false);
      message.error(response.message);
    }
  };

  useEffect(() => {
    if (currentRegisterData) {
      getVendorListData();
    }
  }, [currentRegisterData?._id]);

  const handleCancel = (e) => {
    setModelDeleteVisible(false);
  };

  const deleteSelectedVendor = async () => {
    setLoading(true);
    const { allSelectedRowsForDelete } = state;

    let allvariantdsForDelete = [];
    if (allSelectedRowsForDelete && allSelectedRowsForDelete.length > 0) {
      for (let item of allSelectedRowsForDelete) {
        let checkCategory = await dispatch(
          addOrFindVendorCategory({ name: item.category })
        );

        if (checkCategory && !checkCategory.error && checkCategory.data) {
          allvariantdsForDelete.push({
            name: item.product_name,
            category: checkCategory.data._id,
            measure: item.unit_of_measure,
            product_id: item.product_id,
          });
        }
      }

      const getDeletedVariant = await dispatch(
        addOrUpdateVendorItem({ items: allvariantdsForDelete })
      );
      if (getDeletedVariant && !getDeletedVariant.error) {
        getVendorListData();
        setModelDeleteVisible(false);
        setState({
          ...state,
          selectedRows: [],
        });
      }
    }
  };

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setModelDeleteVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Set as purchase</span>
      </NavLink>
    </>
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForDelete: selectedRows,
      });
    },
  };

  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined style={{ marginLeft: "12px" }} />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "4%",
    },
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
      fixed: "left",
      className: "products_list_fix",
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Unit of measure",
      dataIndex: "unit_of_measure",
      key: "unit_of_measure",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  return (
    <>
      <Row gutter={25}>
        <Col md={24} xs={24}>
          <Cards headless>
            <Row gutter={15} className="list-row">
              <Col span={24} className="pricebookTableBox">
                <div className="priceBook_titles_content">
                  <div className="productCount">
                    <h2>Pos Products</h2>
                    <span
                      className="title-counter"
                      style={{ marginTop: "-17px" }}
                    >
                      {vendorList.length} Pos Products{" "}
                    </span>
                  </div>
                  <div
                    style={{
                      boxShadow: "none",
                      marginBottom: "16px",
                      marginLeft: "20px",
                    }}
                    className="table_search"
                  >
                    <Input
                      suffix={<SearchOutlined />}
                      placeholder="Search by name"
                      autoFocus={
                        deviceWidth && deviceWidth <= 992 ? false : true
                      }
                      style={{
                        borderRadius: "30px",
                        width: "250px",
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                    />
                  </div>
                </div>
                {/* <div className="pricebookTables-toolbox-menu">
                  <div key="1" className="page-header-actions">
                    <NavLink
                      to="/vendor/item/add"
                      className="ant-btn ant-btn-primary ant-btn-md"
                    >
                      <FeatherIcon
                        icon="plus"
                        size={16}
                        className="pls_iconcs"
                      />
                      Add Item
                    </NavLink>
                  </div>
                </div> */}
              </Col>
            </Row>

            <UserTableStyleWrapper>
              <div className="contact-table">
                <TableWrapper className="table-responsive">
                  {loader ? (
                    <Table
                      rowKey="product_id"
                      locale={locale}
                      className="products_lsttable"
                      size="small"
                      dataSource={[]}
                      columns={columns}
                      fixed={true}
                      scroll={{ x: 800 }}
                      pagination={false}
                    />
                  ) : (
                    <Table
                      rowKey="product_id"
                      className="products_lsttable"
                      rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                      }}
                      size="small"
                      dataSource={vendorList?.filter((value) =>
                        value.product_name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )}
                      columns={columns}
                      fixed={true}
                      scroll={{ x: 800 }}
                      pagination={{
                        showSizeChanger: true,
                        total: vendorList.length,
                        pageSizeOptions: ["10", "50", "100", "500", "1000"],
                      }}
                    />
                  )}
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </Cards>
          <p style={{ display: "none" }}>{loading}</p>
          <Modal
            title="Confirm Update"
            okText={
              loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 16,
                        color: "white",
                        margin: "0px 14px",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                "Update"
              )
            }
            visible={modalDeleteVisible}
            onOk={deleteSelectedVendor}
            onCancel={handleCancel}
            width={600}
          >
            <p>Are you sure you want to purchase selected item ?</p>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export { PosItems };
