import { getItem, setItem } from "../../utility/localStorageControl";
import { getAllroomTypeList } from "../roomType/actionCreator";
import actions from "./actions";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const {
  roomAdd,
  roomTypeAddErr,
  roomList,
  roomListErr,
  roomId,
  roomIdErr,
  roomDelete,
  roomDeleteErr,
} = actions;

const getAllRoomList = (registerId, checksell) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");

      if (checksell == "sell" && allSetupcache?.roomList) {
        let filter = allSetupcache?.roomList.filter(
          (val) => val.register_id == registerId
        );
        return dispatch(roomList(filter.reverse()));
      } else {
        let url = API.room.getAllRoom;
        const getroomTypeList = await DataService.get(url);
        if (!getroomTypeList.data.error) {
          allSetupcache.roomList = getroomTypeList.data.data;
          setItem("setupCache", allSetupcache);
          let filter = getroomTypeList.data.data.filter(
            (val) => val.register_id == registerId
          );
          return dispatch(roomList(filter.reverse()));
        } else {
          return dispatch(roomListErr(getroomTypeList.data));
        }
      }
    } catch (err) {
      return false;
    }
  };
};

const addOrUpdateRoom = (roomList, room_id, updateReservationData) => {
  return async (dispatch) => {
    try {
      let formData = {
        roomList: roomList,
      };

      let allSetupcache = getItem("setupCache");

      if (allSetupcache && allSetupcache.shopDetails) {
        formData["shopId"] = allSetupcache.shopDetails._id;
      }
      let getAddedroomType = {};
      if (room_id) {
        if (updateReservationData) {
          (formData["reservationId"] = updateReservationData.reservationId),
            (formData["roomInfoId"] = updateReservationData.roomInfoId);
        }

        getAddedroomType = await DataService.put(
          `${API.room.editRoom}/${room_id}`,
          formData
        );
        if (!getAddedroomType.data.error) {
          if (updateReservationData == undefined) {
            let registerId = roomList?.register_id;
            await dispatch(getAllRoomList(registerId));
            dispatch(getAllroomTypeList(registerId));
          }
          return getAddedroomType.data;
        } else {
          return getAddedroomType.data;
        }
      } else {
        getAddedroomType = await DataService.post(API.room.addRoom, formData);
      }

      if (!getAddedroomType.data.error) {
        let registerId = roomList[0]?.register_id;
        await dispatch(getAllRoomList(registerId));
        dispatch(getAllroomTypeList(registerId));
        return getAddedroomType.data;
      } else {
        return getAddedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const getRoomById = (id) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");
      let roomTypeDetails;
      if (allSetupcache != null && allSetupcache.roomType) {
        roomTypeDetails = allSetupcache.roomType.find((val) => val._id == id);
      }
      if (roomTypeDetails) {
        return dispatch(roomId(roomTypeDetails));
      } else {
        const roomTypeByIdData = await DataService.get(
          `${API.roomType.getroomTypeById}/${id}`
        );
        if (!roomTypeByIdData.data.error) {
          return dispatch(roomId(roomTypeByIdData.data.data));
        } else {
          return dispatch(roomIdErr(roomTypeByIdData.data));
        }
      }
    } catch (err) {
      dispatch(roomIdErr(err));
    }
  };
};

const deleteRoom = (roomTypeIds, registerId) => {
  return async (dispatch) => {
    try {
      const getDeletedRoom = await DataService.post(
        API.room.deleteRoom,
        roomTypeIds
      );
      if (!getDeletedRoom.data.error) {
        await dispatch(getAllRoomList(registerId));
        await dispatch(getAllroomTypeList(registerId));

        return getDeletedRoom.data;
      } else {
        return getDeletedRoom.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export { addOrUpdateRoom, getAllRoomList, getRoomById, deleteRoom };
