import React, { useState, useRef, useEffect } from "react";
import { Table, Input, Modal, Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Popover } from "../../components/popup/popup";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../components/page-headers/page-headers";
import "../../container/profile/settings/setting.css";
import { UserTableStyleWrapper } from "../pages/style";
import {
  SearchOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getItem, setItem } from "../../utility/localStorageControl";
import { CardToolbox } from "../Customer/Style";
import { TableWrapper, Main } from "../styled";
import {
  getExpenseCategoryList,
  deleteExpenseCategory,
} from "../../redux/vendor/actionCreator";
const ExpenseCategoryList = () => {
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let [search, setsearch] = useState("");
  let isMounted = useRef(true);
  const [DynoList, setDynoList] = useState([]);
  const [selectionType] = useState("checkbox");
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  const offLineMode = useSelector((state) => state.auth.offlineMode);
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");
  async function fetchExpenseCategory(val) {
    setLoader(true);
    const getInventoryList = await dispatch(getExpenseCategoryList(val));

    if (isMounted.current && getInventoryList && getInventoryList.data) {
      setLoader(false);
      setDynoList(getInventoryList.data);
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      fetchExpenseCategory();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [state, setState] = useState({
    item: DynoList,
  });
  const { selectedRowKeys, item } = state;
  useEffect(() => {
    if (DynoList) {
      setState({
        item: DynoList,
        selectedRowKeys,
      });
    }
  }, [DynoList, selectedRowKeys]);

  const deleteSelectedRegister = async () => {
    setLoading(true);
    const { allSelectedRowsForDelete } = state;
    let allRegisterIdsForDelete = [];
    allSelectedRowsForDelete.map((item) => {
      allRegisterIdsForDelete.push(item.id);
    });
    const getDeletedBingages = await dispatch(
      deleteExpenseCategory({ ids: allRegisterIdsForDelete })
    );
    if (getDeletedBingages && !getDeletedBingages.error) {
      fetchExpenseCategory(undefined);
      setModelDeleteVisible(false);
    }
  };
  const [offLineModeCheck, setOfflineModeCheck] = useState(false);

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setModelDeleteVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Delete Selected item</span>
      </NavLink>
    </>
  );

  const dataSource = [];

  let searchArrTaxes = DynoList?.filter((value) =>
    value.name?.toLowerCase().includes(search.toLowerCase())
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForDelete: selectedRows,
      });
    },
  };

  const handleCancel = (e) => {
    setModelDeleteVisible(false);
  };

  if (DynoList.length)
    searchArrTaxes.map((value) => {
      const { _id, name, description } = value;

      return dataSource.push({
        id: _id,
        name: name,
        description: description,
        ...value,
      });
    });

  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined style={{ marginLeft: "12px" }} />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      width: "2%",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  return (
    <div style={{ marginTop: "-18px" }}>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div className="table_titles">
                  <h2>Expence categories</h2>
                  <span className="title-counter">
                    {" "}
                    {dataSource.length} Expence categories{" "}
                  </span>
                </div>
                <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Input
                    suffix={<SearchOutlined />}
                    className="set_serbt"
                    autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                    placeholder="Search by name"
                    style={{
                      borderRadius: "30px",
                      width: "250px",
                    }}
                    onChange={(e) => setsearch(e.target.value)}
                    value={search}
                  />
                </div>
              </>
            }
            buttons={[
              <>
                <NavLink
                  to={offLineMode ? "#" : "/vendor/expenses"}
                  className="ant-btn ant-btn-md"
                  style={{ marginBottom: "10px" }}
                  onClick={() =>
                    offLineMode
                      ? setOfflineModeCheck(true)
                      : setOfflineModeCheck(false)
                  }
                >
                  <FeatherIcon
                    icon="arrow-left"
                    size={16}
                    className="pls_iconcs"
                  />
                  Go back
                </NavLink>

                <NavLink
                  to={offLineMode ? "#" : "/vendor/expense/add/category"}
                  className="ant-btn ant-btn-primary ant-btn-md"
                  style={{ color: "#FFF", marginBottom: "10px" }}
                  onClick={() =>
                    offLineMode
                      ? setOfflineModeCheck(true)
                      : setOfflineModeCheck(false)
                  }
                >
                  <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                  Add Category
                </NavLink>
              </>,
            ]}
          />
        </CardToolbox>
        <Row gutter={15}>
          <Col span={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="id"
                        dataSource={[]}
                        columns={columns}
                        rowSelection={{
                          type: selectionType,
                          ...rowSelection,
                        }}
                        size="small"
                        style={{ marginTop: "8px" }}
                      />
                    ) : (
                      <Table
                        rowKey="id"
                        dataSource={dataSource}
                        columns={columns}
                        rowSelection={{
                          type: selectionType,
                          ...rowSelection,
                        }}
                        onRow={(row) => ({
                          onClick: () =>
                            offLineMode
                              ? setOfflineModeCheck(true)
                              : history.push(`/vendor/expense/add/category`, {
                                  categoryDetails: row,
                                }),
                        })}
                        size="small"
                        style={{ marginTop: "8px" }}
                        scroll={{ x: 600 }}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
        <Modal
          title="Confirm Delete"
          okText={
            loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                      color: "white",
                      margin: "0px 14px",
                    }}
                    spin
                  />
                }
              />
            ) : (
              "Delete"
            )
          }
          visible={modalDeleteVisible}
          onOk={deleteSelectedRegister}
          onCancel={handleCancel}
          width={600}
        >
          <p>Are you sure you want to delete category ?</p>
        </Modal>
        <Modal
          title="You are Offline"
          visible={offLineModeCheck}
          onOk={() => setOfflineModeCheck(false)}
          onCancel={() => setOfflineModeCheck(false)}
          width={600}
        >
          <p>You are offline not add and update </p>
        </Modal>
      </Main>
    </div>
  );
};

export default ExpenseCategoryList;
