import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  message,
  Form,
  DatePicker,
  Select,
  Radio,
  Button,
  Tooltip,
} from "antd";
import {
  SearchOutlined,
  CaretDownOutlined,
  FilterFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { TableWrapper } from "../../styled";
import { Popover } from "../../../components/popup/popup";
import { Cards } from "../../../components/cards/frame/cards-frame";
import "../option.css";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import commonFunction from "../../../utility/commonFunctions";
import { UserTableStyleWrapper } from "../../pages/style";
import { getItem } from "../../../utility/localStorageControl";
import {
  deleteVendorBill,
  getVendorBillList,
  getVendorList,
} from "../../../redux/vendor/actionCreator";
import moment from "moment";
import ReportExport from "./ReportExport";

const VendorBillList = () => {
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const [invoiceSearchModalVisible, setInvoiceSearchModalVisible] = useState(
    false
  );
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  const [vendorSearchVisible, setVendorSearchVisible] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearch] = useState("");
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [resetLoader, setResetLoader] = useState(false);
  const deviceWidth = localStorage.getItem("windowWidth");
  const [changeStatus, setChangeStatus] = useState("paid");
  const [paymnetStatus, setPaymnetStatus] = useState();
  const [vendorBillList, setVendorBillList] = useState([]);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [
    openStatusUpdateModalVisleble,
    SetOpenStatusUpdateModalVisible,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const [invoiceDateForm] = Form.useForm();
  const [vendorList, setVendorList] = useState([]);
  const [invoiceFilter, setInvoiceFilter] = useState(false);
  const [vendorFilter, setVendorFilter] = useState(false);
  const [paymentFilter, setPaymnetFilter] = useState(false);
  const [vendorSearchForm] = Form.useForm();
  const [openPopover, setOpenPopover] = useState(false);
  const [totalPaybal, setTotalPaybal] = useState(0);
  let [filterObj, setFilterObj] = useState({});
  const [changePage, setChangePage] = useState(1);
  const [reportType, setReportType] = useState();
  const searchDidmount = useRef();
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [modalVisible, setModelVisible] = useState(false);
  const getVendorListData = async () => {
    let response = await dispatch(getVendorList());
    if (response && !response.error) {
      setVendorList(response.data);
    } else {
      message.error(response.message);
    }
  };

  const getVendorBillListData = async (currentPage, obj) => {
    setLoader(true);
    let response = await dispatch(getVendorBillList(currentPage, obj));
    if (response && !response.error) {
      setVendorBillList(response.data.billList);
      setTotalPaybal(response.data.paybal);
      setLoader(false);
      setVendorSearchVisible(false);
      setInvoiceSearchModalVisible(false);
      setResetLoader(false);
    } else {
      setInvoiceSearchModalVisible(false);
      setVendorSearchVisible(false);
      setLoader(false);
      setResetLoader(false);
      message.error(response.message);
    }
  };

  useEffect(() => {
    getVendorListData();
  }, []);

  useEffect(() => {
    if (currentRegisterData) {
      getVendorBillListData(1, filterObj);
    }
  }, [filterObj, currentRegisterData?._id]);

  const handleCancel = () => {
    setModalDeleteVisible(false);
    SetOpenStatusUpdateModalVisible(false);
  };

  const deleteSelectedVendorBill = async (status) => {
    setLoading(true);
    const { allSelectedRowsForDelete } = state;

    let allVendorBillsForDelete = [];
    if (allSelectedRowsForDelete && allSelectedRowsForDelete.length > 0) {
      allSelectedRowsForDelete.map((item) => {
        allVendorBillsForDelete.push(item._id);
      });

      const getDeletedVendorBills = await dispatch(
        deleteVendorBill({ ids: allVendorBillsForDelete, status: status })
      );
      if (getDeletedVendorBills && !getDeletedVendorBills.error) {
        getVendorBillListData(1, {});
        setModalDeleteVisible(false);
        SetOpenStatusUpdateModalVisible(false);
        setState({
          ...state,
          selectedRows: [],
        });
      }
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForDelete: selectedRows,
      });
    },
  };

  const onChange = (e) => {
    setPaymnetStatus(e.target.value);
  };

  const handleInvoiceDateFilter = async (value) => {
    invoiceDateForm.validateFields().then(async (value) => {
      setInvoiceFilter(true);
      setFilterObj({
        ...filterObj,
        invoice_start_date: moment(value.invoice_start_date).format(
          "YYYY-MM-DD"
        ),
        invoice_end_date: moment(value.invoice_end_date).format("YYYY-MM-DD"),
      });
    });
  };

  const handleSearchFilter = async () => {
    vendorSearchForm.validateFields().then(async (value) => {
      setVendorFilter(true);
      setFilterObj({
        ...filterObj,
        search_vendor: value.search_vendor,
      });
    });
  };

  const onPaymentFilter = () => {
    setPaymnetFilter(true);
    setOpenPopover(false);
    setFilterObj({
      ...filterObj,
      paymnetStatus: paymnetStatus,
    });
  };

  const handleReset = async (type) => {
    setResetLoader(true);
    if (type == "invoice") {
      delete filterObj.invoice_start_date;
      delete filterObj.invoice_end_date;
      setFilterObj({ ...filterObj });
      setInvoiceFilter(false);
      setInvoiceSearchModalVisible(false);
      invoiceDateForm.resetFields();
    }
    if (type == "payment") {
      delete filterObj.paymnetStatus;
      setFilterObj({ ...filterObj });
      setPaymnetFilter(false);
      setOpenPopover(false);
      setPaymnetStatus();
    }
    if (type == "vendor") {
      delete filterObj.search_vendor;
      setFilterObj({ ...filterObj });
      vendorSearchForm.resetFields();
      setVendorFilter(false);
      setVendorSearchVisible(false);
    }
  };

  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setModalDeleteVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Delete Selected item</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setChangeStatus("paid");
          SetOpenStatusUpdateModalVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Paid</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setChangeStatus("unpaid");
          SetOpenStatusUpdateModalVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Unpaid</span>
      </NavLink>
    </>
  );
  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined style={{ marginLeft: "12px" }} />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "4%",
    },
    {
      title: "Bill Number",
      dataIndex: "invoice_number",
      key: "mobile",
      fixed: "left",
      className: "products_list_fix",
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },
    {
      title: (
        <div className="filterContainer">
          Bill Date
          <FilterFilled
            style={{ color: invoiceFilter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setInvoiceSearchModalVisible(!invoiceSearchModalVisible);
            }}
          />
        </div>
      ),
      dataIndex: "invoice_date",
      key: "invoice_date",
      render(created_at, record) {
        return {
          children: record.cancel ? (
            <span style={{ textDecoration: "line-through" }}>
              {commonFunction.convertToDate(created_at, "MMM DD, Y")}
            </span>
          ) : (
            <span>{commonFunction.convertToDate(created_at, "MMM DD, Y")}</span>
          ),
        };
      },
    },
    {
      title: (
        <div className="filterContainer">
          Vendor
          <FilterFilled
            style={{ color: vendorFilter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setVendorSearchVisible(!vendorSearchVisible);
            }}
          />
        </div>
      ),
      dataIndex: "vendor_id",
      key: "vendor_id",
      align: "left",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: (
        <div className="filterContainer">
          Payment
          <Popover
            className="receiptPopover"
            content={
              <div className="filterPopoverFooter">
                <p
                  onClick={() => handleReset("payment")}
                  className="filterReset"
                >
                  {resetLoader ? "Loading..." : "Reset"}
                </p>
                <p onClick={() => onPaymentFilter()} className="filterOk">
                  Ok
                </p>
              </div>
            }
            title={
              <Radio.Group onChange={onChange} value={paymnetStatus}>
                <Radio value={"paid"}>Paid</Radio>
                <Radio value={"unpaid"}>Unpaid</Radio>
              </Radio.Group>
            }
            trigger="click"
            placement="bottom"
            open={openPopover}
            onOpenChange={handleOpenChange}
          >
            <FilterFilled
              style={{ color: paymentFilter ? "#bd025d" : "#5A5F7D" }}
            />
          </Popover>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (text) => <span>{text == "unpaid" ? "Unpaid" : "Paid"}</span>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "left",
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "left",
      render: (text) => (
        <span>
          {rsSymbol}
          {Number(text).toFixed(2)}
        </span>
      ),
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  useEffect(() => {
    if (searchDidmount.current) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      const timer = setTimeout(() => {
        if (searchText == "") {
          delete filterObj.invoice_number;
          setFilterObj({
            ...filterObj,
            invoice_number: searchText,
          });
        } else {
          setFilterObj({
            ...filterObj,
            invoice_number: searchText,
          });
        }
      }, 500);
      setDebounceTimer(timer);
      return () => clearTimeout(timer);
    } else {
      searchDidmount.current = true;
    }
  }, [searchText]);

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setReportType("CSV");
          setModelVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  return (
    <>
      <Row
        gutter={15}
        className="list-row"
        style={{ padding: "13px 0px 0px 0px" }}
      >
        <Col span={24} className="pricebookTableBox">
          <div className="priceBook_titles_content">
            <div className="productCount">
              <h2>Bills</h2>
            </div>
            <div
              style={{
                boxShadow: "none",
                marginBottom: "16px",
                marginLeft: "20px",
              }}
              className="table_search"
            >
              <Input
                suffix={<SearchOutlined />}
                placeholder="Search by invoice number"
                autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                style={{
                  borderRadius: "30px",
                  width: "250px",
                }}
                onChange={(e) => setSearch(e.target.value)}
                value={searchText}
              />
            </div>
          </div>

          <div className="pricebookTables-toolbox-menu">
            <div
              key="1"
              className="page-header-actions"
              style={{ marginRight: "10px" }}
            >
              <Tooltip
                title={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Showing bills from{" "}
                    {moment()
                      .startOf("month")
                      .format("MMMM Do YYYY")}
                    {" to"} {moment().format("MMMM Do YYYY")}
                  </p>
                }
              >
                <ExclamationCircleOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
              <Popover placement="bottomLeft" content={content} trigger="hover">
                <Button size="small" type="white">
                  <FeatherIcon icon="download" size={14} />
                  Export
                </Button>
              </Popover>
            </div>

            <div key="1" className="page-header-actions">
              <NavLink
                to="/vendor/bill/add"
                className="ant-btn ant-btn-primary ant-btn-md"
              >
                <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                Add Bill
              </NavLink>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={25}>
        <Col md={24} xs={24}>
          <Cards headless>
            <UserTableStyleWrapper>
              <div className="contact-table">
                <TableWrapper className="table-responsive">
                  {loader ? (
                    <Table
                      rowKey="id"
                      locale={locale}
                      className="products_lsttable"
                      size="small"
                      dataSource={[]}
                      columns={columns}
                      fixed={true}
                      scroll={{ x: 800 }}
                      pagination={false}
                    />
                  ) : (
                    <Table
                      rowKey="_id"
                      className="products_lsttable"
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                      }}
                      onRow={(row) => ({
                        onClick: () =>
                          history.push(`/vendor/bill/add`, {
                            billDetails: row,
                          }),
                      })}
                      size="small"
                      dataSource={vendorBillList}
                      columns={columns}
                      fixed={true}
                      scroll={{ x: 800 }}
                      pagination={false}
                    />
                  )}
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Button
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50px",
                }}
                onClick={() => {
                  setChangePage(changePage - 1);
                  getVendorBillListData(changePage - 1, filterObj);
                }}
                disabled={changePage == 1 ? true : false}
              >
                <FeatherIcon
                  size={20}
                  icon="chevron-left"
                  style={{ position: "relative", left: "-11px", top: "2px" }}
                />
              </Button>
              <Button
                disabled={vendorBillList.length != 10 ? true : false}
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50px",
                  marginLeft: "40px",
                }}
                onClick={() => {
                  setChangePage(changePage + 1);
                  getVendorBillListData(changePage + 1, filterObj);
                }}
              >
                <FeatherIcon
                  size={20}
                  icon="chevron-right"
                  style={{ position: "relative", left: "-9px", top: "2px" }}
                />
              </Button>
            </p>
          </Cards>
          <p style={{ display: "none" }}>{loading}</p>
          <Modal
            title="Confirm Delete"
            okText={
              loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 16,
                        color: "white",
                        margin: "0px 14px",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                "Delete"
              )
            }
            visible={modalDeleteVisible}
            onOk={() => deleteSelectedVendorBill("delete")}
            onCancel={handleCancel}
            width={600}
          >
            <p>Are you sure you want to delete selected vendor bills?</p>
          </Modal>
          <Modal
            title="Invoice Date Wise Filter"
            visible={invoiceSearchModalVisible}
            onCancel={() => setInvoiceSearchModalVisible(false)}
            footer={[
              <Button onClick={() => handleReset("invoice")}>
                {resetLoader ? "Loading.." : "Reset"}
              </Button>,
              <Button onClick={() => handleInvoiceDateFilter()} type="primary">
                {loader ? "Loading.." : "Submit"}
              </Button>,
            ]}
            width={600}
          >
            <div>
              <Form form={invoiceDateForm}>
                <Form.Item
                  label="Start Date"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 12px)",
                  }}
                  name="invoice_start_date"
                >
                  <DatePicker
                    style={{ height: "35px" }}
                    placeholder="dd-mm-yyyy"
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 12px)",
                  }}
                  name="invoice_end_date"
                >
                  <DatePicker
                    style={{ height: "35px", marginBottom: 10 }}
                    placeholder="dd-mm-yyyy"
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Form>
            </div>
          </Modal>
          <Modal
            title="Vendor Filter"
            visible={vendorSearchVisible}
            onCancel={() => setVendorSearchVisible(false)}
            footer={[
              <Button onClick={() => handleReset("vendor")}>
                {resetLoader ? "Loading.." : "Reset"}
              </Button>,
              <Button onClick={() => handleSearchFilter()} type="primary">
                {loader ? "Loading.." : "Submit"}
              </Button>,
            ]}
            width={600}
          >
            <div>
              <Form form={vendorSearchForm}>
                <Form.Item name="search_vendor">
                  <Select
                    showSearch
                    mode="multiple"
                    className="custom_select4545"
                    style={{ margin: "8px 0 0" }}
                    placeholder="Select multile vendor"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {vendorList?.map((val) => {
                      return (
                        <Select.Option key={val._id} value={val._id}>
                          {val.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </Modal>
          <Modal
            title="Confirm Update"
            okText={
              loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 16,
                        color: "white",
                        margin: "0px 14px",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                "Update"
              )
            }
            visible={openStatusUpdateModalVisleble}
            onOk={() => deleteSelectedVendorBill(changeStatus)}
            onCancel={handleCancel}
            width={600}
          >
            {changeStatus == "paid" ? (
              <p>Are you sure want to paid the selected items?</p>
            ) : (
              <p>Are you sure want to stop unpaid the selected items?</p>
            )}
          </Modal>
        </Col>
      </Row>
      <ReportExport
        modalVisible={modalVisible}
        setModelVisible={setModelVisible}
        reportType={reportType}
        vendorList={vendorList}
      />
    </>
  );
};

export { VendorBillList };
