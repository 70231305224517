import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Input,
  Modal,
  Row,
  Col,
  Form,
  Select,
  Button,
  Radio,
} from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import { Popover } from "../../../../../components/popup/popup";
import FeatherIcon from "feather-icons-react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import "../../setting.css";
import { NavLink, useLocation } from "react-router-dom";
import {
  addProductInInvenory,
  addAddjustmentAction,
  getAllInventoryList,
  addUpdateInevntoryItems,
} from "../../../../../redux/inventory/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import { getAllRegisterList } from "../../../../../redux/register/actionCreator";
import { Spin } from "antd";
import { TableWrapper, Main } from "../../../../styled";
import "./inventory.css";
import TextArea from "antd/lib/input/TextArea";
import { ActionTypes } from "redux-devtools";
import { number } from "prop-types";
import { getItem } from "../../../../../utility/localStorageControl";
import {
  addOrUpdateVendorItem,
  getVendorItemList,
} from "../../../../../redux/vendor/actionCreator";
const InwardInventory = () => {
  const [loading, setLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let isMounted = useRef(true);
  const [DynoList, setDynoList] = useState([]);
  let location = useLocation();
  const [form] = Form.useForm();
  const [actionType, setActionType] = useState("Inward");
  const [inventoryList, setInventoryList] = useState([]);

  async function fetchInventoryList() {
    setFetchLoader(true);
    let response = await dispatch(getVendorItemList());
    let getInventoryList = {};
    if (isMounted.current && location.state && location.state.inventory_Id) {
      getInventoryList["taxesList"] = JSON.parse(
        JSON.stringify(location.state.inventory_Id.posProductsList)
      );

      if (location?.state?.inventory_Id?.inventory_items?.length > 0) {
        console.log(
          "hfgajsgfahjfgajsfgfafafsfasfasfaf",
          location.state.inventory_Id.inventory_items
        );
        location.state.inventory_Id.inventory_items.map((val) => {
          getInventoryList.taxesList.push({
            type: "inventory",
            category: val.vendorCategory ? val.vendorCategory : "",
            isNotTracked: val.isTracked,
            isSellable: true,
            product_id: val._id,
            product_name: val.inventory_item_name,
            unit_of_measure: val.unit_of_measure,
            linked_products: val.linked_products,
          });
        });
      }

      if (response && response.data && response.data?.length) {
        for (let val of response.data) {
          let check = false;
          if (getInventoryList.taxesList && getInventoryList.taxesList.length) {
            check = getInventoryList.taxesList.findIndex(
              (h) => h.product_name.toLowerCase() == val.name.toLowerCase()
            );
          }
          if (check == false || check == -1) {
            getInventoryList.taxesList.push({
              type: "inventory",
              category: val?.category?.name,
              isNotTracked: true,
              isSellable: true,
              createInventroyItem: true,
              product_id: val._id,
              product_name: val.name,
              unit_of_measure: val.measure,
              vendoritem_id: val._id,
              inventory_item_id: val.inventory_item_id,
            });
          } else if (check > -1) {
            getInventoryList.taxesList[check]["unit_of_measure"] = val.measure;
          }
        }
      }
      setDynoList(getInventoryList.taxesList);

      if (location.state.action_type == "Transfer Out") {
        let reponse = await dispatch(getAllInventoryList("sell"));
        if (reponse && reponse.taxesList?.length) {
          setInventoryList(
            reponse.taxesList.filter(
              (p) => p._id != location?.state?.inventory_Id._id
            )
          );
        }
      }
      setFetchLoader(false);
      setActionType(location.state.action_type);
      // setDynoList(location?.state?.inventory_Id?.productList);
    }
  }
  useEffect(() => {
    if (isMounted.current) {
      fetchInventoryList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const dataSource = [];

  if (DynoList.length)
    DynoList.map((value) => {
      const { product_id, product_name, category, isTracked } = value;
      if (isTracked == undefined || isTracked) {
        let findQty = location?.state?.inventory_Id?.productList.find(
          (h) => h.product_id == product_id
        );
        return dataSource.push({
          value: product_id,
          label: product_name,
          id: product_id,
          product_name: product_name,
          category: category,
          purchased: "No",
          quantity: findQty ? findQty.quantity : 0,
          ...value,
        });
      }
    });

  const hanldeItemChanges = (qty, index) => {
    if (index != undefined) {
      const items = form.getFieldValue("products");
      const newItems = [...items];
      newItems[index].availableQty = qty;
      form.setFieldsValue({ products: newItems });
    } else {
      form.setFieldsValue({
        availableQty: qty,
      });
    }
  };

  const handleSubmit = async (formData) => {
    if (loading == false) {
      setLoading(true);
      let userInfo = getItem("userDetails");
      const Obj = {};
      let originalProduct = DynoList.find(
        (val) => val.product_id == formData.product_name
      );
      if (originalProduct.category) {
        Obj.product_category = originalProduct.category;
      }

      Obj.product_name = originalProduct?.product_name;
      Obj.quantity = Number(formData.quantity);
      Obj.product_id = originalProduct.createInventroyItem
        ? originalProduct?.inventory_item_id
        : formData.product_name;
      Obj.createInventroyItem = originalProduct.createInventroyItem;
      Obj.vendoritem_id = originalProduct.vendoritem_id;
      Obj.availableQty = formData.availableQty;
      if (originalProduct.linked_products) {
        Obj.linked_products = originalProduct.linked_products;
      }
      if (originalProduct.type) {
        Obj.type = originalProduct.type;
      }
      if (originalProduct.unit_of_measure) {
        Obj.unit_of_measure = originalProduct.unit_of_measure;
      }
      if (actionType == "Adjustment") {
        if (Number(formData.availableQty) == Number(formData.quantity)) {
          Obj.adjustment_type = "Equal";
        }
        if (Number(formData.availableQty) > Number(formData.quantity)) {
          Obj.adjustment_type = "Decrease";
          Obj.decrease_adjustment_value =
            Number(formData.availableQty) - Number(formData.quantity);
        }
        if (Number(formData.availableQty) < Number(formData.quantity)) {
          Obj.adjustment_type = "Increase";
          Obj.increase_adjustment_value =
            Number(formData.quantity) - Number(formData.availableQty);
        }
      }
      const dataSource = [Obj];
      if (formData.products && formData.products.length > 0) {
        formData.products.map((j) => {
          let originalProductDetails = DynoList.find(
            (val) => val.product_id == j.product_name
          );
          let productDetails = {
            product_name: originalProductDetails?.product_name,
            product_category: originalProductDetails?.category,
            quantity: Number(j.quantity),
            createInventroyItem: originalProductDetails.createInventroyItem,
            product_id: originalProductDetails.createInventroyItem
              ? originalProductDetails?.inventory_item_id
              : j.product_name,
            availableQty: j.availableQty,
            vendoritem_id: originalProductDetails.vendoritem_id,
            inventory_item_id: originalProductDetails.inventory_item_id,
          };
          if (originalProductDetails.linked_products) {
            productDetails.linked_products =
              originalProductDetails.linked_products;
          }
          if (originalProductDetails.type) {
            productDetails.type = originalProductDetails.type;
          }
          if (originalProductDetails.unit_of_measure) {
            productDetails.unit_of_measure =
              originalProductDetails.unit_of_measure;
          }
          if (actionType == "Adjustment") {
            if (Number(j.availableQty) == Number(j.quantity)) {
              productDetails.adjustment_type = "Equal";
            }
            if (Number(j.availableQty) > Number(j.quantity)) {
              productDetails.adjustment_type = "Decrease";
              productDetails.decrease_adjustment_value =
                Number(j.availableQty) - Number(j.quantity);
            }
            if (Number(j.availableQty) < Number(j.quantity)) {
              productDetails.adjustment_type = "Increase";
              productDetails.increase_adjustment_value =
                Number(j.quantity) - Number(j.availableQty);
            }
          }
          dataSource.push(productDetails);
        });
      }

      let filterWithoutInventroyItems = dataSource.filter(
        (l) => l.createInventroyItem
      );
      if (filterWithoutInventroyItems && filterWithoutInventroyItems.length) {
        let dataSource1 = [];
        for (let newInvetoryItems of filterWithoutInventroyItems) {
          dataSource1.push({
            _id: newInvetoryItems.product_id,
            inventory_item_name: newInvetoryItems.product_name,
            unit_of_measure: newInvetoryItems.unit_of_measure,
            isTracked: true,
            vendorCategory: newInvetoryItems.product_category,
            vendoritem_id: newInvetoryItems.vendoritem_id,
            inventory_item_id: newInvetoryItems.inventory_item_id,
          });
        }
        let payload = {
          inventory_items: dataSource1,
        };
        const getAddedProduct = await dispatch(
          addUpdateInevntoryItems(location.state.inventory_Id._id, payload)
        );

        for (let mainObj of dataSource1) {
          let value = {};
          value["inventoryItemData"] = mainObj;
          value["inventoryItemData"]["inventory_id"] =
            location.state.inventory_Id._id;
          let reponse = await dispatch(
            addOrUpdateVendorItem(value, mainObj.vendoritem_id)
          );
        }
      }

      if (actionType == "Transfer Out") {
        let payload1 = {
          type: "Inward",
          products: dataSource,
          trnsaferIn: true,
          entry_date: new Date(),
          username: userInfo.username,
          reason: formData?.reason,
        };

        const getAddedProduct1 = await dispatch(
          addProductInInvenory(formData.transfer_inventory_id, payload1)
        );
      }
      let payload = {
        type: actionType == "Transfer Out" ? "Wastage" : actionType,
        products: dataSource,
      };
      const getAddedProduct = await dispatch(
        addProductInInvenory(location.state.inventory_Id._id, payload)
      );
      formData["products"] = dataSource;
      formData["entry_date"] = new Date();
      formData["inventory_id"] = location.state.inventory_Id._id;

      delete formData.quantity;
      delete formData.product_name;
      delete formData.availableQty;
      formData["action_type"] = actionType;
      const response = await dispatch(addAddjustmentAction(formData));
      if (
        !getAddedProduct?.TaxesDeletedData?.error &&
        !response.TaxesDeletedData?.error
      ) {
        setLoading(false);
        history.push("/inventory/adjustment", {
          inventory_Id: location.state.inventory_Id,
        });
      }
    }
  };

  const [errValid, setErrValid] = useState(false);
  const [placeholderArray, setPlaceholderArray] = useState([]);

  const checkvalid = (value, allProduct) => {
    allProduct.splice(-1);
    if (allProduct.length > 0) {
      if (allProduct.find((val) => val == value)) {
        setErrValid("This ingredient Name name is already in use");
        return true;
      } else {
        setErrValid(false);
        return true;
      }
    } else {
      return Promise.resolve();
    }
  };
  return (
    <Main className="inventory-items">
      <Row gutter={16}>
        <Cards title="New Adjustment">
          {fetchLoader ? (
            <Spin
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            />
          ) : (
            <Form
              autoComplete="off"
              size="large"
              onFinish={handleSubmit}
              form={form}
              onValuesChange={(item, allFileds) => {
                if (item?.product_name) {
                  let allFiledsArray = [allFileds.product_name];
                  checkvalid(item?.product_name, allFiledsArray);
                } else if (
                  item?.products &&
                  item?.products.length &&
                  item?.products[item?.products.length - 1] &&
                  item?.products[item?.products.length - 1].product_name
                ) {
                  let allFiledsArray = [allFileds.product_name];

                  allFileds.products.map((l) =>
                    allFiledsArray.push(l.product_name)
                  );
                  checkvalid(
                    item?.products[item?.products.length - 1].product_name,
                    allFiledsArray
                  );
                }
              }}
            >
              {/* <Row>
              <Col xs={24} xl={12} className="gutter-box">
                <Form.Item
                  label="Action type"
                  name="action_type"
                  initialValue={"Inward"}
                  rules={[
                    {
                      required: true,
                      message: "Action type is required",
                    },
                  ]}
                >
                  <Radio.Group onChange={(e) => setActionType(e.target.value)}>
                    <Radio value="Inward">Inward</Radio>
                    <Radio value="Adjustment">Adjustment</Radio>
                    <Radio value="Wastage">Wastage</Radio>
                   
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row> */}
              <Row>
                <Col xs={24} xl={12} className="gutter-box">
                  <Form.Item name="reason" label="Reason / Notes">
                    <Input
                      style={{ marginBottom: 0, width: "100%" }}
                      placeholder="Reason / Notes"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {actionType == "Transfer Out" && (
                <Row>
                  <Col xs={24} xl={12} className="gutter-box">
                    <Form.Item
                      name="transfer_inventory_id"
                      label="Destination Inventory"
                      rules={[
                        {
                          required: true,
                          message: "Destination Inventory is Required.",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select a Inventory"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {inventoryList.map((groupedData) => (
                          <Option key={groupedData._id} value={groupedData._id}>
                            {groupedData.inventory_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={24} xl={8} className="gutter-box">
                  <Form.Item
                    label="Item Name"
                    name="product_name"
                    rules={[
                      {
                        required: true,
                        message: "Product name is Required.",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      className="custom_select4545"
                      placeholder={
                        <span style={{ fontSize: "15px" }}>Search items</span>
                      }
                      onChange={(val) => {
                        let option = dataSource.find((k) => k.value == val);
                        console.log("afjaklsfhafafafasffasfafafasffas", option);
                        placeholderArray[0] = option.unit_of_measure;
                        setPlaceholderArray([...placeholderArray]);
                        hanldeItemChanges(option.quantity);
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataSource.map((val) => {
                        if (val) {
                          return (
                            <Option key={val.value} value={val.value}>
                              {val.label}
                            </Option>
                          );
                        }
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={6} className="gutter-box">
                  <Form.Item label={"Available Quantity"} name="availableQty">
                    <Input
                      style={{ margin: "8px 0 0" }}
                      className="input-text"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} xl={6} className="gutter-box">
                  <Form.Item
                    label={
                      actionType == "Inward"
                        ? "Quantity to Add"
                        : actionType == "Wastage"
                        ? "Quantity to Reduce"
                        : actionType == "Transfer Out"
                        ? "Transfer Quantity"
                        : actionType == "Reorder Level"
                        ? "Reorder Quantity"
                        : "New Quantity"
                    }
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Quantity is Required.",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      style={{ margin: "8px 0 0" }}
                      step="any"
                      initialValue={0}
                      className="input-text"
                      placeholder={
                        placeholderArray[0] ? placeholderArray[0] : "Units"
                      }
                      onKeyPress={(event) => {
                        if (event.key.match("[0-9,.]+")) {
                          return true;
                        } else {
                          return event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} xl={4} className="gutter-box">
                  <Form.Item
                    label="Action"
                    style={{ marginLeft: "10px" }}
                    className="action-class"
                  ></Form.Item>
                </Col>
              </Row>
              <Form.List name="products">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row
                        className="sub_prod"
                        key={field.key}
                        // style={{ margin: "8px 0 0" }}
                      >
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.area !== curValues.area ||
                            prevValues.sights !== curValues.sights
                          }
                        >
                          {() => (
                            <>
                              <Col xs={24} xl={8} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "product_name"]}
                                  fieldKey={[field.fieldKey, "product_name"]}
                                  validateStatus={
                                    fields.length - 1 == index && errValid
                                      ? "error"
                                      : undefined
                                  }
                                  help={fields.length - 1 == index && errValid}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Product name is Required.",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    className="custom_select4545"
                                    placeholder="Search items"
                                    optionFilterProp="children"
                                    onChange={(val) => {
                                      let option = dataSource.find(
                                        (k) => k.value == val
                                      );
                                      placeholderArray[index + 1] =
                                        option.unit_of_measure;
                                      hanldeItemChanges(option.quantity, index);
                                      setPlaceholderArray([
                                        ...placeholderArray,
                                      ]);
                                    }}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {dataSource.map((val) => {
                                      if (val) {
                                        return (
                                          <Option
                                            key={val.value}
                                            value={val.value}
                                          >
                                            {val.label}
                                          </Option>
                                        );
                                      }
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={6} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "availableQty"]}
                                  fieldKey={[field.fieldKey, "availableQty"]}
                                >
                                  <Input
                                    type="number"
                                    className="input-text"
                                    disabled
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={6} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "quantity"]}
                                  fieldKey={[field.fieldKey, "quantity"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Quantity is Required.",
                                    },
                                  ]}
                                >
                                  <Input
                                    type="number"
                                    min={0}
                                    initialValue={0}
                                    className="input-text"
                                    placeholder={
                                      placeholderArray[index + 1]
                                        ? placeholderArray[index + 1]
                                        : "Units"
                                    }
                                    onKeyPress={(event) => {
                                      if (event.key.match("[0-9,.]+")) {
                                        return true;
                                      } else {
                                        return event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={24} xl={4} className="gutter-box">
                                <Form.Item
                                  {...field}
                                  className="action-class"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <DeleteOutlined
                                    onClick={() => {
                                      remove(field.name);
                                      handleDelete();
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Form.Item>
                      </Row>
                    ))}
                    <div style={{ marginLeft: 9 }}>
                      <Form.Item>
                        <Button
                          type="primary"
                          info
                          //   disabled={isDisabled}
                          style={{ marginBottom: 10 }}
                          size="medium"
                          onClick={() => {
                            add();
                            // setDisabled(true);
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add Product
                        </Button>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          size="medium"
                          onClick={() =>
                            history.push("/inventory/itemList", {
                              inventoryData: location.state.inventory_Id,
                            })
                          }
                          style={{ marginRight: 10 }}
                        >
                          Go Back
                        </Button>
                        <Button
                          type="primary"
                          info
                          htmlType="submit"
                          size="medium"
                        >
                          {loading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: 16,
                                    color: "white",
                                    margin: "0px 15px",
                                  }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Form.Item>
                    </div>
                  </>
                )}
              </Form.List>
            </Form>
          )}
        </Cards>
      </Row>
    </Main>
  );
};

export { InwardInventory };
