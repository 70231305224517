import actions from "./actions";
const {
  ROOM_ADD,
  ROOM_ADD_ERR,
  ROOM_LIST,
  ROOM_LIST_ERR,
  ROOM_ID,
  ROOM_ID_ERR,
  ROOM_DELETED,
  ROOM_DELETED_ERR,
} = actions;

const initialState = {
  roomData: {},
  roomList: [],
  roomIdData: {},
  roomDeletedData: {},
};

const roomReducer = (state = initialState, action) => {
  const {
    type,
    err,
    roomData,
    roomList,
    roomIdData,
    roomDeletedData,
  } = action;

  switch (type) {
    case ROOM_ADD:
      return {
        ...state,
        roomData,
      };
    case ROOM_ADD_ERR:
      return {
        ...state,
        err,
      };
    case ROOM_LIST:
      return {
        ...state,
        roomList,
      };
    case ROOM_LIST_ERR:
      return {
        ...state,
        err,
      };
    case ROOM_ID:
      return {
        ...state,
        roomIdData,
      };
    case ROOM_ID_ERR:
      return {
        ...state,
        err,
      };
    case ROOM_DELETED:
      return {
        ...state,
        roomDeletedData,
      };
    case ROOM_DELETED_ERR:
      return {
        ...state,
        err,
      };
    default:
      return state;
  }
};
export { roomReducer };
