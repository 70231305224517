import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Input, Button, DatePicker, Select, Tabs } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../styled";
import Heading from "../../../components/heading/heading";
import { AddSingleCustomer } from "../../../redux/customer/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Radio } from "antd";
import { message } from "antd";
import moment from "moment";
import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  addOrUpdateVendorBill,
  getVendorItemList,
  getVendorList,
  getVendorTaxList,
} from "../../../redux/vendor/actionCreator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddNewProduct from "./AddNewProduct";
import { addProductInInvenory } from "../../../redux/inventory/actionCreator";
const AddVendorBill = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [modalVisible, setModelVisible] = useState({
    index: 0,
    visible: false,
  });
  const history = useHistory();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymnetMode] = useState();
  const [PaymentType, setPaymnetType] = useState(false);
  const [allFiledDisabled, setAllFiledDisable] = useState(false);
  const [currentKey, setCurrentKey] = useState("1");
  const [vendorList, setVendorList] = useState([]);
  let [vendorTaxList, setVendorTaxList] = useState([]);
  let [vendorItemList, setVendorItemList] = useState([]);
  let [placeholderArray, setPlaceholderArray] = useState([]);
  const location = useLocation();
  let [isDisabled, setDisabled] = useState(true);
  const [DateString, setDateString] = useState(
    location?.state?.billDetails
      ? moment(location?.state?.billDetails?.invoice_date).format("LL")
      : moment().format("LL")
  );
  const [billId, setBillId] = useState();

  const getVendorListData = async () => {
    let response = await dispatch(getVendorList());
    if (response && !response.error) {
      setVendorList(response.data);
    } else {
      // message.error(response.message);
    }
  };

  const getVendorTaxListData = async () => {
    let response = await dispatch(getVendorTaxList());
    if (response && !response.error && response.data?.length) {
      setVendorTaxList(
        response.data.map((k) => {
          return { vendor_tax: k._id, name: k.name, value: 0 };
        })
      );
    } else {
      // message.error(response.message);
    }
  };

  const getVendorItemListData = async (check) => {
    check == undefined && setFetchLoader(true);
    let response = await dispatch(getVendorItemList());
    if (response && !response.error) {
      setVendorItemList(
        response.data.map((k) => {
          return { value: k._id, label: k.name, ...k };
        })
      );
      check == undefined && setFetchLoader(false);
    } else {
      // message.error(response.message);
    }
  };

  useEffect(() => {
    if (location?.state?.billDetails) {
      setAllFiledDisable(true);
      let {
        _id,
        invoice_number,
        tax_number,
        total,
        status,
        payment_type,
        partial_payment,
        taxes,
        vendor_id,
        items,
        invoice_date,
        notes,
      } = location?.state?.billDetails;

      setPaymnetType(payment_type);
      setVendorTaxList(taxes);
      setBillId(_id);
      setPaymnetMode(status);

      form.setFieldsValue({
        invoice_number,
        tax_number,
        total,
        status,
        payment_type,
        partial_payment,
        vendor_id: vendor_id?._id,
        vendor_item_id: items[0].vendor_item_id,
        totalAmount: items[0].totalAmount,
        quantity: items[0].quantity,
        cost_price: items[0].cost_price,
        itemNotes: items[0]?.itemNotes,
        notes,
        items:
          items.length > 1
            ? items.slice(1, items.length).map((j) => {
                return {
                  vendor_item_id: j.vendor_item_id,
                  totalAmount: j.totalAmount,
                  quantity: j.quantity,
                  cost_price: j.cost_price,
                  itemNotes: j.itemNotes,
                };
              })
            : [],
      });
      setPlaceholderArray(items.map((m) => ({ measure: m.measure })));
      setDisabled(false);
    } else {
      getVendorTaxListData();
    }
    getVendorListData();
    getVendorItemListData();
  }, []);

  const handleSubmit = async (values) => {
    if (loading == false) {
      values.invoice_date = new Date(DateString);
      let items = [];
      let findVendorItem = vendorItemList.find(
        (k) => k.value == values.vendor_item_id
      );

      let obj = {
        vendor_item_id: values.vendor_item_id,
        name: findVendorItem?.name,
        totalAmount: values.totalAmount,
        quantity: values.quantity,
        cost_price: values.cost_price,
        measure: placeholderArray[0]?.measure,
        itemNotes: values.itemNotes,
        vendorCategory: findVendorItem?.category?.name,
      };

      if (findVendorItem.track_inventory) {
        let { inventoryItemData } = findVendorItem;

        obj["inventory_id"] = findVendorItem.inventory_Id;
        obj["vendorCategory"] = inventoryItemData.vendorCategory;
        obj["product_id"] = inventoryItemData._id;
      }
      items.push(obj);
      if (values.items && values.items.length) {
        values.items.map((o, index) => {
          let findVendorItem = vendorItemList.find(
            (k) => k.value == o.vendor_item_id
          );
          let obj = {
            ...o,
            measure: placeholderArray[index + 1]?.measure,
            name: findVendorItem?.name,
            vendorCategory: findVendorItem?.category?.name,
          };

          if (findVendorItem.track_inventory) {
            let { inventoryItemData } = findVendorItem;
            obj["inventory_id"] = inventoryItemData.inventory_id;
            obj["vendorCategory"] = inventoryItemData.vendorCategory;
            obj["product_id"] = inventoryItemData._id;
          }
          items.push(obj);
        });
      }
      values.items = items;

      values.taxes = vendorTaxList;
      delete values.vendor_item_id;
      delete values.cost_price;
      delete values.quantity;
      delete values.totalAmount;
      delete values.itemNotes;
      setLoading(true);

      const reponse = await dispatch(addOrUpdateVendorBill(values, billId));
      if (reponse && !reponse.error) {
        setLoading(false);
        history.push("/vendor/bills");
      } else if (reponse && reponse.messageCode != "ALREADY_EXIST") {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const hanldeItemChanges = (costPrice, index) => {
    if (index != undefined) {
      const items = form.getFieldValue("items");
      const newItems = [...items];
      newItems[index].cost_price = costPrice;
      newItems[index].totalAmount =
        newItems[index].quantity * newItems[index].cost_price
          ? newItems[index].quantity * newItems[index].cost_price
          : 0;
      form.setFieldsValue({ items: newItems });
    } else {
      form.setFieldsValue({
        cost_price: costPrice,
        totalAmount:
          costPrice * form.getFieldValue("quantity")
            ? costPrice * form.getFieldValue("quantity")
            : 0,
      });
    }
  };

  const handleQty = (value, index) => {
    if (index != undefined) {
      const items = form.getFieldValue("items");
      const newItems = [...items];
      newItems[index].totalAmount = value * newItems[index].cost_price;
      form.setFieldsValue({ items: newItems });
    } else {
      form.setFieldsValue({
        totalAmount:
          value * form.getFieldValue("cost_price")
            ? value * form.getFieldValue("cost_price")
            : 0,
      });
    }
  };

  const handleChangeCostPrice = (value, index) => {
    if (index != undefined) {
      const items = form.getFieldValue("items");
      const newItems = [...items];
      newItems[index].cost_price = value;
      newItems[index].totalAmount = value * newItems[index].quantity;
      form.setFieldsValue({ items: newItems });
    } else {
      form.setFieldsValue({
        totalAmount:
          value * form.getFieldValue("quantity")
            ? value * form.getFieldValue("quantity")
            : 0,
        cost_price: value,
      });
    }
  };

  const handleFormChange = (item, arrayList) => {
    let allFileds = JSON.parse(JSON.stringify(arrayList));

    if (allFileds.length < 13) {
      setDisabled(true);
      return true;
    }
    allFileds.splice(12, 1);
    allFileds.splice(0, 7);

    if (
      allFileds
        .filter(
          (k) =>
            !k.name.includes("itemNotes") && !k.name.includes("totalAmount")
        )
        .find((val, index) => val.value == undefined || val.value == "")
    ) {
      setDisabled(true);
      return true;
    } else {
      setDisabled(false);
      return true;
    }
  };

  const validateFields = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentKey("2");
        if (location?.state?.billDetails) {
          setDisabled(false);
        }
      })
      .catch((errorInfo) => {});
  };

  const handleTotalCalculation = () => {
    let total = 0;
    if (form.getFieldValue("totalAmount")) {
      total = total + Number(form.getFieldValue("totalAmount"));
    }

    if (form.getFieldValue("items") && form.getFieldValue("items").length > 0) {
      form.getFieldValue("items").forEach((element) => {
        if (element && element.totalAmount) {
          total = total + Number(element.totalAmount);
        }
      });
    }
    return total;
  };

  const saveItemValue = (val, index) => {
    if (index == 0 && val) {
      placeholderArray.push({
        measure: val[0].measure,
        cost_price: val[0].cost_price,
      });
      form.setFieldsValue({
        vendor_item_id: val[0]._id,
        cost_price: val[0].cost_price,
        totalAmount:
          val[0].cost_price * form.getFieldValue("quantity")
            ? val[0].cost_price * form.getFieldValue("quantity")
            : 0,
      });
      setPlaceholderArray([...placeholderArray]);
    } else {
      const items = form.getFieldValue("items");
      placeholderArray[index] = {
        measure: val[0].measure,
        cost_price: val[0].cost_price,
      };
      setPlaceholderArray([...placeholderArray]);
      const newItems = [...items];
      let obj = {
        vendor_item_id: val[0]._id,
        cost_price: val[0].cost_price,
        totalAmount:
          newItems[index - 1]?.quantity * newItems[index - 1]?.cost_price
            ? newItems[index - 1].quantity * newItems[index - 1].cost_price
            : 0,
      };
      newItems[index - 1] = obj;
      form.setFieldsValue({ items: newItems });
    }
  };

  return (
    <>
      <Main>
        <PageHeader ghost className="comman-custom-pageheader" />
        <br></br>
        <Form
          className="comman-input"
          autoComplete="off"
          style={{ width: "100%" }}
          form={form}
          name="editProduct"
          onFinish={handleSubmit}
          onFieldsChange={(val, allFileds) => handleFormChange(val, allFileds)}
        >
          <Cards headless>
            <Tabs
              defaultActiveKey="1"
              activeKey={currentKey}
              onChange={(val) => {
                if (val == 2) {
                  form.validateFields().then(() => {
                    setCurrentKey(val);
                  });
                } else {
                  setCurrentKey(val);
                }
              }}
            >
              <Tabs.TabPane tab="Bill" key="1">
                {fetchLoader ? (
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <div>
                    {/* <Row gutter={10} justify="center">
                      {billId && (
                        <Col xxl={2} md={2} sm={18} xs={24}>
                          <br></br>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setAllFiledDisable(false)}
                          >
                            Edit{" "}
                            <EditOutlined
                              style={
                                allFiledDisabled
                                  ? { marginLeft: 4 }
                                  : { marginLeft: 4, color: "#BD015D" }
                              }
                            />
                          </div>
                        </Col>
                      )}
                      <Col xxl={12} md={14} sm={18} xs={24}>
                        <Row gutter={10}>
                          <Col xxl={12} md={12} sm={24} xs={24}>
                            <Form.Item
                              name="invoice_number"
                              label="Bill No."
                              rules={[
                                {
                                  message: "Bill No. is required",
                                  required: true,
                                },
                                {
                                  max: 50,
                                  message:
                                    "Bill No. cannot be more than 50 characters long.",
                                },
                              ]}
                            >
                              <Input
                                disabled={allFiledDisabled}
                                style={{ marginBottom: 10 }}
                                placeholder="Bill No."
                              />
                            </Form.Item>
                          </Col>
                          <Col xxl={12} md={12} sm={24} xs={24}>
                            <Form.Item name="invoice_date" label="Bill Date">
                              <DatePicker
                                disabled={allFiledDisabled}
                                className="book_picker"
                                style={{ width: "100%", minHeight: "47px" }}
                                size="large"
                                defaultValue={moment(DateString, "LL")}
                                onChange={(date, string) => {
                                  setDateString(string);
                                }}
                                format="LL"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          name="vendor_id"
                          label="Vendor"
                          rules={[
                            {
                              message: "Please select vendor",
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            disabled={
                              location?.state?.billDetails
                                ? true
                                : allFiledDisabled
                            }
                            placeholder="Select vendor"
                            showSearch
                            style={{ marginBottom: 10 }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {vendorList.map((val) => {
                              return (
                                <Select.Option value={val._id}>
                                  {val.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item name="tax_number" label="Tax Number">
                          <Input
                            disabled={allFiledDisabled}
                            style={{ marginBottom: 10 }}
                            placeholder="Tax Number (optional)"
                          />
                        </Form.Item>
                        {vendorTaxList.length > 0 && <p>Taxs</p>}
                        {vendorTaxList.map((val, index) => {
                          return (
                            <div>
                              <Form.Item
                                style={{
                                  display: "inline-block",
                                  width: "calc(50% - 12px)",
                                }}
                              >
                                <Input
                                  value={val.name}
                                  style={{ marginBottom: 10 }}
                                  disabled={true}
                                />
                              </Form.Item>
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "24px",
                                  lineHeight: "32px",
                                  textAlign: "center",
                                }}
                              ></span>

                              <Form.Item
                                style={{
                                  display: "inline-block",
                                  width: "calc(50% - 12px)",
                                }}
                              >
                                <Input
                                  type="number"
                                  disabled={allFiledDisabled}
                                  style={{ marginBottom: 10 }}
                                  placeholder="Tax Amount (optional)"
                                  defaultValue={val.value}
                                  onKeyPress={(event) => {
                                    if (event.key.match("[0-9.]")) {
                                      return true;
                                    } else {
                                      return event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      vendorTaxList[index]["value"] =
                                        e.target.value;
                                      setVendorList([...vendorTaxList]);
                                    }
                                  }}
                                />
                              </Form.Item>
                            </div>
                          );
                        })}
                        <Form.Item
                          name="total"
                          label="Total"
                          rules={[
                            {
                              message: "Please enter Total",
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            disabled={allFiledDisabled}
                            type="number"
                            onKeyPress={(event) => {
                              if (event.key.match("[0-9.]")) {
                                return true;
                              } else {
                                return event.preventDefault();
                              }
                            }}
                            style={{ marginBottom: 10 }}
                            placeholder="Total"
                          />
                        </Form.Item>
                        <Form.Item
                          name="status"
                          label="Status"
                          rules={[
                            {
                              message: "Please select status",
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            disabled={allFiledDisabled}
                            placeholder="Select payment status"
                            style={{ marginBottom: 10 }}
                            onChange={(value) => setPaymnetMode(value)}
                          >
                            <Select.Option value="paid">Paid</Select.Option>
                            <Select.Option value="unpaid">UnPaid</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item name="notes" label="Notes">
                          <Input
                            disabled={allFiledDisabled}
                            style={{ marginBottom: 10 }}
                            placeholder="Notes"
                          />
                        </Form.Item>
                        <div
                          className="add-form-action"
                          style={{ float: "left", marginTop: "15px" }}
                        >
                          <Form.Item>
                            <NavLink
                              to="/vendor/bills/"
                              style={{ marginRight: 10 }}
                            >
                              <Button
                                className="btn-cancel btn-custom"
                                size="medium"
                              >
                                Go Back
                              </Button>
                            </NavLink>
                            <Button
                              size="medium"
                              className="btn-custom"
                              type="primary"
                              raised
                              onClick={validateFields}
                            >
                              Next
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row> */}
                    <Row gutter={10} justify="center">
                      {/* {billId && (
                        <Col xxl={2} md={2} sm={18} xs={24}>
                          <br></br>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setAllFiledDisable(false)}
                          >
                            Edit{" "}
                            <EditOutlined
                              style={
                                allFiledDisabled
                                  ? { marginLeft: 4 }
                                  : { marginLeft: 4, color: "#BD015D" }
                              }
                            />
                          </div>
                        </Col>
                      )} */}
                      <div className="formDiv vendorFromWidth">
                        <div className="mainFormdiv">
                          <Row gutter={10}>
                            <Col xxl={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name="invoice_number"
                                label="Bill No."
                                rules={[
                                  {
                                    message: "Bill No. is required",
                                    required: true,
                                  },
                                  {
                                    max: 50,
                                    message:
                                      "Bill No. cannot be more than 50 characters long.",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={allFiledDisabled}
                                  style={{ marginBottom: 10 }}
                                  placeholder="Bill No."
                                />
                              </Form.Item>
                            </Col>
                            <Col xxl={12} md={12} sm={24} xs={24}>
                              <Form.Item name="invoice_date" label="Bill Date">
                                <DatePicker
                                  disabled={allFiledDisabled}
                                  className="book_picker"
                                  style={{ width: "100%", minHeight: "47px" }}
                                  size="large"
                                  defaultValue={moment(DateString, "LL")}
                                  onChange={(date, string) => {
                                    setDateString(string);
                                  }}
                                  format="LL"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            name="vendor_id"
                            label="Vendor"
                            className="addVendorbill"
                            rules={[
                              {
                                message: "Please select vendor",
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              disabled={
                                location?.state?.billDetails
                                  ? true
                                  : allFiledDisabled
                              }
                              placeholder="Select vendor"
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      padding: 8,
                                    }}
                                  >
                                    <NavLink
                                      to="/vendor/add"
                                      style={{
                                        flex: "none",
                                        padding: "8px",
                                        display: "block",
                                        cursor: "pointer",
                                        color: "#008cba",
                                      }}
                                    >
                                      <PlusOutlined
                                        style={{ color: "rgb(0, 140, 186)" }}
                                      />{" "}
                                      <lable
                                        style={{ color: "rgb(0, 140, 186)" }}
                                      >
                                        Add New Vendor
                                      </lable>
                                    </NavLink>
                                  </div>
                                </div>
                              )}
                              showSearch
                              className="addBillselect"
                              style={{ marginBottom: 10 }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {vendorList.map((val) => {
                                return (
                                  <Select.Option value={val._id}>
                                    {val.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item name="tax_number" label="Tax Number">
                            <Input
                              disabled={allFiledDisabled}
                              style={{ marginBottom: 10 }}
                              placeholder="Tax Number (optional)"
                            />
                          </Form.Item>
                          {vendorTaxList.length > 0 && (
                            <p style={{ padding: 0, marginBottom: "4px" }}>
                              Tax
                            </p>
                          )}
                          {vendorTaxList.map((val, index) => {
                            return (
                              <div>
                                <Form.Item
                                  style={{
                                    display: "inline-block",
                                    width: "calc(50% - 12px)",
                                  }}
                                >
                                  <Input
                                    value={val.name}
                                    style={{ marginBottom: 10 }}
                                    disabled={true}
                                  />
                                </Form.Item>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "24px",
                                    lineHeight: "32px",
                                    textAlign: "center",
                                  }}
                                ></span>

                                <Form.Item
                                  style={{
                                    display: "inline-block",
                                    width: "calc(50% - 12px)",
                                  }}
                                >
                                  <Input
                                    type="number"
                                    disabled={allFiledDisabled}
                                    style={{ marginBottom: 10 }}
                                    placeholder="Tax Amount (optional)"
                                    defaultValue={val.value}
                                    onKeyPress={(event) => {
                                      if (event.key.match("[0-9.]")) {
                                        return true;
                                      } else {
                                        return event.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        vendorTaxList[index]["value"] =
                                          e.target.value;
                                        setVendorList([...vendorTaxList]);
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            );
                          })}
                          <Form.Item
                            name="total"
                            label="Total"
                            rules={[
                              {
                                message: "Please enter Total",
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              disabled={allFiledDisabled}
                              type="number"
                              onKeyPress={(event) => {
                                if (event.key.match("[0-9.]")) {
                                  return true;
                                } else {
                                  return event.preventDefault();
                                }
                              }}
                              style={{ marginBottom: 10 }}
                              placeholder="Total"
                            />
                          </Form.Item>
                          <Form.Item
                            name="status"
                            label="Status"
                            rules={[
                              {
                                message: "Please select status",
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              disabled={allFiledDisabled}
                              placeholder="Select payment status"
                              style={{ marginBottom: 10 }}
                              onChange={(value) => setPaymnetMode(value)}
                            >
                              <Select.Option value="paid">Paid</Select.Option>
                              <Select.Option value="unpaid">
                                UnPaid
                              </Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item name="notes" label="Notes">
                            <Input
                              disabled={allFiledDisabled}
                              style={{ marginBottom: 10 }}
                              placeholder="Notes"
                            />
                          </Form.Item>
                          <div
                            className="add-form-action"
                            style={{ float: "right", marginTop: "15px" }}
                          >
                            <Form.Item>
                              <NavLink
                                to="/vendor/bills/"
                                style={{ marginRight: 10 }}
                              >
                                <Button
                                  className="btn-cancel btn-custom"
                                  size="medium"
                                >
                                  Go Back
                                </Button>
                              </NavLink>
                              <Button
                                className="btn-cancel btn-custom"
                                size="medium"
                                style={{ marginRight: 10 }}
                                onClick={() => setAllFiledDisable(false)}
                              >
                                Edit
                              </Button>
                              <Button
                                size="medium"
                                className="btn-custom"
                                type="primary"
                                raised
                                onClick={validateFields}
                              >
                                Next
                              </Button>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Items" key="2">
                {fetchLoader ? (
                  <Spin />
                ) : (
                  <div>
                    <Row>
                      <Col xs={24} xl={5} className="gutter-box">
                        <Form.Item
                          label="Item Name"
                          className="customSelectHeight"
                          name="vendor_item_id"
                          rules={[
                            {
                              required: true,
                              message: "Item name is Required.",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            disabled={allFiledDisabled}
                            style={{
                              margin: "8px 0 0",
                              minHeight: "35px !important",
                            }}
                            placeholder="Type to choose item from list"
                            optionFilterProp="children"
                            onChange={(val, option) => {
                              placeholderArray.push({
                                measure: option.measure,
                                cost_price: option.cost_price,
                              });
                              hanldeItemChanges(option.cost_price);
                              setPlaceholderArray([...placeholderArray]);
                            }}
                            filterOption={(input, option) =>
                              (option?.name?.toLowerCase() ?? "").includes(
                                input.toLowerCase()
                              )
                            }
                            options={vendorItemList}
                            dropdownRender={(menu) => (
                              <div>
                                {menu}
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    padding: 8,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setModelVisible({ index: 0, visible: true })
                                  }
                                >
                                  <PlusOutlined
                                    style={{
                                      color: "rgb(0, 140, 186)",
                                      marginRight: 8,
                                    }}
                                  />{" "}
                                  <lable style={{ color: "rgb(0, 140, 186)" }}>
                                    Add New Item
                                  </lable>
                                </div>
                              </div>
                            )}
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} xl={5} className="gutter-box">
                        <Form.Item label="Item notes" name="itemNotes">
                          <Input
                            disabled={allFiledDisabled}
                            type="text"
                            style={{ margin: "8px 0 0" }}
                            placeholder="Item notes"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} xl={4} className="gutter-box">
                        <Form.Item
                          label="Quantity to add"
                          name="quantity"
                          className="custom-input-text"
                          rules={[
                            {
                              required: true,
                              message: "Quantity is Required.",
                            },
                          ]}
                        >
                          <Input
                            disabled={allFiledDisabled}
                            type="number"
                            min={0}
                            style={{ margin: "8px 0 0" }}
                            step="any"
                            initialValue={0}
                            placeholder={
                              placeholderArray[0]?.measure
                                ? placeholderArray[0].measure
                                : "Units"
                            }
                            onChange={(e) => handleQty(e.target.value)}
                            onKeyPress={(event) => {
                              if (event.key.match("[0-9,.]+")) {
                                return true;
                              } else {
                                return event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} xl={4} className="gutter-box">
                        <Form.Item
                          label="Cost price"
                          name="cost_price"
                          rules={[
                            {
                              required: true,
                              message: "Cost price",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            disabled={allFiledDisabled}
                            min={0}
                            style={{ margin: "8px 0 0" }}
                            step="any"
                            placeholder="Cost price"
                            onKeyPress={(event) => {
                              if (event.key.match("[0-9.]")) {
                                return true;
                              } else {
                                return event.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              handleChangeCostPrice(e.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} xl={4} className="gutter-box">
                        <Form.Item
                          label="Total price"
                          name="totalAmount"
                          rules={[
                            {
                              required: true,
                              message: "Total  price",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            min={0}
                            onKeyPress={(event) => {
                              if (event.key.match("[0-9.]")) {
                                return true;
                              } else {
                                return event.preventDefault();
                              }
                            }}
                            style={{ margin: "8px 0 0" }}
                            step="any"
                            disabled
                            placeholder="Total price"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} xl={2} className="gutter-box">
                        <Form.Item
                          label="Action"
                          style={{ marginLeft: "10px" }}
                          className="action-class"
                        ></Form.Item>
                      </Col>
                    </Row>
                    <Form.List name="items">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => (
                            <Row
                              key={field.key}
                              // style={{ margin: "8px 0 0" }}
                            >
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area ||
                                  prevValues.sights !== curValues.sights
                                }
                              >
                                {() => (
                                  <>
                                    <Col xs={24} xl={5} className="gutter-box">
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "vendor_item_id"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "vendor_item_id",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Item name is Required.",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          className="custom_select4545"
                                          style={{
                                            margin: "8px 0 0",
                                            height: "35px",
                                          }}
                                          disabled={allFiledDisabled}
                                          placeholder="Type to choose item from list"
                                          optionFilterProp="children"
                                          onChange={(val, option) => {
                                            placeholderArray.push({
                                              measure: option.measure,
                                              cost_price: option.cost_price,
                                            });
                                            hanldeItemChanges(
                                              option.cost_price,
                                              index
                                            );
                                            setPlaceholderArray([
                                              ...placeholderArray,
                                            ]);
                                          }}
                                          filterOption={(input, option) =>
                                            (
                                              option?.name?.toLowerCase() ?? ""
                                            ).includes(input.toLowerCase())
                                          }
                                          options={vendorItemList}
                                          dropdownRender={(menu) => (
                                            <div>
                                              {menu}
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "nowrap",
                                                  padding: 8,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  setModelVisible({
                                                    index: index + 1,
                                                    visible: true,
                                                  })
                                                }
                                              >
                                                <PlusOutlined
                                                  style={{
                                                    color: "rgb(0, 140, 186)",
                                                    marginRight: 8,
                                                  }}
                                                />{" "}
                                                <lable
                                                  style={{
                                                    color: "rgb(0, 140, 186)",
                                                  }}
                                                >
                                                  Add New Item
                                                </lable>
                                              </div>
                                            </div>
                                          )}
                                        ></Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={5} className="gutter-box">
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "itemNotes"]}
                                        fieldKey={[field.fieldKey, "itemNotes"]}
                                      >
                                        <Input
                                          disabled={allFiledDisabled}
                                          style={{ margin: "8px 0 0" }}
                                          type="text"
                                          placeholder="Item notes"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={6} xl={4} className="gutter-box">
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "quantity"]}
                                        fieldKey={[field.fieldKey, "quantity"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Quantity is Required.",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          disabled={allFiledDisabled}
                                          min={0}
                                          style={{ margin: "8px 0 0" }}
                                          step="any"
                                          initialValue={0}
                                          placeholder={
                                            placeholderArray[index + 1]?.measure
                                              ? placeholderArray[index + 1]
                                                  .measure
                                              : "Units"
                                          }
                                          onChange={(e) =>
                                            handleQty(e.target.value, index)
                                          }
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9,.]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={4} className="gutter-box">
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "cost_price"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "cost_price",
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          disabled={allFiledDisabled}
                                          style={{ margin: "8px 0 0" }}
                                          step="any"
                                          placeholder="Cost Price"
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9.]")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) =>
                                            handleChangeCostPrice(
                                              e.target.value,
                                              index
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={4} className="gutter-box">
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "totalAmount"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "totalAmount",
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9.]")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                          disabled
                                          style={{ margin: "8px 0 0" }}
                                          step="any"
                                          initialValue={0}
                                          placeholder="Total price"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={2} className="gutter-box">
                                      <Form.Item
                                        {...field}
                                        className="action-class"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <DeleteOutlined
                                          onClick={() => {
                                            remove(field.name);
                                            setDisabled(false);
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}
                              </Form.Item>
                            </Row>
                          ))}
                          <div style={{ marginLeft: 9, marginTop: 15 }}>
                            <Row>
                              <Col xs={24} xl={5}>
                                <Button
                                  type="primary"
                                  info
                                  disabled={isDisabled}
                                  style={{ marginBottom: 10 }}
                                  size="medium"
                                  onClick={() => {
                                    setDisabled(true);
                                    add();
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  Add Item
                                </Button>
                              </Col>
                              <Col xs={24} xl={5} className="gutter-box"></Col>
                              <Col xs={24} xl={4} className="gutter-box"></Col>
                              <Col xs={24} xl={4} className="gutter-box"></Col>
                              <Col xs={24} xl={4} className="gutter-box">
                                Total:
                                {handleTotalCalculation()}
                              </Col>
                              <Col xs={24} xl={2} className="gutter-box"></Col>
                            </Row>

                            <Form.Item>
                              <Button
                                size="medium"
                                onClick={() => history.push("/vendor/bills")}
                                style={{ marginRight: 10 }}
                              >
                                Go Back
                              </Button>
                              <Button
                                type="primary"
                                info
                                htmlType="submit"
                                size="medium"
                              >
                                {loading ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{
                                          fontSize: 16,
                                          color: "white",
                                          margin: "0px 15px",
                                        }}
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </Form.Item>
                          </div>
                        </>
                      )}
                    </Form.List>
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          </Cards>

          <br></br>
        </Form>
        {modalVisible?.visible && (
          <AddNewProduct
            modalVisible={modalVisible}
            setModelVisible={setModelVisible}
            getVendorItemListData={getVendorItemListData}
            saveItemValue={saveItemValue}
          />
        )}
      </Main>
    </>
  );
};

export default AddVendorBill;
