let Direct = [
  {
    value: "Website",
    label: "Website",
  },
  {
    value: "Walk-In",
    label: "Walk-In",
  },
  {
    value: "Email",
    label: "Email",
  },
  {
    value: "Phone",
    label: "Phone",
  },
];
export default Direct;
