import React, { useState, useRef, useEffect } from "react";
import { Table, Input, Modal, Spin, Row, Col, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Cards } from "../../../../../../components/cards/frame/cards-frame";
import { Popover } from "../../../../../../components/popup/popup";
import FeatherIcon from "feather-icons-react";
import { CaretDownOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../../setting.css";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "../../../../../../components/buttons/buttons";
import {
  getAllTaxesList,
  deleteTaxes,
} from "../../../../../../redux/taxes/actionCreator";
import {
  getAllPosProductsList,
  getInventoryById,
  trackUpdate,
} from "../../../../../../redux/inventory/actionCreator";
import {
  getItem,
  setItem,
} from "../../../../../../utility/localStorageControl";
import { LoadingOutlined } from "@ant-design/icons";
import { TopToolBox } from "../../../../../ProductOption/Style";
import { UserTableStyleWrapper } from "../../../../../pages/style";
import { TableWrapper, Main } from "../../../../../styled";
import "../inventory.css";
import { getAllProductList } from "../../../../../../redux/products/actionCreator";
import {
  addOrFindVendorCategory,
  addOrUpdateVendorItem,
  getVendorItemList,
} from "../../../../../../redux/vendor/actionCreator";

const PosProducts = ({ inventoryId }) => {
  const [vendorItems, setVendorItems] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  let [search, setsearch] = useState("");
  let isMounted = useRef(true);
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  const [PosProductsList, setPosProductsList] = useState([]);
  const [selectionType] = useState("checkbox");
  const offLineMode = useSelector((state) => state.auth.offlineMode);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [track, setTrack] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [inventoryData, setInventoryData] = useState();
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  const createPosItems = (list) => {
    let finalList = [];
    list.map((val) => {
      if (
        val.limit_to_register?.length == 0 ||
        val?.limit_to_register.includes(currentRegisterData._id)
      ) {
        if (val.option_variant_group.length > 0) {
          val.option_variant_group.map((j) => {
            j.product_variants.sort((a, b) =>
              a.sort_order > b.sort_order ? 1 : -1
            );
          });
          val.option_variant_group.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          );
          let newArray = [];
          let n = val.option_variant_group.length;
          let indices = new Array(n);

          for (let i = 0; i < n; i++) {
            indices[i] = 0;
          }

          let loop = true;

          while (loop == true) {
            let variantArray = [];
            let title = val.product_name;
            let id = val._id;
            let variantTitle = "";
            let variantIds = "";
            for (let i = 0; i < n; i++) {
              variantTitle =
                variantTitle +
                " / " +
                val.option_variant_group[i]["product_variants"][indices[i]]
                  .variant_name;
              variantIds =
                variantIds +
                "--varient-" +
                val.option_variant_group[i]["product_variants"][indices[i]]._id;
              variantArray.push(
                val.option_variant_group[i]["product_variants"][indices[i]]
              );
            }
            title = title + variantTitle;
            id = id + variantIds;

            finalList.push({
              category: val.product_category.category_name,
              product_id: id,
              isNotTracked: true,
              isSellable: true,
              product_name: title,
              limit_to_register: val.limit_to_register,
              unit_of_measure: val.unit_of_measure,
            });

            let next = n - 1;
            while (
              next >= 0 &&
              indices[next] + 1 >=
                val.option_variant_group[next]["product_variants"].length
            ) {
              next--;
            }

            if (next < 0) {
              loop = false;
            }

            indices[next]++;

            for (let i = next + 1; i < n; i++) {
              indices[i] = 0;
            }
          }
        } else {
          finalList.push({
            category: val.product_category.category_name,
            product_id: val._id,
            isNotTracked: true,
            isSellable: true,
            product_name: val.product_name,
            limit_to_register: val.limit_to_register,
            unit_of_measure: val.unit_of_measure,
          });
        }
      }
    });
    return finalList;
  };

  async function fetchInventoryData() {
    setLoader(true);
    // const allLocalData = getItem("setupCache");
    // if (allLocalData?.inventorys?.length) {
    //   let findInventory = allLocalData.inventorys.find((val) =>
    //     val.linked_registers.includes(currentRegisterData._id)
    //   );
    //   if (findInventory && findInventory._id) {
    //     let response = await dispatch(getInventoryById(findInventory._id));
    //     if (response && response.taxesIdData) {
    //       let Productresponse = await dispatch(getAllProductList("sell"));
    //       let posItems = createPosItems(Productresponse.productList);
    //       if (posItems && posItems.length) {
    //         setPosProductsList(posItems);
    //         let mainProductList = response.taxesIdData?.products
    //           ? response.taxesIdData.products
    //           : [];
    //         if (response.taxesIdData.products) {
    //           delete response.taxesIdData.products;
    //         }
    //         setInventoryData({
    //           ...response.taxesIdData,
    //           productList: mainProductList,
    //           posProductsList: posItems,
    //         });
    //         setLoader(false);
    //       }
    //     }
    //   }
    // }
    let response = await dispatch(getVendorItemList());
    if (response && response.data) {
      setVendorItems(response.data);
      setLoader(false);
    }
  }
  useEffect(() => {
    if (isMounted.current) {
      fetchInventoryData();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setInventoryData(inventoryId);
    if (inventoryId && inventoryId.posProductsList) {
      setPosProductsList(inventoryId?.posProductsList);
    }
  }, [inventoryId]);

  const [state, setState] = useState({
    item: PosProductsList,
  });
  const { item } = state;
  useEffect(() => {
    if (PosProductsList) {
      setState({
        item: PosProductsList,
      });
    }
  }, [PosProductsList]);

  const deleteSelectedTaxes = async () => {
    setLoading(true);
    const { allSelectedRowsForDelete } = state;
    let allTaxesIdsForDelete = [];
    if (track == "purchase") {
      console.log("daskghdjkahdjkasdahdasdadda", allSelectedRowsForDelete);
      if (allSelectedRowsForDelete && allSelectedRowsForDelete.length > 0) {
        for (let item of allSelectedRowsForDelete) {
          let checkCategory = await dispatch(
            addOrFindVendorCategory({ name: item.category })
          );

          if (checkCategory && !checkCategory.error && checkCategory.data) {
            allTaxesIdsForDelete.push({
              name: item.product_name,
              category: checkCategory.data._id,
              measure: item.unit_of_measure,
              product_id: item.id,
            });
          }
        }

        const getDeletedVariant = await dispatch(
          addOrUpdateVendorItem({ items: allTaxesIdsForDelete })
        );
        if (getDeletedVariant && !getDeletedVariant.error) {
          setSelectedRowKeys([]);
          setState({
            ...state,
            selectedRows: [],
          });
          setModelDeleteVisible(false);
          setLoading(false);
        }
      }
    } else {
      allSelectedRowsForDelete.map((item) => {
        allTaxesIdsForDelete.push({
          product_name: item.product_name,
          product_id: item.id,
          isTracked: track == "track" ? true : false,
          product_category: item.category,
        });
      });

      if (inventoryData) {
        const getDeletedTaxes = await dispatch(
          trackUpdate(inventoryData._id, allTaxesIdsForDelete)
        );
        if (
          getDeletedTaxes &&
          getDeletedTaxes.TaxesDeletedData &&
          !getDeletedTaxes.TaxesDeletedData.error
        ) {
          setInventoryData({
            ...inventoryData,
            productList: getDeletedTaxes.TaxesDeletedData.data.products,
          });
          setSelectedRowKeys([]);
          setState({
            ...state,
            selectedRows: [],
          });
          setModelDeleteVisible(false);
        }
      }
    }
  };

  const contentforaction = (
    <>
      {/* <NavLink
        to="#"
        onClick={() => {
          setModelDeleteVisible(true);
          setLoading(false);
          setTrack("purchase");
        }}
      >
        <span>Set as purchase</span>
      </NavLink> */}
      <NavLink
        to="#"
        onClick={() => {
          setModelDeleteVisible(true);
          setLoading(false);
          setTrack("track");
        }}
      >
        <span>Set as tracked</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelDeleteVisible(true);
          setLoading(false);
          setTrack("notTrack");
        }}
      >
        <span>Set as not tracked</span>
      </NavLink>
    </>
  );

  const dataSource = [];

  let searchArrTaxes = item.filter((value) =>
    value.product_name.toLowerCase().includes(search.toLowerCase())
  );

  if (searchArrTaxes.length && inventoryData && inventoryData.productList) {
    searchArrTaxes.map((value) => {
      let trackproduct = inventoryData.productList.find(
        (l) => l.product_id == value.product_id
      );
      let recipelist = [];
      if (
        trackproduct &&
        trackproduct.linked_products &&
        trackproduct.linked_products.length
      ) {
        trackproduct.linked_products
          .map((val) => recipelist.push(val))
          .toString();
      }

      // inventoryData.productList.find((j) => {
      //   let findrecipe =
      //     j &&
      //     j.linked_products &&
      //     j.linked_products.find((e) => e.product_id == value.product_id);
      //   if (findrecipe) {
      //     recipelist.push(j);
      //   }
      // });

      const {
        product_id,
        product_name,
        tax_percentage,
        is_linked_to_tax_group,
        pos_id,
        category,
      } = value;
      return dataSource.push({
        pos_id: pos_id,
        id: product_id,
        product_name: product_name,
        isNotTracked: trackproduct
          ? trackproduct.isTracked == undefined ||
            trackproduct.isTracked == true
            ? "Yes"
            : "No"
          : "Yes",
        is_linked: is_linked_to_tax_group,
        category: category,
        recipe: recipelist,
      });
    });
  }

  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      width: "2%",
      // fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
      fixed: "left",
      className: "products_list_fix",
      // width: 150,
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },
    {
      title: "Item Category",
      dataIndex: "category",
      key: "category",
      className: "center-col",
    },
    {
      title: "Tracked",
      dataIndex: "isNotTracked",
      key: "isNotTracked",
      className: "center-col",
    },
    {
      title: "Recipe",
      dataIndex: "recipe",
      key: "recipe",
      className: "center-col",
      render(text, record) {
        return {
          children: (
            <div>
              {text.length
                ? text
                    .map((val, index) => index < 2 && ` ${val.product_name} `)
                    .filter((val) => !val == false)
                    .toString()
                : "-"}
              {text.length > 2 && (
                <Tooltip
                  title={text.map((val, index) => ` ${val.product_name} , `)}
                >
                  <ExclamationCircleOutlined
                    style={{
                      cursor: "pointer",
                      marginLeft: "3px",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: "Add Recipe",
      dataIndex: "tax_percent",
      key: "tax_percent",
      className: "center-col",
      render(text, record) {
        return {
          children: (
            <div>
              <EditOutlined
                onClick={() => {
                  history.push(`/inventory/add-inventory-recipe`, {
                    inventory_Id: inventoryData,
                    currentProduct: record,
                    posProductsList: PosProductsList,
                    vendorItems: vendorItems,
                  });
                }}
              />
            </div>
          ),
        };
      },
    },
    ,
  ];
  const [offLineModeCheck, setOfflineModeCheck] = useState(false);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys([...selectedRowKeys]);
      setState({
        ...state,
        allSelectedRowsForDelete: selectedRows,
      });
    },
  };
  const handleCancel = (e) => {
    setModelDeleteVisible(false);
  };
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  return (
    <>
      <p style={{ display: "none" }}>{loading}</p>
      <Cards headless>
        {/* <TopToolBox> */}

        <Row gutter={15} className="list-row">
          <Col span={24} className="pricebookTableBox">
            <div className="priceBook_titles_content">
              <div className="productCount">
                <h2>POS Products</h2>
                <span className="title-counter" style={{ marginTop: "-17px" }}>
                  {dataSource?.length}{" "}
                </span>
              </div>
              <div
                style={{
                  boxShadow: "none",
                  marginBottom: "16px",
                  marginLeft: "20px",
                }}
                className="table_search"
              >
                <Input
                  suffix={<SearchOutlined />}
                  autoFocus
                  className="set_serbt"
                  placeholder="Search by Name"
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                  }}
                  onChange={(e) => setsearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
            <div className="pricebookTables-toolbox-menu">
              <div key="1" className="page-header-actions">
                <Button
                  className="goBackInventory"
                  onClick={() => {
                    if (offLineMode) {
                      setOfflineModeCheck(true);
                    } else {
                      history.push(`/inventory/itemList`, {
                        inventoryData: inventoryData,
                      });
                      setOfflineModeCheck(false);
                    }
                  }}
                >
                  <ArrowLeftOutlined />
                  Go back
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {/* </TopToolBox> */}
        <Modal
          title="You are Offline"
          visible={offLineModeCheck}
          onOk={() => setOfflineModeCheck(false)}
          onCancel={() => setOfflineModeCheck(false)}
          width={600}
        >
          <p>You are offline not add and update </p>
        </Modal>
        <Modal
          title="Confirm Update"
          okText={
            loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                    spin
                  />
                }
              />
            ) : (
              "Update"
            )
          }
          visible={modalDeleteVisible}
          onOk={deleteSelectedTaxes}
          onCancel={handleCancel}
          width={600}
        >
          {track == "track" ? (
            <p>Are you sure want to track the selected items?</p>
          ) : track == "notTrack" ? (
            <p>Are you sure want to stop tracking the selected items?</p>
          ) : (
            <p>Are you sure you want to purchase selected item ?</p>
          )}
        </Modal>
        <UserTableStyleWrapper>
          <div className="contact-table posProductTbl">
            <TableWrapper className="table-responsive">
              {loader ? (
                <Table
                  rowKey="id"
                  locale={locale}
                  dataSource={[]}
                  className="products_lsttable"
                  columns={columns}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  scroll={{
                    x: 1000,
                  }}
                  size="small"
                  style={{ marginTop: "8px" }}
                />
              ) : (
                <Table
                  rowKey="id"
                  dataSource={dataSource}
                  className="products_lsttable"
                  columns={columns}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  scroll={{
                    x: 1000,
                  }}
                  size="small"
                  style={{ marginTop: "8px" }}
                />
              )}
            </TableWrapper>
          </div>
        </UserTableStyleWrapper>
      </Cards>
    </>
  );
};

export default PosProducts;
