import actions from "./actions";
import { getItem, setItem } from "../../utility/localStorageControl";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import axios from "axios";
import moment from "moment";
const {
  searchHeaderBegin,
  searchHeaderSuccess,
  searchHeaderErr,
  ReceiptsList,
  ReceiptsListErr,
  ReceiptsId,
  ReceiptsIdErr,
  cancelOrderAdd,
  cancelOrderAddErr,
  receiptDelete,
  receiptDeleteErr,
  filterDateList,
  receiptLoader,
  masterReservationDate,
  paymnetModeDate,
  roomReservationDate,
} = actions;

const headerGlobalSearchAction = (searchData) => {
  return (dispatch) => {
    try {
      dispatch(searchHeaderBegin());
      dispatch(searchHeaderSuccess(searchData));
    } catch (err) {
      dispatch(searchHeaderErr(err));
    }
  };
};

const getAllReceiptsList = (
  currentPage,
  limit,
  startDate,
  endDate,
  payment_status,
  receipt_number
) => {
  return async (dispatch) => {
    try {
      let regId = getItem("setupCache").register.find((val) => val.active)._id;

      let getReceiptsList;
      if (startDate) {
        getReceiptsList = await DataService.get(
          `${
            API.recepits.getAllReceipts
          }?page=${currentPage}&limit=${limit}&register_id=${regId}&startDate=${startDate}&endDate=${endDate}&timeZon=${
            Intl.DateTimeFormat().resolvedOptions().timeZone
          }&payment_status=${payment_status}&receipt_number=${receipt_number}`
        );
      } else {
        getReceiptsList = await DataService.get(
          `${
            API.recepits.getAllReceipts
          }?page=${currentPage}&limit=${limit}&register_id=${regId}&startDate=&endDate=&timeZon=${
            Intl.DateTimeFormat().resolvedOptions().timeZone
          }&payment_status=${payment_status}&receipt_number=${receipt_number}`
        );
      }

      if (!getReceiptsList.data.error) {
        let receiptCount =
          getReceiptsList.data.messageCode == "NO_DATA_FOUND"
            ? 0
            : getReceiptsList.data.pagination.total_counts;

        return dispatch(
          ReceiptsList(
            getReceiptsList.data.data,
            receiptCount,
            startDate,
            endDate
          )
        );
      } else {
        return dispatch(ReceiptsListErr(getReceiptsList.data));
      }
    } catch (err) {
      dispatch(ReceiptsListErr(err));
    }
  };
};
const getReceiptsById = (id) => {
  return async (dispatch) => {
    try {
      const ReceiptsByIdData = await DataService.get(
        `${API.recepits.getReceiptsById}/${id}`
      );

      if (!ReceiptsByIdData.data.error) {
        return dispatch(ReceiptsId(ReceiptsByIdData.data.data));
      } else {
        return dispatch(ReceiptsIdErr(ReceiptsByIdData.data));
      }
    } catch (err) {
      dispatch(ReceiptsIdErr(err));
    }
  };
};

const cancelOrder = (formData, cancelOrder_id) => {
  return async (dispatch) => {
    try {
      const userDetails = getItem("userDetails");
      if (
        cancelOrder_id &&
        cancelOrder_id.details &&
        cancelOrder_id.details.bingagePaymnetType == "Room" &&
        cancelOrder_id.details.register_data &&
        cancelOrder_id.details.register_data.hotelDetalis &&
        !cancelOrder_id.details.poseaseRoom
      ) {
        let apiData = await axios({
          method: "POST",
          url: `https://api-controller.streetgooser.io/application/posease/v1?propertyUuid=${cancelOrder_id.details.register_data.hotelDetalis.hotelId}`,
          data: {
            invoiceNumber: cancelOrder_id.ReceiptNumber,
            cancellation: formData.cancellation,
            reservationUuid: cancelOrder_id.details.roomDetails.reservation_id,
            roomUuid: cancelOrder_id.details.roomDetails.RoomId,
            items: cancelOrder_id.details.itemsSold,
            subtotal: Number(cancelOrder_id.details.priceSummery.sub_total),
            total: Number(cancelOrder_id.details.priceSummery.total),
            taxes: Number(cancelOrder_id.details.priceSummery.totalTaxes),
          },
          headers: {
            api_key: cancelOrder_id.details.register_data.hotelDetalis.hotelKey,
          },
        });
      }
      let regId = getItem("setupCache")?.register?.find((val) => val.active)
        ?._id;
      formData.register_id = regId;
      if (formData.cancellation) {
        formData.cancellation["userName"] = userDetails?.username;
      }
      let getAddedcancelOrder = await DataService.put(
        `${API.recepits.canselorder}/${cancelOrder_id._id}`,
        formData
      );
      if (!getAddedcancelOrder.data.error) {
        return dispatch(cancelOrderAdd(getAddedcancelOrder.data.data));
      } else {
        return dispatch(cancelOrderAddErr(getAddedcancelOrder.data.data));
      }
    } catch (err) {
      dispatch(cancelOrderAddErr(err));
    }
  };
};
const deleteReceipt = (receiptIds) => {
  return async (dispatch) => {
    try {
      const getDeletedreceipt = await DataService.delete(
        `${API.recepits.deleteReceipt}/${receiptIds}`
      );
      if (!getDeletedreceipt.data.error) {
        return dispatch(receiptDelete(getDeletedreceipt.data));
      } else {
        return dispatch(receiptDeleteErr(getDeletedreceipt.data));
      }
    } catch (err) {
      dispatch(receiptDeleteErr(err));
    }
  };
};
const getDateWiseReceptsList = (start, end) => {
  return async (dispatch) => {
    dispatch(receiptLoader(true));
    let regId = getItem("setupCache").register.find((val) => val.active)._id;
    const getReceiptsList = await DataService.get(
      `${
        API.recepits.getAllReceipts
      }?page=${1}&limit=${10}&register_id=${regId}&startDate=${start}&endDate=${end}&timeZon=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    );

    if (!getReceiptsList.data.error) {
      let receiptCount =
        getReceiptsList.data.messageCode == "NO_DATA_FOUND"
          ? 0
          : getReceiptsList.data.pagination.total_counts;

      return dispatch(
        ReceiptsList(getReceiptsList.data.data, receiptCount, start, end)
      );
    } else {
      return dispatch(ReceiptsListErr(getReceiptsList.data));
    }
  };
};

const exportSales = (payloads) => {
  return async () => {
    try {
      let url;
      if (payloads.category == "sales") {
        url = API.recepits.exportSaleReport;
      } else if (payloads.category == "payment") {
        url = API.recepits.paymentReport;
      } else if (payloads.category == "daily") {
        url = API.recepits.dailySaleAndPaymnetReport;
      } else if (payloads.category == "product") {
        url = API.recepits.productWiseReport;
      } else if (payloads.category == "shift") {
        url = API.recepits.shiftsReport;
      } else if (payloads.category == "order_tiket") {
        url = API.recepits.orderTiketsReport;
      } else if (payloads.category == "room") {
        url = API.recepits.roomInvoice;
      }
      delete payloads["category"];
      let startDate;
      let endDate;

      if (payloads.dateRange == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (payloads.dateRange == "yesterday") {
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "custom") {
        startDate = payloads.startDate;
        endDate = payloads.endDate;
      }

      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      payloads["startDate"] = startDate;
      payloads["endDate"] = endDate;
      payloads["timeZone"] = timeZone;

      const resp = await DataService.post(url, payloads);

      if (resp && !resp.data.error) {
        return resp.data;
      } else {
        return resp.data;
      }
    } catch (error) {
      return false;
    }
  };
};

const handleMasterReservationDate = (start, end) => {
  return (dispatch) => {
    dispatch(masterReservationDate(start, end));
  };
};

const handleRoomReservationDate = (start, end) => {
  return (dispatch) => {
    dispatch(roomReservationDate(start, end));
  };
};

const handlePaymnetModeDate = (start, end, change) => {
  return (dispatch) => {
    dispatch(paymnetModeDate(start, end, change));
  };
};

export {
  headerGlobalSearchAction,
  getAllReceiptsList,
  getReceiptsById,
  cancelOrder,
  deleteReceipt,
  getDateWiseReceptsList,
  exportSales,
  handleMasterReservationDate,
  handleRoomReservationDate,
  handlePaymnetModeDate,
};
