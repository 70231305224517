import React, { useState } from "react";
import { Row, Col, Tabs } from "antd";
import { New } from "../Incoming/New";
import { Accepted } from "../Incoming/Accepted";
import { QRNew } from "../QrOrder/QRNew";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { TopToolBox } from "../Style";
import { useEffect } from "react";
import {
  createOnlineTakeAway,
  setItem,
  getItem,
  acceptOnlineOrderList,
} from "../../../utility/localStorageControl";
import { useSelector } from "react-redux";

const IncomingOrderBuilder = (props) => {
  const { currentRegisterData, orderTabChange, setLocalCartCount } = props;
  const { TabPane } = Tabs;
  const [activeTab, changeTab] = useState("third");
  const { onlineOrderCount, QrOrderCount } = useSelector((state) => {
    let acceptList = acceptOnlineOrderList();
    return {
      onlineOrderCount: state.kitchenUser.kitchenUserList.filter(
        (val) => acceptList.find((j) => j.onlineOrder.id == val.id) == undefined
      )?.length,
      QrOrderCount: state.kitchenUser.kitchenUserIdData.length,
    };
  });

  const [acceptOrdersCount, setAcceptOrderCount] = useState(0);
  useEffect(() => {
    setAcceptOrderCount(acceptOnlineOrderList()?.length);
  }, [acceptOnlineOrderList()?.length]);

  useEffect(() => {
    setItem("QrOrderCount", QrOrderCount);
  }, [QrOrderCount]);

  return (
    <>
      <Cards headless>
        <TopToolBox>
          <Row
            gutter={15}
            style={{
              marginBottom: "0.5% ",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col span={24}>
              <Tabs
                className="QrOrderTabs"
                defaultActiveKey="1"
                activeKey={activeTab}
                onChange={changeTab}
                size="small"
                type="card"
                centered={true}
              >
                <TabPane
                  tab={
                    <span className="drft_counno">
                      QR Orders
                      <span>{getItem("QrOrderCount")}</span>
                    </span>
                  }
                  key="third"
                  style={{ outline: "none" }}
                />
                <TabPane
                  tab={
                    <span className="drft_counno">
                      Online Orders
                      <span>{onlineOrderCount}</span>
                    </span>
                  }
                  key="one"
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                </TabPane>
                <TabPane
                  tab={
                    <span className="drft_counno">
                      Accepted Orders
                      <span>{acceptOrdersCount}</span>
                    </span>
                  }
                  key="second"
                  style={{ marginRight: "20px" }}
                />
              </Tabs>
            </Col>
          </Row>
        </TopToolBox>
        {
          activeTab == "one" ?
            <New
              currentRegisterData={currentRegisterData}
              changeTab={changeTab}
            /> :
            activeTab == "second" ?
              <Accepted
                currentRegisterData={currentRegisterData}
                changeTab={changeTab}
              /> :
              activeTab == "third" ?
                <QRNew
                  currentRegisterData={currentRegisterData}
                  changeTab={changeTab}
                  setLocalCartCount={setLocalCartCount}
                /> : ""
        }
      </Cards>
    </>
  );
};

export { IncomingOrderBuilder };
