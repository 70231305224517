import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Radio,
  Button,
  Modal,
  Form,
  Pagination,
  Popover,
  Checkbox,
  message,
  Select,
} from "antd";
import {
  SearchOutlined,
  LockTwoTone,
  DeleteOutlined,
  CaretDownOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "./Style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";
import commonFunction from "../../utility/commonFunctions";
// import { Popover } from "../../components/popup/popup";
import "./UserManagement.css";
import {
  getAllActiveUsersList,
  changeStatus,
  FilterActiveInactiveStatus,
  deleteUser,
  ExportUser,
  getAllActiveUsersApiResp,
  updateUserStatus,
  getAllActiveRegisterApiResp,
} from "../../redux/users/actionCreator";
import { AutoCompleteStyled } from "../../components/autoComplete/style";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getItem, setItem } from "../../utility/localStorageControl";
import moment from "moment";

const RegisterManagement = () => {
  const [loading, setLoading] = useState(false);
  let [search, setsearch] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [modalVisible, setModelVisible] = useState(false);
  let [modalVisibleExport, setModelVisibleExport] = useState(false);
  const [userId, setUserId] = useState(false);
  const [modalVisibleDelete, setModelVisibleDelete] = useState(false);
  const [exportType, setExportType] = useState();

  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [ActiveUsersList, setActiveUsersList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  // console.log("🚀 ~ file: UserManagement.js:42 ~ UserManagement ~ paginationData:", paginationData)
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const { startDate, endDate } = useSelector((state) => {
    return {
      startDate: state.receipts.paymnetModeStartDate,
      endDate: state.receipts.paymnetModeEndDate,
    };
  });
  const [confirmUpdateVisible, setConfirmUpdateVisible] = useState(false);
  const [selectionType] = useState("checkbox");
  const [checkedVal, setCheckedVal] = useState("");
  const [checked, setChecked] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [filterStatus, setFilterStatus] = useState(undefined);
  const [subscriptionStatus, setScribtionStatus] = useState(undefined);
  const [countStatus, setCountStatus] = useState(undefined);
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  useEffect(() => {
    fetchDataListResp(currentPage, dataPerPage, filterStatus, countStatus);
  }, [filterStatus, subscriptionStatus, startDate, endDate, countStatus]);

  const fetchDataListResp = async (page, pageNumber) => {
    let params = {
      page: page,
      limit: pageNumber,
      filterStatus: filterStatus,
      status: subscriptionStatus,
      startDate: startDate,
      endDate: endDate,
      countStatus: countStatus,
    };
    const dataList = await dispatch(getAllActiveRegisterApiResp(params));
    console.log("dataListdasdasdasdad", dataList);
    if (dataList?.data) {
      setActiveUsersList(dataList?.data?.data);
      setPaginationData(dataList?.data?.pagination);
    }
  };

  const handlePagination = (page, pageNumber) => {
    // console.log("🚀 ~ file: UserManagement.js:72 ~ handlePagination ~ page:", page)
    fetchDataListResp(page, pageNumber);
    setCurrentPage(page);
    setDataPerPage(pageNumber);
  };

  //change status
  let isDeActive = async () => {
    let changedata = {
      user_id: userId,
      status: "deactivated",
    };
    await dispatch(changeStatus(changedata));
    setModelVisible(false);
    fetchDataListResp(currentPage, dataPerPage);
    // dispatch(getAllActiveUsersList());
  };

  const showModal = (id) => {
    if (id && id !== "") {
      setModelVisible(true);
      setUserId(id);
    }
  };

  const submitExport = (values) => {
    values.type = exportType;
    if (exportType) {
      dispatch(ExportUser(values));
      setModelVisibleExport(false);
      form.resetFields();
    }
  };
  const DeleteUser = async () => {
    if (userId) {
      let allUserId = [];
      allUserId.push(userId);
      const getDeleteUser = await dispatch(deleteUser({ ids: allUserId }));
      fetchDataListResp(currentPage, dataPerPage);
      // dispatch(getAllActiveUsersList());
      setModelVisibleDelete(false);
    }
  };

  const handleChangeForFilter = (e) => {
    setFilterStatus(e.target.value);
    fetchDataListResp(currentPage, dataPerPage, e.target.value);
  };

  const showModalDelete = (id) => {
    if (id && id !== "") {
      setModelVisibleDelete(true);
      setUserId(id);
    }
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisibleExport(true);
          setExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisibleExport(true);
          setExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisibleExport(true);
          setExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  const dataSource = [];

  const onChangeFilter = (e) => {
    setChecked(e.target.checked);
    e.target.checked == true
      ? setCheckedVal(e.target.value)
      : setCheckedVal("");
  };

  const handleCancel = (e) => {
    setLoading(false);
    setConfirmUpdateVisible(false);
    setChecked(false);
    setCheckedVal("");
  };
  const [state, setState] = useState({
    item: ActiveUsersList,
  });
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForStatus: selectedRows,
      });
    },
  };

  const updateStatusFilter = async (val) => {
    setLoading(true);
    const { allSelectedRowsForStatus } = state;
    let allUsersForFilter = [];
    allSelectedRowsForStatus.map((item) => {
      allUsersForFilter.push(item.id);
    });

    const payload = {
      ids: allUsersForFilter,
      filterStatus: val,
    };

    if (
      (allUsersForFilter.length > 0 && val !== null && val !== "",
      val !== undefined)
    ) {
      const updateStatus = await dispatch(updateUserStatus(payload));
      if (updateStatus) {
        setLoading(false);
        setConfirmUpdateVisible(false);
        setChecked(false);
        setCheckedVal("");
        setState({
          ...state,
          allSelectedRowsForStatus: [],
        });
      }
    }
  };

  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const ResetFilter = () => {
    setOpenPopover(false);
    setChecked(false);
    setCheckedVal("");
    setState({
      ...state,
      allSelectedRowsForStatus: [],
    });
  };
  const handleCopyClick = async (id) => {
    try {
      await navigator.clipboard.writeText(
        `https://web.posease.com/app/user/login/${id}`
      );

      message.success("Text copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy text");
    }
  };
  const columns = [
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render(text, record) {
        return <>{commonFunction.convertToDate(text, "MMM DD, Y, h:mm A")}</>;
      },
    },
    {
      title: "User Email",
      dataIndex: "user_id",
      key: "user_id",
      render(text, record) {
        return <>{text?.email}</>;
      },
    },
    {
      title: "Shop",
      dataIndex: "shop_name",
      key: "shop_name",
      render(text, record) {
        return <>{text}</>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "user_id",
      key: "user_id",
      render(text, record) {
        return <>{text?.number}</>;
      },
    },

    {
      title: "Register name",
      dataIndex: "register_name",
      key: "register_name",
    },
    {
      title: "Subscription",
      dataIndex: "subscription_status",
      key: "subscription_status",
    },
    {
      title: "Business Type",
      dataIndex: "type",
      key: "type",
      render(text, record) {
        return (
          <>
            {text == "hotel"
              ? "Hotel"
              : text == "restaurant"
              ? "Restaurant"
              : text}
          </>
        );
      },
    },
    {
      title: "Receipts",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "PaymentType",
      dataIndex: "paymentType",
      key: "PaymentType",
      render(text, record) {
        return <>{record?.subscription_status == ""}</>;
      },
    },
    {
      title: "Table Numbers",
      dataIndex: "table_numbers",
      key: "table_numbers",
    },
  ];
  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            className="admin-custom-pageheader"
            ghost
            title="Registers Management"
            subTitle={
              <>
                <span className="title-counter">
                  {paginationData?.total} Registers
                </span>

                <Input
                  suffix={<SearchOutlined />}
                  autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                  onChange={(e) => setsearch(e.target.value)}
                  value={search}
                  placeholder="Search by mobile number"
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                    marginLeft: "10px",
                  }}
                />
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions">
                <CalendarButtonPageHeader key="7" type="payment-mode" />
                <Popover
                  placement="bottomLeft"
                  content={content}
                  trigger="click"
                >
                  <Button size="small" type="white">
                    <FeatherIcon icon="download" size={14} />
                    Export
                  </Button>
                </Popover>
              </div>,
            ]}
          />
        </CardToolbox>
        <Cards headless>
          <Row gutter={15}>
            <Col md={8} sm={8} lg={8} xxl={8} xs={24}>
              Business Type
              <Select
                style={{ width: "100%", marginBottom: 10 }}
                placeholder="Business Type"
                allowClear={true}
                onChange={(value) => setFilterStatus(value)}
              >
                <Option value="restaurant">Restaurant</Option>
                <Option value="hotel">Hotel</Option>
              </Select>
            </Col>
            <Col md={8} sm={8} lg={8} xxl={8} xs={24}>
              Subscription
              <Select
                style={{ width: "100%", marginBottom: 10 }}
                placeholder="Status"
                allowClear={true}
                onChange={(value) => setScribtionStatus(value)}
              >
                <Option value="subscribed">Subscribed</Option>
                <Option value="unsubscribed">Unsubscribed</Option>
              </Select>
            </Col>
            <Col md={8} sm={8} lg={8} xxl={8} xs={24}>
              Receipts
              <Select
                style={{ width: "100%", marginBottom: 10 }}
                placeholder="Status"
                allowClear={true}
                onChange={(value) => setCountStatus(value)}
              >
                <Option value="asc">Ascending</Option>
                <Option value="des">Descending</Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={ActiveUsersList}
                  columns={columns}
                  fixed={true}
                  scroll={{ x: 800 }}
                  pagination={false}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                />
                <Pagination
                  className="userManagement_Pagination"
                  total={paginationData?.total}
                  current={currentPage}
                  onChange={handlePagination}
                  pageSizeOptions={[10, 20, 50, 100]}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  pageSize={dataPerPage}
                  showSizeChanger
                />
              </TableWrapper>
            </Col>
          </Row>
          <Modal
            title="Confirm Delete"
            okText="Delete Entry"
            open={modalVisibleDelete}
            onOk={DeleteUser}
            onCancel={() => setModelVisibleDelete(false)}
            width={600}
          >
            <p>
              Deleting the entry will permanently remove it and will no longer
              appear on reports. Are you sure you want to proceed?
            </p>
          </Modal>
          <Modal
            title="Confirm Deactivate"
            okText="Deactivate User"
            open={modalVisible}
            onOk={isDeActive}
            onCancel={() => setModelVisible(false)}
            width={600}
          >
            <p>Are you sure you want to Deactivate this user?</p>
          </Modal>
          <Modal
            title="Export Users"
            open={modalVisibleExport}
            onOk={form.submit}
            onCancel={() => setModelVisibleExport(false)}
            width={600}
          >
            <div>
              <Form
                style={{ width: "100%" }}
                name="export"
                form={form}
                onFinish={submitExport}
              >
                <div className="add-product-block">
                  <div className="add-product-content">
                    <Form.Item
                      label="Send to Email Address"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email",
                          type: "email",
                        },
                      ]}
                    >
                      <Input placeholder="Enter email" />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            title="Update Status"
            okText={
              loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 16,
                        color: "white",
                        margin: "0px 23px",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                "Confirm"
              )
            }
            open={confirmUpdateVisible}
            onOk={() => updateStatusFilter(checkedVal)}
            onCancel={handleCancel}
            width={600}
          >
            <p>Are you sure you want to Update Status of this Users ?</p>
          </Modal>
        </Cards>
      </Main>
    </>
  );
};

export default RegisterManagement;
