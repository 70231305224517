import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  DatePicker,
  Radio,
  Select,
} from "antd";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import Heading from "../../components/heading/heading";
import "../VendorItems/option.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  addOrUpdateExpenseCategory,
  getExpenseCategoryList,
  addOrUpdateExpense,
  getVendorList,
} from "../../redux/vendor/actionCreator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { getItem } from "../../utility/localStorageControl";
import "./expenses.css";
const AddExpense = () => {
  const [PaymentType, setPaymentType] = useState("cash");
  const [PaymentTypeList, setPaymentTypeList] = useState(
    getItem("setupCache")?.customFields?.paymnetType?.length > 0
      ? getItem("setupCache").customFields.paymnetType
      : []
  );

  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [expenseId, setExpenseId] = useState();
  const [expenseList, setExpensesList] = useState([]);
  const [DateString, setDateString] = useState(
    location?.state?.billDetails
      ? moment(location?.state?.billDetails?.invoice_date).format("LL")
      : moment().format("LL")
  );
  const [vendorList, setVendorList] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const getExpenseCategory = async () => {
    setFetchLoader(true);
    let response = await dispatch(getExpenseCategoryList());
    let response1 = await dispatch(getVendorList());
    if (response && !response.error) {
      setExpensesList(response.data);
      setVendorList(response1.data);
      setFetchLoader(false);
    } else {
      // message.error(response.message);
    }
  };
  useEffect(() => {
    if (location && location.state?.expenseDetails) {
      setExpenseId(location.state?.expenseDetails?._id);
      form.setFieldsValue(location.state?.expenseDetails);
    } else {
      setExpenseId();
    }
    getExpenseCategory();
  }, []);

  const handleSubmit = async (value) => {
    if (loading == false) {
      value.invoice_date = new Date(DateString);
      setLoading(true);
      let response = await dispatch(addOrUpdateExpense(value, expenseId));
      if (response && !response.error) {
        setLoading(false);
        history.push("/vendor/expenses");
      } else if (response && response.messageCode !== "ALREADY_EXIST") {
        setLoading(false);
        message.error(response.message);
      } else {
        setLoading(false);
      }
    }
  };

  let tickImg = (
    <svg
      width="13px"
      style={{ marginRight: "2px" }}
      viewBox="0 0 123 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
        fill="#BE3D5D"
      />
    </svg>
  );
  return (
    <>
      <Main>
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Expense Details</Heading>
              <span>Add your expense details here</span>
            </div>
          }
        >
          <Row gutter={25} justify="center">
            <Col xxl={12} md={14} sm={18} xs={24}>
              <div className="formDiv">
                <div className="mainFormdiv">
                  <Form
                    form={form}
                    size="large"
                    name="Expense"
                    onFinish={handleSubmit}
                  >
                    <Row gutter={10}>
                      <Col xxl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="invoice_number"
                          label="Reference #"
                          rules={[
                            {
                              max: 50,
                              message:
                                "Invoice No. cannot be more than 50 characters long.",
                            },
                          ]}
                        >
                          <Input
                            style={{ marginBottom: 10 }}
                            placeholder="Reference No."
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          name="invoice_date"
                          label="Invoice Date"
                          initialValue={moment(DateString, "LL")}
                          rules={[
                            {
                              message: "Please enter invoice Date",
                              required: true,
                            },
                          ]}
                        >
                          <DatePicker
                            className="book_picker"
                            style={{ width: "100%", minHeight: "47px" }}
                            size="large"
                            onChange={(date, string) => {
                              setDateString(string);
                            }}
                            format="LL"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      name="expense_category"
                      label="Expense Category"
                      className="customSelectHeight"
                      rules={[
                        {
                          message: "Please select category",
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        className="select-height"
                        placeholder="Select category"
                        showSearch
                        style={{ marginBottom: 10 }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {expenseList.map((val) => {
                          return (
                            <Select.Option value={val._id}>
                              {val.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="vendor_id"
                      label="Vendor"
                      className="customSelectHeight"
                    >
                      <Select
                        placeholder="Select vendor"
                        showSearch
                        style={{ marginBottom: 10 }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {vendorList.map((val) => {
                          return (
                            <Select.Option value={val._id}>
                              {val.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="total"
                      label="Expense Amount"
                      rules={[
                        {
                          message: "Please enter amount",
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        style={{ marginBottom: 10 }}
                        placeholder="Expense Amount"
                        type="number"
                        onKeyPress={(event) => {
                          if (event.key.match("[0-9.]")) {
                            return true;
                          } else {
                            return event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="payment_type"
                      label="Payment type"
                      initialValue={PaymentType}
                      rules={[
                        {
                          message: "Please select payment type",
                          required: true,
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={(e) => setPaymentType(e.target.value)}
                        className="tick-radio"
                      >
                        <Radio.Button
                          value="cash"
                          style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {PaymentType === "cash" ? tickImg : ""} Cash
                        </Radio.Button>
                        <Radio.Button
                          value="card"
                          style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {PaymentType === "card" ? tickImg : ""} Credit / Debit
                          Card
                        </Radio.Button>
                        <Radio.Button
                          value="other"
                          style={{
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {PaymentType === "other" ? tickImg : ""} Other
                        </Radio.Button>
                        {PaymentTypeList.map((val, index) => {
                          return (
                            <Radio.Button
                              value={val.name}
                              style={{
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {PaymentType === val.name ? tickImg : ""}
                              {val.name}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item name="notes" label="Notes">
                      <TextArea
                        style={{ marginBottom: 20 }}
                        placeholder="Notes (optional)"
                      />
                    </Form.Item>
                    <Form.Item style={{ float: "right" }}>
                      <NavLink to="/vendor/expenses">
                        <Button size="medium" style={{ marginRight: 10 }}>
                          Go Back
                        </Button>
                      </NavLink>
                      <Button
                        type="primary"
                        info
                        htmlType="submit"
                        size="medium"
                      >
                        {loading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 16,
                                  color: "white",
                                  margin: "0px 8px",
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default AddExpense;
