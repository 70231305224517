import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Input,
  Form,
  Card,
  Button,
  Modal,
  Tooltip,
} from "antd";
import { Main } from "../styled";
import { OrderBuilder } from "./Orders/OrderBuilder";
import { DraftBuilder } from "./Orders/DraftBuilder";
import { IncomingOrderBuilder } from "./Incoming/IncomingOrderBuilder";
import { QrOrderBuilder } from "./QrOrder/QrOrderBuilder";
import CurrentBuilder from "./Current/CurrentBuilder";
import { TopToolBox, PricingCard, ListGroup, Badge } from "./Style";
import { SearchOutlined, CloseCircleFilled } from "@ant-design/icons";
import { BookingList } from "./Booking/BookingList";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCartwithKeyandPush,
  getLocalCartCount,
  getCartInfoFromLocalKey,
  getItem,
  setItem,
  setCartInfoFromLocalKey,
  getCartInfoLocalListsData,
  getIndexdbProductList,
} from "../../utility/localStorageControl";
import {
  getLastDevice,
  getLastReceipt,
  createCheckout,
  saveCurrentDevice,
} from "../../redux/sell/actionCreator";
import { InfoCircleFilled } from "@ant-design/icons";
import sellmsg from "../../static/img/sell/sellmsg.svg";
import Lock from "./Lock";
import {
  generate_random_number,
  generate_random_string,
} from "../../utility/utility";
import "./sell.css";
import {
  CreateOrder,
  AddAndUpdateBooking,
} from "../../redux/sell/actionCreator";
import Heading from "./heading/heading";
import { List } from "./subscriptionCardList/List";
import { PageHeader } from "../../components/page-headers/page-headers";
import Billing from "../Billing/Billing";
const SellBuilder = () => {
  const {
    currentRegisterData,
    offLineMode,
    newOrderCount,
    QrOrderCount,
  } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
      offLineMode: state.auth.offlineMode,
      newOrderCount: state.kitchenUser.kitchenUserList.length,
      QrOrderCount: state.kitchenUser.kitchenUserIdData.length,
    };
  });

  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );

  const { TabPane } = Tabs;
  const [tableStatus, settableStatus] = useState("");
  const [loadAysn, setloadAsyn] = useState(false);
  let [activeTab, changeTab] = useState(
    currentRegisterData?.table_numbers == "" ? "CURRENT" : "ORDER"
  );
  const [searchItems, setSeacrhItems] = useState("");
  const [searchtables, setSearhTables] = useState("");
  const [orderCartData, setOrderCartData] = useState({});
  const [localCartInfo, setlocalCartInfo] = useState({});
  const [LocalCartCount, setLocalCartCount] = useState(0);
  const [cusrrentTabDisbled, setCurrentTabDisbled] = useState();
  const [tableIsCustome, setTableIsCustome] = useState(false);
  const [swapTableNameList, setSwapTableNameList] = useState([]);
  const [customeTableList, setCustomeTableList] = useState([]);
  const [tableName, setTableName] = useState();
  const [lastReceiptData, setLastReceiptData] = useState({});
  const [lastDeviceData, setLastDeviceData] = useState({});
  const [deviceName, setDeviceName] = useState();

  let localData = getItem("setupCache");
  const dispatch = useDispatch();
  const didMount = useRef(false);
  let [productListOfdata, setProductListOfdata] = useState([]);
  const [isRregister, setIsRregister] = useState(false);
  let [registerWiseCategoryList, setFilterCategoryList] = useState([]);
  const [startSellingButton, setStartSellingButton] = useState(
    "Start Selling here"
  );
  const [draftCount, setDarftCount] = useState(0);

  let suffix =
    searchItems != "" ? (
      <CloseCircleFilled onClick={() => setSeacrhItems("")} />
    ) : (
      <SearchOutlined />
    );
  let suffixTables =
    searchtables != "" ? (
      <CloseCircleFilled onClick={() => setSearhTables("")} />
    ) : (
      <SearchOutlined />
    );

  const categoryFilter = (val, currentRegisterData) => {
    if (val?.limit_to_register.length > 0 && currentRegisterData) {
      if (val.limit_to_register.includes(currentRegisterData?._id)) {
        let checkFind = registerWiseCategoryList.findIndex(
          (j) => j._id == val.product_category._id
        );

        if (checkFind == -1) {
          registerWiseCategoryList.push({
            category_name: val.product_category.category_name,
            sort_order: val.product_category.sort_order,
            _id: val.product_category._id,
          });
        }
      }
    } else {
      let checkFind = registerWiseCategoryList.findIndex(
        (j) => j._id == val.product_category._id
      );
      if (checkFind == -1) {
        registerWiseCategoryList.push({
          category_name: val.product_category.category_name,
          sort_order: val.product_category.sort_order,
          _id: val.product_category._id,
        });
      }
    }
    localData.productCategory = registerWiseCategoryList;
  };
  const regiterWiseFilterList = (val) => {
    categoryFilter(val, currentRegisterData);
    if (val?.limit_to_register.length > 0 && currentRegisterData) {
      return val.limit_to_register.includes(currentRegisterData?._id)
        ? val
        : "";
    } else {
      return val;
    }
  };

  const getProductList = async () => {
    let list = await getIndexdbProductList();
    let productList = list?.filter(regiterWiseFilterList);
    setProductListOfdata(productList);
  };
  useEffect(() => {
    getProductList();
  }, [currentRegisterData]);

  const showModal = () => {
    setStartSellingButton("Loading..");
    async function fetchLastReceipt() {
      const lastReceipt = await dispatch(getLastReceipt());
      if (lastReceipt) {
        setStartSellingButton("Start Selling here");
        setLastReceiptData(lastReceipt.receiptData);
        setIsRregister(true);
      }
    }
    async function fetchLastDevice() {
      const lastDevice = await dispatch(getLastDevice());
      if (lastDevice) {
        setLastDeviceData(lastDevice.deviceData);
      }
    }
    fetchLastReceipt();
  };

  useEffect(() => {
    let registerData = localData?.register.find((val) => val.active);
    if (getItem("active_cart") && registerData) {
      let localCartInFoData = getCartInfoFromLocalKey(
        getItem("active_cart"),
        registerData
      );
      if (localCartInFoData) {
        // setlocalCartInfo(localCartInFoData);
        // setTableName(localCartInFoData?.tableName);
        // changeTab("CURRENT");
      }
    } else if (registerData) {
      return registerData?.table_numbers == "" ||
        getItem("active_cart")?.table_numbers == "" ||
        getItem("active_cart")
        ? changeTab("CURRENT")
        : changeTab("ORDER");
    }
  }, [currentRegisterData]);

  const escKeyDown = (event, current) => {
    if (event.key == "Escape") {
      setSeacrhItems("");
      setSearhTables("");
      return;
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", escKeyDown);
    return () => {
      window.removeEventListener("keydown", escKeyDown);
    };
  }, []);

  useEffect(() => {
    setLocalCartCount(getLocalCartCount(currentRegisterData));
  }, []);

  const tabChangeToCurrentFunction = (tab, BookingDetils) => {
    changeTab(tab);
  };

  function searchNull(value) {
    setSeacrhItems(value);
  }

  const createNewTakeawayInLocalAndNavigateFunction = (
    type,
    navigateTo,
    data,
    SwapList,
    customTablesInfo,
    splitName,
    splitIndex
  ) => {
    changeTab(navigateTo);
    type == "custom-table-local"
      ? setTableIsCustome(true)
      : setTableIsCustome(false);
    setSwapTableNameList(SwapList);
    setCustomeTableList(customTablesInfo);
    console.log("asdahdadahdajdagdjagdajas", data);
    let localCartInFoData = createNewCartwithKeyandPush(
      type,
      data,
      currentRegisterData,
      {},
      splitName,
      splitIndex
    );
    if (localCartInFoData) {
      let localTableData = getCartInfoLocalListsData(currentRegisterData);

      setSeacrhItems("");
      setlocalCartInfo(localCartInFoData);
      setTableName(data.tableName);
      setLocalCartCount(getLocalCartCount(currentRegisterData));
    }
  };

  const getTakeawayInLocalAndNavigateFunction = (type, navigateTo, key) => {
    let localCartInFoData = getCartInfoFromLocalKey(key, currentRegisterData);
    setlocalCartInfo(localCartInFoData);
    setTableName(localCartInFoData.tableName);
    changeTab(navigateTo);
  };

  const editCartProductDetailsFunction = (data) => {
    let returnObj = setCartInfoFromLocalKey(
      data.cartKey,
      data.data,
      "darftupdate"
    );
    let localCartInFoData = returnObj.default_cart_object;
    localCartInFoData["update"] = true;
    setlocalCartInfo(localCartInFoData);

    setTableName(localCartInFoData.tableName);
    changeTab("CURRENT");
  };

  const setCustomerAndCartDataFunction = (data) => {
    setOrderCartData(data);
  };

  const updateCartCountFunction = () => {
    setLocalCartCount(getLocalCartCount(currentRegisterData));
  };

  const chargePageIsShow = (value) => {
    setChargePageIs(value);
  };

  let registerData = localData?.register?.find((val) => val.active);
  let receiptModalTitle =
    registerData?.register_name !== null
      ? "Activate " + registerData?.register_name + " in this device"
      : "";

  let filterProductList = (product) => {
    return (
      product.product_name.toLowerCase().includes(searchItems.toLowerCase()) ||
      (product.product_code !== undefined &&
        product.product_code.toLowerCase().includes(searchItems.toLowerCase()))
    );
  };
  const handleLastDeviceSubmit = () => {
    const objData = {
      device_name: deviceName,
      last_receipt_number: lastReceiptData.receipt_number,
      register_id: registerData.register_id,
    };
    dispatch(saveCurrentDevice(objData));
    setItem("isStartSellingFromThisDevice", false);
    setIsRregister(false);

    if (lastReceiptData && lastReceiptData.receipt_number !== null) {
      let receiptSlashPartRemove = lastReceiptData.receipt_number.split("/");
      let receiptpart = receiptSlashPartRemove[1].split("-");
      if (getItem(`Bill-${receiptpart[0].trim()}`) !== null) {
        setItem(`Bill-${receiptpart[0].trim()}`, {
          receipt: `${receiptpart[0].trim() +
            "-" +
            receiptpart[1].trim()}-${receiptpart[2].trim()}`,
          sn: Number(receiptpart[3].trim()),
        });
      } else {
        setItem(`Bill-${receiptpart[0].trim()}`, {
          receipt: `${receiptpart[0].trim() +
            "-" +
            receiptpart[1].trim()}-${receiptpart[2].trim()}`,
          sn: Number(receiptpart[3].trim()),
        });
      }
    } else {
      setItem(`Bill-${registerData.receipt_number_prefix}`, {
        receipt: `${
          registerData.receipt_number_prefix
        }-${generate_random_string(3)}-${generate_random_number(4)}`,
        sn: 1,
      });
    }
  };

  let tabCalCalCulation = 2;
  getItem("dyno_api_enable")
    ? (tabCalCalCulation += 1)
    : getItem("create_receipt_while_fullfilling_booking")
    ? (tabCalCalCulation += 1)
    : "";

  const handleAsyncReceipts = async () => {
    if (loadAysn == false) {
      setloadAsyn(true);
      let pendingReceiptsList = getItem("pendingReceipts")?.reverse();
      if (
        pendingReceiptsList != null &&
        pendingReceiptsList &&
        pendingReceiptsList.length > 0
      ) {
        let pendinglist = [];
        let totalCount = pendingReceiptsList.length;
        let pending = [];
        pendingReceiptsList.map(async (val, index) => {
          if (val.draftList) {
            let data = await dispatch(AddAndUpdateBooking(val));
            if (data) {
              pending.push(index);
              pendingReceiptsList.shift();
              pendinglist = pendingReceiptsList;
            } else {
              pendinglist = pendingReceiptsList;
            }
          } else {
            let data = await dispatch(CreateOrder(val));
            if (data) {
              pending.push(index);
              pendingReceiptsList.shift();
              pendinglist = pendingReceiptsList;
            } else {
              pendinglist = pendingReceiptsList;
            }
          }
        });
        setloadAsyn(false);
        setItem("pendingReceipts", pendinglist);
      }
    }
  };

  useEffect(() => {
    didMount.current && !offLineMode && getItem("pendingReceipts")?.length
      ? handleAsyncReceipts()
      : (didMount.current = true);
  }, [getItem("pendingReceipts")?.length]);

  let showbilling = true;
  if (
    registerData &&
    registerData.is_main == false &&
    (registerData.subscription_status == undefined ||
      registerData.subscription_status == "unsubscribed")
  ) {
    showbilling = true;
  } else if (
    registerData &&
    registerData.is_main == true &&
    registerData.expires_at_date &&
    (registerData.subscription_status == undefined ||
      registerData.subscription_status == "unsubscribed")
  ) {
    showbilling = true;
  } else {
    if (
      registerData &&
      registerData.lastNumber &&
      registerData.lastNumber >= 250
    ) {
      showbilling = true;
    } else {
      showbilling = false;
    }
    // showbilling = false;
    // console.log("Sagardasdasdasdddaddadadadad", registerData?.lastNumber);
  }

  return (
    <>
      {registerData ? (
        showbilling ? (
          <Billing currentRegisterData={registerData} />
        ) : (
          <div>
            {currentRegisterData && (
              <Main className="sellscroll">
                {getItem("enable_billing_only_when_shift_is_opened") != null &&
                getItem("enable_billing_only_when_shift_is_opened") == true &&
                getItem("shfitOpenedTS") != null &&
                getItem("shfitOpenedTS") == "close" ? (
                  <Lock />
                ) : getItem("localReceipt") != null &&
                  getItem("localReceipt") === true &&
                  getItem("isStartSellingFromThisDevice") === true ? (
                  <Card>
                    <div className="start_selling">
                      <img src={sellmsg} alt="" width={200} />
                      <h3>Start selling from this device?</h3>
                      <p>
                        To start selling here, you have to <span>sign out</span>{" "}
                        of other devices that use{" "}
                        {currentRegisterData.register_name}
                        <br></br> and ensure that there are{" "}
                        <span>no receipts pending sync.</span>
                      </p>
                      <Button
                        size="small"
                        className="btn-custom"
                        type="primary"
                        onClick={showModal}
                      >
                        {startSellingButton}
                      </Button>
                      <em>
                        You are seeing this because you have enforced sequential
                        local receipt numbers in your preferences setup.
                      </em>
                      <Modal
                        title={receiptModalTitle}
                        visible={isRregister}
                        onOk={handleLastDeviceSubmit}
                        onCancel={() => setIsRregister(false)}
                      >
                        <Form.Item
                          name="sort_order"
                          label={
                            <span>
                              Device Name&nbsp;&nbsp;
                              <Tooltip
                                title="Give a name like My Shop Desktop will help you to recollect this device when you switch device later."
                                color="#FFFF"
                              >
                                <InfoCircleFilled
                                  style={{ color: "#AD005A" }}
                                />
                              </Tooltip>
                            </span>
                          }
                        >
                          <Input
                            type="txt"
                            style={{ width: "100%" }}
                            onBlur={(e) => setDeviceName(e.target.value)}
                          />
                        </Form.Item>
                        <div className="previous_pop">
                          {lastDeviceData &&
                          lastDeviceData.device_name !== null ? (
                            <p>
                              Your previous device was{" "}
                              {lastDeviceData.device_name}.
                            </p>
                          ) : lastReceiptData &&
                            lastReceiptData.receipt_number ? (
                            <p>
                              Your previous local receipt number was{" "}
                              {lastReceiptData.receipt_number}.{" "}
                              <Tooltip
                                title="Contact support in case you want to reset your local receipt number."
                                color="#FFFF"
                              >
                                <InfoCircleFilled
                                  style={{ color: "#AD005A" }}
                                />
                              </Tooltip>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Modal>
                    </div>
                  </Card>
                ) : (
                  <>
                    <Row>
                      <Col xxl={10} lg={10} xl={10} xs={24} sm={24}>
                        <Tabs
                          className="sell-tabs"
                          type="card"
                          activeKey={activeTab}
                          size="small"
                          onChange={(val) => {
                            changeTab(val);
                            setSeacrhItems("");
                          }}
                        >
                          {getItem("dyno_api_enable") ? (
                            <TabPane
                              tab={
                                <span className="drft_counno">
                                  Incoming
                                  <span>{newOrderCount + QrOrderCount}</span>
                                </span>
                              }
                              key="INCOMING"
                              style={{ outline: "none" }}
                            />
                          ) : (
                            ""
                          )}

                          {currentRegisterData?.table_numbers != "" ? (
                            <TabPane
                              tab={
                                <span className="drft_counno">
                                  Orders
                                  <span>{LocalCartCount}</span>
                                </span>
                              }
                              key="ORDER"
                              style={{ outline: "none" }}
                            />
                          ) : (
                            ""
                          )}
                          <TabPane
                            tab="Current"
                            key="CURRENT"
                            disabled={
                              getItem("active_cart") == null &&
                              currentRegisterData?.table_numbers != ""
                                ? true
                                : false
                            }
                            style={{ outline: "none" }}
                          />
                          {currentRegisterData?.table_numbers == "" ? (
                            <TabPane
                              tab={
                                <span className="drft_counno">
                                  Drafts{" "}
                                  <span>{draftCount ? draftCount : 0}</span>
                                </span>
                              }
                              key="DRAFTS"
                              style={{ outline: "none" }}
                            ></TabPane>
                          ) : getItem(
                              "create_receipt_while_fullfilling_booking"
                            ) &&
                            (getItem("enable_quick_billing") == false ||
                              getItem("enable_quick_billing") == null) ? (
                            <TabPane
                              tab="Bookings"
                              key="BOOKING"
                              style={{ outline: "none" }}
                            />
                          ) : (
                            ""
                          )}
                        </Tabs>
                      </Col>
                      <Col
                        style={{ paddingTop: "5px" }}
                        className="offlineMod-line"
                        xxl={14}
                        lg={14}
                        xl={14}
                        xs={24}
                        sm={24}
                      >
                        {newOrderCount > 0 &&
                          `${newOrderCount}  New Incoming Order`}
                        <span
                          onClick={() => handleAsyncReceipts()}
                          style={{ cursor: "pointer" }}
                        >
                          {getItem("pendingReceipts") != null &&
                          getItem("pendingReceipts").length > 0
                            ? `You are offline, but can continue billing. ${
                                getItem("pendingReceipts").length
                              } receipts to async`
                            : offLineMode &&
                              "You are offline, but can continue billing"}
                        </span>
                      </Col>
                    </Row>

                    <Row gutter={25}>
                      <Col span={24}>
                        {activeTab === "ORDER" ? (
                          <OrderBuilder
                            search={searchtables}
                            setSearhTables={setSearhTables}
                            suffixTables={suffixTables}
                            tabChangeToCurrent={tabChangeToCurrentFunction}
                            createNewTakeawayInLocalAndNavigate={
                              createNewTakeawayInLocalAndNavigateFunction
                            }
                            getTakeawayInLocalAndNavigate={
                              getTakeawayInLocalAndNavigateFunction
                            }
                            setCurrentTabDisbled={setCurrentTabDisbled}
                            localCartInfo={localCartInfo}
                            currentRegisterData={currentRegisterData}
                          />
                        ) : activeTab === "QRORDER" ? (
                          <QrOrderBuilder
                            currentRegisterData={currentRegisterData}
                            orderTabChange={changeTab}
                          />
                        ) : activeTab === "INCOMING" ? (
                          <IncomingOrderBuilder
                            currentRegisterData={currentRegisterData}
                            orderTabChange={changeTab}
                            setLocalCartCount={setLocalCartCount}
                          />
                        ) : activeTab === "DRAFTS" ? (
                          <DraftBuilder
                            setLocalCartCount={setLocalCartCount}
                            tabChangeToCurrent={tabChangeToCurrentFunction}
                            createNewTakeawayInLocalAndNavigate={
                              createNewTakeawayInLocalAndNavigateFunction
                            }
                            getTakeawayInLocalAndNavigate={
                              getTakeawayInLocalAndNavigateFunction
                            }
                            editCartProductDetails={
                              editCartProductDetailsFunction
                            }
                            currentRegisterData={currentRegisterData}
                            setlocalCartInfo={setlocalCartInfo}
                            setTableName={setTableName}
                          />
                        ) : activeTab === "BOOKING" ? (
                          <BookingList
                            tabChangeToCurrent={tabChangeToCurrentFunction}
                            setlocalCartInfo={setlocalCartInfo}
                          />
                        ) : activeTab === "CURRENT" ? (
                          <CurrentBuilder
                            search={searchItems}
                            suffix1={suffix}
                            setSeacrhItems1={setSeacrhItems}
                            nullSearch={searchNull}
                            tabChangeToCurrent={tabChangeToCurrentFunction}
                            setCustomerAndCartData={
                              setCustomerAndCartDataFunction
                            }
                            localCartInfo={localCartInfo}
                            tableName={tableName}
                            updateCartCount={updateCartCountFunction}
                            tableIsCustome={tableIsCustome}
                            swapTableNameList={swapTableNameList}
                            setlocalCartInfo={setlocalCartInfo}
                            setTableName={setTableName}
                            customeTableList={customeTableList}
                            chargePageIsShow={chargePageIsShow}
                            registerData={currentRegisterData}
                            setDarftCount={setDarftCount}
                            productListOfdata={productListOfdata?.filter(
                              filterProductList
                            )}
                            allLocalData={{
                              ...localData,
                              productCategory: registerWiseCategoryList,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Main>
            )}
          </div>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default SellBuilder;
