const actions = {
  ROOM_ADD: "ROOM_ADD",
  ROOM_ADD_ERR: "ROOM_ADD_ERR",
  ROOM_LIST: "ROOM_LIST",
  ROOM_LIST_ERR: "ROOM_LIST_ERR",
  ROOM_ID: "ROOM_ID",
  ROOM_ID_ERR: "ROOM_ID_ERR",
  ROOM_DELETED: "ROOM_DELETED",
  ROOM_DELETED_ERR: "ROOM_DELETED_ERR",

  roomAdd: (roomData) => {
    return {
      type: actions.ROOM_ADD,
      roomData,
    };
  },

  roomAddErr: (err) => {
    return {
      type: actions.ROOM_ADD_ERR,
      err,
    };
  },
  roomList: (roomList) => {
    return {
      type: actions.ROOM_LIST,
      error: false,
      roomList,
    };
  },
  roomListErr: (err) => {
    return {
      type: actions.ROOM_LIST_ERR,
      error: true,
      err,
    };
  },
  roomId: (roomIdData) => {
    return {
      type: actions.ROOM_ID,
      roomIdData,
    };
  },
  roomIdErr: (err) => {
    return {
      type: actions.ROOM_ID_ERR,
      err,
    };
  },
  roomDelete: (roomDeletedData) => {
    return {
      type: actions.ROOM_DELETED,
      roomDeletedData,
    };
  },
  roomDeleteErr: (err) => {
    return {
      type: actions.ROOM_DELETED_ERR,
      err,
    };
  },
};

export default actions;
