import React from "react";
import { Main } from "../styled";


const Analytics = (props) => {


  return (
    <>
      <Main>
        <h1>Under Construction</h1>
      </Main>
    </>
  );
};

export default Analytics;
