import React, { Suspense, useEffect, useState, useRef } from "react";
import { Row, Col, Skeleton, Empty, Divider, Button, Tabs } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, NavLink, useRouteMatch } from "react-router-dom";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Main } from "../../styled";
import { ShowRoom } from "./ShowRoom";
import "../../Sell/sell.css";
import "./houseKeeping.css";
import { CurrentRoom } from "./CurrentRoom";
import Housekeeper from "./Housekeeper";
const HousekeepingBuilder = () => {
  const { path } = useRouteMatch();
  const { TabPane } = Tabs;
  let [activeTab, changeTab] = useState("ROOMS");
  const [oneRoomDetials, setOneRoomDetails] = useState();
  const handdleCurrentRoom = (room) => {
    setOneRoomDetails(room);
    changeTab("CURRENT");
  };
  return (
    <>
      <Main className="sellscroll1">
        <Row>
          <Col span={24}>
            <Tabs
              className="sell-tabs"
              type="card"
              activeKey={activeTab}
              size="small"
              onChange={(val) => {
                changeTab(val);
              }}
            >
              <TabPane tab="Rooms" key="ROOMS" style={{ outline: "none" }} />
              {/* <TabPane
                tab="Current"
                key="CURRENT"
                style={{ outline: "none" }}
              /> */}
              <TabPane
                tab="Housekeeper"
                key="HOUSEKEEPER"
                style={{ outline: "none" }}
              />
            </Tabs>
          </Col>
        </Row>

        <Row gutter={25}>
          <Col span={24}>
            {activeTab == "ROOMS" && (
              <ShowRoom handdleCurrentRoom={handdleCurrentRoom} />
            )}
            {/* {activeTab == "CURRENT" && (
              <CurrentRoom
                changeTab={changeTab}
                oneRoomDetials={oneRoomDetials}
              />
            )} */}
            {activeTab == "HOUSEKEEPER" && (
              <Housekeeper changeTab={changeTab} />
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default HousekeepingBuilder;
