import React from "react";
import { Main } from "../styled";

const SmsReceipts = (props) => {

  return (
    <>
      <Main>
        <h1>Under Construction</h1>
      </Main>
    </>
  );
};

export default SmsReceipts;
