import { Button, Table, Input, Popover, Modal, Spin } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import {
  CaretDownOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getRegisterById } from "../../../../../redux/register/actionCreator";
import { getAllRoomList } from "../../../../../redux/sell/actionCreator";
function ViewQRCode() {
  const history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  let isMounted = useRef(true);
  const [selectionType] = useState("checkbox");
  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModelVisible] = useState(false);
  let [search, setsearch] = useState("");
  const deviceWidth = localStorage.getItem("windowWidth");
  // console.log("windowWidth:::---view table qr---->>>>>>>>>>::", deviceWidth)

  async function fetchTablesList() {
    if (location?.state?.register_id && location?.state?.type == "table") {
      setLoading(true);
      const gettableList = await dispatch(
        getAllRoomList(
          location?.state?.register_id,
          undefined,
          undefined,
          "table",
          true
        )
      );
      console.log("sagartsdnajkdnaskdasd", gettableList.data);
      if (gettableList && gettableList.data) {
        setTableList(gettableList.data);
        setLoading(false);
      }
    } else if (
      location?.state?.register_id &&
      location?.state?.type == "room"
    ) {
      setLoading(true);
      const gettableList = await dispatch(
        getAllRoomList(
          location?.state?.register_id,
          undefined,
          undefined,
          "room"
        )
      );
      console.log("sagartsdnajkdnaskdasd", gettableList);
      if (gettableList && gettableList.data) {
        setTableList(gettableList.data);
        setLoading(false);
      }
    }

    return true;
  }

  useEffect(() => {
    if (isMounted.current) {
      fetchTablesList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function downloadQrCode(url, table_name) {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = `${table_name}.png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function toDataURL(url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setModelVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span style={{ marginLeft: "10px" }}>Download Selected Items</span>
      </NavLink>
    </>
  );

  const [state, setState] = useState({
    item: tableList,
  });
  const { selectedRowKeys, item } = state;
  useEffect(() => {
    if (tableList) {
      setState({
        item: tableList,
        selectedRowKeys,
      });
    }
  }, [tableList, selectedRowKeys]);

  const downloadSelectedQRCodes = async () => {
    setLoading(true);

    const { allSelectedQRDownload } = state;
    console.log("dasdjkahdkdhaskdasdas", allSelectedQRDownload);
    allSelectedQRDownload.map(async (item) => {
      await downloadQrCode(item.qrCodeUrl, item.table_name);
    });

    setModelVisible(false);

    setState({
      ...state,
      selectedRows: [],
    });
  };

  const handleCancel = (e) => {
    setModelVisible(false);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedQRDownload: selectedRows,
      });
    },
  };

  const dataSource = [];
  Object.entries(tableList).map((item, i) => {
    const { table_id, table_name, qrCodeUrl, _id } = item[1];
    return dataSource.push({
      id: table_id ? table_id : _id,
      key: i,
      table_name: table_name,
      qrCodeUrl: qrCodeUrl,
    });
  });

  dataSource.forEach(function(item, i) {
    if (
      item.table_name === "Takeaway/Delivery" ||
      item.table_name === "Takeaway-Delivery"
    ) {
      dataSource.splice(i, 1);
      dataSource.unshift(item);
    }
  });

  console.log("view qrcode data source:::", dataSource);
  const searchDataSource = [];

  let searchArrTaxes = dataSource.filter((value) =>
    value.table_name.toLowerCase().includes(search.toLowerCase())
  );

  if (searchArrTaxes.length)
    searchArrTaxes.map((value, i) => {
      searchDataSource.push({
        id: value.id,
        key: value.key,
        table_name: value.table_name,
        qrCodeUrl: value.qrCodeUrl,
      });
    });

  const columns = [
    // {
    //   title: (
    //     <>
    //       <Popover placement="top" content={contentforaction} trigger="hover">
    //         <CaretDownOutlined style={{ marginLeft: "12px" }} />
    //       </Popover>
    //     </>
    //   ),
    //   key: "action",
    //   dataIndex: "action",
    //   width: "5%",
    // },
    {
      title: "Table Name",
      dataIndex: "table_name",
      textAlign: "center",
      key: "table_name",
      // fixed: "left",
      // width: "100px",
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },
    {
      title: "QR Code",
      dataIndex: "qrCodeUrl",
      key: "qrCodeUrl",
      render(text, record) {
        return (
          <>
            <img src={record.qrCodeUrl} width={60} height={60} />
          </>
        );
      },
    },
    {
      title: "Download QR Code",
      dataIndex: "download",
      key: "download",
      render(text, record) {
        return (
          <>
            <FeatherIcon
              size={18}
              icon="download"
              onClick={() =>
                downloadQrCode(record.qrCodeUrl, record.table_name)
              }
            />
          </>
        );
      },
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  // console.log("state:::::", state);
  return (
    <div style={{ padding: "24px 0px" }}>
      <Cards
        title={
          <div style={{ boxShadow: "none", margin: " 0 0 0 3px" }}>
            <Input
              suffix={<SearchOutlined />}
              className="set_serbt"
              autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
              placeholder="Search by Table Name"
              style={{
                borderRadius: "30px",
                width: "250px",
              }}
              onChange={(e) => setsearch(e.target.value)}
              value={search}
            />
          </div>
        }
        isbutton={
          <Button
            className="ant-btn ant-btn-primary ant-btn-md"
            style={{ color: "#FFF" }}
            onClick={() => history.push(`/settings/registers`)}
          >
            <FeatherIcon icon="arrow-left" size={16} className="pls_iconcs" />
            Go Back
          </Button>
        }
      >
        {loading ? (
          <Table
            rowKey="id"
            locale={locale}
            dataSource={[]}
            columns={columns}
            size="small"
            style={{ marginTop: "8px" }}
            scroll={{ x: "100px" }}
          />
        ) : (
          <Table
            rowKey="id"
            dataSource={search == "" ? dataSource : searchDataSource}
            columns={columns}
            size="small"
            style={{ marginTop: "8px" }}
            scroll={{ x: "100px" }}
          />
        )}

        <p style={{ display: "none" }}>{loading}</p>
        <Modal
          title="Confirm Download"
          okText={
            loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                    spin
                  />
                }
              />
            ) : (
              "Download"
            )
          }
          visible={modalVisible}
          onOk={downloadSelectedQRCodes}
          onCancel={handleCancel}
          width={600}
        >
          <p>Are you sure you want to Download all QR Codes ?</p>
        </Modal>
      </Cards>
    </div>
  );
}

export { ViewQRCode };
