import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Input,
  Modal,
  Switch,
  message,
  Button,
  Badge,
  Row,
  Col,
  Tabs,
  DatePicker,
  Form,
  Select,
  Tooltip,
} from "antd";
import Exportform from "./Exportform";
import { useHistory } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import { Popover } from "../../../../../components/popup/popup";
import FeatherIcon from "feather-icons-react";
import {
  ArrowRightOutlined,
  CaretDownOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  MenuUnfoldOutlined,
  PlusOutlined,
  RestOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "../../setting.css";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { TableWrapper, Main } from "../../../../styled";
import { CardToolbox } from "../../../../Customer/Style";
import { PageHeader } from "../../../../../components/page-headers/page-headers";
import { UserTableStyleWrapper } from "../../../../pages/style";
import { getItem, setItem } from "../../../../../utility/localStorageControl";
import commonFunction from "../../../../../utility/commonFunctions";
import { getAdjustmentList } from "../../../../../redux/inventory/actionCreator";
const AdjustmentInventory = () => {
  const [loading, setLoading] = useState(false);
  const [entryDateSearchModalVisible, setEntryDateSearchModal] = useState(
    false
  );
  const [DateFilter, setDateFliter] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const { TabPane } = Tabs;
  const [searchText, setSearch] = useState("");
  let isMounted = useRef(true);
  const [DateForm] = Form.useForm();
  const [actionSearchForm] = Form.useForm();
  const [loader, setloader] = useState(false);
  const [adjustMentList, setAdjustMentList] = useState([]);
  const [inventoryId, setInventoryId] = useState();
  const [changePage, setChangePage] = useState(1);
  const offLineMode = useSelector((state) => state.auth.offlineMode);
  const deviceWidth = getItem("windowWidth");
  let [filterObj, setFilterObj] = useState({
    inventory_id: location?.state?.inventory_Id?._id,
  });
  const [actionTypeSearchVisible, setActionTypeSearchVisible] = useState(false);
  const searchDidmount = useRef();
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [actionTypeFilter, setActiionTypeFilter] = useState(false);
  const [reportType, setReportType] = useState();
  async function fetchInvetory(page, obj) {
    setloader(true);
    if (location?.state?.inventory_Id) {
      setInventoryId(location?.state?.inventory_Id);
      let reponse = await dispatch(getAdjustmentList(page, obj));
      setloader(false);
      setEntryDateSearchModal(false);
      setResetLoader(false);
      setActionTypeSearchVisible(false);
      setAdjustMentList(reponse);
    }
    return true;
  }
  const [resetLoader, setResetLoader] = useState(false);
  useEffect(() => {
    if (location?.state?.inventory_Id) {
      fetchInvetory(1, filterObj);
    }
  }, [filterObj]);

  useEffect(() => {
    if (searchDidmount.current) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      const timer = setTimeout(() => {
        if (searchText == "") {
          delete filterObj.reason;
          setFilterObj({
            ...filterObj,
            reason: searchText,
          });
        } else {
          setFilterObj({
            ...filterObj,
            reason: searchText,
          });
        }
      }, 500);
      setDebounceTimer(timer);
      return () => clearTimeout(timer);
    } else {
      searchDidmount.current = true;
    }
  }, [searchText]);
  const columns = [
    {
      title: (
        <div className="filterContainer">
          Action type
          <FilterFilled
            style={{ color: actionTypeFilter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setActionTypeSearchVisible(!actionTypeSearchVisible);
            }}
          />
        </div>
      ),
      dataIndex: "action_type",
      key: "action_type",
      fixed: "left",
      className: "item_name_col",
      render(text, record) {
        return {
          children: (
            <div style={{ color: "#008cba" }} className="item_name_text">
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <div className="filterContainer">
          Entry Date
          <FilterFilled
            style={{ color: DateFilter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setEntryDateSearchModal(!entryDateSearchModalVisible);
            }}
          />
        </div>
      ),
      dataIndex: "entry_date",
      key: "entry_date",
      width: "20%",
      render(created_at, record) {
        return {
          children: (
            <span>
              {commonFunction.convertToDate(created_at, "MMM DD, Y, h:mm A")}
            </span>
          ),
        };
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      className: "center-col",
    },
    {
      title: "User",
      dataIndex: "username",
      key: "username",
      align: "left",
      className: "center-col",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Transfer inventory",
      dataIndex: "transfer_inventory_id",
      key: "transfer_inventory_id",
      align: "left",
      className: "center-col",
      render(text, record) {
        return {
          children: <div>{text ? text.inventory_name : "-"}</div>,
        };
      },
    },

    {
      title: "Total Items",
      dataIndex: "products",
      key: "products",
      className: "center-col",
      render(text, record) {
        return {
          children: <div>{text.length}</div>,
        };
      },
    },
    {
      title: "View items",
      dataIndex: "update_date",
      key: "update_date",
      align: "left",
      className: "center-col",
      render(text, record) {
        return {
          children: (
            <div>
              <ArrowRightOutlined
                onClick={() => {
                  history.push(`/inventory/view-adjustmet-items`, {
                    inventory_Id: inventoryId,
                    items: record.products,
                    action_type: record.action_type,
                  });
                }}
              ></ArrowRightOutlined>
            </div>
          ),
        };
      },
    },
  ];
  const [offLineModeCheck, setOfflineModeCheck] = useState(false);
  const [modalVisible, setModelVisible] = useState(false);
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const handleReset = async (type) => {
    setResetLoader(true);
    if (type == "invoice") {
      delete filterObj.startDate;
      delete filterObj.endDate;
      setFilterObj({ ...filterObj });
      setDateFliter(false);
      setEntryDateSearchModal(false);
      DateForm.resetFields();
    }
    if (type == "vendor") {
      delete filterObj.action_type;
      setFilterObj({ ...filterObj });
      actionSearchForm.resetFields();
      setActiionTypeFilter(false);
      setActionTypeSearchVisible(false);
    }
  };
  const handleDateFilter = async (value) => {
    DateForm.validateFields().then(async (value) => {
      setDateFliter(true);
      setFilterObj({
        ...filterObj,
        startDate: moment(value.startDate).format("YYYY-MM-DD"),
        endDate: moment(value.endDate).format("YYYY-MM-DD"),
      });
    });
  };
  const handleActionSearchFilter = async () => {
    actionSearchForm.validateFields().then(async (value) => {
      setActiionTypeFilter(true);
      setFilterObj({
        ...filterObj,
        action_type: value.action_type,
      });
    });
  };
  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setReportType("CSV");
          setModelVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  return (
    <Main className="inventory-items">
      <CardToolbox>
        <PageHeader
          ghost
          className="comman-other-custom-pageheader receipts-top0 inventory_header"
          subTitle={
            <>
              <div className="table_titles">
                <h2>Actions</h2>
                {/* <span className="title-counter">
                  <span>{dataSource?.length} </span>
                </span> */}
              </div>
              <div
                style={{ boxShadow: "none", marginLeft: "10px" }}
                className="search_lrm"
              >
                <Input
                  suffix={<SearchOutlined />}
                  autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                  className="set_serbt"
                  placeholder="Search by Reason"
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                  value={searchText}
                />
              </div>
            </>
          }
          buttons={[
            <div className="inventory_action_btn">
              <Tooltip
                title={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Showing bills from{" "}
                    {moment()
                      .startOf("month")
                      .format("MMMM Do YYYY")}
                    {" to"} {moment().format("MMMM Do YYYY")}
                  </p>
                }
              >
                <ExclamationCircleOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
              <Button
                className="ant-btn ant-btn-md btn-cancel btn"
                style={{ marginRight: "2px" }}
                onClick={() => {
                  if (offLineMode) {
                    setOfflineModeCheck(true);
                  } else {
                    history.push(`/inventory`);
                    setOfflineModeCheck(false);
                  }
                }}
              >
                <ArrowLeftOutlined />
                Go back
              </Button>
              {/* <Popover placement="bottomLeft" content={content} trigger="hover">
                <Button
                  className="ant-btn ant-btn-md btn-cancel btn"
                  style={{ marginRight: "2px" }}
                >
                  <FeatherIcon icon="download" size={14} />
                  Export
                </Button>
              </Popover>

              <Button
                className="ant-btn ant-btn-md btn-cancel btn"
                style={{ marginRight: "2px" }}
                disabled={loader ? true : false}
                onClick={() => {
                  if (offLineMode) {
                    setOfflineModeCheck(true);
                  } else {
                    history.push(`/inventory/inward`, {
                      inventory_Id: inventoryId,
                      action_type: "Wastage",
                    });
                    setOfflineModeCheck(false);
                  }
                }}
              >
                <RestOutlined />
                Wastage
              </Button>
              <Button
                className="ant-btn ant-btn-md btn-cancel btn"
                style={{ marginRight: "2px" }}
                disabled={loader ? true : false}
                onClick={() => {
                  if (offLineMode) {
                    setOfflineModeCheck(true);
                  } else {
                    history.push(`/inventory/inward`, {
                      inventory_Id: inventoryId,
                      action_type: "Adjustment",
                    });
                    setOfflineModeCheck(false);
                  }
                }}
              >
                <MenuUnfoldOutlined />
                Adjustment
              </Button>
              <Button
                className="ant-btn ant-btn-md btn-cancel btn"
                style={{ marginRight: "2px" }}
                disabled={loader ? true : false}
                onClick={() => {
                  if (offLineMode) {
                    setOfflineModeCheck(true);
                  } else {
                    history.push(`/inventory/inward`, {
                      inventory_Id: inventoryId,
                      action_type: "Transfer Out",
                    });
                    setOfflineModeCheck(false);
                  }
                }}
              >
                <FeatherIcon icon="chevrons-up" size={14} />
                Transfer Out
              </Button>
              <Button
                className="ant-btn ant-btn-md  btn-cancel btn"
                style={{ marginRight: "2px" }}
                disabled={loader ? true : false}
                onClick={() => {
                  if (offLineMode) {
                    setOfflineModeCheck(true);
                  } else {
                    history.push(`/inventory/inward`, {
                      inventory_Id: inventoryId,
                      action_type: "Inward",
                    });
                    setOfflineModeCheck(false);
                  }
                }}
              >
                <FeatherIcon icon="download" size={14} />
                Inward
              </Button> */}

              {/* </div> */}
            </div>,
          ]}
        />
      </CardToolbox>

      <Row gutter={15}>
        <Col span={24}>
          <Cards headless>
            <UserTableStyleWrapper>
              <div className="contact-table">
                <TableWrapper className="table-responsive">
                  {loader ? (
                    <Table
                      rowKey="id"
                      locale={locale}
                      dataSource={[]}
                      columns={columns}
                      size="small"
                      style={{ marginTop: "8px" }}
                    />
                  ) : (
                    <Table
                      rowKey="id"
                      dataSource={adjustMentList}
                      pagination={false}
                      columns={columns}
                      size="small"
                      style={{ marginTop: "8px" }}
                    />
                  )}
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Button
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50px",
                }}
                onClick={() => {
                  setChangePage(changePage - 1);
                  fetchInvetory(changePage - 1, filterObj);
                }}
                disabled={changePage == 1 ? true : false}
              >
                <FeatherIcon
                  size={20}
                  icon="chevron-left"
                  style={{ position: "relative", left: "-11px", top: "2px" }}
                />
              </Button>
              <Button
                disabled={adjustMentList.length != 10 ? true : false}
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50px",
                  marginLeft: "40px",
                }}
                onClick={() => {
                  setChangePage(changePage + 1);
                  fetchInvetory(changePage + 1, filterObj);
                }}
              >
                <FeatherIcon
                  size={20}
                  icon="chevron-right"
                  style={{ position: "relative", left: "-9px", top: "2px" }}
                />
              </Button>
            </p>
          </Cards>
        </Col>
      </Row>
      <Modal
        title="You are Offline"
        visible={offLineModeCheck}
        onOk={() => setOfflineModeCheck(false)}
        onCancel={() => setOfflineModeCheck(false)}
        width={600}
      >
        <p>You are offline not add and update </p>
      </Modal>
      <Modal
        title="Invoice Date Wise Filter"
        visible={entryDateSearchModalVisible}
        onCancel={() => setEntryDateSearchModal(false)}
        footer={[
          <Button onClick={() => handleReset("invoice")}>
            {resetLoader ? "Loading.." : "Reset"}
          </Button>,
          <Button onClick={() => handleDateFilter()} type="primary">
            {loader ? "Loading.." : "Submit"}
          </Button>,
        ]}
        width={600}
      >
        <div>
          <Form form={DateForm}>
            <Form.Item
              label="Start Date"
              style={{
                display: "inline-block",
                width: "calc(50% - 12px)",
              }}
              name="startDate"
            >
              <DatePicker
                style={{ height: "35px" }}
                placeholder="dd-mm-yyyy"
                format="YYYY-MM-DD"
              />
            </Form.Item>
            <Form.Item
              label="End Date"
              style={{
                display: "inline-block",
                width: "calc(50% - 12px)",
              }}
              name="endDate"
            >
              <DatePicker
                style={{ height: "35px", marginBottom: 10 }}
                placeholder="dd-mm-yyyy"
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Action Type Filter"
        visible={actionTypeSearchVisible}
        onCancel={() => setActionTypeSearchVisible(false)}
        footer={[
          <Button onClick={() => handleReset("vendor")}>
            {resetLoader ? "Loading.." : "Reset"}
          </Button>,
          <Button onClick={() => handleActionSearchFilter()} type="primary">
            {loader ? "Loading.." : "Submit"}
          </Button>,
        ]}
        width={600}
      >
        <div>
          <Form form={actionSearchForm}>
            <Form.Item name="action_type">
              <Select
                className="custom_select4545"
                style={{ margin: "8px 0 0" }}
                placeholder="Select action type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Select.Option key={1} value={"Inward"}>
                  Inward
                </Select.Option>
                <Select.Option key={1} value={"Adjustment"}>
                  Adjustment
                </Select.Option>
                <Select.Option key={1} value={"Wastage"}>
                  Wastage
                </Select.Option>
                <Select.Option key={1} value={"Sell"}>
                  Sell
                </Select.Option>
                <Select.Option key={1} value={"Transfer Out"}>
                  Transfer Out
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Exportform
        modalVisible={modalVisible}
        setModelVisible={setModelVisible}
        reportType={reportType}
        inventoryId={inventoryId?._id}
      />
    </Main>
  );
};

export { AdjustmentInventory };
