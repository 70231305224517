import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Modal, Tabs, Form, Input, Tag, Row, Radio, Button, Col } from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;
const EditRoomPrice = (props) => {
  let {
    editModalOpen,
    setEditModalOpen,
    roomDetails,
    handleEditRatePlanSave,
    loader,
  } = props;

  const [editForm] = Form.useForm();
  const [taxGroup, setTaxGroup] = useState();
  // edit price rate modal
  const submitEditRate = (val) => {
    if (taxGroup) {
      roomDetails["room_type"]["tax_group"] = taxGroup;
    }
    handleEditRatePlanSave(
      {
        ...roomDetails.rate_plan,
        ...val,
      },
      roomDetails
    );
  };
  useEffect(() => {
    if (roomDetails && roomDetails.rate_plan) {
      editForm.setFieldsValue(roomDetails.rate_plan);
      setTaxGroup(roomDetails?.room_type?.tax_group);
    }
  }, []);
  const handleTaxEdit = (value) => {
    if (taxGroup) {
      setTaxGroup({ ...taxGroup, taxes_inclusive_in_product_price: value });
    }
  };
  return (
    <Modal
      title="Edit Room Price"
      open={editModalOpen}
      onOk={editForm.submit}
      onCancel={() => setEditModalOpen(false)}
      okText={loader ? "Loading..." : "Update"}
    >
      <p>
        <strong>Note:</strong> Please note all the rates provided below must for
        per night rate.
      </p>
      <Form
        style={{ width: "100%" }}
        form={editForm}
        name="editNewRate"
        onFinish={submitEditRate}
        className="comman-input"
      >
        <Form.Item
          name="default_adult_rate"
          label="Default Rate"
          rules={[
            {
              pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
              message: "rate cannot be negative",
            },
            {
              required: true,
              message: "Please enter default rate",
            },
          ]}
        >
          <Input
            min={0}
            type="number"
            step="any"
            placeholder="Enter default rate"
            style={{ marginBottom: 10 }}
            onKeyPress={(event) => {
              if (event.key.match("[0-9,.]+")) {
                return true;
              } else {
                return event.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="extra_adult_rate"
          label="Extra adult rate"
          rules={[
            {
              pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
              message: "Price cannot be negative",
            },
          ]}
        >
          <Input
            min={0}
            type="number"
            step="any"
            defaultValue={0}
            placeholder="Enter default adult rate"
            style={{ marginBottom: 10 }}
            onKeyPress={(event) => {
              if (event.key.match("[0-9,.]+")) {
                return true;
              } else {
                return event.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="extra_child_rate"
          label="Extra children rate"
          rules={[
            {
              pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
              message: "Price cannot be negative",
            },
          ]}
        >
          <Input
            min={0}
            type="number"
            step="any"
            defaultValue={0}
            placeholder="Enter children rate"
            style={{ marginBottom: 10 }}
            onKeyPress={(event) => {
              if (event.key.match("[0-9,.]+")) {
                return true;
              } else {
                return event.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="extra_infant_rate"
          label="Extra infant rate"
          rules={[
            {
              pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
              message: "Price cannot be negative",
            },
          ]}
        >
          <Input
            min={0}
            type="number"
            step="any"
            placeholder="Enter infant rate"
            style={{ marginBottom: 10 }}
            defaultValue={0}
            onKeyPress={(event) => {
              if (event.key.match("[0-9,.]+")) {
                return true;
              } else {
                return event.preventDefault();
              }
            }}
          />
        </Form.Item>
        <Form.Item name={`tax-group`} label={taxGroup?.tax_group_name}>
          <Radio.Group
            className="tick-radio"
            onChange={(e) => handleTaxEdit(e.target.value)}
          >
            <Radio.Button
              value="remove"
              style={{
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              {taxGroup?.taxes_inclusive_in_product_price === "remove" ? (
                <svg
                  width="13px"
                  style={{ marginRight: "2px" }}
                  viewBox="0 0 123 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                    fill="#BE3D5D"
                  />
                </svg>
              ) : (
                ""
              )}
              Remove
            </Radio.Button>
            <Radio.Button
              value={true}
              style={{
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              {taxGroup?.taxes_inclusive_in_product_price === true ? (
                <svg
                  width="13px"
                  style={{ marginRight: "2px" }}
                  viewBox="0 0 123 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                    fill="#BE3D5D"
                  />
                </svg>
              ) : (
                ""
              )}{" "}
              Inclusive
            </Radio.Button>

            <Radio.Button
              value={false}
              style={{
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              {taxGroup?.taxes_inclusive_in_product_price === false ? (
                <svg
                  width="13px"
                  style={{ marginRight: "2px" }}
                  viewBox="0 0 123 102"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                    fill="#BE3D5D"
                  />
                </svg>
              ) : (
                ""
              )}
              Exclusive
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(EditRoomPrice);
