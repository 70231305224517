import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Space,
  Spin,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "./Style";
import { UserTableStyleWrapper } from "../pages/style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../styled";
import { Button } from "../../components/buttons/buttons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";

import { getAllRoomReceipts } from "../../redux/reservation/actionCreator";
import { Popover } from "../../components/popup/popup";
import Exportform from "./Exportform";
import commonFunction from "../../utility/commonFunctions";
import "./receipt.css";
import { getItem, setItem } from "../../utility/localStorageControl";
import moment from "moment";
import {
  ExclamationCircleOutlined,
  DownOutlined,
  FilterFilled,
} from "@ant-design/icons";

const { Search } = Input;

const RoomReceipts = () => {
  const history = useHistory();
  let searchInput = useRef(null);
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const dispatch = useDispatch();
  let [search, setsearch] = useState("");
  const [state, setState] = useState();
  const [reportType, setReportType] = useState();
  const {
    totalReceipts,
    currentRegisterData,
    startDate,
    endDate,
    loader,
  } = useSelector(
    (state) => ({
      totalReceipts: state.receipts.totalReceipts,
      startDate: state.receipts.startDate,
      endDate: state.receipts.endDate,
      loader: state.receipts.receiptLoader,
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    }),
    shallowEqual
  );
  const [loading, setloading] = useState(true);
  const [ReceiptsList, setReceiptList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );

  const [paymentStatus, setPaymentStatus] = useState(undefined);
  const [receiptNum, setReceiptNum] = useState(undefined);
  const [openPopover, setOpenPopover] = useState(false);
  const [checkedVal, setCheckedVal] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);

  const receiptDataList = async (searchObj) => {
    setloading(true);
    const receiptList = await dispatch(
      getAllRoomReceipts(undefined, currentRegisterData._id, searchObj)
    );
    if (receiptList && receiptList.error == false) {
      setloading(false);
      setReceiptList(receiptList.data);
      // message.success({
      //   content: receiptList.message,
      //   style: {
      //     float: "center",
      //     marginTop: "2vh",
      //   },
      // });
    } else {
      message.error({
        content: receiptList.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  };
  useEffect(() => {
    if (currentRegisterData) {
      receiptDataList({
        startDate,
        endDate,
        currentPage,
        receiptNum,
      });
    }
  }, [currentRegisterData?._id, startDate, endDate, currentPage, receiptNum]);

  const onSearch = (val) => {
    setReceiptNum(val);
  };

  const onChangeFilter = (e) => {
    setChecked(e.target.checked);
    e.target.checked == true
      ? setCheckedVal("unpaid")
      : setCheckedVal(undefined);
  };
  const ResetFilter = () => {
    setOpenPopover(false);
    setChecked(false);
    setCheckedVal(undefined);
    setPaymentStatus(undefined);
  };
  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const onPaymentFilter = (val) => {
    setPaymentStatus(val);
    setOpenPopover(false);
  };

  const [modalVisible, setModelVisible] = useState(false);

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setReportType("CSV");
          setModelVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  const dataSource = [];

  if (ReceiptsList.length)
    ReceiptsList.map((value) => {
      const {
        _id,
        invoiceSringId,
        createAt,
        payment,
        reservationObjectId,
        items,
        customerId,
        notes,
        reservationStringId,
      } = value;

      return dataSource.push({
        id: _id,
        created: createAt,
        receipt_number: invoiceSringId,
        customer_mobile: customerId
          ? customerId?.name
            ? customerId.name
            : customerId.mobile
          : "-",
        payment_status: notes ? notes : "-",
        total: payment.total,
        room_name: items.map((val) => val.title),
        reservationObjectId: reservationObjectId,
        reservationStringId: reservationStringId,
        delete: value.delete,
      });
    });

  let lastIndex = 0;
  const updateIndex = () => {
    lastIndex++;
    return lastIndex;
  };
  const columns = [
    {
      title: "Number",
      dataIndex: "receipt_number",
      key: "receipt_number",

      className: "center-col-padding",
      render(text, record) {
        let path = `#`;
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: record.delete ? (
            <NavLink
              to={path}
              style={{
                textDecoration: "line-through",
                color: "#008cba",
              }}
            >
              {text}
            </NavLink>
          ) : (
            <NavLink to={path} style={{ color: "#008cba" }}>
              {text}
            </NavLink>
          ),
        };
      },
    },

    {
      title: "Generated On",
      dataIndex: "created",
      key: `created${updateIndex()}`,

      render(created_at, record) {
        return {
          children: record.delete ? (
            <span style={{ textDecoration: "line-through" }}>
              {commonFunction.convertToDate(created_at, "MMM DD, Y, h:mm A")}
            </span>
          ) : (
            <span>
              {commonFunction.convertToDate(created_at, "MMM DD, Y, h:mm A")}
            </span>
          ),
        };
      },
    },
    {
      title: "Reservation Reference",
      dataIndex: "reservationStringId",
      key: "reservationStringId",

      render(text, record) {
        return {
          children: (
            <div
              style={record.delete ? { textDecoration: "line-through" } : {}}
            >
              #{text}
            </div>
          ),
        };
      },
    },
    {
      title: "Generated For",
      dataIndex: "customer_mobile",
      key: `customer_mobile${updateIndex()}`,

      // ...getColumnSearchProps("customer_mobile"),
      render(text, record) {
        return {
          children: record.delete ? (
            <div style={{ textDecoration: "line-through" }}> {text}</div>
          ) : (
            <div>{text}</div>
          ),
        };
      },
    },
    {
      title: "Notes",
      dataIndex: "payment_status",
      key: `payment_status${updateIndex()}`,
      render(text, record) {
        return (
          <div style={record.delete ? { textDecoration: "line-through" } : {}}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "left",
      render(text, record) {
        return {
          children: record.delete ? (
            <div style={{ textDecoration: "line-through" }}>
              {rsSymbol}
              {Number(text).toFixed(2)}
            </div>
          ) : (
            <div>
              {rsSymbol}
              {Number(text).toFixed(2)}
            </div>
          ),
        };
      },
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  return (
    <>
      {/* <Main> */}
      {/* <CardToolbox>
        <PageHeader
          ghost
          title=""
          subTitle={
            <>
              <div className="table_titles">
                <h2>Receipts</h2>
              </div>

              <Search
                className="receipts-search"
                placeholder="Search by Bill Number"
                enterButton
                onSearch={(e) => onSearch(e)}
                suffix={<SearchOutlined style={{ fontSize: "20px" }} />}
                allowClear={{
                  clearIcon: <CloseOutlined />,
                }}
              />
            </>
          }
          buttons={[
            <div key="1" className="page-header-actions">
              <Tooltip
                title={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    Showing receipts from{" "}
                    {startDate
                      ? moment(startDate).format("MMMM Do YYYY")
                      : moment()
                          .startOf("month")
                          .format("MMMM Do YYYY")}
                    {" to"}{" "}
                    {endDate
                      ? moment(endDate).format("MMMM Do YYYY")
                      : moment().format("MMMM Do YYYY")}
                  </p>
                }
              >
                <ExclamationCircleOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
              <CalendarButtonPageHeader key="1" type="receipts" />
              {
                <Popover
                  placement="bottomLeft"
                  content={content}
                  trigger="hover"
                >
                  <Button size="small" type="white">
                    <FeatherIcon icon="download" size={14} />
                    Export
                  </Button>
                </Popover>
              }
            </div>,
          ]}
        />
      </CardToolbox> */}

      <Row gutter={15}>
        <Col md={24}>
          <Cards headless>
            <Row gutter={15} className="list-row">
              <Col span={24} className="pricebookTableBox">
                <div className="priceBook_titles_content">
                  <div className="productCount">
                    <h2>Receipts</h2>
                  </div>
                  <div
                    style={{
                      boxShadow: "none",
                      marginBottom: "16px",
                      marginLeft: "-10px",
                    }}
                    className="table_search"
                  >
                    <Search
                      className="receipts-search"
                      placeholder="Search by Bill Number"
                      enterButton
                      onSearch={(e) => onSearch(e)}
                      suffix={<SearchOutlined style={{ fontSize: "20px" }} />}
                      allowClear={{
                        clearIcon: <CloseOutlined />,
                      }}
                    />
                  </div>
                </div>
                <div key="1" className="page-header-actions">
                  <Tooltip
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Showing receipts from{" "}
                        {startDate
                          ? moment(startDate).format("MMMM Do YYYY")
                          : moment()
                              .startOf("month")
                              .format("MMMM Do YYYY")}
                        {" to"}{" "}
                        {endDate
                          ? moment(endDate).format("MMMM Do YYYY")
                          : moment().format("MMMM Do YYYY")}
                      </p>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    />
                  </Tooltip>
                  <CalendarButtonPageHeader key="1" type="receipts" />
                  {
                    <Popover
                      placement="bottomLeft"
                      content={content}
                      trigger="hover"
                    >
                      <Button
                        size="small"
                        type="white"
                        style={{ border: "1px solid #e3e6ef" }}
                      >
                        <FeatherIcon icon="download" size={14} />
                        Export
                      </Button>
                    </Popover>
                  }
                </div>
              </Col>
            </Row>

            <UserTableStyleWrapper>
              <div className="contact-table">
                <TableWrapper className="table-responsive">
                  {loading ? (
                    <>
                      <Table
                        className="receipt-custom-table"
                        locale={locale}
                        rowKey="id"
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                        pagination={false}
                      />
                    </>
                  ) : (
                    <Table
                      className="receipt-custom-table"
                      locale={loading ? locale : false}
                      rowKey="id"
                      size="small"
                      dataSource={dataSource}
                      columns={columns}
                      fixed={true}
                      scroll={{ x: 800 }}
                      onRow={(row) => ({
                        onClick: () => {
                          history.push(`room-receipts/${row.id}`);
                        },
                      })}
                      pagination={false}
                    />
                  )}
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Button
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50px",
                }}
                className="remove-extra-css"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage == 1 ? true : false}
              >
                <FeatherIcon
                  size={20}
                  icon="chevron-left"
                  style={{
                    position: "relative",
                    left: "-9px",
                    top: "3px",
                  }}
                />
              </Button>

              <Button
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50px",
                  marginLeft: "40px",
                }}
                className="remove-extra-css"
                disabled={ReceiptsList?.length < 10 ? true : false}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <FeatherIcon
                  size={20}
                  icon="chevron-right"
                  style={{
                    position: "relative",
                    left: "-7px",
                    top: "3px",
                  }}
                />
              </Button>
            </p>
          </Cards>
        </Col>
      </Row>

      <Exportform
        modalVisible={modalVisible}
        setModelVisible={setModelVisible}
        reportType={reportType}
        type="room"
      />
      {/* </Main> */}
    </>
  );
};

export default RoomReceipts;
