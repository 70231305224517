import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  Form,
  Input,
  Radio,
  Tag,
  Tooltip,
  Spin,
} from "antd";
import ReactDOMServer from "react-dom/server";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { InvoiceLetterBox, ProductTable, OrderSummary } from "./Style";
import OrderTicketPrint from "../Sell/Current/OrderTicketPrint";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import Heading from "../../components/heading/heading";
import { Button } from "../../components/buttons/buttons";
import { useDispatch } from "react-redux";
import ReceiptPrint from "../Sell/Print/ReceiptPrint";

import commonFunction from "../../utility/commonFunctions";
import { getItem } from "../../utility/localStorageControl";

import "./receipt.css";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  addOrDeleteInvoice,
  getSingleReservation,
} from "../../redux/reservation/actionCreator";
import { getRoomReceiptsById } from "../../redux/reservation/actionCreator";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import HotelInvoice from "../Reservation/HotelInvoice";
import moment from "moment";
// import { LoadingOutlined } from "@ant-design/icons";

const ShowRoomReceipt = ({ match }) => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const userDetails = getItem("userDetails");
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const [deleteReceiptsModalVisible, setDeleteReceiptsModalVisible] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  let isMounted = useRef(true);
  let [RecepitsData, setRecepitsData] = useState();
  const [ItemsList, setItemsList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [transactionList, setTrancationList] = useState([]);
  async function fetchRecepitsData(id, check) {
    const getRecepitsData = await dispatch(getRoomReceiptsById(id));
    if (getRecepitsData) {
      setRecepitsData(getRecepitsData);
      if (getRecepitsData && getRecepitsData.reservationObjectId?.roomInfo) {
        let transactions = [];
        getRecepitsData.reservationObjectId?.roomInfo.map((val) => {
          if (val.roomInfoId.transationList?.length) {
            val.roomInfoId.transationList.map((k) => {
              transactions.push({
                type: k.transationId.type,
                value: k.transationId.amount,
                paidTime: k.transationId.paidTime,
              });
            });
          }
        });
        setTrancationList(transactions);
      }
      if (check) {
        setDeleteReceiptsModalVisible(false);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      fetchRecepitsData(match.params.id);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { rtl } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });
  const invoiceTableColumns = [
    {
      title: "Date",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      render(text, record) {
        if (record.type == "room") {
          return {
            children: (
              <div>
                {" "}
                <span>
                  {commonFunction.convertToDate(
                    record.reservationInDateAndTime,
                    "ddd DD MMM YYYY"
                  )}{" "}
                  -{" "}
                  {commonFunction.convertToDate(
                    record.reservationOutDateAndTime,
                    "ddd DD MMM YYYY"
                  )}
                </span>
                <p
                  style={{
                    marginTop: "1px",
                    marginBottom: "1px",
                  }}
                >
                  {moment(record?.reservationOutDateAndTime).diff(
                    moment(record?.reservationInDateAndTime),
                    "days"
                  )}{" "}
                  nights
                </p>
              </div>
            ),
          };
        } else {
          return {
            children: (
              <div>
                {commonFunction.convertToDate(
                  record.addTime,
                  "ddd DD MMM YYYY , h:mm A"
                )}
              </div>
            ),
          };
        }
      },
    },
    {
      title: "Room type / Rate plan / Number",
      dataIndex: "title",
      key: "title",
      render(text, record) {
        if (record.type == "room") {
          let { room_type_name, rate_plan, room_name, guest } = record;
          return {
            children: (
              <div>{`${
                room_type_name ? room_type_name : record.room_type
              } / ${rate_plan} / ${room_name}`}</div>
            ),
          };
        } else {
          return {
            children: (
              <div>
                {" "}
                {`${record.room_type} / ${record.rate_plan} / ${record.room_name}`}
              </div>
            ),
          };
        }
      },
    },
    {
      title: "Guest",
      dataIndex: "guest",
      key: "title",
      render(text, record) {
        if (record.type == "room") {
          let { room_type_name, rate_plan, room_name, guest } = record;
          return {
            children: (
              <div>
                {" "}
                {guest.adults > 0 && (
                  <p
                    style={{
                      marginTop: "1px",
                      marginBottom: "1px",
                    }}
                  >
                    {guest.adults} adult(s)
                  </p>
                )}
                {guest.children > 0 && (
                  <p
                    style={{
                      marginTop: "1px",
                      marginBottom: "1px",
                    }}
                  >
                    {guest.children} children(s){" "}
                  </p>
                )}
                {guest.infants > 0 && (
                  <p
                    style={{
                      marginTop: "1px",
                      marginBottom: "1px",
                    }}
                  >
                    {guest.infants} infants(s)
                  </p>
                )}
              </div>
            ),
          };
        } else {
          return {
            children: <div>-</div>,
          };
        }
      },
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      render(text, record) {
        if (record.type == "room") {
          let { tax_group } = record;
          return {
            children: (
              <div>
                <span>{text}</span>
                {tax_group.taxes_inclusive_in_product_price != "remove" &&
                  tax_group.taxes.map((k) => {
                    if (k.tax_percentage) {
                      return (
                        <p
                          style={{
                            marginTop: "1px",
                            marginBottom: "1px",
                          }}
                        >
                          {k.tax_name}
                        </p>
                      );
                    }
                  })}
              </div>
            ),
          };
        } else if (record.type == "addtional") {
          return {
            children: (
              <div>
                <span>
                  {record.itemName} x {record.amount.qty}
                </span>
                {record.tax_group.taxes_inclusive_in_product_price !=
                  "remove" &&
                  record.tax_group.taxes.map((k) => {
                    if (k.tax_percentage) {
                      return (
                        <p
                          style={{
                            marginTop: "1px",
                            marginBottom: "1px",
                          }}
                        >
                          {" "}
                          {k.tax_name}
                        </p>
                      );
                    }
                  })}
              </div>
            ),
          };
        } else {
          return {
            children: (
              <div>
                <span>In Room Service | #{record.itemName}</span>
              </div>
            ),
          };
        }
      },
    },
    {
      title: "Total",
      dataIndex: "value",
      key: "value",
      className: "pricetgt",
      render(text, record) {
        console.log("faklsfhlahflafhlahflaffafaf", record);
        if (record.type == "room") {
          let { tax_group } = record;
          return {
            children: (
              <div>
                <span>
                  {" "}
                  {rsSymbol}
                  {Number(text).toFixed(2)}
                </span>
                {tax_group.taxes_inclusive_in_product_price != "remove" &&
                  tax_group.taxes.map((k) => {
                    if (k.tax_percentage) {
                      let tax = (text * k.tax_percentage) / 100;
                      return (
                        <p
                          style={{
                            marginTop: "1px",
                            marginBottom: "1px",
                          }}
                        >
                          {rsSymbol}
                          {Number(tax).toFixed(2)}
                        </p>
                      );
                    }
                  })}
              </div>
            ),
          };
        } else if (record.type == "addtional") {
          let price = record.amount.total - record.amount.tax;
          return {
            children: (
              <div>
                <span>
                  {" "}
                  {rsSymbol}
                  {Number(price).toFixed(2)}
                </span>
                {record.tax_group.taxes_inclusive_in_product_price !=
                  "remove" &&
                  record.tax_group.taxes.map((k) => {
                    if (k.tax_percentage) {
                      let tax = (price * k.tax_percentage) / 100;
                      return (
                        <p
                          style={{
                            marginTop: "1px",
                            marginBottom: "1px",
                          }}
                        >
                          {" "}
                          {rsSymbol}
                          {Number(tax).toFixed(2)}
                        </p>
                      );
                    }
                  })}
              </div>
            ),
          };
        } else {
          return {
            children: (
              <div>
                <span>
                  {" "}
                  {rsSymbol}
                  {Number(record.value).toFixed(2)}
                </span>
              </div>
            ),
          };
        }
      },
    },
  ];
  const [loaderReceipt, setReceiptloader] = useState(false);
  const handleReservation = async (id) => {
    setReceiptloader(true);
    let response = await dispatch(getSingleReservation(id));

    if (response && response.data) {
      setReceiptloader(false);
      history.push("/reservation", { reservationData: response.data });
    }
  };

  const handlePrint = () => {
    if (RecepitsData) {
      let {
        reservationBookTime,
        reservationInDateAndTime,
        reservationStringId,
        reservationOutDateAndTime,
        numberOfNights,
      } = RecepitsData.reservationObjectId;
      let { payment, items, invoiceSringId } = RecepitsData;
      let customer = RecepitsData.customerId;
      let receiptsDetails = {
        reservationData: {
          reservationBookTime,
          reservationInDateAndTime,
          reservationStringId,
          reservationOutDateAndTime,
          numberOfNights,
        },
        payment: {
          total: payment.total,
          totalTax: payment.totalTax,
          grandTotal: RecepitsData?.reservationObjectId?.payment.grandTotal,
        },
        items: items,
        customer: customer,
      };
      let pendingAmount = 0;
      console.log(
        "RecepitsData.reservationObjectId",
        RecepitsData.reservationObjectId
      );
      RecepitsData.reservationObjectId.roomInfo.map((k, index) => {
        let val = k.roomInfoId;

        if (!val.isCancelled) {
          pendingAmount = pendingAmount + Number(val.calculatedPriceWithTax);
        }
        val?.addtionalItem?.map((r) => {
          if (!r.cancel) {
            pendingAmount = pendingAmount + Number(r.amount.total);
          }
        });
        val?.transationList?.map((k) => {
          let r = k.transationId;
          if (r) {
            if (!r.cancel && r.type != "pending") {
              pendingAmount = pendingAmount - Number(r.amount);
            }
          }
        });
      });
      console.log("pendingAmountpendingAmount", pendingAmount);
      window.frames[
        "print_frame"
      ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <HotelInvoice
          rsSymbol={rsSymbol}
          reservationData={RecepitsData.reservationObjectId}
          shopDetails={getItem("setupCache")?.shopDetails}
          receiptsDetails={receiptsDetails}
          registerData={currentRegisterData}
          invoiceNo={invoiceSringId}
          pendingPrice={pendingAmount}
          createDate={RecepitsData?.created_at}
        />
      );
      window.frames["print_frame"].window.focus();
      window.frames["print_frame"].window.print();
    }
  };
  let subTotal = 0;
  let taxObj = {};
  if (RecepitsData?.items) {
    RecepitsData?.items.map((val) => {
      let { room_type_name, rate_plan, room_name, guest, type } = val;

      if (
        type == "room" &&
        val?.tax_group?.taxes &&
        val.tax_group.taxes_inclusive_in_product_price != "remove"
      ) {
        subTotal += Number(val.value);
        val?.tax_group?.taxes.map((k) => {
          if (k.tax_percentage) {
            let taxPrice = (val.value * k.tax_percentage) / 100;
            if (taxObj[k._id]) {
              taxObj[k._id] = {
                name: k.tax_name,
                value: taxObj[k._id]["value"] + taxPrice,
              };
            } else {
              taxObj[k._id] = {
                name: k.tax_name,
                value: taxPrice,
              };
            }
          }
        });
      } else if (type == "room") {
        subTotal += Number(val.value);
      }
      if (
        type == "addtional" &&
        val?.tax_group?.taxes &&
        val.tax_group.taxes_inclusive_in_product_price != "remove"
      ) {
        subTotal += Number(val.amount.total - val.amount.tax);
        val?.tax_group?.taxes.map((k) => {
          if (k.tax_percentage) {
            let price = Number(val.amount.total - val.amount.tax);
            let taxPrice = (price * k.tax_percentage) / 100;
            if (taxObj[k._id]) {
              taxObj[k._id] = {
                name: k.tax_name,
                value: taxObj[k._id]["value"] + taxPrice,
              };
            } else {
              taxObj[k._id] = {
                name: k.tax_name,
                value: taxPrice,
              };
            }
          }
        });
      } else if (type == "addtional") {
        subTotal += Number(val.amount.total - val.amount.tax);
      }
      if (type == "posease-restaurant") {
        subTotal += Number(val.value);
      }
    });
  }

  const deleteReceipts = async (e) => {
    setLoading(true);
    let payload = {
      type: "delete",
      reservationId: RecepitsData?.reservationObjectId._id,
      invoiceDetails: {
        _id: RecepitsData._id,
        deleteAt: new Date(),
      },
    };
    const getallRoomTable = await dispatch(addOrDeleteInvoice(payload));
    console.log("jskhhdkhjadhakhdahkdakd", getallRoomTable);
    if (getallRoomTable && getallRoomTable.error == false) {
      fetchRecepitsData(match.params.id, true);
    }
  };
  console.log("receiptDataRecepitsData", RecepitsData);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div style={userDetails?.role == "cashier" ? { marginTop: "-25px" } : {}}>
      {RecepitsData ? (
        <>
          <Main className="receipts_inv">
            <PageHeader
              ghost
              className="custome-status-header"
              title={
                <>
                  <span>
                    Status &nbsp;
                    <Tag color="#43ac6a">Paid</Tag>
                    {RecepitsData.delete ? (
                      <Tag color="#f04124">Cancelled</Tag>
                    ) : (
                      <Tag color="#008cba">Fulfilled</Tag>
                    )}
                  </span>
                </>
              }
              buttons={[
                <div key="1" className="page-header-actions">
                  <Button shape="round" type="default" onClick={handlePrint}>
                    <FeatherIcon
                      icon="printer"
                      size={14}
                      style={{ position: "relative", top: "3px" }}
                    />
                    Print
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={() => history.push(`/receipts?type=pms`)}
                  >
                    Go Back
                  </Button>
                  {RecepitsData.delete == false && (
                    <Button
                      shape="round"
                      type="primary"
                      onClick={() => setDeleteReceiptsModalVisible(true)}
                    >
                      Cancel
                    </Button>
                  )}
                </div>,
              ]}
            />

            <Row gutter={15}>
              <Col md={24}>
                <Cards headless>
                  <InvoiceLetterBox>
                    <div className="invoice-letter-inner">
                      <Row align="top">
                        <Col lg={10} xs={24}>
                          <article className="invoice-author">
                            <Heading className="invoice-author__title" as="h3">
                              Invoice
                            </Heading>
                            <p>No : #{RecepitsData?.invoiceSringId}</p>

                            <p className="color-5">
                              Prepared by {RecepitsData?.invoiceUserName}
                            </p>
                            <p>
                              on{" "}
                              {commonFunction.convertToDate(
                                RecepitsData?.created_at,
                                "MMM DD, Y h:mm A"
                              )}
                            </p>
                            <p>
                              Reference :{" "}
                              {loaderReceipt ? (
                                "Loading..."
                              ) : (
                                <span
                                  style={{
                                    color: "#008cba",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleReservation(
                                      RecepitsData?.reservationObjectId?._id
                                    )
                                  }
                                >
                                  #{RecepitsData?.reservationStringId}
                                </span>
                              )}
                            </p>
                            <p>
                              Booked on :{" "}
                              {commonFunction.convertToDate(
                                RecepitsData?.reservationObjectId
                                  ?.reservationBookTime,
                                "MMM DD, Y h:mm A"
                              )}
                            </p>
                          </article>
                        </Col>

                        <Col lg={14} xs={24}>
                          <address className="invoice-customer">
                            <Heading
                              className="invoice-customer__title"
                              as="h5"
                            >
                              Invoice To:
                            </Heading>
                            <p>
                              {" "}
                              {RecepitsData.customerId?.name
                                ? RecepitsData.customerId?.mobile ||
                                  RecepitsData.customerId?.email
                                  ? `${RecepitsData.customerId?.name} | `
                                  : RecepitsData.customerId?.name
                                : null}
                              {RecepitsData.customerId?.mobile
                                ? RecepitsData.customerId?.email
                                  ? `${RecepitsData.customerId?.mobile} | `
                                  : RecepitsData.customerId?.mobile
                                : null}
                              {RecepitsData.customerId?.email
                                ? RecepitsData.customerId?.email
                                : null}{" "}
                              {RecepitsData.customerId?.shipping_address ? (
                                <br />
                              ) : null}
                              {RecepitsData.customerId?.shipping_address
                                ? RecepitsData.customerId?.city ||
                                  RecepitsData.customerId?.zipcode
                                  ? `${RecepitsData.customerId?.shipping_address} `
                                  : RecepitsData.customerId?.shipping_address
                                : null}
                              {RecepitsData.customerId.city ? <br /> : null}
                              {RecepitsData.customerId?.city
                                ? RecepitsData.customerId?.zipcode
                                  ? `${RecepitsData.customerId?.city}`
                                  : RecepitsData.customerId?.city
                                : null}
                              {RecepitsData.customerId?.zipcode
                                ? ` - ${RecepitsData.customerId?.zipcode}`
                                : null}{" "}
                              {RecepitsData.customerId?.company_name ? (
                                <br />
                              ) : null}
                              {RecepitsData.customerId?.company_name
                                ? `${RecepitsData.customerId?.company_name}`
                                : null}
                              {RecepitsData.customerId?.GSTIN_number ? (
                                <br />
                              ) : null}
                              {RecepitsData.customerId?.GSTIN_number
                                ? `${RecepitsData.customerId?.GSTIN_number}`
                                : null}
                            </p>

                            {RecepitsData.customerId?.company_address
                              ? RecepitsData.customerId?.company_address
                                  .split("\n")
                                  .map((k) => {
                                    return <p>{k}</p>;
                                  })
                              : null}
                          </address>
                        </Col>
                      </Row>
                      <div className="checkinReceipt">
                        <div>
                          <p style={{ margin: 0 }} className="textAlign">
                            Check in
                          </p>
                          <p
                            style={{
                              fontWeight: "700",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              textAlign: "center",
                              margin: 0,
                            }}
                          >
                            {" "}
                            {commonFunction.convertToDate(
                              RecepitsData.reservationObjectId
                                ?.reservationInDateAndTime,
                              "ddd DD MMM YYYY"
                            )}
                          </p>
                        </div>
                        <div>
                          <p style={{ margin: 0 }} className="textAlign">
                            Number of nights
                          </p>
                          <p
                            style={{
                              fontWeight: "700",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              textAlign: "center",
                              margin: 0,
                            }}
                          >
                            {moment(
                              RecepitsData.reservationObjectId
                                ?.reservationOutDateAndTime
                            ).diff(
                              moment(
                                RecepitsData.reservationObjectId
                                  ?.reservationInDateAndTime
                              ),
                              "days"
                            )}
                          </p>
                        </div>
                        <div>
                          <p style={{ margin: 0 }} className="textAlign">
                            {" "}
                            Check out
                          </p>
                          <p
                            style={{
                              fontWeight: "700",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              textAlign: "center",
                              margin: 0,
                            }}
                          >
                            {commonFunction.convertToDate(
                              RecepitsData.reservationObjectId
                                ?.reservationOutDateAndTime,
                              "ddd DD MMM YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </InvoiceLetterBox>

                  <br />
                  <div>
                    <ProductTable>
                      <div className="table-invoice table-responsive">
                        <Table
                          dataSource={RecepitsData?.items}
                          columns={invoiceTableColumns}
                          pagination={false}
                          rowClassName="invoice-table"
                          scroll={{ x: 800 }}
                        />
                      </div>
                    </ProductTable>

                    <Row justify="end" style={{ paddingRight: "17px" }}>
                      <Col xxl={4} xl={5} sm={8} xs={14} offset={rtl ? 0 : 10}>
                        <OrderSummary>
                          <div className="invoice-summary-inner">
                            <ul className="summary-list">
                              <li>
                                <span className="summary-list-title">
                                  Subtotal :
                                </span>

                                <span className="summary-list-text">
                                  {rsSymbol}
                                  {Number(subTotal).toFixed(2)}
                                </span>
                              </li>
                              {Object.values(taxObj)?.length > 0 &&
                                Object.values(taxObj)?.map((val) => {
                                  return (
                                    <li>
                                      <span className="summary-list-title">
                                        {val.name} :
                                      </span>

                                      <span className="summary-list-text">
                                        {rsSymbol}
                                        {Number(val.value).toFixed(2)}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                            <Heading className="summary-total" as="h4">
                              <span className="summary-total-label">
                                Total :
                              </span>
                              <span className="summary-total-amount">
                                {rsSymbol}
                                {Number(RecepitsData?.payment?.total).toFixed(
                                  2
                                )}
                              </span>
                            </Heading>
                          </div>
                        </OrderSummary>
                      </Col>
                      <Col></Col>
                    </Row>

                    <div className="border-top">
                      <Row>
                        Reference No :
                        {[1].map((i, index) => {
                          return (
                            <div>
                              <span>
                                <span
                                  style={{
                                    color: "rgb(0, 140, 186)",
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {
                                    RecepitsData?.reservationObjectId
                                      ?.reservationStringId
                                  }
                                </span>
                              </span>
                            </div>
                          );
                        })}
                      </Row>
                      <Row>
                        {transactionList.map((val) => {
                          return (
                            <>
                              <Col lg={6} md={18} sm={24} offset={0}>
                                <div className="receipt-payment-transactions">
                                  <p>{`${rsSymbol}${
                                    val.value
                                  } on ${capitalizeFirstLetter(val.type)}`}</p>
                                  <p className="text-muted">
                                    {commonFunction.convertToDate(
                                      val.paidTime,
                                      "MMM DD, Y h:mm A"
                                    )}
                                  </p>
                                </div>
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    </div>
                  </div>
                </Cards>
              </Col>
            </Row>
          </Main>
        </>
      ) : (
        <Main>
          <Spin
            style={{
              color: "#BD025D",
              position: "absolute",
              marginLeft: "48%",
              marginTop: "21%",
              transform: "translate(-50%,-50%)",
            }}
          />
        </Main>
      )}
      <Modal
        title="Confirm Cencel"
        visible={deleteReceiptsModalVisible}
        onCancel={() => setDeleteReceiptsModalVisible(false)}
        cancelText="Go Back"
        onOk={deleteReceipts}
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 16,
                    color: "white",
                    margin: "0px 14px",
                  }}
                  spin
                />
              }
            />
          ) : (
            "Cancel Receipt"
          )
        }
      >
        <p>Are you sure cancel invoice ?</p>
      </Modal>
    </div>
  );
};

export default ShowRoomReceipt;
