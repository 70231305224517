import { Switch } from "react-router";

/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    register: "users/register",
    login: "users/login",
    forgotPassword: "users/forgot-password",
    resetPassword: "users/reset-password",
    secretPinAuth: "restaurant/users/secret-pin-auth",
    lockRegister: "restaurant/users/lock",
    forgotPin: "restaurant/users/forgot-pin",
    localStorageStore: "local_Storage_store",
    verifyOtp: "users/verify-user",
    userLogin: "users/user-login",
  },
  products: {
    addProduct: "products",
    getProducts: "products",
    updateProducts: "products",
    deleteAllProduct: "products/deleteAll",
    getCategoryList: "product/category",
    addProductCategory: "product/category",
    getAllOrderTicketGroupList: "product/order_ticket_groups",
    addOrderTicketGroup: "product/order_ticket_groups",
    deleteAllOrderTicketGrouped: "product/order_ticket_groups/deleteAll",
    deleteAllProductCategory: "product/category/deleteAll",
    importProductGroup: "products/import/preview",
    importPreview: "products/import",
    exportProduct: "products/export",
    searchProductList: "products/list",
    topSell: "sales/topList",
    img: "products/productImg",
  },
  variants: {
    getVariantList: "product/variants",
    addVariant: "product/variants",
    importVariant: "product/variants/import/preview",
    importPreview: "product/variants/import",
    deleteAllVariant: "product/variants/deleteAll",
    exportVariant: "product/variants/export",
  },
  addons: {
    getAddonList: "product/addons",
    addAddon: "product/addons",
    importAddon: "product/addons/import/preview",
    importPreview: "product/addons/import",
    deleteAllAddon: "product/addons/deleteAll",
    exportAddon: "product/addons/export",
  },
  variantsGroup: {
    getVariantGroupList: "product/variant_groups",
    addVariantGroup: "product/variant_groups",
    importVariantGroup: "product/variant_groups/import/preview",
    importPreview: "product/variant_groups/import",
    deleteAllVariantGroup: "product/variant_groups/deleteAll",
    exportVariantGroup: "product/variant_groups/export",
  },
  addonsGroup: {
    getAddonGroupList: "product/addon_groups",
    addAddonGroup: "product/addon_groups",
    importAddonGroup: "product/addon_groups/import/preview",
    importPreview: "product/addon_groups/import",
    deleteAllAddonGroup: "product/addon_groups/deleteAll",
    exportAddonGroup: "product/addon_groups/export",
  },
  itemGroup: {
    getItemGroupList: "product/item_groups",
    addItemGroup: "product/item_groups",
    deleteAllItemGroup: "product/item_groups/deleteAll",
  },
  customer: {
    list: "customers",
    export: "customers/export",
    detail: "customers",
    update: "customers",
    add: "customers",
    exportCustomer: "customers/export",
    Customerdetail: "customers",
    Customerupdate: "customers",
    Customeradd: "customers",
    importCustomer: "customers/import/preview",
    importPreview: "customers/import",
    searchList: "customers",
    docImage: "customers/docImg",
  },
  shop: {
    add: "shops",
  },
  enquiry: {
    list: "users/pending",
    changeStatus: "users/changeStatus",
  },
  users: {
    list: "users/all",
    deactiveStatus: "users/changeStatus",
    getUserById: "users/get-profile",
    updateUserDetail: "users/update",
    deleteUser: "users/deleteAll",
    exportUser: "users/export",
    subscribe: "subscription/manual/create",
    filterStatus: "/users/filterStatus",
  },
  pricebook: {
    addPriceBook: "product/price_books",
    getAllRegisters: "registers",
    getAllPriceBook: "product/price_books",
    getPriceBookById: "product/price_books",
    deleteAllPriceBook: "product/price_books/deleteAll",
    updateProductPricebook: "product/price_books/manage",
    getAllPricebookProducts: "products/bypricebook",
    getAllPricebookAddons: "product/addons/bypricebook",
    getAllPricebookVariants: "product/variants/bypricebook",
    exportPricebookProducts: "products/bypricebookexport",
    exportPricebookAddons: "product/addons/bypricebookexport",
    exportPricebookVariants: "product/variants/bypricebookexport",
    importProductList: "products/bypricebookexport/import/preview",
    importProductData: "products/bypricebookexport/import",
    importAddonsList: "product/addons/bypricebookexport/import/preview",
    importAddonsData: "product/addons/bypricebookexport/import",
  },

  taxGroup: {
    getAllTaxGroup: "product/tax_groups",
    getTaxName: "product/taxes",
    addTaxGroup: "product/tax_groups",
    getTaxesById: "product/tax_groups",
    deleteAllTaxGroup: "product/tax_groups/deleteAll",
  },
  taxes: {
    addTaxes: "product/taxes",
    getAllTaxes: "product/taxes",
    getTaxesById: "product/taxes",
    deleteAllTaxes: "product/taxes/deleteAll",
  },
  inventory: {
    addInventory: "inventory",
    getAllInventory: "inventory",
    getInventoryById: "inventory",
    deleteAllInventory: "inventory/deleteAll",
    getAllPosproducts: "posproducts",
    getAllItemList: "inventory/inventory-items",
    addProduct: "inventory/addProduct",
    adjustMentInventroy: "adjustment_inventory/create",
    adjustMentInventoryList: "adjustment_inventory/list",
    adjustMentInventoryReport: "adjustment_inventory/exportReport",
    updatePosProducts: "posproducts/updateAll",
    trackUpdate: "inventory/updateProduct",
    addUpdateInventoryProducts: "inventory/addUpdateInventoryItems",
    addProductInventoryItems: "inventory/addProductInventoryItems",
    addPosProductsLinkInventoryItems:
      "inventory/addPosProductsLinkInventoryItems",
    trackAndUntrackProduct: "inventory/trackProduct",
  },
  cashiers: {
    addCashiers: "restaurant/users",
    getAllCashiers: "restaurant/users",
    deleteAllCashiers: "restaurant/users/deleteAll",
    getcashiersById: "restaurant/users",
  },
  appUser: {
    addAppUser: "restaurant/users",
    getAllAppUser: "restaurant/users",
    deleteAllAppUser: "restaurant/users/deleteAll",
    getAppUserById: "restaurant/users",
  },
  kitchenUser: {
    addKitchenUser: "restaurant/users",
    getAllKitchenUser: "restaurant/users",
    deleteAllKitchenUser: "restaurant/users/deleteAll",
    getKitchenUserById: "restaurant/users",
  },
  waiterUser: {
    addWaiterUser: "restaurant/users",
    getAllWaiterUser: "restaurant/users",
    deleteAllWaiterUser: "restaurant/users/deleteAll",
    getWaiterUserById: "restaurant/users",
  },
  addtionalCharge: {
    addAddtionalCharge: "product/additional_charges",
    getAllAddtionalCharge: "product/additional_charges",
    getAddtionalChargeById: "product/additional_charges",
    deleteAllAddtionalCharge: "product/additional_charges/deleteAll",
  },
  customField: {
    addCustomField: "custom_fields",
    getAllPaymentType: "custom_fields?type=payment_type",
    getAllPattyCash: "custom_fields?type=petty_cash_category",
    getCustomFieldById: "custom_fields",
    deleteAllCustomField: "custom_fields/deleteAll",
    getAllAddtional: "custom_fields?type=additional_detail",
    getAllTag: "custom_fields?type=tag",
  },
  register: {
    getAllRegister: "registers",
    SwitchCurrentRegister: "registers/switch",
    addRegister: "registers",
    connectChannel: "registers/connect/channel",
    deleteAllRegister: "registers/deleteAll",
    getRegisterById: "registers",
    img: "registers/footerImg",
    adminList: "registers/list/admin",
  },
  bingage: {
    addBingage: "bingage/register",
    updateBingage: "bingage/update",
    getAllBingages: "bingage/list",
    deleteAllBingages: "bingage/deleteAll",
  },
  hotel: {
    addhotel: "hotel/register",
    updatehotel: "hotel/update",
    getAllhotels: "hotel/list",
    deleteAllhotels: "hotel/deleteAll",
  },
  prefernce: {
    addPrefernce: "preferences",
    getPreferenceById: "preferences",
  },
  application: {
    addApplication: "applications",
    getApplicationById: "applications",
  },
  pattyCash: {
    addPatty: "petty_cash",
    getAllPatty: "petty_cash",
    getDate: "petty_cash?",
    exportOfPatty: "petty_cash/export",
    deletePatty: "petty_cash",
  },
  recepits: {
    getAllReceipts: "receipts",
    getReceiptsById: "receipts",
    canselorder: "sales/cancel",
    deleteReceipt: "receipts",
    exportSaleReport: "receipts/sales_report_export",
    orderTiketsReport: "receipts/order_tiket_report_export",
    paymentReport: "receipts/payment_report",
    dailySaleAndPaymnetReport: "receipts/daily_sales_and_payment_report",
    productWiseReport: "receipts/productwise_report_export",
    productWise: "receipts/productReport/report",
    shiftsReport: "receipts/shift_open_and_close_report",
    roomInvoice: "receipts/roomInvoice",
  },
  shift: {
    UpdateShift: "shifts",
  },
  discountRules: {
    addDiscountRules: "discount_rules",
    getAlldiscountRules: "discount_rules",
    getDiscountRulesById: "discount_rules",
    deleteAllDiscountRules: "discount_rules/deleteAll",
  },
  sell: {
    getAllTables: "tables",
    createOrder: "sales",
    createBooking: "sales/draft",
    getAllBooking: "sales/getbookings",
    getBookingById: "sales",
    updateBooking: "sales",
    getLastRecepitNumber: "receipts/getLastReceipt",
    getLastDevice: "last_device",
    checkReservationId: "tables/checkReservation",
    checkout: "subscription/checkout/create",
    subscriptionDelete: "subscription/checkout/delete",
    transaction: "transaction",
  },
  dashboard: {
    getAllData: "dashboard",
    test: "dashboard/test",
    expense: "expense/dashboard",
    prchase: "vendorBill/dashboard",
    paymnetMode: "dashboard/paymentmode",
    dailySaleReport: "dashboard/dailySaleReport",
    taxesReport: "dashboard/taxesReport",
  },

  printer: {
    addPrinter: "printers",
    setUp: "printerSetup/add",
    setUpList: "printerSetup/list",
    setUpEdit: "printerSetup",
    deleteAllPrinter: "printers/deleteAll",
  },
  onlineOrder: {
    addDyno: "online_order/register",
    dynoList: "online_order/list",
    deleteAllDyno: "online_order/deleteAll",
    updateDyno: "online_order/update",
    qrOrderList: "qr_order/ordersList",
    acceptOrReject: "qr_order",
  },
  roomType: {
    addroomType: "room_type/create",
    getAllroomType: "room_type/list",
    deleteAllroomType: "room_type/deleteAll",
    updateroomType: "room_type/update",
  },
  room: {
    addRoom: "room/add",
    getAllRoom: "room/list",
    deleteRoom: "room/deleteAll",
    editRoom: "room/update",
  },
  housekeeper: {
    addHousekeeper: "housekeeper/create",
    getAllHousekeeper: "housekeeper/list",
    deleteHousekeeper: "housekeeper/deleteAll",
    editHousekeeper: "housekeeper/update",
  },
  roomLocation: {
    addRoomLocation: "room_location/create",
    updateRoomLocation: "room_location/update",
    getAllRoomLocation: "room_location/list",
    deleteRoomLocation: "room_location/deleteAll",
  },
  rateDate: {
    addUpdateRate: "rate/create",
    multiAdd: "rate/multi/create",
    getAllRates: "rate/list",
  },
  reservation: {
    addReservation: "hotel/reservation/create",
    getAllReservation: "hotel/reservation/list",
    roomReservation: "hotel/roomInfo/reservation",
    checkIn: "hotel/checkin/objectid",
    checkOut: "hotel/checkout/objectid",
    reservationComplated: "hotel/reservation/reservationComplated",
    receipts: "hotel/invoice/list",
    getRoomReceipt: "hotel/invoice/get",
    roomIdByUpdate: "hotel/reservation/roomIdByReservationUpdate",
    customer: "hotel/reservation/singleCustomerUpdate",
    addtionalRoom: "hotel/reservation/addAddtionalRoom",
    addNotes: "hotel/reservation/addNotes",
    updateNotes: "hotel/reservation/updateNotes",
    addInvoice: "hotel/reservation/addInvoice",
    customerFilter: "customers/search",
    getById: "hotel/reservation/getbyobjectid",
    checkRoom: "hotel/roomInfo/check-availability",
    bookedRoom: "hotel/roomInfo/bookRoom",
    dashboard: "hotel/roomInfo/dashboard",
    revenueDashBoard: "hotel/room_management/dashboard",
    getAvailability: "hotel/room_management/dashboard/availability",
  },
  hotelReport: {
    masterList: "hotel/reservation/normal/list",
    masterExport: "hotel/reservation/master/export",
    roomReservation: "hotel/roomInfo/list",
    roomReservationExport: "hotel/roomInfo/export",
    saleReoprt: "hotel/reservation/saleReport",
    roomOccupancy: "hotel/room_management/list",
  },
  vendor: {
    add: "vendor/create",
    get: "vendor/get",
    list: "vendor/list",
    update: "vendor/update",
    deleteAll: "vendor/deleteAll",
    taxAdd: "vendorTax/create",
    taxList: "vendorTax/list",
    taxUpdate: "vendorTax/update",
    taxDeleteAll: "vendorTax/deleteAll",
    itemCategoryAdd: "vendorItemCategory/create",
    itemCategoryFindAndAdd: "vendorItemCategory/add",
    itemCategoryList: "vendorItemCategory/list",
    itemCategoryUpdate: "vendorItemCategory/update",
    itemCategoryAddDeleteAll: "vendorItemCategory/deleteAll",
    itemAdd: "vendorItem/create",
    itemList: "vendorItem/list",
    itemUpdate: "vendorItem/update",
    itemDeleteAll: "vendorItem/deleteAll",
    billUpdate: "vendorBill/update",
    billAdd: "vendorBill/create",
    billList: "vendorBill/list",
    getVendorByPurchase: "vendorBill/vendorByPurchase",
    billDeleteAll: "vendorBill/deleteAll",
    exportReport: "vendorBill/exportReport",
    expenseCategoryAdd: "expenseCategory/create",
    expenseCategoryList: "expenseCategory/list",
    expenseCategoryUpdate: "expenseCategory/update",
    expenseCategoryDeleteAll: "expenseCategory/deleteAll",
    expenseAdd: "expense/create",
    expenseList: "expense/list",
    expenseCancel: "expense/cancel",
    exportExpenseReport: "expense/exportReport",
  },
};

export { API };
