import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Form, Input, Button, message, Spin } from "antd";

import { AuthWrapper } from "./style";
import Heading from "../../../../components/heading/heading";
import {
  forgotPassword,
  logOut,
  logOutForWebsiteLogin,
  login,
} from "../../../../redux/authentication/actionCreator";

const UserLogin = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchData = async () => {
    await dispatch(logOutForWebsiteLogin(history));
    let response = await dispatch(login({}, history, match.params.id));
    if (response) {
      if (response.is_shop) {
        history.push("/pin-auth");
      } else {
        history.push("/settings/shop");
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <AuthWrapper>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spin size="lage" style={{ textAlign: "center", marginTop: "30px" }} />
      </div>
    </AuthWrapper>
  );
};

export { UserLogin };
