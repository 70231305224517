import actions from "./actions";
import { getItem } from "../../utility/localStorageControl";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
import moment from "moment";
const {
  dashboardData,
  dashboardDataErr,
  datewiseChangeData,
  datewiseChangeDataErr,
  saleSummarydatewiseChangeData,
  saleSummarydatewiseChangeDataErr,
} = actions;

export const getAlldashboradData = () => {
  return async (dispatch) => {
    try {
      let startDate = moment().format("YYYY-MM-DD");
      let endDate = moment().format("YYYY-MM-DD");
      let userDetails = getItem("userDetails");
      let pmsregisterId = false;
      let registerList = getItem("setupCache").register;
      let Data = registerList?.find((val) => val.active);

      if (
        userDetails.business_type == "hotel_management" &&
        userDetails.role == "cashier" &&
        userDetails.allow_other_register
      ) {
        if (userDetails.hotel_register_assigned_to) {
          let activeRegisterName = registerList.find(
            (val) => val._id == userDetails.hotel_register_assigned_to
          );
          if (activeRegisterName) {
            pmsregisterId = activeRegisterName._id;
          }
        }
        if (userDetails.register_assigned_to) {
          Data = registerList.find(
            (val) => val._id == userDetails.register_assigned_to
          );
        }
      }
      if (
        userDetails.business_type == "hotel_management" &&
        userDetails.role == "cashier" &&
        !userDetails.allow_other_register
      ) {
        if (userDetails.casier_business_type == "hotel_management") {
          Data = registerList.find(
            (val) => val._id == userDetails.hotel_register_assigned_to
          );
        } else {
          Data = registerList.find(
            (val) => val._id == userDetails.register_assigned_to
          );
        }
      }

      let url =
        userDetails.role == "cashier"
          ? `${
              API.dashboard.getAllData
            }?&startDate=${startDate}&endDate=${endDate}&register_id=${
              Data._id
            }&pmsregisterId=${pmsregisterId}&timeZon=${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }`
          : `${
              API.dashboard.getAllData
            }?&startDate=${startDate}&endDate=${endDate}&register_id=${"allRegister"}&timeZon=${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }`;

      const dashboradDetails = await DataService.get(url);
      if (!dashboradDetails.data.error) {
        return dispatch(dashboardData(dashboradDetails.data.data));
      } else {
        return dispatch(dashboardDataErr(dashboradDetails.data));
      }
    } catch (err) {
      dispatch(dashboardDataErr(err));
    }
  };
};

export const getAlldashboradDataCopy = () => {
  return async (dispatch) => {
    try {
      let Data = getItem("setupCache").register.find((val) => val.active);
      let startDate = moment().format("YYYY-MM-DD");
      let endDate = moment().format("YYYY-MM-DD");
      let url =
        getItem("userDetails").role == "cashier"
          ? `${
              API.dashboard.test
            }?&startDate=${startDate}&endDate=${endDate}&register_id=${
              Data._id
            }&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
          : `${
              API.dashboard.test
            }?&startDate=${startDate}&endDate=${endDate}&register_id=${"allRegister"}&timeZon=${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }`;

      const dashboradDetails = await DataService.get(url);
      if (!dashboradDetails.data.error) {
        return dispatch(dashboardData(dashboradDetails.data.data));
      } else {
        return dispatch(dashboardDataErr(dashboradDetails.data));
      }
    } catch (err) {
      dispatch(dashboardDataErr(err));
    }
  };
};

export const getAlldashboradDatwiseChangeData = (startDate, endDate, id) => {
  return async (dispatch) => {
    try {
      const dashboradDetails = await DataService.get(
        `${API.dashboard.getAllData}?register_id=${id}&timeZon=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      );
      if (!dashboradDetails.data.error) {
        return dispatch(datewiseChangeData(dashboradDetails.data.data));
      } else {
        return dispatch(datewiseChangeDataErr(dashboradDetails.data));
      }
    } catch (err) {
      dispatch(datewiseChangeDataErr(err));
    }
  };
};

export const getSaleSummaryDatwiseChangeData = (
  ab,
  cd,
  type,
  id,
  check,
  pmsregisterId,
  pmsCasierRegisterId
) => {
  return async (dispatch) => {
    try {
      let startDate;
      let endDate;

      if (type == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (type == "yesterday") {
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (type == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (type == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      }
      if (pmsCasierRegisterId) {
        if (pmsCasierRegisterId != "allRegister") {
          id = pmsCasierRegisterId;
          pmsregisterId = false;
        }
      }
      const dashboradDetails = await DataService.get(
        `${
          check == "test" ? API.dashboard.test : API.dashboard.getAllData
        }?startDate=${startDate}&endDate=${endDate}&dateRange=${type}&register_id=${id}&pmsregisterId=${pmsregisterId}&timeZon=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      );
      if (!dashboradDetails.data.error) {
        return dispatch(
          saleSummarydatewiseChangeData(dashboradDetails.data.data)
        );
      } else {
        return dispatch(
          saleSummarydatewiseChangeDataErr(dashboradDetails.data)
        );
      }
    } catch (err) {
      dispatch(datewiseChangeDataErr(err));
    }
  };
};

export const getExpensesTotal = (type, id) => {
  return async (dispatch) => {
    try {
      let startDate;
      let endDate;

      if (type == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (type == "yesterday") {
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (type == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (type == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      }

      const dashboradDetails = await DataService.get(
        `${
          API.dashboard.expense
        }?startDate=${startDate}&endDate=${endDate}&dateRange=${type}&register_id=${id}&timeZon=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      );
      if (!dashboradDetails.data.error) {
        return dashboradDetails.data;
      } else {
        return dashboradDetails.data;
      }
    } catch (err) {
      dispatch(datewiseChangeDataErr(err));
    }
  };
};

export const getPurchaseTotal = (type, id) => {
  return async (dispatch) => {
    try {
      let startDate;
      let endDate;

      if (type == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (type == "yesterday") {
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (type == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (type == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      }

      const dashboradDetails = await DataService.get(
        `${
          API.dashboard.prchase
        }?startDate=${startDate}&endDate=${endDate}&dateRange=${type}&register_id=${id}&timeZon=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      );
      if (!dashboradDetails.data.error) {
        return dashboradDetails.data;
      } else {
        return dashboradDetails.data;
      }
    } catch (err) {
      dispatch(datewiseChangeDataErr(err));
    }
  };
};
function isBetweenGivenDates(myStartDate, myEndDate) {
  // Convert strings to Date objects
  let startDate = new Date(
    moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  let endDate = new Date(
    moment()
      .endOf("month")
      .format("YYYY-MM-DD")
  );
  console.log("djhasdjadakdhadhadadad0", startDate, endDate);
  myStartDate = new Date(myStartDate);
  myEndDate = new Date(myEndDate);

  // Check if myStartDate and myEndDate fall between startDate and endDate
  return myStartDate >= startDate && myEndDate <= endDate;
}

export const getPaymnetMode = (startDate, endDate, id, check) => {
  return async (dispatch) => {
    try {
      let checkIsLastMonth = isBetweenGivenDates(startDate, endDate);
      console.log("checkIsLastMonth", checkIsLastMonth);
      const dashboradDetails = await DataService.get(
        `${
          check == "payment"
            ? API.dashboard.paymnetMode
            : check == "taxes"
            ? API.dashboard.taxesReport
            : API.dashboard.dailySaleReport
        }?startDate=${startDate}&endDate=${endDate}&register_id=${id}&checkIsLastMonth=${checkIsLastMonth}&timeZon=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      );
      if (!dashboradDetails.data.error) {
        return dispatch(
          saleSummarydatewiseChangeData(dashboradDetails.data.data)
        );
      } else {
        return dispatch(
          saleSummarydatewiseChangeDataErr(dashboradDetails.data)
        );
      }
    } catch (err) {
      dispatch(datewiseChangeDataErr(err));
    }
  };
};

export const getProductWise = (startDate, endDate, id, check) => {
  return async (dispatch) => {
    try {
      const dashboradDetails = await DataService.get(
        `${
          API.recepits.productWise
        }?startDate=${startDate}&endDate=${endDate}&register_id=${id}&timeZon=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`
      );
      if (!dashboradDetails.data.error) {
        return dispatch(
          saleSummarydatewiseChangeData(dashboradDetails.data.data)
        );
      } else {
        return dispatch(
          saleSummarydatewiseChangeDataErr(dashboradDetails.data)
        );
      }
    } catch (err) {
      dispatch(datewiseChangeDataErr(err));
    }
  };
};
