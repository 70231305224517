import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  NavLink,
  useRouteMatch,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Checkbox,
  InputNumber,
  Spin,
  Button,
  Modal,
  Form,
  Popover,
} from "antd";
import "../../product.css";
import {
  getAllPricebookAddonList,
  exportPricebookAddons,
  UpdateProductPriceBook,
} from "../../../../redux/pricebook/actionCreator";
import {
  SearchOutlined,
  ImportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { getAllProductList } from "../../../../redux/products/actionCreator";

const AddonPrice = forwardRef((props, ref) => {
  let { passFun } = props;
  const dispatch = useDispatch();
  const [AddonsListData, setAddonsListData] = useState([]);
  let isMounted = useRef(true);
  const [editPrice, setEditPrice] = useState(false);
  let [editItemList, setEditItemList] = useState([]);
  const location = useLocation();
  const queryParams = useParams();
  const [loading, setloading] = useState(true);
  const [editPriceValue, setEditPriceValue] = useState(false);
  let [search, setsearch] = useState("");
  const [form] = Form.useForm();
  const history = useHistory();

  const [modalVisible1, setModelVisible1] = useState(false);
  const [ExportType, SetExportType] = useState("");
  const [modalVisible, setModelVisible] = useState(false);
  let email = localStorage.getItem("email_id");
  const deviceWidth = localStorage.getItem("windowWidth");
  // console.log("windowWidth:::---addon pricebook----->>>>>>>>>>::", localStorage.getItem("windowWidth"))

  useEffect(() => {
    setloading(true);
    if (isMounted.current) {
      fetchAddonsList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchAddonsList = async () => {
    const getAddonsList = await dispatch(
      getAllPricebookAddonList(queryParams.pricebook_id)
    );
    if (isMounted.current && getAddonsList && getAddonsList.PricebookAddonList) {
      setEditItemList([]);
      setAddonsListData(getAddonsList.PricebookAddonList);
      setEditPriceValue(false);
    }
    setloading(false);
  };

  const [state, setState] = useState({
    item: "",
  });

  // useImperativeHandle(ref, () => ({
  //   async Addonslist() {
  //     const getAddonsList = await dispatch(
  //       getAllPricebookAddonList(queryParams.pricebook_id)
  //     );
  //     if (getAddonsList && getAddonsList.PricebookAddonList)
  //       setAddonsListData(getAddonsList.PricebookAddonList);
  //     return getAddonsList.PricebookAddonList;
  //   },
  // }));

  const { selectedRowKeys, item } = state;

  passFun(editItemList);

  useEffect(() => {
    if (AddonsListData) {
      setState({
        item: AddonsListData,
        selectedRowKeys,
      });
    }
  }, [AddonsListData, selectedRowKeys]);
  function pushToArray(editItemList, obj) {
    const index = editItemList.findIndex((e) => e.addon_id === obj.addon_id);
    if (index === -1) {
      setEditItemList([...editItemList, obj]);
    } else {
      editItemList[index].addon_id = obj.addon_id;
      obj.price && (editItemList[index].price = obj.price);
      obj.disable && (editItemList[index].disable = obj.disable);
    }
  }

  function onChange(value, editPrice) {
    const findIndex = item.findIndex((product) => product._id === editPrice);
    pushToArray(editItemList, {
      addon_id: editPrice,
      price: value,
      disable: item[findIndex].is_disabled ? true : false,
    });
    item[findIndex].price = value;
  }

  function checkboxChange(e, id) {
    const findIndex = item.findIndex((product) => product._id === id);
    pushToArray(editItemList, {
      addon_id: id,
      disable: e.target.checked,
      price: item[findIndex].price,
    });
    item[findIndex].disabled = e.target.checked;
  }
  const dataSource = [];

  if (AddonsListData.length)
    item.map((value) => {
      const {
        _id,
        addon_name,
        addon_groups,
        price,
        is_disabled,
        is_price_overridden,
      } = value;

      return dataSource.push({
        id: _id,
        addon_name: addon_name,
        is_disabled: is_disabled,
        addon_group: addon_groups,
        addon_price: price,
        is_price_overridden: is_price_overridden ? is_price_overridden : "-",
      });
    });

  // if (search !== '') {
  const searchDataSource = [];
  let searchArrTaxes = AddonsListData.filter((value) =>
    value.addon_name.toLowerCase().includes(search.toLowerCase())
  );

  if (searchArrTaxes.length)
    searchArrTaxes.map((value) => {
      const {
        _id,
        addon_name,
        addon_groups,
        price,
        is_disabled,
        is_price_overridden,
      } = value;
      return searchDataSource.push({
        id: _id,
        addon_name: addon_name,
        is_disabled: is_disabled,
        addon_group: addon_groups,
        addon_price: price,
        is_price_overridden: is_price_overridden ? is_price_overridden : "-",
      });
    });
  // }
  const columns = [
    {
      title: "Addon Name",
      dataIndex: "addon_name",
      key: "addon_name",
      render(text, record) {
        return {
          children: (
            <div
              style={{ color: "#008cba" }}
              onClick={() => setEditPrice(false)}
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "Addon Groups",
      dataIndex: "addon_group",
      key: "addon_group",
      render(text, record) {
        return {
          children: (
            <div
              style={{ color: "#008cba" }}
              onClick={() => setEditPrice(false)}
            >
              {text.map((value, index) => {
                return (
                  <>
                    {index > 0 ? (
                      <span>{value + " ,"}</span>
                    ) : (
                      <span>{value}</span>
                    )}
                  </>
                );
              })}
            </div>
          ),
        };
      },
    },
    {
      title: "Addon Price",
      dataIndex: "addon_price",
      key: "addon_price",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: (
            <div onClick={() => setEditPriceValue(true)}>
              {editPrice === record.id && editPriceValue == true ? (
                <InputNumber
                  size="small"
                  className="product_price_input"
                  defaultValue={text}
                  onPressEnter={() => setEditPrice(false)}
                  onChange={(value) => onChange(value, editPrice)}
                />
              ) : (
                <div
                  style={{ borderBottom: "1px dashed #a8b9ce", width: "100px" }}
                  onClick={() => setEditPrice(record.id)}
                >
                  {text}
                </div>
              )}
            </div>
          ),
        };
      },
    },

    {
      title: "Is Price Overridden?",
      dataIndex: "is_price_overridden",
      key: "is_price_overridden",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: <div onClick={() => setEditPrice(false)}>{text}</div>,
        };
      },
    },
    {
      title: "Is Disabled?",
      dataIndex: "is_disabled",
      key: "is_disabled",
      render(text, record) {
        return {
          // props: {
          //   style: { textAlign: "center" },
          // },
          children: (
            <div onClick={() => setEditPrice(false)}>
              <Checkbox
                defaultChecked={text}
                onChange={(value) => checkboxChange(value, record.id)}
              />
            </div>
          ),
        };
      },
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const handleUpdateProductList = async () => {
    setloading(true);
    if (editItemList.length > 0) {
      let obj;
      editItemList[0].addon_id && (obj = { addons: editItemList });
      const getAllUpdateData = await dispatch(
        UpdateProductPriceBook(obj, queryParams.pricebook_id)
      );
      if (getAllUpdateData && getAllUpdateData.UpdateProductData) {
        if (obj.addons) {
          setModelVisible(false);
          fetchAddonsList();
          await dispatch(getAllProductList());
        }
      }
    }
  };

  const submitExport = (values) => {
    values.type = ExportType;
    if (ExportType) {
      dispatch(exportPricebookAddons(values, queryParams.pricebook_id));
      setModelVisible1(false);
    }
  };

  const handleCancel = (e) => {
    setModelVisible1(false);
    setModelVisible(false);
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible1(true);
          SetExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  return (
    <div>
      <Row gutter={15} className="list-row">
        <Col span={24} className="pricebookTableBox">
          <div className="priceBook_titles_content">
            <div className="productCount">
              <h2>Addon</h2>
              <span className="title-counter" style={{ marginTop: "-17px" }}>
                {AddonsListData.length} Addons{" "}
              </span>
            </div>
            <div
              style={{
                boxShadow: "none",
                marginBottom: "16px",
                marginLeft: "20px",
              }}
              className="table_search"
            >
              <Input
                suffix={<SearchOutlined />}
                className="set_serbt"
                autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                placeholder="Search by addon name"
                style={{
                  borderRadius: "30px",
                  width: "250px",
                }}
                onChange={(e) => setsearch(e.target.value)}
                value={search}
              />
            </div>
          </div>
          <div className="pricebookTables-toolbox-menu">
            <div key="1" className="page-header-actions">
              {/* <NavLink
                to={`pricebook-items/addon/import/${queryParams.pricebook_id}`}
                className="ant-btn ant-btn-white ant-btn-md"
              >
                <ImportOutlined /> Import
              </NavLink>
              <Popover placement="bottomLeft" content={content} trigger="hover">
                <Button size="small" type="white">
                  <FeatherIcon icon="download" size={14} />
                  Export
                </Button>
              </Popover> */}
              <Button
                size="middle"
                type="primary"
                Style={{ padding: 10 }}
                onClick={() => history.push("/products/pricebook")}
              >
                Go back
              </Button>
              <Button
                size="middle"
                type="primary"
                Style={{ padding: 10 }}
                onClick={() => setModelVisible(true)}
              >
                Save Addon
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      {loading == true ? (
        <>
          <Table
            rowKey="id"
            size="small"
            locale={locale}
            dataSource={[]}
            columns={columns}
            fixed={true}
            scroll={{ x: 800 }}
          />
        </>
      ) : (
        <Table
          rowKey="id"
          size="small"
          dataSource={dataSource.filter((value) =>
            value.addon_name.toLowerCase().includes(search.toLowerCase())
          )}
          columns={columns}
          fixed={true}
          scroll={{ x: 800 }}
          pagination={{
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
            showSizeChanger: true,
            total: dataSource.filter((value) =>
              value.addon_name.toLowerCase().includes(search.toLowerCase())
            ).length,
          }}
        />
      )}

      <Modal
        title="Products"
        visible={modalVisible1}
        width={600}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form name="export" form={form} onFinish={submitExport}>
          <div className="add-product-block">
            <div className="add-product-content">
              <Form.Item
                name="email"
                initialValue={email ? email : ""}
                label="Send to Email Address"
                rules={[
                  {
                    message: "Email address is required",
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Save Addons"
        visible={modalVisible}
        onOk={handleUpdateProductList}
        onCancel={handleCancel}
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                  spin
                />
              }
            />
          ) : (
            "Save Addons"
          )
        }
        width={600}
      >
        <p>Are you sure you want to Save Addons"</p>
      </Modal>
    </div>
  );
});

export { AddonPrice };
