import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col, Table, Input, Modal, Button, Form, message } from "antd";
import {
  SearchOutlined,
  ImportOutlined,
  CaretDownOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { Main, TableWrapper } from "../../styled";
import { Popover } from "../../../components/popup/popup";
import { Cards } from "../../../components/cards/frame/cards-frame";
import "../option.css";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  getAllVariantList,
  deleteVariant,
  ExportVariant,
} from "../../../redux/variant/actionCreator";
import { UserTableStyleWrapper } from "../../pages/style";
import { getItem } from "../../../utility/localStorageControl";
import {
  deleteVendorItemCategory,
  getVendorItemCategoryList,
} from "../../../redux/vendor/actionCreator";

const VendorItemCategoryList = (props) => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  const dispatch = useDispatch();
  let [search, setSearch] = useState("");
  const history = useHistory();
  const [selectionType] = useState("checkbox");
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(true);
  const deviceWidth = localStorage.getItem("windowWidth");
  const [vendorList, setVendorList] = useState([]);

  const [modalVisible, setModelVisible] = useState(false);
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ExportType, SetExportType] = useState("");
  const [state, setState] = useState({});
  const getVendorListData = async () => {
    setLoader(true);
    let response = await dispatch(getVendorItemCategoryList());
    if (response && !response.error) {
      setVendorList(response.data);
      setLoader(false);
    } else {
      setLoader(false);
      message.error(response.message);
    }
  };

  useEffect(() => {
    getVendorListData();
  }, [currentRegisterData?._id]);

  const handleCancel = (e) => {
    setModelVisible(false);
    setModelDeleteVisible(false);
  };

  const deleteSelectedVendor = async () => {
    setLoading(true);
    const { allSelectedRowsForDelete } = state;

    let allvariantdsForDelete = [];
    if (allSelectedRowsForDelete && allSelectedRowsForDelete.length > 0) {
      allSelectedRowsForDelete.map((item) => {
        allvariantdsForDelete.push(item._id);
      });

      const getDeletedVariant = await dispatch(
        deleteVendorItemCategory({ ids: allvariantdsForDelete })
      );
      if (getDeletedVariant && !getDeletedVariant.error) {
        getVendorListData();
        setModelDeleteVisible(false);
        setState({
          ...state,
          selectedRows: [],
        });
      }
    }
  };

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setModelDeleteVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Delete Selected item</span>
      </NavLink>
    </>
  );
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForDelete: selectedRows,
      });
    },
  };
  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined style={{ marginLeft: "12px" }} />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "4%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      className: "products_list_fix",
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "mobile",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text ? text : "-"}</div>,
        };
      },
    },
  ];

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  return (
    <>
      <Main style={{ paddingTop: "10px" }}>
        <Row gutter={15} className="list-row">
          <Col span={24} className="pricebookTableBox">
            <div className="priceBook_titles_content">
              <div className="productCount">
                <h2>Categories</h2>
                <span className="title-counter" style={{ marginTop: "-17px" }}>
                  {vendorList.length} Categories{" "}
                </span>
              </div>
              <div
                style={{
                  boxShadow: "none",
                  marginBottom: "16px",
                  marginLeft: "20px",
                }}
                className="table_search"
              >
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Search by Name"
                  autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>

            <div className="pricebookTables-toolbox-menu">
              <div
                key="1"
                className="page-header-actions"
                style={{ marginRight: 10 }}
              >
                <NavLink
                  to="/vendor/items/categories"
                  className="ant-btn ant-btn-md btn-cancel btn"
                >
                  <ArrowLeftOutlined style={{ marginRight: "8px" }} />
                  Go Back
                </NavLink>
              </div>
              <div key="1" className="page-header-actions">
                <NavLink
                  to="/vendor/itemCategory/add"
                  className="ant-btn ant-btn-primary ant-btn-md"
                >
                  <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                  Add Category
                </NavLink>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={25}>
          <Col md={24} xs={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        rowKey="id"
                        locale={locale}
                        className="products_lsttable"
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                        pagination={false}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        className="products_lsttable"
                        rowSelection={{
                          type: selectionType,
                          ...rowSelection,
                        }}
                        onRow={(row) => ({
                          onClick: () =>
                            history.push(`/vendor/itemCategory/add`, {
                              vendorDetails: row,
                            }),
                        })}
                        size="small"
                        dataSource={vendorList?.filter((value) =>
                          value.name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                        pagination={{
                          showSizeChanger: true,
                          total: vendorList.length,
                          pageSizeOptions: ["10", "50", "100", "500", "1000"],
                        }}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Cards>
            <p style={{ display: "none" }}>{loading}</p>
            <Modal
              title="Confirm Delete"
              okText={
                loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 16,
                          color: "white",
                          margin: "0px 14px",
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  "Delete"
                )
              }
              visible={modalDeleteVisible}
              onOk={deleteSelectedVendor}
              onCancel={handleCancel}
              width={600}
            >
              <p>Are you sure you want to delete selected vendors tax ?</p>
            </Modal>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export { VendorItemCategoryList };
