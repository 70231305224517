import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { VendorList } from "./Vendor/VendorList";
import { VendorTaxList } from "../Vendors/VendorTax/VendorTaxList";
import { setItem, getItem } from "../../utility/localStorageControl";
const VendorsBuild = (props) => {
  const { TabPane } = Tabs;
  const search = new URLSearchParams(props.location.search);
  let type = search.get("type");
  const [activeTab, changeTab] = useState(
    type == "tax" ? "VENDOR_TAX" : "VENDOR"
  );
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  // console.log("windowWidth:::---product option-builder---->>>>>>>>>>::", getItem("windowWidth"))

  return (
    <>
      <Main>
        <PageHeader
          className="comman-custom-pageheader"
          size="small"
          ghost
          title={
            <>
              <Tabs
                type="card"
                activeKey={activeTab}
                size="small"
                onChange={changeTab}
              >
                <TabPane tab="Vendor" key="VENDOR"></TabPane>
                <TabPane tab="Vendor tax" key="VENDOR_TAX"></TabPane>
              </Tabs>
            </>
          }
        />

        {activeTab === "VENDOR" ? <VendorList /> : ""}
        {activeTab === "VENDOR_TAX" ? <VendorTaxList /> : ""}
      </Main>
    </>
  );
};

export default VendorsBuild;
