import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Space,
  Spin,
  Tooltip,
  Checkbox,
  Tabs,
} from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "./Style";
import { UserTableStyleWrapper } from "../pages/style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../styled";
import { Button } from "../../components/buttons/buttons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";

import {
  getAllReceiptsList,
  getDateWiseReceptsList,
} from "../../redux/receipts/actionCreator";
import { Popover } from "../../components/popup/popup";
import Exportform from "./Exportform";
import commonFunction from "../../utility/commonFunctions";
import "./receipt.css";
import { getItem, setItem } from "../../utility/localStorageControl";
import moment from "moment";
import {
  ExclamationCircleOutlined,
  DownOutlined,
  FilterFilled,
} from "@ant-design/icons";
import TabPane from "antd/lib/tabs/TabPane";
import RoomReceipts from "./roomReceipts";

const { Search } = Input;

const Receipts = (props) => {
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const userDetails = getItem("userDetails");
  const search1 = new URLSearchParams(props.location.search);
  let type = search1.get("type");
  console.log("typetype", type);
  let [activeTab, changeTab] = useState(
    userDetails?.business_type == "hotel_management"
      ? userDetails.casier_business_type == "hotel_management"
        ? "ROOM"
        : type == "pms"
        ? "ROOM"
        : "ORDER"
      : type == "pms"
      ? "ROOM"
      : "ORDER"
  );
  const history = useHistory();
  let searchInput = useRef(null);
  const dispatch = useDispatch();
  let [search, setsearch] = useState("");
  const [state, setState] = useState();
  const [reportType, setReportType] = useState();
  const {
    totalReceipts,
    currentRegister,
    ReceiptsListDeatils,
    startDate,
    endDate,
    loader,
  } = useSelector(
    (state) => ({
      ReceiptsListDeatils: state.receipts.ReceiptsList,
      totalReceipts: state.receipts.totalReceipts,
      startDate: state.receipts.startDate,
      endDate: state.receipts.endDate,
      currentRegister: state.register.RegisterList.find((val) => val.active),
      loader: state.receipts.receiptLoader,
    }),
    shallowEqual
  );
  const [loading, setloading] = useState(true);
  const [ReceiptsList, setReceiptList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [dataListUpdate, setDataListUpdate] = useState(false);

  const [paymentStatus, setPaymentStatus] = useState(undefined);
  const [receiptNum, setReceiptNum] = useState(undefined);

  const [openPopover, setOpenPopover] = useState(false);

  const [checkedVal, setCheckedVal] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(async () => {
    if (startDate !== undefined) {
      const clearDate = await dispatch(getDateWiseReceptsList("", ""));
      if (clearDate?.start === "") {
        setDataListUpdate(true);
        receiptDataList();
      } else {
        setDataListUpdate(false);
      }
    }
  }, []);

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  useEffect(async () => {
    if (activeTab == "ORDER") {
      setloading(true);
      receiptDataList(
        currentPage,
        startDate,
        endDate,
        paymentStatus,
        receiptNum
      );
    }
  }, [currentRegister, currentPage, paymentStatus, receiptNum, activeTab]);

  const receiptDataList = async (
    currentPage,
    startDate,
    endDate,
    payment_status,
    receipt_number
  ) => {
    const receiptList = await dispatch(
      getAllReceiptsList(
        currentPage,
        10,
        startDate,
        endDate,
        payment_status,
        receipt_number
      )
    );
    if (receiptList?.ReceiptsList) {
      setloading(false);
    }
  };

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      setloading(loader);
    } else {
      didMount.current = true;
    }
  }, [loader]);

  const onSearch = (val) => {
    setReceiptNum(val);
  };

  const onChangeFilter = (e) => {
    setChecked(e.target.checked);
    e.target.checked == true
      ? setCheckedVal("unpaid")
      : setCheckedVal(undefined);
  };
  const ResetFilter = () => {
    setOpenPopover(false);
    setChecked(false);
    setCheckedVal(undefined);
    setPaymentStatus(undefined);
  };
  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const onPaymentFilter = (val) => {
    setPaymentStatus(val);
    setOpenPopover(false);
  };

  const [modalVisible, setModelVisible] = useState(false);
  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setReportType("CSV");
          setModelVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  const dataSource = [];

  if (ReceiptsListDeatils.length)
    ReceiptsListDeatils.map((value) => {
      const { _id, receipt_number, order_details } = value;
      let created_at;

      if (order_details?.details?.orderTicketsData?.length > 0) {
        created_at = order_details.details.orderTicketsData[0].enterDate;
      } else {
        created_at = order_details.actual_time;
      }
      return dataSource.push({
        id: _id,
        created: created_at,
        receipt_number: receipt_number,
        customer_mobile: order_details?.customer
          ? order_details?.customer.mobile
            ? order_details?.customer.mobile
            : "-"
          : "-",
        payment_status:
          order_details?.details?.immediate_sale?.multiple_payments_type,
        total: order_details?.details.priceSummery?.total,
        fulfillmentStatus: order_details?.details.fulfillmentStatus,
        cancelReceipt: order_details?.cancellationStatus ? true : false,
        channel: order_details?.details.tableName,
      });
    });

  let lastIndex = 0;
  const updateIndex = () => {
    lastIndex++;
    return lastIndex;
  };
  const columns = [
    {
      title: "Bill Number",
      dataIndex: "receipt_number",
      key: "receipt_number",
      width: "25%",
      className: "center-col-padding",
      render(text, record) {
        let path = `#`;
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: record.cancelReceipt ? (
            <NavLink
              to={path}
              style={{
                textDecoration: "line-through",
                color: "#008cba",
              }}
            >
              {text}
            </NavLink>
          ) : (
            <NavLink to={path} style={{ color: "#008cba" }}>
              {text}
            </NavLink>
          ),
        };
      },
    },
    {
      title: "Created At",
      dataIndex: "created",
      key: `created${updateIndex()}`,
      width: "20%",
      render(created_at, record) {
        return {
          children: record.cancelReceipt ? (
            <span style={{ textDecoration: "line-through" }}>
              {commonFunction.convertToDate(created_at, "MMM DD, Y, h:mm A")}
            </span>
          ) : (
            <span>
              {commonFunction.convertToDate(created_at, "MMM DD, Y, h:mm A")}
            </span>
          ),
        };
      },
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: `channel`,
      width: "15%",
      render(text, record) {
        return {
          children: record.cancelReceipt ? (
            <div style={{ textDecoration: "line-through" }}> {text}</div>
          ) : (
            <div>{text}</div>
          ),
        };
      },
    },
    {
      title: "Customer Mobile",
      dataIndex: "customer_mobile",
      key: `customer_mobile${updateIndex()}`,
      width: "15%",
      // ...getColumnSearchProps("customer_mobile"),
      render(text, record) {
        return {
          children: record.cancelReceipt ? (
            <div style={{ textDecoration: "line-through" }}> {text}</div>
          ) : (
            <div>{text}</div>
          ),
        };
      },
    },
    {
      title: (
        <div className="filterContainer">
          Payment
          <Popover
            className="receiptPopover"
            content={
              <div className="filterPopoverFooter">
                <p onClick={ResetFilter} className="filterReset">
                  Reset
                </p>
                <p
                  onClick={() => onPaymentFilter(checkedVal)}
                  className="filterOk"
                >
                  Ok
                </p>
              </div>
            }
            title={
              <Checkbox onChange={onChangeFilter} checked={checked}>
                Unpaid
              </Checkbox>
            }
            trigger="click"
            placement="bottom"
            open={openPopover}
            onOpenChange={handleOpenChange}
          >
            <FilterFilled style={{ color: checked ? "#bd025d" : "#5A5F7D" }} />
          </Popover>
        </div>
      ),
      dataIndex: "payment_status",
      width: "15%",
      key: `payment_status${updateIndex()}`,
      render(text, record) {
        if (record?.payment_status && record?.payment_status.length) {
          return record?.payment_status?.length > 1 ? (
            <div className="multiple_paymentType">
              <p>Split</p>
              <Tooltip
                title={
                  <div className="payment_types">
                    {record?.payment_status
                      ?.map((item, i) => {
                        return item.name === "other"
                          ? "Other"
                          : item.name === "UPI"
                          ? "UPI"
                          : item.name === "cash"
                          ? "Cash"
                          : item.name === "card"
                          ? "Card"
                          : item.name === "pending"
                          ? "Unpaid"
                          : item.name;
                      })
                      .join(" , ")}
                  </div>
                }
              >
                <ExclamationCircleOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
            </div>
          ) : record?.payment_status[0]?.name === "other" ? (
            "Other"
          ) : record?.payment_status[0]?.name === "UPI" ? (
            "UPI"
          ) : record?.payment_status[0]?.name === "cash" ? (
            "Cash"
          ) : record?.payment_status[0]?.name === "pending" ? (
            "Unpaid"
          ) : record?.payment_status[0]?.name === "card" ? (
            "Card"
          ) : (
            record?.payment_status[0]?.name
          );
        }
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "10%",
      align: "left",
      render(text, record) {
        return {
          children: record.cancelReceipt ? (
            <div style={{ textDecoration: "line-through" }}>
              {rsSymbol}
              {Number(text).toFixed(2)}
            </div>
          ) : (
            <div>
              {rsSymbol}
              {Number(text).toFixed(2)}
            </div>
          ),
        };
      },
    },
  ];
  let changePageData = async (value, limit) => {
    setloading(true);
    const receiptList = await dispatch(getAllReceiptsList(value, limit));
    if (receiptList?.ReceiptsList) {
      setloading(false);
    }
  };

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  return (
    <>
      <Main>
        {userDetails?.business_type == "hotel_management" && (
          <Tabs
            className="sell-tabs"
            type="card"
            activeKey={activeTab}
            size="small"
            onChange={(val) => {
              changeTab(val);
            }}
          >
            {userDetails.role == "cashier" ? (
              userDetails.casier_business_type == "food_and_drink" ? (
                <TabPane tab="POS" key="ORDER" style={{ outline: "none" }} />
              ) : (
                userDetails?.register_assigned_to &&
                userDetails.allow_other_register && (
                  <TabPane tab="POS" key="ORDER" style={{ outline: "none" }} />
                )
              )
            ) : (
              <TabPane tab="POS" key="ORDER" style={{ outline: "none" }} />
            )}
            {userDetails.role == "cashier" ? (
              userDetails.casier_business_type == "hotel_management" ? (
                <TabPane tab="PMS" key="ROOM" style={{ outline: "none" }} />
              ) : (
                userDetails?.hotel_register_assigned_to &&
                userDetails.allow_other_register && (
                  <TabPane tab="PMS" key="ROOM" style={{ outline: "none" }} />
                )
              )
            ) : (
              <TabPane tab="PMS" key="ROOM" style={{ outline: "none" }} />
            )}
          </Tabs>
        )}
        {activeTab == "ORDER" ? (
          <div>
            {userDetails?.business_type != "hotel_management" && (
              <CardToolbox>
                <PageHeader
                  ghost
                  title=""
                  subTitle={
                    <>
                      <div className="table_titles">
                        <h2>Receipts</h2>
                      </div>

                      <Search
                        className="receipts-search"
                        placeholder="Search by Bill Number"
                        enterButton
                        onSearch={(e) => onSearch(e)}
                        suffix={<SearchOutlined style={{ fontSize: "20px" }} />}
                        allowClear={{
                          clearIcon: <CloseOutlined />,
                        }}
                      />
                    </>
                  }
                  buttons={[
                    <div key="1" className="page-header-actions">
                      <Tooltip
                        title={
                          <p
                            style={{
                              textAlign: "center",
                              marginTop: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            Showing receipts from{" "}
                            {startDate
                              ? moment(startDate).format("MMMM Do YYYY")
                              : moment()
                                  .startOf("month")
                                  .format("MMMM Do YYYY")}
                            {" to"}{" "}
                            {endDate
                              ? moment(endDate).format("MMMM Do YYYY")
                              : moment().format("MMMM Do YYYY")}
                          </p>
                        }
                      >
                        <ExclamationCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            fontSize: "18px",
                          }}
                        />
                      </Tooltip>
                      <CalendarButtonPageHeader key="1" type="receipts" />
                      {
                        <Popover
                          placement="bottomLeft"
                          content={content}
                          trigger="hover"
                        >
                          <Button
                            size="small"
                            type="white"
                            style={{ border: "1px solid #e3e6ef !important" }}
                          >
                            <FeatherIcon icon="download" size={14} />
                            Export
                          </Button>
                        </Popover>
                      }
                    </div>,
                  ]}
                />
              </CardToolbox>
            )}

            <Row gutter={15}>
              <Col md={24}>
                <Cards headless>
                  {userDetails?.business_type == "hotel_management" && (
                    <Row gutter={15} className="list-row">
                      <Col span={24} className="pricebookTableBox">
                        <div className="priceBook_titles_content">
                          <div className="productCount">
                            <h2>Receipts</h2>
                          </div>
                          <div
                            style={{
                              boxShadow: "none",
                              marginBottom: "16px",
                              marginLeft: "-10px",
                            }}
                            className="table_search"
                          >
                            <Search
                              className="receipts-search"
                              placeholder="Search by Bill Number"
                              enterButton
                              onSearch={(e) => onSearch(e)}
                              suffix={
                                <SearchOutlined style={{ fontSize: "20px" }} />
                              }
                              allowClear={{
                                clearIcon: <CloseOutlined />,
                              }}
                            />
                          </div>
                        </div>
                        <div key="1" className="page-header-actions">
                          <Tooltip
                            title={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                Showing receipts from{" "}
                                {startDate
                                  ? moment(startDate).format("MMMM Do YYYY")
                                  : moment()
                                      .startOf("month")
                                      .format("MMMM Do YYYY")}
                                {" to"}{" "}
                                {endDate
                                  ? moment(endDate).format("MMMM Do YYYY")
                                  : moment().format("MMMM Do YYYY")}
                              </p>
                            }
                          >
                            <ExclamationCircleOutlined
                              style={{
                                cursor: "pointer",
                                marginRight: "10px",
                                fontSize: "18px",
                              }}
                            />
                          </Tooltip>
                          <CalendarButtonPageHeader key="1" type="receipts" />
                          {
                            <Popover
                              placement="bottomLeft"
                              content={content}
                              trigger="hover"
                            >
                              <Button
                                size="small"
                                type="white"
                                style={{ border: "1px solid #e3e6ef" }}
                              >
                                <FeatherIcon icon="download" size={14} />
                                Export
                              </Button>
                            </Popover>
                          }
                        </div>
                      </Col>
                    </Row>
                  )}

                  <UserTableStyleWrapper>
                    <div className="contact-table">
                      <TableWrapper className="table-responsive">
                        {loading ? (
                          <>
                            <Table
                              className="receipt-custom-table"
                              locale={locale}
                              rowKey="id"
                              size="small"
                              dataSource={[]}
                              columns={columns}
                              fixed={true}
                              scroll={{ x: 800 }}
                              onRow={(row) => ({
                                onClick: () => {
                                  history.push(`receipts/${row.id}`);
                                },
                              })}
                              pagination={false}
                            />
                          </>
                        ) : (
                          <Table
                            className="receipt-custom-table"
                            locale={loading ? locale : false}
                            rowKey="id"
                            size="small"
                            dataSource={dataSource}
                            columns={columns}
                            fixed={true}
                            scroll={{ x: 800 }}
                            onRow={(row) => ({
                              onClick: () => {
                                history.push(`receipts/${row.id}`);
                              },
                            })}
                            pagination={false}
                          />
                        )}
                      </TableWrapper>
                    </div>
                  </UserTableStyleWrapper>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50px",
                      }}
                      className="remove-extra-css"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage == 1 ? true : false}
                    >
                      <FeatherIcon
                        size={20}
                        icon="chevron-left"
                        style={{
                          position: "relative",
                          left: "-9px",
                          top: "3px",
                        }}
                      />
                    </Button>
                    {/* <p>{dataSource?.length}</p> */}
                    <Button
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50px",
                        marginLeft: "40px",
                      }}
                      className="remove-extra-css"
                      disabled={ReceiptsListDeatils?.length < 10 ? true : false}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <FeatherIcon
                        size={20}
                        icon="chevron-right"
                        style={{
                          position: "relative",
                          left: "-7px",
                          top: "3px",
                        }}
                      />
                    </Button>
                  </p>
                </Cards>
              </Col>
            </Row>

            <Exportform
              modalVisible={modalVisible}
              setModelVisible={setModelVisible}
              reportType={reportType}
            />
          </div>
        ) : (
          <RoomReceipts />
        )}
      </Main>
    </>
  );
};

export default Receipts;
