import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Input,
  Modal,
  Switch,
  message,
  Button,
  Badge,
  Row,
  Col,
  Tabs,
} from "antd";
import { useHistory } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import { Popover } from "../../../../../components/popup/popup";
import FeatherIcon from "feather-icons-react";
import {
  ArrowRightOutlined,
  CaretDownOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  RestOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "../../setting.css";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAllTaxesList,
  deleteTaxes,
} from "../../../../../redux/taxes/actionCreator";

import {
  onlineOrderProductList,
  markInOutOfStock,
} from "../../../../../redux/onlineOrder/actionCreator";
import {
  getAllInventoryList,
  deleteInventory,
  trackUpdate,
  getAllItemList,
  getAllPosProductsList,
} from "../../../../../redux/inventory/actionCreator";
import moment from "moment";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { TableWrapper, Main } from "../../../../styled";
import { CardToolbox } from "../../../../Customer/Style";
import { PageHeader } from "../../../../../components/page-headers/page-headers";
import { UserTableStyleWrapper } from "../../../../pages/style";
import { getItem, setItem } from "../../../../../utility/localStorageControl";
import commonFunction from "../../../../../utility/commonFunctions";
import { getAdjustmentList } from "../../../../../redux/inventory/actionCreator";
const viewAdjustmentsItems = () => {
  const csvLinkRef = useRef();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  let [search, setsearch] = useState("");
  let isMounted = useRef(true);
  const [loader, setloader] = useState(false);
  const [adjustMentList, setAdjustMentList] = useState([]);
  const [inventoryId, setInventoryId] = useState();
  const [type, setType] = useState();
  const offLineMode = useSelector((state) => state.auth.offlineMode);
  const deviceWidth = getItem("windowWidth");
  async function fetchInvetory() {
    setloader(true);
    if (location?.state?.inventory_Id && location?.state?.items) {
      setInventoryId(location?.state?.inventory_Id);
      setloader(false);
      let items = [];
      location?.state?.items.map((val) => {
        if (
          val.wastageQty != undefined &&
          location?.state?.action_type == "Wastage"
        ) {
          val["quantity"] = val.wastageQty;
        }
        if (
          val.inwardQty != undefined &&
          location?.state?.action_type == "Inward"
        ) {
          val["quantity"] = val.inwardQty;
        }
        items.push(val);
      });
      setAdjustMentList(items);
      setType(location?.state?.action_type);
    }
    return true;
  }

  useEffect(() => {
    if (isMounted.current) {
      fetchInvetory();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const columns = [
    {
      title: "Item Name",
      dataIndex: "product_name",
      key: "product_name",
      fixed: "left",
      className: "item_name_col",
      render(text, record) {
        return {
          children: (
            <div style={{ color: "#008cba" }} className="item_name_text">
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "Previous Quantity",
      dataIndex: "availableQty",
      key: "availableQty",
      width: "20%",
      render(text, record) {
        return {
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: type + " Quantity",
      dataIndex: type == "Sell" ? "sellQuantity" : "quantity",
      key: type == "Sell" ? "sellQuantity" : "quantity",
      className: "center-col",
    },
    {
      title: "Unit of measure",
      dataIndex: "unit_of_measure",
      key: "unit_of_measure",
      align: "left",
      className: "center-col",
      render(text, record) {
        return {
          children: <div>{text ? text : "-"}</div>,
        };
      },
    },
  ];
  const [offLineModeCheck, setOfflineModeCheck] = useState(false);

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  const handleDownloadCSV = () => {
    csvLinkRef.current.link.click();
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(adjustMentList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Actionitems.xlsx");
  };

  const handleDownloadPdf = () => {
    let col = columns.filter((val) => val.dataIndex != "action");
    const doc = new jsPDF();
    doc.text("Vendors", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [col.map((column) => column.title)],
      body: adjustMentList.map((row) =>
        col.map((column) => row[column.dataIndex])
      ),
    });
    doc.save("Actionitems.pdf");
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadPdf();
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadExcel();
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadCSV();
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  const userDetails = getItem("userDetails");
  return (
    <Main className="inventory-items">
      <CardToolbox
        style={userDetails.role == "cashier" ? { marginTop: "-25px" } : {}}
      >
        <PageHeader
          ghost
          className="comman-other-custom-pageheader receipts-top0 inventory_header"
          subTitle={
            <>
              <div className="table_titles">
                <h2>Items</h2>
                <span className="title-counter">
                  <span>{adjustMentList?.length} Items </span>
                </span>
              </div>
              <div
                style={{ boxShadow: "none", marginLeft: "10px" }}
                className="search_lrm"
              >
                <Input
                  suffix={<SearchOutlined />}
                  autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                  className="set_serbt"
                  placeholder="Search by Item Name"
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                  }}
                  onChange={(e) => setsearch(e.target.value)}
                  value={search}
                />
              </div>
            </>
          }
          buttons={[
            <div className="inventory_action_btn">
              <Popover placement="bottomLeft" content={content} trigger="hover">
                <Button
                  size="small"
                  type="white"
                  style={{ marginRight: "12px", height: "34px" }}
                >
                  <FeatherIcon icon="download" size={14} />
                  Export
                </Button>
              </Popover>
              <Button
                className="ant-btn ant-btn-md ant-btn-primary btn-cancel btn"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  if (offLineMode) {
                    setOfflineModeCheck(true);
                  } else {
                    history.push(`/inventory/adjustment`, {
                      inventory_Id: location?.state?.inventory_Id,
                    });
                    setOfflineModeCheck(false);
                  }
                }}
              >
                <ArrowLeftOutlined />
                Go back
              </Button>
            </div>,
          ]}
        />
      </CardToolbox>

      <Row gutter={15}>
        <Col span={24}>
          <Cards headless>
            <CSVLink
              style={{ display: "none" }}
              data={adjustMentList}
              headers={columns
                .filter((val) => val.dataIndex != "action")
                .map((column) => ({
                  label: column.title,
                  key: column.dataIndex,
                }))}
              filename={"Actionitems.csv"}
              ref={csvLinkRef}
            >
              Hidden Download CSV Link
            </CSVLink>
            <UserTableStyleWrapper>
              <div className="contact-table">
                <TableWrapper className="table-responsive">
                  {loader ? (
                    <Table
                      rowKey="id"
                      locale={locale}
                      dataSource={[]}
                      columns={columns}
                      size="small"
                      style={{ marginTop: "8px" }}
                    />
                  ) : (
                    <Table
                      rowKey="id"
                      dataSource={adjustMentList.filter((val) =>
                        val?.product_name
                          ?.toString()
                          .toLowerCase()
                          .includes(search?.toString().toLowerCase())
                      )}
                      pagination={{
                        total: adjustMentList.length,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "50", "100", "500", "1000"],
                      }}
                      columns={columns}
                      size="small"
                      style={{ marginTop: "8px" }}
                    />
                  )}
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </Cards>
        </Col>
      </Row>
      <Modal
        title="You are Offline"
        visible={offLineModeCheck}
        onOk={() => setOfflineModeCheck(false)}
        onCancel={() => setOfflineModeCheck(false)}
        width={600}
      >
        <p>You are offline not add and update </p>
      </Modal>
    </Main>
  );
};

export { viewAdjustmentsItems };
