import moment from "moment";
import { getItem, setItem } from "../../utility/localStorageControl";
import actions from "./actions";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const {
  taxesAdd,
  taxesAddErr,
  taxesList,
  taxesListErr,
  taxesId,
  taxesIdErr,
  taxesDelete,
  taxesDeleteErr,
} = actions;

const addOrUpdateInventory = (formData, taxes_id) => {
  return async (dispatch) => {
    try {
      let getAddedtaxes = {};
      if (taxes_id) {
        getAddedtaxes = await DataService.put(
          `${API.inventory.addInventory}/${taxes_id}`,
          formData
        );
        if (getAddedtaxes.data.data) {
          return dispatch(taxesAdd(getAddedtaxes.data.data));
        }
      } else {
        getAddedtaxes = await DataService.post(
          API.inventory.addInventory,
          formData
        );
      }
      if (!getAddedtaxes.data.error) {
        return dispatch(taxesAdd(getAddedtaxes.data.data));
      } else {
        return dispatch(taxesAddErr(getAddedtaxes.data.data));
      }
    } catch (err) {
      dispatch(taxesAddErr(err));
    }
  };
};

const getAllInventoryList = (checksell) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");
      if (checksell == "sell" && allSetupcache?.taxes) {
        console.log("fsaafafafasfasfasfasfasfasfasf", allSetupcache);
        return dispatch(taxesList(allSetupcache.inventorys));
      } else {
        const getTaxesList = await DataService.get(
          API.inventory.getAllInventory
        );

        if (!getTaxesList.data.error) {
          allSetupcache.inventorys = getTaxesList.data.data;
          setItem("setupCache", allSetupcache);
          return dispatch(taxesList(getTaxesList.data.data));
        } else {
          return dispatch(taxesListErr(getTaxesList.data));
        }
      }
    } catch (err) {
      return dispatch(taxesListErr(err));
    }
  };
};
const getAllItemList = (inventoryId) => {
  return async (dispatch) => {
    try {
      const getTaxesList = await DataService.get(
        `${API.inventory.getAllItemList}/${inventoryId}`
      );
      if (!getTaxesList.data.error) {
        return getTaxesList.data.data;
      } else {
        return dispatch(taxesListErr(getTaxesList.data));
      }
    } catch (err) {
      return dispatch(taxesListErr(err));
    }
  };
};
const getInventoryById = (id) => {
  return async (dispatch) => {
    try {
      const taxesByIdData = await DataService.get(
        `${API.inventory.getInventoryById}/${id}`
      );
      if (!taxesByIdData.data.error) {
        return dispatch(taxesId(taxesByIdData.data.data));
      } else {
        return dispatch(taxesIdErr(taxesByIdData.data));
      }
    } catch (err) {
      dispatch(taxesIdErr(err));
    }
  };
};

const deleteInventory = (TaxesIds) => {
  return async (dispatch) => {
    try {
      const getDeletedTaxes = await DataService.post(
        API.inventory.deleteAllInventory,
        TaxesIds
      );

      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};
const getAllPosProductsList = (checksell) => {
  return async (dispatch) => {
    try {
      const getTaxesList = await DataService.get(
        API.inventory.getAllPosproducts
      );
      if (!getTaxesList.data.error) {
        return dispatch(taxesList(getTaxesList.data.data));
      } else {
        return dispatch(taxesListErr(getTaxesList.data));
      }
    } catch (err) {
      return dispatch(taxesListErr(err));
    }
  };
};
const addProductInInvenory = (id, payload) => {
  return async (dispatch) => {
    try {
      const getDeletedTaxes = await DataService.put(
        `${API.inventory.addProduct}/${id}`,
        payload
      );

      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};
const addAddjustmentAction = (payload) => {
  return async (dispatch) => {
    try {
      let userInfo = getItem("userDetails");
      if (userInfo && userInfo.username) {
        payload["username"] = userInfo.username;
      }
      const getDeletedTaxes = await DataService.post(
        `${API.inventory.adjustMentInventroy}`,
        payload
      );

      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};
const addProductInInvenoryItems = (id, payload) => {
  return async (dispatch) => {
    try {
      const getDeletedTaxes = await DataService.put(
        `${API.inventory.addProductInventoryItems}/${id}`,
        payload
      );

      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};
const addUpdateInevntoryItems = (id, payload) => {
  return async (dispatch) => {
    try {
      const getDeletedTaxes = await DataService.put(
        `${API.inventory.addUpdateInventoryProducts}/${id}`,
        payload
      );
      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};
const addInevntoryItems = (id, payload) => {
  return async (dispatch) => {
    try {
      const getDeletedTaxes = await DataService.put(
        `${API.inventory.addPosProductsLinkInventoryItems}/${id}`,
        payload
      );
      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};

const updateAllPosProducts = (payload) => {
  return async (dispatch) => {
    try {
      console.log("payload44342424", payload);
      const getDeletedTaxes = await DataService.post(
        API.inventory.updatePosProducts,
        payload
      );
      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};
const trackUpdate = (id, payload, track) => {
  return async (dispatch) => {
    try {
      const getDeletedTaxes = await DataService.put(
        `${API.inventory.trackUpdate}/${id}`,
        { products: payload, remove: track == "remove" ? true : false }
      );
      if (!getDeletedTaxes.data.error) {
        return dispatch(taxesDelete(getDeletedTaxes.data));
      } else {
        return dispatch(taxesDeleteErr(getDeletedTaxes.data));
      }
    } catch (err) {
      dispatch(taxesDeleteErr(err));
    }
  };
};
const getAdjustmentList = (currentPage, obj) => {
  return async (dispatch) => {
    try {
      let url = `${
        API.inventory.adjustMentInventoryList
      }?page=${currentPage}&limit=${10}&inventory_id=${
        obj.inventory_id
      }&startDate=${obj.startDate}&endDate=${obj.endDate}&timeZon=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }&action_type=${obj.action_type}&reason=${obj.reason}`;
      const getTaxesList = await DataService.get(url);
      if (!getTaxesList.data.error) {
        return getTaxesList.data.data;
      } else {
        return dispatch(taxesListErr(getTaxesList.data));
      }
    } catch (err) {
      return dispatch(taxesListErr(err));
    }
  };
};
export const exportReport = (payloads) => {
  return async () => {
    try {
      let url = API.inventory.adjustMentInventoryReport;
      let startDate;
      let endDate;

      if (payloads.dateRange == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (payloads.dateRange == "yesterday") {
        console.log("yesterday");
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "custom") {
        startDate = payloads.startDate;
        endDate = payloads.endDate;
      }

      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      payloads["startDate"] = startDate;
      payloads["endDate"] = endDate;
      payloads["timeZone"] = timeZone;

      const resp = await DataService.post(url, payloads);

      if (resp && !resp.data.error) {
        return resp.data;
      } else {
        return resp.data;
      }
    } catch (error) {
      return false;
    }
  };
};
export {
  addOrUpdateInventory,
  getAllInventoryList,
  getInventoryById,
  deleteInventory,
  getAllPosProductsList,
  getAllItemList,
  addProductInInvenory,
  updateAllPosProducts,
  trackUpdate,
  addUpdateInevntoryItems,
  addProductInInvenoryItems,
  addInevntoryItems,
  addAddjustmentAction,
  getAdjustmentList,
};
