import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { useDispatch } from "react-redux";
import { AuthWrapper } from "./style";
import Heading from "../../../../components/heading/heading";
import {
  register,
  login,
} from "../../../../redux/authentication/actionCreator";
import { Checkbox } from "../../../../components/checkbox/checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const SignUp = () => {
  const [errMsg, setErrmsg] = useState();
  const [loading, setLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    code: "",
    codeString: "",
  });
  const [state, setState] = useState({
    checked: null,
  });

  const handleSubmit = async (formData) => {
    console.log("ghjasgfjahfgakfgafkgafgasfa", formData);
    // if (phoneNumber) {
    //   formData["number"] = phoneNumber.number;
    //   formData["country_code"] = phoneNumber.code;
    //   formData["country_code_string"] = phoneNumber.codeString;
    // }
    setLoader(true);
    const getResponseUserRegistered = await dispatch(
      register(formData, history)
    );
    console.log("jhdashdkahdjhadkajdd", getResponseUserRegistered);
    if (
      getResponseUserRegistered &&
      getResponseUserRegistered.data &&
      !getResponseUserRegistered.data.error
    ) {
      // message.success({
      //   content:
      //     "We have sent account verification OTP to your mobile number, Please verify your account to continue",
      //   style: {
      //     float: "right",
      //     marginTop: "2vh",
      //   },
      // });
      // history.push("/verify-otp", {
      //   getResponseUserRegistered: getResponseUserRegistered,
      //   formData: formData,
      // });

      let response = await dispatch(login(formData, history));

      if (response?.data?.error) {
        setErr(true);
        setErrMsg(response.data.message);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } else {
      if (getResponseUserRegistered?.err?.error) {
        setLoader(false);
        setErrmsg(getResponseUserRegistered.err.message);
      }
    }
  };

  const onChange = (checked) => {
    setState({ ...state, checked });
  };

  return (
    <AuthWrapper>
      <p className="auth-notice">
        Already have an account?{" "}
        <NavLink to="/login" style={{ color: "#008cba" }}>
          Sign In
        </NavLink>
      </p>
      <div className="auth-contents">
        <Form name="register" onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            Sign Up to <span className="color-secondary">PosEase</span>
          </Heading>
          <Form.Item
            label="Organization Name"
            name="shop_name"
            rules={[
              {
                required: true,
                message: "Please enter your organization name",
              },
            ]}
          >
            <Input
              placeholder="Organization name"
              style={{ marginBottom: 10 }}
            />
          </Form.Item>
          <p style={{ display: "none" }}>{errMsg}</p>
          <Form.Item
            name="email"
            label="Email Address"
            validateStatus={
              errMsg == "Email is already exist." ? "error" : false
            }
            help={errMsg == "Email is already exist." ? errMsg : null}
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Invalid Email Address",
              },
            ]}
          >
            <Input
              placeholder="Email"
              style={{ marginBottom: 10 }}
              onChange={() => setErrmsg()}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              style={{ marginBottom: 10 }}
            />
          </Form.Item>
          <Form.Item
            name="number"
            label="Mobile No"
            validateStatus={
              errMsg == "Mobile Number is already exist." ? "error" : false
            }
            help={errMsg == "Mobile Number is already exist." ? errMsg : null}
            rules={[
              {
                required: true,
                message: "Mobile number is required",
              },
            ]}
          >
            <Input
              placeholder="Mobile Number"
              style={{ marginBottom: 10 }}
              onChange={() => setErrmsg()}
            />
          </Form.Item>
          {/* <Form.Item
            name="number"
            label="Mobile No"
            validateStatus={
              errMsg == "Mobile Number is already exist." ? "error" : false
            }
            help={errMsg == "Mobile Number is already exist." ? errMsg : null}
            rules={[
              {
                required: true,
                message: "Mobile number is required",
              },
            ]}
          >
            <PhoneInput
              country={"in"}
              inputProps={{
                name: "phone",
                required: true,
              }}
              inputStyle={{ width: "100%", height: "48px" }}
              style={{ marginBottom: 10 }}
              enableSearch={true}
              onChange={(phone, country) => {
                console.log("kjahdkasdhkdadas", phone, country);
                setPhoneNumber({
                  number: phone.substring(country.dialCode.length),
                  code: country.dialCode,
                  codeString: country.countryCode,
                });
              }}
            />
          </Form.Item> */}

          <Form.Item
            name="checkbox"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "You must agree to Terms & Conditions and Privacy Policy"
                        )
                      ),
              },
            ]}
          >
            <div className="auth-form-action">
              <Checkbox onChange={onChange}>
                Creating an account means you’re okay with our Terms of Service
                and Privacy Policy
              </Checkbox>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              className="btn-create"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {loading ? "Loading..." : "Create Account"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export { SignUp };
