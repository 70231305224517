import React, { useState, useRef, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Form, Input, Select, Button, Tooltip, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteOutlined,
  PlusOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import "../Product/product.css";
import {
  getAllRoomList,
  addOrUpdateRoom,
} from "../../redux/room/actionCreator";

import { getTaxGroupList } from "../../redux/taxGroup/actionCreator";
import { getItem } from "../../utility/localStorageControl";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getAllRegisterList } from "../../redux/register/actionCreator";
const AddRoom = () => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const history = useHistory();
  let isMounted = useRef(true);
  let location = useLocation();
  const [RegisterNameList, setRegisterNameList] = useState([]);
  const dispatch = useDispatch();
  let [roomTypeList, setRoomTypeList] = useState([]);
  const [roomLocationList, setRoomLocationList] = useState([]);
  let [isDisabled, setDisabled] = useState(true);
  let [name, setname] = useState(false);
  const [loading, setLoading] = useState(false);

  async function fetchRegisterName() {
    const getRgisterNameList = await dispatch(getAllRegisterList("sell"));
    if (getRgisterNameList && getRgisterNameList.RegisterList) {
      setRegisterNameList(
        getRgisterNameList.RegisterList.filter((val) => val.type == "hotel")
      );
    }
  }
  useEffect(() => {
    fetchRegisterName();
    if (location?.state?.roomTypeList) {
      setRoomTypeList(location.state.roomTypeList);
    }
    if (location?.state?.roomLocationList) {
      setRoomLocationList(location.state.roomLocationList);
    }
  }, []);
  console.log("dhdhdHkadasklslasasaas", currentRegisterData);
  const handleSubmit = async (formData) => {
    setLoading(true);
    const Obj = {};
    Obj.location = formData.location;
    Obj.room_name = formData.room_name;
    Obj.room_type = formData.room_type;
    Obj.register_id = currentRegisterData._id;

    const payload = [Obj];
    if (formData && formData.room && formData.room.length > 0) {
      formData.room.map((room) => {
        payload.push({ ...room, register_id: currentRegisterData._id });
      });
    }

    const AddNewRoom = await dispatch(addOrUpdateRoom(payload));
    if (AddNewRoom) {
      const getProductList = await dispatch(getAllRoomList(currentRegisterData._id,"sell"));
      if (getProductList && !getProductList.error && getProductList.roomList) {
        setLoading(false);
        history.push("/rooms");
      }
    }
  };

  const handleDelete = () => {
    if (name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleFormChange = (item, allFileds) => {
    allFileds.splice(-1);
    console.log("ckkjdhajkdhdkadaddadad", allFileds);
    if (
      allFileds.find((val, index) => val.value == undefined || val.value == "")
    ) {
      setDisabled(true);
      return true;
    } else {
      setDisabled(false);
      return true;
    }
  };

  return (
    <>
      <Main className="padding-top-form" style={{ paddingTop: 30 }}>
        <Cards headless>
          <Form
            autoComplete="off"
            size="large"
            onFinish={handleSubmit}
            onFieldsChange={(val, allFileds) =>
              handleFormChange(val, allFileds)
            }
          >
            <Row>
              <Col
                xxl={8}
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={24}
                className="gutter-box"
              >
                <Form.Item
                  label={
                    <span>
                      Room Name &nbsp;&nbsp;
                      <Tooltip title="Add like (101..,A..)" color="#FFFF">
                        <InfoCircleFilled style={{ color: "#AD005A" }} />
                      </Tooltip>
                    </span>
                  }
                  name="room_name"
                  rules={[{ required: true, message: "Room name is required" }]}
                >
                  <Input
                    style={{ margin: "8px 0 0" }}
                    onBlur={(e) => {
                      e.target.value !== "" ? setname(true) : setname(false);
                    }}
                    className="input-text"
                    placeholder="Room Name"
                  />
                </Form.Item>
              </Col>{" "}
              <Col
                xxl={8}
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={24}
                className="gutter-box"
              >
                <Form.Item
                  className="custom-input-text"
                  name="room_type"
                  label="Room Type"
                  rules={[
                    {
                      required: true,
                      message: "Room type is Required",
                    },
                  ]}
                >
                  <Select
                    className="high_addpd"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select room type"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <NavLink
                            to="/room-type/add"
                            style={{
                              flex: "none",
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                              color: "#008cba",
                            }}
                          >
                            <PlusOutlined
                              style={{ color: "rgb(0, 140, 186)" }}
                            />{" "}
                            <lable style={{ color: "rgb(0, 140, 186)" }}>
                              Add New Room Type
                            </lable>
                          </NavLink>
                        </div>
                      </div>
                    )}
                  >
                    {roomTypeList &&
                      roomTypeList.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data?.name}
                        </option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xxl={7}
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={24}
                className="gutter-box"
              >
                <Form.Item
                  className="custom-input-text"
                  label="Location"
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Location is Required.",
                    },
                  ]}
                >
                  <Select
                    className="high_addpd"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select room location"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <NavLink
                            to="/room-location/add"
                            style={{
                              flex: "none",
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                              color: "#008cba",
                            }}
                          >
                            <PlusOutlined
                              style={{ color: "rgb(0, 140, 186)" }}
                            />{" "}
                            <lable style={{ color: "rgb(0, 140, 186)" }}>
                              Add New Room Location
                            </lable>
                          </NavLink>
                        </div>
                      </div>
                    )}
                  >
                    {roomLocationList &&
                      roomLocationList.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data?.name}
                        </option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="room">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row
                      className="sub_prod"
                      key={field.key}
                      // style={{ margin: "8px 0 0" }}
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area ||
                          prevValues.sights !== curValues.sights
                        }
                      >
                        {() => (
                          <>
                            <Col
                              xxl={8}
                              xl={8}
                              lg={8}
                              md={8}
                              sm={12}
                              xs={24}
                              className="gutter-box"
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "room_name"]}
                                fieldKey={[field.fieldKey, "room_name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Product name is required.",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Room Name"
                                  className="input-text"
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              xxl={8}
                              xl={8}
                              lg={8}
                              md={8}
                              sm={12}
                              xs={24}
                              className="gutter-box Category_select"
                            >
                              <Form.Item
                                {...field}
                                fieldKey={[field.fieldKey, "room_type"]}
                                name={[field.name, "room_type"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Room type is Required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select room type"
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {roomTypeList.map((data) => (
                                    <option key={data._id} value={data._id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col
                              xxl={7}
                              xl={7}
                              lg={7}
                              md={7}
                              sm={12}
                              xs={22}
                              className="gutter-box Category_select"
                            >
                              <Form.Item
                                {...field}
                                fieldKey={[field.fieldKey, "location"]}
                                name={[field.name, "location"]}
                                className="custom-input-text"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter location",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select room location"
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {roomLocationList.map((data) => (
                                    <option key={data._id} value={data._id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col
                              xxl={1}
                              xl={1}
                              lg={1}
                              md={1}
                              sm={12}
                              xs={2}
                              className="gutter-box"
                            >
                              <Form.Item {...field} className="action-class">
                                <DeleteOutlined
                                  onClick={() => {
                                    remove(field.name);
                                    handleDelete();
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Form.Item>
                    </Row>
                  ))}
                  <div style={{ marginLeft: 9 }}>
                    <Form.Item>
                      <Button
                        type="primary"
                        info
                        disabled={isDisabled}
                        style={{ marginBottom: 10 }}
                        size="medium"
                        onClick={() => {
                          add();
                          setDisabled(true);
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add Room
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        size="medium"
                        onClick={() => history.push("/rooms")}
                        style={{ marginRight: 10 }}
                      >
                        Go Back
                      </Button>
                      <Button
                        type="primary"
                        info
                        htmlType="submit"
                        size="medium"
                      >
                        {loading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 16,
                                  color: "white",
                                  margin: "0px 15px",
                                }}
                                spin
                              />
                            }
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>
          </Form>
        </Cards>
      </Main>
    </>
  );
};

export default AddRoom;
