import React from "react";
import { Row, Col, Form, Input } from "antd";
import commonFunction from "../../utility/commonFunctions";
import "./style.css";
import moment from "moment";
import "../Sell/sell.css";
import { getItem } from "../../utility/localStorageControl";
function HotelInvoice({
  receiptsDetails,
  registerData,
  shopDetails,
  invoiceNo,
  rsSymbol,
  createDate,
  pendingPrice,
}) {
  let fullAddress = registerData?.bill_header.split("\n");
  let customerCompanyAddres = "";
  if (receiptsDetails?.customer?.company_address) {
    customerCompanyAddres = receiptsDetails?.customer?.company_address.split(
      "\n"
    );
  }
  console.log("ajfjajalfhasfafalfhaflahfafla", receiptsDetails);

  let subTotal = 0;
  let taxObj = {};
  if (receiptsDetails?.items) {
    receiptsDetails?.items.map((val) => {
      let { room_type_name, rate_plan, room_name, guest, type } = val;

      if (
        type == "room" &&
        val?.tax_group?.taxes &&
        val.tax_group.taxes_inclusive_in_product_price != "remove"
      ) {
        subTotal += Number(val.value);
        val?.tax_group?.taxes.map((k) => {
          if (k.tax_percentage) {
            let taxPrice = (val.value * k.tax_percentage) / 100;
            if (taxObj[k._id]) {
              taxObj[k._id] = {
                name: k.tax_name,
                value: taxObj[k._id]["value"] + taxPrice,
              };
            } else {
              taxObj[k._id] = {
                name: k.tax_name,
                value: taxPrice,
              };
            }
          }
        });
      } else if (type == "room") {
        subTotal += Number(val.value);
      }

      if (
        type == "addtional" &&
        val?.tax_group?.taxes &&
        val.tax_group.taxes_inclusive_in_product_price != "remove"
      ) {
        subTotal += Number(val.amount.total - val.amount.tax);
        val?.tax_group?.taxes.map((k) => {
          if (k.tax_percentage != undefined) {
            let price = Number(val.amount.total - val.amount.tax);
            let taxPrice = (price * k.tax_percentage) / 100;
            if (taxObj[k._id]) {
              taxObj[k._id] = {
                name: k.tax_name,
                value: taxObj[k._id]["value"] + taxPrice,
              };
            } else {
              taxObj[k._id] = {
                name: k.tax_name,
                value: 0 + taxPrice,
              };
            }
          }
        });
      } else if (type == "addtional") {
        subTotal += Number(val.amount.total - val.amount.tax);
      }
      if (type == "posease-restaurant") {
        subTotal += Number(val.value);
      }
    });
  }
  console.log("receiptsDetailsreceiptsDetails", receiptsDetails);
  return (
    <>
      {receiptsDetails && shopDetails && registerData && (
        <>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              {registerData.include_shop_logo &&
                shopDetails.shop_logo != "false" &&
                shopDetails.shop_logo != "" && (
                  <p
                    style={{
                      fontFamily: "Arial, Helvetica, sans-serif",
                      lineHeight: "16px",
                      padding: "0",
                      margin: "0",
                      textAlign: "center",
                      marginBottom: "2px",
                    }}
                  >
                    <img
                      src={shopDetails.shop_logo}
                      alt=""
                      style={{
                        width: "60px",
                        height: "60px",
                        textAlign: "left",
                      }}
                    />
                  </p>
                )}
            </div>
            <div>
              <p
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: "20px",
                  fontWeight: "700",
                  padding: "0",
                  textAlign: "right",
                  marginBottom: "0px",
                }}
              >
                {"Invoice"}
              </p>
              {invoiceNo && (
                <p
                  style={{
                    fontSize: "14px",

                    fontFamily: "Arial, Helvetica, sans-serif",
                    textAlign: "right",
                    margin: 0,
                  }}
                >
                  Invoice No: {invoiceNo}
                </p>
              )}
              <p
                style={{
                  fontSize: "14px",

                  fontFamily: "Arial, Helvetica, sans-serif",
                  textAlign: "right",
                  margin: 0,
                }}
              >
                Date of issue:{" "}
                {commonFunction.convertToDate(
                  createDate ? createDate : new Date(),
                  "MMM DD, Y, h:mm A"
                )}
              </p>
            </div>
          </div>
          <br></br>
          <Row gutter={[16, 16]}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ background: "#fff" }}>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  <h1
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "left",
                      margin: 0,
                    }}
                  >
                    Recipient
                  </h1>
                  {receiptsDetails.customer.name && (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        margin: 0,
                      }}
                    >
                      {receiptsDetails.customer.name}
                    </p>
                  )}

                  {receiptsDetails.customer.mobile &&
                  receiptsDetails.customer.mobile != 0 &&
                  receiptsDetails.customer.mobile != NaN &&
                  receiptsDetails.customer.mobile != "" &&
                  !isNaN(receiptsDetails.customer.mobile) ? (
                    <p
                      style={{
                        fontSize: "14px",

                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        margin: 0,
                      }}
                    >
                      {receiptsDetails.customer.mobile}
                    </p>
                  ) : (
                    ""
                  )}
                  {receiptsDetails.customer.email &&
                  receiptsDetails.customer.email != "" ? (
                    <p
                      style={{
                        fontSize: "14px",

                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        margin: 0,
                      }}
                    >
                      {receiptsDetails.customer.email}
                    </p>
                  ) : (
                    ""
                  )}
                  {(receiptsDetails.customer?.shipping_address != "" ||
                    receiptsDetails.customer?.city != "" ||
                    receiptsDetails.customer?.zipcode != "") &&
                    (receiptsDetails.customer?.shipping_address != null ||
                      receiptsDetails.customer?.city != null ||
                      receiptsDetails.customer?.zipcode != null) && (
                      <p
                        style={{
                          fontSize: "14px",

                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          margin: 0,
                        }}
                      >
                        Address: {receiptsDetails.customer.shipping_address}{" "}
                        {receiptsDetails.customer.city}{" "}
                        {receiptsDetails.customer.zipcode}
                      </p>
                    )}
                  {receiptsDetails.customer?.company_name && (
                    <p
                      style={{
                        fontSize: "14px",

                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        margin: 0,
                      }}
                    >
                      <span style={{ fontWeight: "700" }}>
                        {receiptsDetails.customer?.company_name}
                      </span>
                    </p>
                  )}
                  {receiptsDetails.customer?.GSTIN_number && (
                    <p
                      style={{
                        fontSize: "14px",

                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        margin: 0,
                      }}
                    >
                      {receiptsDetails.customer?.GSTIN_number}
                    </p>
                  )}
                  {customerCompanyAddres != "" &&
                    customerCompanyAddres?.map((data) => (
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          textAlign: "left",
                          margin: 0,
                        }}
                      >
                        {data}
                      </p>
                    ))}
                  <p
                    style={{
                      fontSize: "14px",
                      marginTop: 8,
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "left",
                      marginBottom: 0,
                    }}
                  >
                    Booked on:{" "}
                    <span style={{ fontWeight: "700" }}>
                      {commonFunction.convertToDate(
                        receiptsDetails?.reservationData?.reservationBookTime,
                        "MMM DD, Y, h:mm A"
                      )}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "14px",

                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "left",
                      margin: 0,
                    }}
                  >
                    Reference:{" "}
                    <span style={{ fontWeight: "700" }}>
                      #{receiptsDetails?.reservationData?.reservationStringId}
                    </span>
                  </p>
                </div>
                <div>
                  <div
                    style={{
                      padding: "0px 3px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "right",
                        margin: 0,
                      }}
                    >
                      {shopDetails.shop_name}
                    </p>{" "}
                  </div>
                  {fullAddress?.map((data) => (
                    <p
                      style={{
                        fontSize: "14px",

                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "right",

                        margin: 0,
                      }}
                    >
                      {data}
                    </p>
                  ))}
                  {getItem("print_register_name") != null &&
                    getItem("print_register_name") == true && (
                      <h5
                        style={{
                          textAlign: "right",
                          fontSize: "14px",
                          marginBottom: "2px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          marginTop: "0px",
                        }}
                      >
                        {registerData.register_name}
                      </h5>
                    )}
                </div>
              </div>
              <br></br>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "left",
                      margin: 0,
                    }}
                  >
                    Check in
                  </span>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "left",
                      margin: 0,
                    }}
                  >
                    {" "}
                    {commonFunction.convertToDate(
                      receiptsDetails?.reservationData
                        ?.reservationInDateAndTime,
                      "ddd DD MMM YYYY"
                    )}
                  </p>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "center",
                      margin: 0,
                    }}
                  >
                    Number of nights
                  </span>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "center",
                      margin: 0,
                    }}
                  >
                    {moment(
                      receiptsDetails?.reservationData
                        ?.reservationOutDateAndTime
                    ).diff(
                      moment(
                        receiptsDetails?.reservationData
                          ?.reservationInDateAndTime
                      ),
                      "days"
                    )}
                  </p>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    {" "}
                    Check out
                  </span>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    {commonFunction.convertToDate(
                      receiptsDetails?.reservationData
                        ?.reservationOutDateAndTime,
                      "ddd DD MMM YYYY"
                    )}
                  </p>
                </div>
              </div>
              <table style={{ width: "100%" }}>
                <tr>
                  <hr
                    style={{
                      margin: "4px 0 0",
                      borderTop: "2px dotted black",
                    }}
                  />
                </tr>
              </table>
              <br></br>
              <div>
                <table style={{ width: "100%" }}>
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        width: "30%",
                        paddingRight: "10px",
                      }}
                    >
                      Date
                      <p style={{ marginBottom: "10px" }}></p>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "center",
                        width: "30%",
                        paddingRight: "10px",
                      }}
                    >
                      Room type / Rate plan
                      <p style={{ marginBottom: "10px" }}></p>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "center",
                        width: "10%",
                        paddingRight: "10px",
                      }}
                    >
                      Guests
                      <p style={{ marginBottom: "10px" }}></p>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "center",
                        width: "20%",
                        paddingRight: "10px",
                      }}
                    >
                      Item
                      <p style={{ marginBottom: "10px" }}></p>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        textAlign: "right",
                        fontWeight: "700",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        width: "10%",
                      }}
                    >
                      Total <p style={{ marginBottom: "10px" }}></p>
                    </div>
                  </div>
                  {receiptsDetails?.items.map((val) => {
                    if (val.type == "room") {
                      let { room_type_name, rate_plan, room_name, guest } = val;
                      return (
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0",
                              width: "30%",
                              margin: 0,

                              fontFamily: "Arial, Helvetica, sans-serif",

                              paddingRight: "10px",
                            }}
                          >
                            <span>
                              {commonFunction.convertToDate(
                                val.reservationInDateAndTime,
                                "ddd DD MMM YYYY"
                              )}{" "}
                              -{" "}
                              {commonFunction.convertToDate(
                                val.reservationOutDateAndTime,
                                "ddd DD MMM YYYY"
                              )}
                            </span>
                            <p
                              style={{ marginTop: "1px", marginBottom: "1px" }}
                            >
                              {moment(val?.reservationOutDateAndTime).diff(
                                moment(val?.reservationInDateAndTime),
                                "days"
                              )}{" "}
                              nights
                            </p>
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "30%",
                              fontFamily: "Arial, Helvetica, sans-serif",

                              paddingRight: "10px",
                            }}
                          >
                            {`${room_type_name} / ${rate_plan} / ${room_name}`}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "10%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            {guest.adults > 0 && (
                              <p
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                {guest.adults} adult(s)
                              </p>
                            )}
                            {guest.children > 0 && (
                              <p
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                {guest.children} children(s){" "}
                              </p>
                            )}
                            {guest.infants > 0 && (
                              <p
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                {guest.infants} infants(s)
                              </p>
                            )}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>

                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "20%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            <span>{val.item}</span>
                            {val.tax_group.taxes_inclusive_in_product_price !=
                              "remove" &&
                              val.tax_group.taxes.map((k) => {
                                if (k.tax_percentage) {
                                  return (
                                    <p
                                      style={{
                                        marginTop: "1px",
                                        marginBottom: "1px",
                                      }}
                                    >
                                      {" "}
                                      {k.tax_name}
                                    </p>
                                  );
                                }
                              })}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0",

                              marginBottom: 0,
                              width: "10%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                            }}
                          >
                            <span>
                              {rsSymbol}
                              {Number(val.value).toFixed(2)}
                            </span>
                            {val.tax_group.taxes_inclusive_in_product_price !=
                              "remove" &&
                              val.tax_group.taxes.map((k) => {
                                if (k.tax_percentage) {
                                  let tax =
                                    (val.value * k.tax_percentage) / 100;
                                  return (
                                    <p
                                      style={{
                                        marginTop: "1px",
                                        marginBottom: "1px",
                                      }}
                                    >
                                      {" "}
                                      {rsSymbol}
                                      {Number(tax).toFixed(2)}
                                    </p>
                                  );
                                }
                              })}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                        </div>
                      );
                    } else if (val.type == "addtional") {
                      let price = val.amount.total - val.amount.tax;
                      return (
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0",
                              margin: 0,
                              width: "30%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            {commonFunction.convertToDate(
                              val.addTime,
                              "ddd DD MMM YYYY , h:mm A"
                            )}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "30%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            {`${val.room_type} / ${val.rate_plan} / ${val.room_name}`}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              fontWeight: "700",
                              width: "10%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            -<p style={{ marginBottom: "10px" }}></p>
                          </div>

                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "20%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            <span>
                              {val.itemName} x {val.amount.qty}
                            </span>
                            {val.tax_group.taxes_inclusive_in_product_price !=
                              "remove" &&
                              val.tax_group.taxes.map((k) => {
                                if (k.tax_percentage) {
                                  return (
                                    <p
                                      style={{
                                        marginTop: "1px",
                                        marginBottom: "1px",
                                      }}
                                    >
                                      {" "}
                                      {k.tax_name}
                                    </p>
                                  );
                                }
                              })}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0",
                              marginBottom: 0,
                              width: "10%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                            }}
                          >
                            <span>
                              {rsSymbol}
                              {Number(price).toFixed(2)}
                            </span>
                            {val.tax_group.taxes_inclusive_in_product_price !=
                              "remove" &&
                              val.tax_group.taxes.map((k) => {
                                if (k.tax_percentage) {
                                  let tax = (price * k.tax_percentage) / 100;
                                  return (
                                    <p
                                      style={{
                                        marginTop: "1px",
                                        marginBottom: "1px",
                                      }}
                                    >
                                      {rsSymbol}
                                      {Number(tax).toFixed(2)}
                                    </p>
                                  );
                                }
                              })}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              padding: "0",
                              margin: 0,
                              width: "30%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            {commonFunction.convertToDate(
                              val.addTime,
                              "ddd DD MMM YYYY , h:mm A"
                            )}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "30%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            {`${val.room_type} / ${val.rate_plan} / ${val.room_name}`}
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "10%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            -<p style={{ marginBottom: "10px" }}></p>
                          </div>

                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                              padding: "0",
                              margin: 0,
                              width: "20%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                              paddingRight: "10px",
                            }}
                          >
                            <span> In Room Service | #{val.itemName}</span>
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              padding: "0",
                              marginBottom: 0,
                              width: "10%",
                              fontFamily: "Arial, Helvetica, sans-serif",
                            }}
                          >
                            <span>
                              {rsSymbol}
                              {Number(val.value).toFixed(2)}
                            </span>
                            <p style={{ marginBottom: "10px" }}></p>
                          </div>
                        </div>
                      );
                    }
                  })}
                </table>

                <table style={{ width: "100%" }}>
                  <tr>
                    <hr
                      style={{
                        margin: "4px 0 0",
                        borderTop: "2px dotted black",
                      }}
                    />
                  </tr>
                </table>

                <tr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "0px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",

                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        marginBottom: "0px",
                      }}
                    >
                      Subtotal
                    </p>
                    <p
                      style={{
                        fontSize: "14px",

                        textAlign: "left",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        marginBottom: "0px",
                      }}
                    >
                      {rsSymbol}
                      {Number(subTotal).toFixed(2)}
                    </p>
                  </div>
                </tr>

                {Object.values(taxObj)?.length > 0 && (
                  <div>
                    {Object.values(taxObj)?.map((val) => {
                      return (
                        <tr>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingBottom: "0px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",

                                fontFamily: "Arial, Helvetica, sans-serif",
                                textAlign: "left",
                                marginBottom: "0px",
                              }}
                            >
                              {val.name}
                            </p>
                            <p
                              style={{
                                fontSize: "14px",

                                textAlign: "left",
                                fontFamily: "Arial, Helvetica, sans-serif",
                                marginBottom: "0px",
                              }}
                            >
                              {rsSymbol}
                              {Number(val.value).toFixed(2)}
                            </p>
                          </div>
                        </tr>
                      );
                    })}
                  </div>
                )}

                <tr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "0px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        marginBottom: "0px",
                      }}
                    >
                      Total
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textAlign: "left",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        marginBottom: "0px",
                      }}
                    >
                      {rsSymbol}
                      {Number(receiptsDetails?.payment?.total).toFixed(2)}
                    </p>
                  </div>
                </tr>
                <table style={{ width: "100%" }}>
                  <tr>
                    <hr
                      style={{
                        margin: "4px 0 0",
                        borderTop: "2px dotted black",
                      }}
                    />
                  </tr>
                </table>
                <tr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        marginBottom: "0px",
                      }}
                    >
                      Received
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        textAlign: "left",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        marginBottom: "0px",
                      }}
                    >
                      {rsSymbol}
                      {Number(
                        Number(receiptsDetails?.payment?.grandTotal) -
                          Number(pendingPrice)
                      ).toFixed(2)}
                    </p>
                  </div>
                </tr>
                <tr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        textAlign: "left",
                        marginBottom: "1px",
                      }}
                    >
                      Outstanding
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        textAlign: "left",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        marginBottom: "1px",
                      }}
                    >
                      {rsSymbol}
                      {Number(pendingPrice).toFixed(2)}
                    </p>
                  </div>
                </tr>

                {registerData.bill_footer && registerData.bill_footer != "" && (
                  <>
                    <br></br>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <hr
                          style={{
                            margin: "4px 0 0",
                            borderTop: "2px dotted black",
                          }}
                        />
                      </tr>
                    </table>

                    {registerData.bill_footer.split("\n").map((val) => {
                      return (
                        <div
                          style={{
                            fontSize: "16px",
                            fontFamily: "Arial, Helvetica, sans-serif",
                            textAlign: "left",
                            marginTop: "1px",
                            marginBottom: "0px",
                          }}
                        >
                          {val}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div>
                {registerData.footer_img &&
                  registerData.footer_img != "false" &&
                  registerData.footer_img != "" && (
                    <p
                      style={{
                        fontFamily: "Arial, Helvetica, sans-serif",
                        lineHeight: "16px",
                        padding: "0",
                        margin: "0",
                        textAlign: "left",
                        marginBottom: "5px",
                        marginTop: "7px",
                      }}
                    >
                      <img
                        src={registerData.footer_img}
                        alt=""
                        style={{
                          width: "120px",
                          height: "120px",
                          textAlign: "center",
                          margin: "0 auto",
                        }}
                      />
                    </p>
                  )}
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  columnGap: "50px",
                  marginTop: 50,
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                  }}
                >
                  <div>
                    <p
                      style={{
                        border: "none",
                        marginBottom: "10px",
                        fontSize: "15px",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        width: "100%",
                        marginTop: 40,
                      }}
                    ></p>
                    <div
                      style={{
                        fontSize: "17px",
                        fontWeight: 500,
                        fontFamily: "Arial, Helvetica, sans-serif",
                        marginBottom: 0,
                        marginTop: 40,
                      }}
                    >
                      Authorized Signature
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default HotelInvoice;
