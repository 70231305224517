import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import withAdminLayout from "../../layout/withAdminLayout";
import Ecommerce from "../../container/dashboard/Ecommerce";
import Receipts from "../../container/Receipts/Receipts";
import Invoice from "../../container/Receipts/Invoice";
import Products from "../../container/Product/Products";
import AddProduct from "../../container/Product/AddProduct";
import ImportProduct from "../../container/Product/Importform";
import Pricebook from "../../container/Product/PriceBook/Pricebook-list";
import AddPriceBook from "../../container/Product/PriceBook/AddPriceBook";
import EditProduct from "../../container/Product/EditProduct";
import PriceBookItemBuilder from "../../container/Product/PriceBook/PriceBookItemBuilder";
import CategoryBuilder from "../../container/ProductCategory/CategoryBuilder";
import ImportPriceBook from "../../container/Product/PriceBook/Importform";

/*----- PriceBook ----------*/

import PriceBookImportProduct from "../../container/Product/PriceBook/Items/importProducts";
import PriceBookImportVariants from "../../container/Product/PriceBook/Items/importVariants";
import PriceBookImportAddons from "../../container/Product/PriceBook/Items/ImportAddons";

/*----- Customer ----------*/

import Customer from "../../container/Customer/Customers";
import EditCustomer from "../../container/Customer/EditCustomer";
import AddCustomer from "../../container/Customer/AddCustomer";
import ImportCustomer from "../../container/Customer/Importform";

/*---- Product Category -------------*/

import AddProductCategory from "../../container/ProductCategory/Add/AddProductCategory";
import AddOrderTicketGroup from "../../container/ProductCategory/Add/AddOrderTicketGroup";

/*---- Product Options -----*/

import ProductOptionBuild from "../../container/ProductOption/ProductOptionBuild";
import ImportVariant from "../../container/ProductOption/Variant/Importform";
import PreviewVariant from "../../container/ProductOption/Variant/PreviewVariant";
import AddVariant from "../../container/ProductOption/Variant/AddVariant";
import EditVariant from "../../container/ProductOption/Variant/EditVariant";

//--- Variat group -----//

import AddVariantGroup from "../../container/ProductOption/VariantGroup/AddVariantGroup";
import EditVariantGroup from "../../container/ProductOption/VariantGroup/EditVariantGroup";
import ImportVariantGroup from "../../container/ProductOption/VariantGroup/Importform";

//---- Addon-----------//

import ImportAddon from "../../container/ProductOption/Addons/Importform";
import AddAddon from "../../container/ProductOption/Addons/AddAddons";
import EditAddon from "../../container/ProductOption/Addons/EditAddons";

// Addon group ---------//
import AddAddonGroup from "../../container/ProductOption/AddonsGroup/AddAddonGroup";
import EditAddonGroup from "../../container/ProductOption/AddonsGroup/EditAddonGroup";
import ImportAddonGroup from "../../container/ProductOption/AddonsGroup/Importform";

//----------- Item group ----------//

import AddItemGroup from "../../container/ProductOption/ItemGroup/AddItemGroup";
import EditItemGroup from "../../container/ProductOption/ItemGroup/EditItemGroup";
import ImportItemGroup from "../../container/ProductOption/ItemGroup/Importform";

//------------ Application -----------//

import Application from "../../container/Application/Application";

//------- Dummy  --------//

import IncomingOrderBuilder from "../../container/Sell/Incoming/IncomingOrderBuilder";
import SellBuilder from "../../container/Sell/SellBuilder";

import Settings from "../../container/profile/settings/Settings";
import PricingTable from "../../container/Subscription/PricingTable";

import Patty from "../../container/PattyCash/Patty";

// --Inventory --//
import { InventoryBuilder } from "../../container/profile/settings/overview/InventoryBuilder";
import { addInventory } from "../../container/profile/settings/overview/Inventory/addInventory";
import { ManageProductsBuilder } from "../../container/profile/settings/overview/Inventory/ManageProductsBuilder";
import { InventoryItemsList } from "../../container/profile/settings/overview/Inventory/InventoryItemsList";
import { AdjustmentInventory } from "../../container/profile/settings/overview/Inventory/adjustmentInventory";
import { Recipe } from "../../container/profile/settings/overview/Inventory/Recipe";
import { InwardInventory } from "../../container/profile/settings/overview/Inventory/InwardInventory";
import { addInventoryRecipe } from "../../container/profile/settings/overview/Inventory/addInventoryRecipe";
import { Addproductrecipe } from "../../container/profile/settings/overview/Inventory/Addproductrecipe";
import { AddRecipe } from "../../container/profile/settings/overview/Inventory/AddRecipe";
import { EditRecipe } from "../../container/profile/settings/overview/Inventory/EditRecipe";
import Billing from "../../container/Billing/Billing";

/*----- Manage Hotels ----------*/
import Rooms from "../../container/Rooms/Room";
import AddRoom from "../../container/Rooms/AddRoom";
import EditRoom from "../../container/Rooms/EditRoom";
import Roomtype from "../../container/RoomType/Roomtype";
import AddEditRoomType from "../../container/RoomType/AddEditRoomType";
import ReservationBuilder from "../../container/Reservation/ResurvationBuilder";
import RoomLocation from "../../container/RoomLocation/RoomLocation";
import RoomReceipts from "../../container/Receipts/roomReceipts";
import AddRoomLocation from "../../container/RoomLocation/AddRoomLocation";
import HotelEcommerce from "../../container/hotelDashboard/HotelEcommerce";
import ShowRoomReceipt from "../../container/Receipts/ShowRoomReceipt";

/*----- Hotel Report ----------*/
import Master from "../../container/hotelDashboard/Reports/Master";
import RoomReservation from "../../container/hotelDashboard/Reports/RoomReservation";
import Occupancy from "../../container/hotelDashboard/Reports/Occupancy";
import saleReport from "../../container/hotelDashboard/Reports/saleReport";
//Purchase and expanses//
import VendorsBuild from "../../container/Vendors/VendorsBuild";
import AddVendor from "../../container/Vendors/Vendor/AddVendor";
import AddVendorTax from "../../container/Vendors/VendorTax/AddVendorTax";
import VendorsItemsBuild from "../../container/VendorItems/VendorsItemsBuild";
import AddVendorItemCategory from "../../container/VendorItems/Category/AddVendorItemCategory";
import Additems from "../../container/VendorItems/Items/Additems";
import EditItem from "../../container/VendorItems/Items/EditItem";
import AddVendorBill from "../../container/VendorsBill/VendorBill/AddVendorBill";
import VendorsBillBuild from "../../container/VendorsBill/VendorsBillBuild";
import ExpenseBillList from "../../container/ExpenseBill/ExpenseBillList";
import ExpenseCategoryList from "../../container/ExpenseBill/ExpenseCategoryList";
import AddExpenseCategory from "../../container/ExpenseBill/addExpenseCategory";
import AddExpense from "../../container/ExpenseBill/AddExpense";
import { viewAdjustmentsItems } from "../../container/profile/settings/overview/Inventory/viewAdjustmentsItems";
import { VendorItemCategoryList } from "../../container/VendorItems/Category/VendorItemCategoryList";
import HousekeepingBuilder from "../../container/Reservation/housekeeping/HousekeepingBuilder";
import RateManagement from "../../container/Reservation/ratemanagement/RateManagement";
import PaymnetMode from "../../container/hotelDashboard/Reports/PaymnetMode";
import DailySaleReport from "../../container/hotelDashboard/Reports/DailySaleReport";
import ProductwiseReport from "../../container/hotelDashboard/Reports/ProductwiseReport";
import TaxesReport from "../../container/hotelDashboard/Reports/TaxesReport";
import { UserLogin } from "../../container/profile/authentication/overview/UserLogin";
const Restaurant = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/user/login/:id" component={UserLogin} />
        <Route exact path={`${path}dashboard`} component={Ecommerce} />
        <Route exact path={`${path}`} component={SellBuilder} />
        <Route exact path={`${path}receipts`} component={Receipts} />
        <Route exact path={`${path}pettycash`} component={Patty} />
        <Route exact path={`${path}products`} component={Products} />
        <Route exact path={`${path}products/add`} component={AddProduct} />
        <Route exact path={`${path}products/edit`} component={EditProduct} />
        <Route exact path={`${path}products/pricebook`} component={Pricebook} />
        <Route
          exact
          path={`${path}products/pricebook/add`}
          component={AddPriceBook}
        />
        <Route
          exact
          path={`${path}products/pricebook/items/:pricebook_id`}
          component={PriceBookItemBuilder}
        />
        <Route
          exact
          path={`${path}products/pricebook/import`}
          component={ImportPriceBook}
        />
        <Route
          exact
          path={`${path}pricebook-items/produt/import/:pricebook_id`}
          component={PriceBookImportProduct}
        />
        <Route
          exact
          path={`${path}pricebook-items/variant/import`}
          component={PriceBookImportVariants}
        />
        <Route
          exact
          path={`${path}pricebook-items/addon/import/:pricebook_id`}
          component={PriceBookImportAddons}
        />
        <Route
          exact
          path={`${path}products/import`}
          component={ImportProduct}
        />
        <Route
          exact
          path={`${path}product-categories`}
          component={CategoryBuilder}
        />
        <Route
          exact
          path={`${path}product-categories/add`}
          component={AddProductCategory}
        />
        <Route
          exact
          path={`${path}product-categories/add-new-order-ticket-group`}
          component={AddOrderTicketGroup}
        />
        <Route exact path={`${path}receipts/:id`} component={Invoice} />
        <Route
          exact
          path={`${path}product-options`}
          component={ProductOptionBuild}
        />
        <Route
          exact
          path={`${path}product-options/variant/import`}
          component={ImportVariant}
        />
        <Route
          exact
          path={`${path}product-options/variant/preview`}
          component={PreviewVariant}
        />
        <Route
          exact
          path={`${path}product-options/variant/add`}
          component={AddVariant}
        />
        <Route
          exact
          path={`${path}product-options/variant/edit`}
          component={EditVariant}
        />
        <Route
          exact
          path={`${path}product-options/variantgroup/add`}
          component={AddVariantGroup}
        />
        <Route
          exact
          path={`${path}product-options/variantgroup/edit`}
          component={EditVariantGroup}
        />
        <Route
          exact
          path={`${path}product-options/variantgroup/import`}
          component={ImportVariantGroup}
        />
        <Route
          exact
          path={`${path}product-options/addon/import`}
          component={ImportAddon}
        />
        <Route
          exact
          path={`${path}product-options/addon/add`}
          component={AddAddon}
        />
        <Route
          exact
          path={`${path}product-options/addon/edit`}
          component={EditAddon}
        />
        <Route
          exact
          path={`${path}product-options/itemgroup/add`}
          component={AddItemGroup}
        />
        <Route
          exact
          path={`${path}product-options/itemgroup/edit/:id`}
          component={EditItemGroup}
        />
        <Route
          exact
          path={`${path}product-options/itemgroup/import`}
          component={ImportItemGroup}
        />
        <Route
          exact
          path={`${path}product-options/addongroup/add`}
          component={AddAddonGroup}
        />
        <Route
          exact
          path={`${path}product-options/addongroup/edit`}
          component={EditAddonGroup}
        />
        <Route
          exact
          path={`${path}product-options/addongroup/import`}
          component={ImportAddonGroup}
        />
        <Route exact path={`${path}sell`} component={SellBuilder} />
        <Route exact path={`${path}customers`} component={Customer} />
        <Route
          exact
          path={`${path}customers/import`}
          component={ImportCustomer}
        />
        <Route
          exact
          path={`${path}customers/edit/:id`}
          component={EditCustomer}
        />
        <Route exact path={`${path}customers/add`} component={AddCustomer} />
        <Route exact path={`${path}inventory`} component={InventoryBuilder} />
        <Route exact path={`${path}inventory/add`} component={addInventory} />
        <Route
          exact
          path={`${path}inventory/POSitems`}
          component={ManageProductsBuilder}
        />
        <Route
          exact
          path={`${path}inventory/itemList`}
          component={InventoryItemsList}
        />
        <Route
          exact
          path={`${path}inventory/adjustment`}
          component={AdjustmentInventory}
        />
        <Route
          exact
          path={`${path}inventory/view-adjustmet-items`}
          component={viewAdjustmentsItems}
        />
        <Route exact path={`${path}inventory/recipe`} component={Recipe} />
        <Route
          exact
          path={`${path}inventory/inward`}
          component={InwardInventory}
        />
        <Route
          exact
          path={`${path}inventory/add-inventory-recipe`}
          component={addInventoryRecipe}
        />
        <Route
          exact
          path={`${path}inventory/add-product-receipe`}
          component={Addproductrecipe}
        />
        <Route
          exact
          path={`${path}inventory/add-recipe`}
          component={AddRecipe}
        />
        <Route
          exact
          path={`${path}inventory/edit-recipe`}
          component={EditRecipe}
        />
        <Route path={`${path}settings`} component={Settings} />
        <Route path={`${path}shop/subscription`} component={PricingTable} />
        <Route path={`${path}appstore`} component={Application} />
        <Route path={`${path}billing`} component={Billing} />
        <Route exact path={`${path}rooms`} component={Rooms} />
        <Route exact path={`${path}room-type`} component={Roomtype} />
        <Route
          exact
          path={`${path}room-type/add`}
          component={AddEditRoomType}
        />
        <Route
          exact
          path={`${path}room-location/add`}
          component={AddRoomLocation}
        />
        <Route exact path={`${path}room-location`} component={RoomLocation} />
        <Route exact path={`${path}rooms/add`} component={AddRoom} />
        <Route exact path={`${path}rooms/edit`} component={EditRoom} />
        <Route
          exact
          path={`${path}reservation`}
          component={ReservationBuilder}
        />
        <Route exact path={`${path}room-receipts`} component={RoomReceipts} />
        <Route
          exact
          path={`${path}room-receipts/:id`}
          component={ShowRoomReceipt}
        />
        <Route exact path={`${path}reports`} component={HotelEcommerce} />
        <Route exact path={`${path}reports/master`} component={Master} />
        <Route
          exact
          path={`${path}reports/roomReservation`}
          component={RoomReservation}
        />
        <Route
          exact
          path={`${path}reports/analytics/manager`}
          component={saleReport}
        />
        <Route exact path={`${path}reports/occupancy`} component={Occupancy} />
        <Route
          exact
          path={`${path}reports/paymentMode`}
          component={PaymnetMode}
        />
        <Route exact path={`${path}reports/taxes`} component={TaxesReport} />
        <Route
          exact
          path={`${path}reports/productWise`}
          component={ProductwiseReport}
        />
        <Route
          exact
          path={`${path}reports/dailySale`}
          component={DailySaleReport}
        />
        <Route path={`${path}vendors`} component={VendorsBuild} />
        <Route path={`${path}vendor/add`} component={AddVendor} />
        <Route path={`${path}vendor/tax/add`} component={AddVendorTax} />
        <Route path={`${path}vendor/items`} component={VendorsItemsBuild} />
        <Route
          exact
          path={`${path}vendor/item/categories`}
          component={VendorItemCategoryList}
        />
        <Route
          path={`${path}vendor/itemCategory/add`}
          component={AddVendorItemCategory}
        />
        <Route path={`${path}vendor/item/add`} component={Additems} />
        <Route exact path={`${path}vendor/item/edit`} component={EditItem} />
        <Route path={`${path}vendor/bills`} component={VendorsBillBuild} />
        <Route path={`${path}vendor/bill/add`} component={AddVendorBill} />
        <Route
          path={`${path}vendor/expenses`}
          component={ExpenseBillList}
        />.{" "}
        <Route path={`${path}vendor/expense/addOne`} component={AddExpense} />
        <Route
          path={`${path}vendor/expense/category`}
          component={ExpenseCategoryList}
        />
        <Route
          path={`${path}vendor/expense/add/category`}
          component={AddExpenseCategory}
        />
        <Route path={`${path}housekeeping`} component={HousekeepingBuilder} />
        <Route path={`${path}rate-inventory`} component={RateManagement} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Restaurant);
