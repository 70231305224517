import actions from "./actions";
const { DataService } = require("../../config/dataService/dataService");
import { getItem, setItem } from "../../utility/localStorageControl";
const { API } = require("../../config/api/index");
const {
  ApplicationAdd,
  ApplicationAddErr,
  ApplicationId,
  ApplicationIdErr,
} = actions;

const addOrUpdateApplication = (formData, register) => {
  console.log("poodapsdaodasdaad", formData);
  let Data = register;
  if (Data && Data._id) {
    formData["register_id"] = Data._id;
  }
  return async (dispatch) => {
    try {
      let getAddedApplication = {};
      getAddedApplication = await DataService.post(
        API.application.addApplication,
        formData
      );
      if (!getAddedApplication.data.error) {
        setItem("application", getAddedApplication.data.data);
        return getAddedApplication.data.data;
      } else {
        return getAddedApplication.data.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getApplicationById = (registerData) => {
  return async (dispatch) => {
    try {
      let register;
      if (registerData) {
        register = registerData._id;
      }
      const ApplicationByIdData = await DataService.get(
        `${API.application.getApplicationById}/?register_id=${register}`
      );

      if (!ApplicationByIdData.data.error) {
        return ApplicationByIdData.data.data[0];
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};

export { addOrUpdateApplication, getApplicationById };
