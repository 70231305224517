import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Button, Select } from "antd";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import Heading from "../../components/heading/heading";
import { addOrUpdateRoomLocation } from "../../redux/roomLocation/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { message } from "antd";
import { getAllRegisterList } from "../../redux/register/actionCreator";
const AddRoomLocation = () => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [roomLocationDetails, setRoomLocationDetails] = useState();
  const [RegisterNameList, setRegisterNameList] = useState([]);
  async function fetchRegisterName() {
    const getRgisterNameList = await dispatch(getAllRegisterList("sell"));
    if (getRgisterNameList && getRgisterNameList.RegisterList) {
      setRegisterNameList(
        getRgisterNameList.RegisterList.filter((val) => val.type == "hotel")
      );
    }
  }
  useEffect(() => {
    fetchRegisterName();
    if (location && location.state && location.state.roomLocation) {
      setRoomLocationDetails(location.state.roomLocation);
      form.setFieldsValue(location.state.roomLocation);
    }
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    let add;
    values["register_id"] = currentRegisterData?._id;
    if (roomLocationDetails && roomLocationDetails._id) {
      add = await dispatch(
        addOrUpdateRoomLocation(values, roomLocationDetails._id)
      );
    } else {
      add = await dispatch(addOrUpdateRoomLocation(values));
    }
    if (add && add.error == false) {
      history.push("/room-location");
    } else {
      message.error({
        content: add.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  };

  return (
    <>
      <Main>
        <PageHeader ghost className="comman-custom-pageheader" />
        <br></br>
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Add Room Location Details</Heading>
            </div>
          }
        >
          <Row gutter={25} justify="center">
            <Col xxl={12} md={14} sm={18} xs={24}>
              <div className="formDiv">
                <div className="mainFormdiv">
                  {" "}
                  <Form
                    className="comman-input"
                    autoComplete="off"
                    style={{ width: "100%" }}
                    form={form}
                    name="editProduct"
                    onFinish={handleSubmit}
                  >
                    <Form.Item
                      name="name"
                      label="Location"
                      rules={[
                        { required: true, message: "Location is required" },
                        {
                          max: 50,
                          message:
                            "Location Name cannot be more than 50 characters long.",
                        },
                      ]}
                    >
                      <Input
                        style={{ marginBottom: 6 }}
                        placeholder="Location Name"
                      />
                    </Form.Item>

                    <Form.Item name="description" label="Description">
                      <Input
                        style={{ marginBottom: 6 }}
                        placeholder="Description"
                      />
                    </Form.Item>

                    <div className="add-form-action" style={{ float: "right" }}>
                      <Form.Item>
                        <NavLink
                          to="/room-location/"
                          style={{ marginRight: 10 }}
                        >
                          <Button
                            className="btn-cancel btn-custom"
                            size="medium"
                          >
                            Go Back
                          </Button>
                        </NavLink>
                        <Button
                          size="medium"
                          className="btn-custom"
                          htmlType="submit"
                          type="primary"
                          raised
                        >
                          {loading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: 16,
                                    color: "white",
                                    margin: "0px 8px",
                                  }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default AddRoomLocation;
