import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Button,
  Radio,
  message,
  Checkbox,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect } from "react";
import {
  addOrUpdateVendorItem,
  exportReport,
  getVendorItemCategoryList,
} from "../../../redux/vendor/actionCreator";
import {
  addProductInInvenory,
  addUpdateInevntoryItems,
  getAllInventoryList,
} from "../../../redux/inventory/actionCreator";
const AddNewProduct = ({
  modalVisible,
  setModelVisible,
  getVendorItemListData,
  saveItemValue,
}) => {
  const dispatch = useDispatch();
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  const [form1] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [trackInventory, setTrackInventory] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const getVendorListData = async () => {
    setFetchLoader(true);
    let response = await dispatch(getVendorItemCategoryList());
    const getInventoryList = await dispatch(getAllInventoryList("sell"));
    if (
      response &&
      !response.error &&
      getInventoryList &&
      getInventoryList.taxesList
    ) {
      setInventoryList(getInventoryList.taxesList);
      setCategoryList(response.data);
      setFetchLoader(false);
    } else {
      setFetchLoader(false);
      message.error(response.message);
    }
  };

  useEffect(() => {
    getVendorListData();
  }, []);

  useEffect(() => {
    if (modalVisible.visible) {
      form1.resetFields();
    }
  }, [modalVisible]);

  console.log("fashfjkasfasfasfasfafafasfasf", modalVisible);
  const handleSubmit = async (value) => {
    if (loading == false) {
      setLoading(true);
      let itemObj = {
        ...value,
        inventory_item_id:
          Date.now().toString(36) +
          Math.random()
            .toString(36)
            .substr(2),
      };
      let Obj = {};
      if (value.track_inventory) {
        Obj.inventory_item_name = value?.name;
        Obj.unit_of_measure = value.measure;
        Obj._id = itemObj.inventory_item_id;
        Obj.isTracked = true;
        Obj.cost_price = value.cost_price;
        Obj.vendorCategory = categoryList.find(
          (p) => p._id == value.category
        )?.name;
        const dataSource = [Obj];
        let dataSource1 = [
          {
            product_name: Obj.inventory_item_name,
            product_category: Obj.vendorCategory,
            quantity: Number(0),
            product_id: Obj._id,
            unit_of_measure: Obj.unit_of_measure,
          },
        ];
        let payload = {
          inventory_items: dataSource,
        };
        let payload1 = {
          type: "Inward",
          products: dataSource1,
        };
        const getAddedProduct = await dispatch(
          addUpdateInevntoryItems(value.inventory_Id, payload)
        );
        const getAddedProduct1 = await dispatch(
          addProductInInvenory(value.inventory_Id, payload1)
        );
      }
      if (value.track_inventory) {
        itemObj["inventoryItemData"] = Obj;
        itemObj["inventoryItemData"]["inventory_id"] = value.inventory_Id;
      }
      let dataSource = [itemObj];
      let formData = {
        items: dataSource,
      };
      let reponse = await dispatch(addOrUpdateVendorItem(formData));
      if (!reponse.error) {
        await getVendorItemListData(true);
        saveItemValue(reponse.data, modalVisible.index);
        setLoading(false);
        setModelVisible({ visible: false, index: 0 });
      } else {
        setLoading(true);
        setModelVisible({ visible: false, index: 0 });
      }
    }
  };

  return (
    <div>
      <Modal
        title="Add New Product"
        visible={modalVisible.visible}
        // onOk={form1.submit}
        onCancel={() => setModelVisible(false)}
        footer={[
          <Button
            onClick={() => {
              setModelVisible({ index: 0, visible: false });
              setLoading(false);
            }}
          >
            Cancel
          </Button>,
          <Button type="primary" onClick={form1.submit}>
            {loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                      color: "white",
                      margin: "0px 8px",
                    }}
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )}
          </Button>,
        ]}
        width={600}
      >
        <div>
          <Form
            style={{ width: "100%" }}
            name="Export"
            form={form1}
            onFinish={handleSubmit}
          >
            <div className="add-product-block">
              <div className="add-product-content">
                <Form.Item
                  label="Item Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Item name is Required.",
                    },
                  ]}
                >
                  <Input
                    // className="input-text"
                    type="text"
                    style={{ margin: "8px 0 0", marginBottom: 10 }}
                    placeholder="Item Name"
                  />
                </Form.Item>

                <Form.Item
                  label="Category"
                  name="category"
                  className="customSelectHeight"
                  rules={[
                    {
                      required: true,
                      message: "Category is Required.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ marginBottom: 10 }}
                    // className="custom_select4545"
                    placeholder={
                      <span style={{ fontSize: "15px" }}>Search Category</span>
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {fetchLoader ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 16,
                              color: "#BD015D",
                              margin: "0px 8px",
                            }}
                            spin
                          />
                        }
                      />
                    ) : (
                      categoryList?.map((val) => {
                        return (
                          <Select.Option key={val._id} value={val._id}>
                            {val.name}
                          </Select.Option>
                        );
                      })
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Cost Price"
                  name="cost_price"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 12px)",
                  }}
                >
                  <Input
                    type="number"
                    min={0}
                    style={{ marginBottom: 10 }}
                    step="any"
                    placeholder="Cost Price"
                    onKeyPress={(event) => {
                      if (event.key.match("[0-9,.]+")) {
                        return true;
                      } else {
                        return event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <span
                  style={{
                    display: "inline-block",
                    width: "24px",
                    lineHeight: "32px",
                    textAlign: "center",
                  }}
                ></span>
                <Form.Item
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 12px)",
                  }}
                  label="Unit Of Measure"
                  name="measure"
                  rules={[
                    {
                      required: true,
                      message: "Unit Of Measure is Required.",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    style={{ marginBottom: 10 }}
                    placeholder="Unit Of Measure"
                  />
                </Form.Item>

                <Form.Item
                  name="track_inventory"
                  valuePropName="checked"
                  style={{ marginTop: "10px" }}
                >
                  <Checkbox
                    className="add-form-check"
                    onChange={(e) => setTrackInventory(e.target.checked)}
                  >
                    Track purchase Inventory for this item
                  </Checkbox>
                </Form.Item>
                {trackInventory && (
                  <Form.Item
                    name="inventory_Id"
                    label="Inventory"
                    rules={[
                      {
                        required: true,
                        message: "A inventory must be selected for the track.",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select a Inventory"
                    >
                      {inventoryList.map((groupedData) => {
                        if (
                          groupedData.linked_registers?.length > 0 &&
                          groupedData.linked_registers.includes(
                            currentRegisterData?._id
                          )
                        ) {
                          return (
                            <Option
                              key={groupedData._id}
                              value={groupedData._id}
                            >
                              {groupedData.inventory_name}
                            </Option>
                          );
                        } else if (groupedData.linked_registers?.length == 0) {
                          return (
                            <Option
                              key={groupedData._id}
                              value={groupedData._id}
                            >
                              {groupedData.inventory_name}
                            </Option>
                          );
                        }
                      })}
                    </Select>
                  </Form.Item>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewProduct;
