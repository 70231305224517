import React, { useState, useRef, useEffect } from "react";
import {
  Checkbox,
  Row,
  Col,
  Input,
  Form,
  Select,
  Tooltip,
  TreeSelect,
} from "antd";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import Heading from "../../../../../components/heading/heading";
import { Button } from "../../../../../components/buttons/buttons";
import "../../setting.css";
import TextArea from "antd/lib/input/TextArea";
import { InfoCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { getItem, setItem } from "../../../../../utility/localStorageControl";
import { getAllRegisterList } from "../../../../../redux/register/actionCreator";
import {
  addOrUpdateBingage,
  getAllBingageList,
} from "../../../../../redux/bingage/actionCreator";
import {
  addOrUpdateInventory,
  getAllInventoryList,
} from "../../../../../redux/inventory/actionCreator";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TableWrapper, Main } from "../../../../styled";
import { getAllCashiersList } from "../../../../../redux/cashiers/actionCreator";
const addInventory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let isMounted = useRef(true);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { SHOW_PARENT } = TreeSelect;
  const [totalIds, setTotalId] = useState([]);
  const [emailId, setEmailid] = useState(false);
  const [enable_user_access, setEnableUserAccess] = useState(false);
  const [userList, setUserList] = useState([]);
  const [bingageErr, setBingageErr] = useState(false);
  const [RegisterList, setRegisterList] = useState([]);
  const [bingageList, setBingageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [apiData, setApiData] = useState();
  const [treevalues, setValues] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [registererr, setRegisterErr] = useState(false);
  const [registerNameList, setRegisterNameList] = useState([]);
  const email = getItem("userDetails")?.email;
  async function fetchInventoryList() {
    const getInventoryList = await dispatch(getAllInventoryList("sell"));
    const userListReponse = await dispatch(getAllCashiersList("sell"));
    console.log("userListReponseuserListReponse", userListReponse);
    if (isMounted.current && getInventoryList && getInventoryList.taxesList) {
      let linkedRegister = [];
      getInventoryList.taxesList.map((val) => {
        val.linked_registers.map((j) => {
          linkedRegister.push(j);
        });
      });
      setInventoryList(linkedRegister);
      if (userListReponse?.cashiersList) {
        setUserList(
          userListReponse.cashiersList.filter(
            (item) => item.has_manager_permission
          )
        );
      }
    }
  }
  useEffect(() => {
    // fetchBingagesList();
    async function fetchRegisterList() {
      const getRegisterList = await dispatch(getAllRegisterList("sell"));
      if (isMounted.current && getRegisterList && getRegisterList.RegisterList)
        setRegisterList(getRegisterList.RegisterList);
      if (location && location.state && location.state.data) {
        console.log("data9090909data", location.state.data);

        setDisabledSave(true);
        setApiData({
          inventory_name: location?.state?.data?.inventory_name,
          description: location.state.data?.description,
          email_alerts: location.state.data?.email_alerts,
          email_id: location.state.data?.email_id,
        });
        form.setFieldsValue({
          inventory_name: location?.state?.data?.inventory_name,
          description: location.state.data?.description,
          email_alerts: location.state.data?.email_alerts,
          email_id: location.state.data?.email_id,
          user_access: location?.state?.data?.user_access,
        });
        setValues(location.state.data?.linked_registers);
        setEmailid(location.state.data?.email_alerts);
      }
    }

    if (isMounted.current) {
      fetchRegisterList();
      fetchInventoryList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (RegisterList.length) {
      const data = [];
      RegisterList.map((value) => {
        if (
          location?.state?.data.linked_registers.includes(value._id) ||
          inventoryList.find((k) => k == value._id) == undefined
        ) {
          data.push(value);
        }
      });
      setRegisterNameList(data);
    }
  }, [RegisterList, inventoryList]);
  useEffect(() => {
    if (
      registerNameList &&
      registerNameList.length &&
      location?.state?.data?.linked_registers?.length
    ) {
      form.setFieldsValue({
        linked_registers: location.state.data?.linked_registers[0],
      });
    }
  }, [registerNameList]);

  const handleSubmit = async (formData) => {
    if (loading == false) {
      if (formData.linked_registers) {
        formData.linked_registers = [formData.linked_registers];
      } else {
        formData.linked_registers = [];
      }
      setLoading(true);
      let response;
      if (location?.state?.data?.id) {
        response = await dispatch(
          addOrUpdateInventory(formData, location?.state?.data?.id)
        );
      } else {
        response = await dispatch(addOrUpdateInventory(formData));
      }

      if (response) {
        // const getInventoryList = await dispatch(getAllInventoryList());
        // if (getInventoryList?.taxesList) {
        setLoading(false);
        history.push(`/inventory`);
        // }
      } else {
        setLoading(false);
      }
    }
  };

  const handleFormChange = (item, allFileds) => {
    setLoading(false);
    if (apiData) {
      let currentFormData = {};
      _.each(apiData, (val, key) => {
        let findData = allFileds.find((k) => k.name[0] == key);
        if (findData) {
          currentFormData[findData.name[0]] =
            findData.name[0] == "tax_percentage"
              ? Number(findData.value)
              : findData.value;
        }
      });
      if (_.isEqual(apiData, currentFormData)) {
        setDisabledSave(true);
      } else {
        setDisabledSave(false);
      }
      return true;
    }
  };

  return (
    <Main className="inventory-items">
      <Cards
        title={
          <div className="setting-card-title">
            <Heading as="h4">Your Inventory Details</Heading>
            <span>
              An inventory is a physical location where you keep your goods.
              Inventory can be your outlet or a separate warehouse.
              <br />
              Inventory can be standalone or linked to one or more registers.
            </span>
          </div>
        }
      >
        <Row gutter={25} justify="center">
          <Col xxl={12} md={14} sm={18} xs={24}>
            <div className="formDiv">
              <div className="mainFormdiv">
                {" "}
                <Form
                  autoComplete="off"
                  style={{ width: "100%" }}
                  form={form}
                  name="add inventory"
                  onFinish={handleSubmit}
                  onFieldsChange={(val, allFileds) =>
                    handleFormChange(val, allFileds)
                  }
                  onChange={() => setBingageErr(false)}
                >
                  <Form.Item
                    name="inventory_name"
                    label="Inventory Name"
                    rules={[
                      {
                        min: 3,
                        message:
                          "Inventory name must be at least 3 characters long",
                      },
                      {
                        max: 50,
                        message:
                          "Inventory name cannot be more than 50 characters long.",
                      },
                      { required: true, message: "Inventory name is required" },
                    ]}
                  >
                    <Input
                      style={{ marginBottom: 10 }}
                      placeholder="Inventory Name"
                    />
                  </Form.Item>
                  <Form.Item name="description" label="Description (optional)">
                    <Input
                      style={{ marginBottom: 10 }}
                      placeholder="Description"
                    />
                  </Form.Item>

                  <Form.Item
                    name="linked_registers"
                    label="Select the Registers linked to this Inventory"
                  >
                    <Select
                      style={{ width: "100%", marginBottom: 10 }}
                      placeholder="Select a Register"
                      showSearch
                      disabled={location?.state?.data ? true : false}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {registerNameList.map((groupedData) => (
                        <Option key={groupedData._id} value={groupedData._id}>
                          {groupedData.register_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item name="enable_user_access" valuePropName="checked">
                <Checkbox
                  className="add-form-check"
                  onChange={(e) => setEnableUserAccess(e.target.checked)}
                >
                  Enable user Access
                </Checkbox>
              </Form.Item> */}
                  <Form.Item name="user_access" label="Enable User Access">
                    <Checkbox.Group
                      style={{
                        width: "100%",
                      }}
                      defaultValue={location?.state?.data?.user_access}
                    >
                      <Row>
                        {userList?.map((g) => {
                          return (
                            <Col span={8}>
                              <Checkbox value={g?._id}>{g?.username}</Checkbox>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item
                    name="email_alerts"
                    valuePropName="checked"
                    label="Email Alerts"
                  >
                    <Checkbox
                      className="add-form-check"
                      onChange={(e) => setEmailid(e.target.checked)}
                    >
                      Receive alert when inventory reaches zero / goes below
                      reorder level
                    </Checkbox>
                  </Form.Item>
                  {emailId && (
                    <Form.Item
                      name="email_id"
                      initialValue={email}
                      label="Email ID"
                      rules={[
                        {
                          message: "Email address is required",
                          required: true,
                        },
                        { type: "email", message: "A valid email is required" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}

                  <Form.Item style={{ float: "right" }}>
                    <Button
                      onClick={() => history.push("/inventory")}
                      className="go-back-button"
                      size="medium"
                      type="white"
                      style={{ marginRight: "10px" }}
                    >
                      Go Back
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      // disabled={disabledSave}
                    >
                      {loading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 16,
                                color: "white",
                                margin: "0px 8px",
                              }}
                              spin
                            />
                          }
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Cards>
    </Main>
  );
};

export { addInventory };
