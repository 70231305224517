import React, { useState } from "react";
import { Form, Input, Select, Modal, DatePicker, Button, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { exportReport } from "../../../../../redux/inventory/actionCreator";
import { useEffect } from "react";
const Exportform = ({
  modalVisible,
  setModelVisible,
  reportType,
  inventoryId,
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  let emailData = localStorage.getItem("email_id");
  const [form1] = Form.useForm();
  const [startdate, setstartdate] = useState();
  const [DateRanged, setDateRange] = useState("Today");
  const [selectReport, setSelectReport] = useState("Stock Report");
  const [mailsend, setmailSend] = useState(false);
  const [endDate, setenddate] = useState();
  const [loading, setLoading] = useState(false);
  const [noDataFound, setNodataFound] = useState(false);
  const submitExport = async (values) => {
    values.reportType = reportType;
    values.inventoryId = inventoryId;
    if (selectReport == "action") {
      values["action_type"] = values.action_report;
    }
    if (DateRanged === "custom") {
      values.endDate = endDate;
      values.startDate = startdate;
      setmailSend(true);
      let response = await dispatch(exportReport(values));
      if (!response.error) {
        message.success(response.message);
      }
    } else {
      setmailSend(true);
      let response = await dispatch(exportReport(values));
      if (!response.error) {
        message.success(response.message);
      }
    }
  };
  useEffect(() => {
    setmailSend(false);
  }, []);
  return (
    <div>
      <Modal
        title="Request a Report"
        visible={modalVisible}
        // onOk={form1.submit}
        onCancel={() => setModelVisible(false)}
        footer={
          mailsend
            ? [
                <Button
                  type="primary"
                  onClick={() => {
                    setModelVisible(false);
                    setmailSend(false);
                    setDateRange("Today");
                    form1.resetFields();
                  }}
                >
                  Save
                </Button>,
              ]
            : [
                <Button
                  onClick={() => {
                    setModelVisible(false);
                    setLoading(false);
                  }}
                >
                  Cancel
                </Button>,
                <Button type="primary" onClick={form1.submit}>
                  {loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            color: "white",
                            margin: "0px 8px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Save"
                  )}
                </Button>,
              ]
        }
        width={600}
      >
        <div>
          {mailsend ? (
            <p>
              The report will be emailed to you in about 5 to 10 minutes. You
              will be notified by email
            </p>
          ) : (
            <Form
              style={{ width: "100%" }}
              name="Export"
              form={form1}
              onFinish={submitExport}
            >
              <div className="add-product-block">
                <div className="add-product-content">
                  <Form.Item
                    name="action_type"
                    label="Choose A Report Type"
                    initialValue={"Stock Report"}
                    rules={[
                      {
                        required: true,
                        message: "Please Select report type",
                      },
                    ]}
                  >
                    <Select
                      name="report"
                      placeholder="Choose A Report Type"
                      style={{ width: "100%", marginBottom: 10 }}
                      onChange={(e) => setSelectReport(e)}
                    >
                      <Option value={"Stock Report"}>Stock Report</Option>
                      <Option value={"Consumption"}>Consumption</Option>
                      <Option value="action">Action Report</Option>
                    </Select>
                  </Form.Item>
                  {selectReport == "action" && (
                    <Form.Item
                      name="action_report"
                      label="Choose A Action Report Type"
                      rules={[
                        {
                          required: true,
                          message: "Please Select report type",
                        },
                      ]}
                    >
                      <Select
                        name="report1"
                        placeholder="Choose A Action Report Type"
                        style={{ width: "100%", marginBottom: 10 }}
                      >
                        <Option value="all">All</Option>
                        <Option value={"Inward"}>Inward</Option>
                        <Option value={"Transfer Out"}>Transfer Out</Option>
                        <Option value={"Wastage"}>Wastage</Option>
                        <Option value={"Adjustment"}>Adjustment</Option>
                        <Option value={"Sell"}>Sell</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {selectReport != "Stock Report" && (
                    <Form.Item
                      initialValue="today"
                      label="Date Range"
                      name="dateRange"
                    >
                      <Select
                        name="report"
                        style={{ width: "100%", marginBottom: 10 }}
                        onChange={(value) => setDateRange(value)}
                      >
                        <Option value="today">Today </Option>
                        <Option value="yesterday">Yesterday</Option>
                        <Option value="this_month">This Month</Option>
                        <Option value="last_month">Last Month</Option>
                        <Option value="custom">Custom selection</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {DateRanged === "custom" ? (
                    <div>
                      <Form.Item
                        label="start"
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                      >
                        <DatePicker
                          style={{ height: "35px", marginBottom: 10 }}
                          placeholder="dd-mm-yyyy"
                          format="YYYY-MM-DD"
                          onChange={(date, datestring) =>
                            setstartdate(datestring)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="end"
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        }}
                      >
                        <DatePicker
                          onChange={(date, datestring) =>
                            setenddate(datestring)
                          }
                          style={{ height: "35px", marginBottom: 10 }}
                          placeholder="dd-mm-yyyy"
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                    </div>
                  ) : (
                    ""
                  )}

                  <Form.Item
                    name="email"
                    label="Send to Email Address"
                    initialValue={emailData}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                      { type: "email", message: "A valid email is required" },
                    ]}
                  >
                    <Input placeholder="Report will be send to this email" />
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Exportform;
