import { Alert } from "antd";
import { getItem } from "../../utility/localStorageControl";
import moment from "moment";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

export const addOrUpdateVendor = (payloads, id) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;
      if (id) {
        getVariantGroup = await DataService.put(
          `${API.vendor.update}/${id}`,
          payloads
        );
      } else {
        getVariantGroup = await DataService.post(API.vendor.add, payloads);
      }

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getVendorList = () => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;

      getVariantGroup = await DataService.get(
        `${API.vendor.list}?register_id=${register_id}`
      );
      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const deleteVendor = (variantIds) => {
  return async (dispatch) => {
    try {
      const getDeletedVariant = await DataService.post(
        API.vendor.deleteAll,
        variantIds
      );
      if (!getDeletedVariant.data.error) {
        return getDeletedVariant.data;
      } else {
        return getDeletedVariant.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const addOrUpdateVendorTax = (payloads, id) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;
      if (id) {
        getVariantGroup = await DataService.put(
          `${API.vendor.taxUpdate}/${id}`,
          payloads
        );
      } else {
        getVariantGroup = await DataService.post(API.vendor.taxAdd, payloads);
      }

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getVendorTaxList = () => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;

      getVariantGroup = await DataService.get(
        `${API.vendor.taxList}?register_id=${register_id}`
      );
      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const deleteVendorTax = (variantIds) => {
  return async (dispatch) => {
    try {
      const getDeletedVariant = await DataService.post(
        API.vendor.taxDeleteAll,
        variantIds
      );
      if (!getDeletedVariant.data.error) {
        return getDeletedVariant.data;
      } else {
        return getDeletedVariant.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const addOrUpdateVendorItemsCategory = (payloads, id) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;
      if (id) {
        getVariantGroup = await DataService.put(
          `${API.vendor.itemCategoryUpdate}/${id}`,
          payloads
        );
      } else {
        getVariantGroup = await DataService.post(
          API.vendor.itemCategoryAdd,
          payloads
        );
      }

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const addOrFindVendorCategory = (payloads) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;

      getVariantGroup = await DataService.post(
        API.vendor.itemCategoryFindAndAdd,
        payloads
      );

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getVendorItemCategoryList = () => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      getVariantGroup = await DataService.get(
        `${API.vendor.itemCategoryList}?register_id=${register_id}`
      );
      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const deleteVendorItemCategory = (variantIds) => {
  return async (dispatch) => {
    try {
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      const getDeletedVariant = await DataService.post(
        API.vendor.itemCategoryAddDeleteAll,
        {
          ids: variantIds.ids,
          register_id: register_id,
        }
      );
      if (!getDeletedVariant.data.error) {
        return getDeletedVariant.data;
      } else {
        return getDeletedVariant.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const addOrUpdateVendorItem = (payloads, id) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;
      if (id) {
        getVariantGroup = await DataService.put(
          `${API.vendor.itemUpdate}/${id}`,
          payloads
        );
      } else {
        getVariantGroup = await DataService.post(API.vendor.itemAdd, payloads);
      }

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getVendorItemList = () => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      getVariantGroup = await DataService.get(
        `${API.vendor.itemList}?register_id=${register_id}`
      );
      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const deleteVendorItem = (variantIds) => {
  return async (dispatch) => {
    try {
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      const getDeletedVariant = await DataService.post(
        API.vendor.itemDeleteAll,
        {
          ids: variantIds.ids,
          register_id: register_id,
        }
      );
      if (!getDeletedVariant.data.error) {
        return getDeletedVariant.data;
      } else {
        return getDeletedVariant.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const addOrUpdateVendorBill = (payloads, id) => {
  return async (dispatch) => {
    try {
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;
      payloads["userName"] = getItem("userDetails")?.username;
      let getVendorBill = {};
      if (id) {
        payloads.entry_date = new Date();
        getVendorBill = await DataService.put(
          `${API.vendor.billUpdate}/${id}`,
          payloads
        );
      } else {
        payloads.actual_time = new Date();
        payloads.month_year = moment(new Date()).format("MM/YYYY");
        getVendorBill = await DataService.post(API.vendor.billAdd, payloads);
      }

      if (!getVendorBill.data.error) {
        return getVendorBill.data;
      } else {
        return getVendorBill.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getVendorBillList = (currentPage, obj) => {
  return async (dispatch) => {
    try {
      let getVendorBill = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      let url = `${
        API.vendor.billList
      }?page=${currentPage}&limit=${10}&register_id=${register_id}&startDate=${
        obj.startDate
      }&endDate=${obj.endDate}&timeZon=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }&status=${obj.status}&invoice_number=${
        obj.invoice_number
      }&invoice_start_date=${obj.invoice_start_date}&invoice_end_date=${
        obj.invoice_end_date
      }&search_vendor=${obj.search_vendor}&&paymnetStatus=${obj.paymnetStatus}`;
      getVendorBill = await DataService.get(url);
      if (!getVendorBill.data.error) {
        return getVendorBill.data;
      } else {
        return getVendorBill.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const deleteVendorBill = (billIds) => {
  return async (dispatch) => {
    try {
      billIds["userName"] = getItem("userDetails")?.username;
      billIds.entry_date = new Date();
      const getDeletedBill = await DataService.post(
        API.vendor.billDeleteAll,
        billIds
      );
      if (!getDeletedBill.data.error) {
        return getDeletedBill.data;
      } else {
        return getDeletedBill.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getVendorByPurchase = (startDate, endDate) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      let url = `${
        API.vendor.getVendorByPurchase
      }?register_id=${register_id}&invoice_start_date=${startDate}&invoice_end_date=${endDate}&timeZon=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`;

      getVariantGroup = await DataService.get(url);
      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const exportReport = (payloads) => {
  return async () => {
    try {
      let url = API.vendor.exportReport;

      let startDate;
      let endDate;

      if (payloads.dateRange == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (payloads.dateRange == "yesterday") {
        console.log("yesterday");
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "custom") {
        startDate = payloads.startDate;
        endDate = payloads.endDate;
      }

      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      payloads["startDate"] = startDate;
      payloads["endDate"] = endDate;
      payloads["timeZone"] = timeZone;

      const resp = await DataService.post(url, payloads);

      if (resp && !resp.data.error) {
        return resp.data;
      } else {
        return resp.data;
      }
    } catch (error) {
      return false;
    }
  };
};

export const addOrUpdateExpenseCategory = (payloads, id) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;
      if (id) {
        getVariantGroup = await DataService.put(
          `${API.vendor.expenseCategoryUpdate}/${id}`,
          payloads
        );
      } else {
        getVariantGroup = await DataService.post(
          API.vendor.expenseCategoryAdd,
          payloads
        );
      }

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getExpenseCategoryList = () => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;

      getVariantGroup = await DataService.get(
        `${API.vendor.expenseCategoryList}?register_id=${register_id}`
      );
      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};
export const deleteExpenseCategory = (variantIds) => {
  return async (dispatch) => {
    try {
      const getDeletedVariant = await DataService.post(
        API.vendor.expenseCategoryDeleteAll,
        variantIds
      );
      if (!getDeletedVariant.data.error) {
        return getDeletedVariant.data;
      } else {
        return getDeletedVariant.data;
      }
    } catch (err) {
      return false;
    }
  };
};
export const addOrUpdateExpense = (payloads, id) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      payloads["register_id"] = register_id;
      payloads["actual_time"] = new Date();
      payloads["userName"] = getItem("userDetails")?.username;
      getVariantGroup = await DataService.post(API.vendor.expenseAdd, payloads);

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export const getExpenseList = (currentPage, obj) => {
  return async (dispatch) => {
    try {
      let getVendorBill = {};
      let register_id = getItem("setupCache")?.register?.find(
        (val) => val.active
      )?._id;
      let url = `${
        API.vendor.expenseList
      }?page=${currentPage}&limit=${10}&register_id=${register_id}&timeZon=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }&notes=${obj.notes}&invoice_start_date=${
        obj.invoice_start_date
      }&search_vendor=${obj.search_vendor}&invoice_end_date=${
        obj.invoice_end_date
      }&expense_category=${obj.expense_category}&payment_type=${
        obj.payment_type
      }`;
      getVendorBill = await DataService.get(url);
      if (!getVendorBill.data.error) {
        return getVendorBill.data;
      } else {
        return getVendorBill.data;
      }
    } catch (err) {
      return false;
    }
  };
};
export const cancelExpense = (payloads) => {
  return async (dispatch) => {
    try {
      let getVariantGroup = await DataService.post(
        API.vendor.expenseCancel,
        payloads
      );

      if (!getVariantGroup.data.error) {
        return getVariantGroup.data;
      } else {
        return getVariantGroup.data;
      }
    } catch (err) {
      return false;
    }
  };
};
export const exportExpenseReport = (payloads) => {
  return async () => {
    try {
      let url = API.vendor.exportExpenseReport;

      let startDate;
      let endDate;

      if (payloads.dateRange == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (payloads.dateRange == "yesterday") {
        console.log("yesterday");
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (payloads.dateRange == "custom") {
        startDate = payloads.startDate;
        endDate = payloads.endDate;
      }

      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      payloads["startDate"] = startDate;
      payloads["endDate"] = endDate;
      payloads["timeZone"] = timeZone;

      const resp = await DataService.post(url, payloads);

      if (resp && !resp.data.error) {
        return resp.data;
      } else {
        return resp.data;
      }
    } catch (error) {
      return false;
    }
  };
};
