import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Input, Modal, Button, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { UserTableStyleWrapper } from "../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../Customer/Style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "../../components/popup/popup";
import { useHistory } from "react-router-dom";
import "../Customer/customer.css";
import { CaretDownOutlined } from "@ant-design/icons";
import { getItem, setItem } from "../../utility/localStorageControl";
import { getAllRoomList, deleteRoom } from "../../redux/room/actionCreator";
import { getAllroomTypeList } from "../../redux/roomType/actionCreator";
import { getAllRoomLocationList } from "../../redux/roomLocation/actionCreator";

const Room = () => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [roomListData, setRoomListData] = useState([]);
  const [loading, setLoading] = useState(false);
  let isMounted = useRef(true);
  const [loader, setLoader] = useState(true);
  const [selectionType] = useState("checkbox");
  let [search, setsearch] = useState("");
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  let [roomTypeList, setRoomTypeList] = useState([]);
  const [selectedItems, setSelecteditems] = useState([]);
  const [roomLocationList, setRoomLocationList] = useState([]);
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  async function fetchRoomTypeList() {
    const getRoomTypeList = await dispatch(
      getAllroomTypeList(currentRegisterData?._id, "sell")
    );
    if (isMounted.current) {
      setRoomTypeList(getRoomTypeList?.data);
    }
  }

  async function fetchRoomLocation() {
    const getRoomLocationList = await dispatch(
      getAllRoomLocationList(currentRegisterData?._id)
    );
    if (getRoomLocationList && getRoomLocationList.error == false) {
      setRoomLocationList(getRoomLocationList?.data);
    }
  }

  async function fetchRoomList() {
    setLoader(true);
    const getRoomList = await dispatch(
      getAllRoomList(currentRegisterData?._id, "sell")
    );
    if (getRoomList && getRoomList.error == false) {
      setLoader(false);
      setRoomListData(getRoomList?.roomList);
    } else {
      message.error({
        content: getRoomList.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  }

  useEffect(() => {
    if (currentRegisterData && currentRegisterData?._id) {
      fetchRoomLocation();
      fetchRoomTypeList();
      fetchRoomList();
    }
  }, [currentRegisterData?._id]);

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelDeleteVisible(true);
          setLoading(false);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Delete Selected item</span>
      </NavLink>
    </>
  );
  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      width: "2%",
    },
    {
      title: "Room Name",
      dataIndex: "room_name",
      key: "room_name",
      fixed: true,
      align: "left",
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      key: "room_type",
      render(text, record) {
        if (record && record.room_type) {
          return {
            children: (
              <div>
                {record?.room_type !== null && record?.room_type?.name !== ""
                  ? record.room_type.name
                  : "-"}
              </div>
            ),
          };
        }
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render(text, record) {
        if (record && record.location) {
          return {
            children: (
              <div>
                {record?.location !== null && record?.location?.name !== ""
                  ? record.location.name
                  : "-"}
              </div>
            ),
          };
        }
      },
    },
  ];
  const deleteSelectedRoom = async () => {
    setLoading(true);
    let allIds = [];
    let roomTypesIds = [];
    selectedItems.map((item) => {
      roomTypesIds.push(item?.room_type?._id);
      allIds.push(item?._id);
    });
    const getDeleted = await dispatch(
      deleteRoom(
        { ids: allIds, roomTypesIds: roomTypesIds },
        currentRegisterData?._id
      )
    );
    if (getDeleted && getDeleted.error == false) {
      fetchRoomList();
      setLoading(false);
      setModelDeleteVisible(false);
    } else {
      message.error({
        content: getDeleted.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
      setLoading(false);
      setModelDeleteVisible(false);
    }
  };
  const handleCancel = (e) => {
    setModelDeleteVisible(false);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelecteditems(selectedRows);
    },
  };
  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div className="table_titles">
                  <h2>Rooms</h2>
                  <span className="title-counter">
                    {roomListData.length} Rooms
                  </span>
                </div>
                <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Input
                    suffix={<SearchOutlined />}
                    autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                    placeholder="Search by Name"
                    style={{
                      borderRadius: "30px",
                      width: "250px",
                    }}
                    onChange={(e) => setsearch(e.target.value)}
                    value={search}
                  />
                </div>
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions custom_action">
                <Button
                  className="ant-btn ant-btn-primary ant-btn-md addprdpls"
                  onClick={() => {
                    history.push(`${path}/add`, {
                      roomTypeList: roomTypeList,
                      roomLocationList: roomLocationList,
                    });
                  }}
                >
                  <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                  Add Room
                </Button>
              </div>,
            ]}
          />
        </CardToolbox>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="_id"
                        className="products_lsttable"
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                        pagination={false}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        className="products_lsttable"
                        rowSelection={{
                          type: selectionType,
                          ...rowSelection,
                        }}
                        onRow={(row) => ({
                          onClick: () =>
                            history.push(`${path}/edit`, {
                              roomDetails: row,
                              roomTypeList: roomTypeList,
                              roomLocationList: roomLocationList,
                            }),
                        })}
                        size="small"
                        dataSource={roomListData.filter((value) =>
                          value.room_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )}
                        //
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                      />
                    )}
                  </TableWrapper>
                  <Modal
                    title="Confirm Delete"
                    okText={
                      loading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 16,
                                color: "white",
                                margin: "0px 14px",
                              }}
                              spin
                            />
                          }
                        />
                      ) : (
                        "Delete"
                      )
                    }
                    visible={modalDeleteVisible}
                    onOk={deleteSelectedRoom}
                    onCancel={handleCancel}
                    width={600}
                  >
                    <p>Are you sure you want to delete selected rooms ?</p>
                  </Modal>
                </div>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Room;
