import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col, Table, Modal, Spin } from "antd";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../Style";
import { UserTableStyleWrapper } from "../../pages/style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import {
  getAllBookingList,
  getBookingById,
} from "../../.././redux/sell/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import "./booking.css";
import { bookingAddInLocalData } from "../../../utility/localStorageControl";
import moment from "moment";

const BookingList = (props) => {
  const { tabChangeToCurrent, setlocalCartInfo } = props;
  const dispatch = useDispatch();
  const { BookingList1 } = useSelector((state) => {
    return {
      BookingList1: state.sellData.bookingList,
    };
  }, shallowEqual);
  const [BookingList, setBookingList] = useState([]);
  const [loading, setloading] = useState(true);
  const didMount = useRef(false);
  const [modalVisible, setModelVisible] = useState(false);

  let locale = {
    emptyText: (<Spin style={{ marginTop: "20px", }} />),
  };


  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );

        return (resizeObserverErr) ? resizeObserverErr.setAttribute("style", "display: none")
          : (resizeObserverErrDiv) ? resizeObserverErrDiv.setAttribute("style", "display: none")
            : " "
      }
    });
    const fetchdata = async () => {
      setloading;
      let bookinglist = await dispatch(getAllBookingList());
      if (bookinglist?.bookingList) {
        setBookingList([
          ...bookinglist?.bookingList.filter(
            (x) =>
              commonFunction.convertToDate(
                new Date(
                  `${x.details.bookingDetails.delivery_date},${x.details.bookingDetails.delivery_time}`
                ),
                "MM/DD/YYYY"
              ) >= moment().format("L") &&
              commonFunction.convertToDate(
                new Date(
                  `${x.details.bookingDetails.delivery_date},${x.details.bookingDetails.delivery_time}`
                ),
                "MM/DD/YYYY"
              ) <= moment().format("L")
          ),
        ]);
        setloading(false);
        didMount.current = true;
      }
    };
    fetchdata();
  }, []);

  useEffect(() => {
    if (didMount.current) {
      setBookingList([...BookingList1]);
    }
  }, [BookingList1]);

  const dataSource = [];
  if (BookingList.length > 0) {
    BookingList.map((value) => {
      const { _id, created_at, details, customer } = value;
      return dataSource.push({
        id: _id,
        created_at: created_at,
        booking_number: details.bookingDetails?.booking_number,
        customer_mobial: customer?.mobile,
        customer_name: customer.name ? customer.name : "-",
        total: details.priceSummery.total,
        booking_amount: details.bookingDetails.booking_advance
          ? details.bookingDetails.booking_advance
          : "-",
        delivery_date_time:
          details.bookingDetails.delivery_date +
          " " +
          details.bookingDetails.delivery_time,
        dateformatDelivery_date_time: moment(
          details.bookingDetails.delivery_date +
          " " +
          details.bookingDetails.delivery_time
        ).format(),
      });
    });
  }

  const columns = [
    {
      title: "Booking Number",
      dataIndex: "booking_number",
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },

    {
      title: "Customer Mobile",
      dataIndex: "customer_mobial",
    },
    ,
    {
      title: "Customer Name",
      dataIndex: "customer_name",
    },
    {
      title: "Booking Amount",
      dataIndex: "booking_amount",
    },
    {
      title: "	Total",
      dataIndex: "total",
    },
    {
      title: "	Created At",
      dataIndex: "created_at",
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (created_at, record) => (
        <span>
          {commonFunction.convertToDate(created_at, "MMM DD, Y, h:mm A")}
        </span>
      ),
    },
    {
      title: "Delivery At",
      dataIndex: "dateformatDelivery_date_time",
      align: "left",
      sorter: (a, b) =>
        moment(a.dateformatDelivery_date_time).unix() -
        moment(b.dateformatDelivery_date_time).unix(),
      render: (text, record) => <span>{record.delivery_date_time}</span>,
    },
  ];

  const getBookingData = async (id) => {
    const bookigData = await dispatch(getBookingById(id));

    if (bookigData && bookigData.bookingIdData.details.localCartInfo) {
      let cartInfo = bookingAddInLocalData(
        bookigData.bookingIdData.details.localCartInfo,
        bookigData.bookingIdData
      );
      setlocalCartInfo(bookigData);
      tabChangeToCurrent("CURRENT");
    }
  };

  return (
    <>
      <Main className="booking_tab bookin_tabtp">
        <CardToolbox>
          <PageHeader
            ghost
            className="rec_booking"
            subTitle={<>
              <div className="table_titles">
                <h2>Bookings</h2>
                <span className="title-counter">
                  {" "}
                  {BookingList1.length} Bookings{" "}
                </span>
              </div>
              <div
                style={{ boxShadow: "none", marginLeft: "10px" }}
                className="search_lrm"
              ></div>
            </>}
            buttons={[
              <div key="1" className="page-header-actions">
                <CalendarButtonPageHeader key="1" type="booking" />
              </div>,
            ]}
          />
        </CardToolbox>

        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table posProductTbl">
                  <TableWrapper className="table-responsive">
                    {loading ? (
                      <Table
                        className="receipt-custom-table"
                        rowKey="id"
                        locale={locale}
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 900 }}
                      />
                    ) : (
                      <Table
                        className="receipt-custom-table"
                        rowKey="id"
                        size="small"
                        dataSource={dataSource}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 900 }}
                        onRow={(row) => ({
                          onClick: () => getBookingData(row.id),
                        })}
                        pagination={{
                          total: dataSource.length,
                          showSizeChanger: true,
                          pageSizeOptions: ["10", "50", "100", "500", "1000"],
                        }}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>

        <Modal
          title="Request a Report"
          visible={modalVisible}
          onOk={() => setModelVisible(false)}
          onCancel={() => setModelVisible(false)}
          width={600}
        ></Modal>
      </Main>
    </>
  );
};

export { BookingList };
