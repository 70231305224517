import React, { useState, useRef, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Form, Input, Button, Tooltip, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AuthWrapper } from "./style";
import {
  verifyOtp,
  forgotPin,
  checkSubScription,
  login,
} from "../../../../redux/authentication/actionCreator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const VerifyOtp = () => {
  const history = useHistory();
  const { Option } = Select;
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.auth.loading);
  const [form] = Form.useForm();
  let isMounted = useRef(true);
  const handleSubmit = async (body) => {
    if (location.state) {
      console.log("haksdhkakdhjkdhadhkdhada", location.state);
      let { formData } = location.state;
      let addPin = await dispatch(
        verifyOtp({ otp: body.otp, email: formData?.email })
      );
      if (addPin) {
        let response = await dispatch(login(formData, history));
      }

      // if (response?.data?.error) {
      //   setErr(true);
      //   setErrMsg(response.data.message);
      //   setLoader(false);
      // } else {
      //   setLoader(false);
      // }
    }
  };

  return (
    <AuthWrapper>
      <div className="auth-contents secret_top">
        <Form
          autoComplete="off"
          name="login"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item name="otp" label="Otp" rules={[{ required: true }]}>
            <Input
              min={0}
              type="number"
              placeholder="Enter your otp"
              style={{ marginBottom: 10 }}
              onKeyPress={(event) => {
                if (event.key.match("[0-9,.]+")) {
                  return true;
                } else {
                  return event.preventDefault();
                }
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {isLoading ? "Loading..." : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export { VerifyOtp };
