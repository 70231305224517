import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Form,
  Spin,
  Button,
  Select,
  Modal,
  Radio,
} from "antd";
import { NavLink } from "react-router-dom";
import { SellModuleNav } from "../Style";
import { LoadingOutlined } from "@ant-design/icons";
import { addOrUpdateRoom } from "../../../redux/room/actionCreator";
import { useDispatch } from "react-redux";
import { getAllhousekeeperList } from "../../../redux/roomType/actionCreator";

const CurrentRoom = (props) => {
  let {
    changeTab,
    oneRoomDetials,
    modalShowRoomVisible,
    setModalShowRoom,
    fetchRoomList,
    currentRegisterData,
  } = props;
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;

  const [housekeeperList, setHouseKeeperList] = useState([]);
  async function fetchHousekeeperList() {
    const list = await dispatch(
      getAllhousekeeperList(currentRegisterData._id, "sell")
    );
    if (list && !list.error) {
      setHouseKeeperList(list.data);
    }
  }
  useEffect(() => {
    fetchHousekeeperList();
  }, []);
  let { status, room_type, room_name } = oneRoomDetials;
  const [selectType, setSelectType] = useState(
    status == "maintainance" || status == "cleaning" ? status : "available"
  );
  console.log("gbfjsgfhjagfjkasfgafafaf", oneRoomDetials);
  const handleSubmit = async (val) => {
    console.log("fafafaffafafafafafhh", val);
    setLoading(true);
    let obj = { remark: val.remark, housekeeper: val.housekeeper };
    if (
      status == "cleaning" ||
      status == "available" ||
      status == "maintainance"
    ) {
      obj["status"] = val.status;
    }
    // if (status == "available" || val.status == "maintainance") {

    // }
    let updateReservationData = {};
    if (
      oneRoomDetials?.reservationObjectId?.roomInfo &&
      oneRoomDetials?.reservationObjectId?.roomInfo?.length
    ) {
      let findRoomInfo = oneRoomDetials?.reservationObjectId?.roomInfo.find(
        (k) => k.roomId?.toString() == oneRoomDetials?._id?.toString()
      );
      if (findRoomInfo && findRoomInfo?._id) {
        updateReservationData = {
          roomInfoId: findRoomInfo?._id,
          reservationId: oneRoomDetials?.reservationObjectId?._id,
        };
      }
    }
    console.log("objobj", obj);
    let getRoom = await dispatch(
      addOrUpdateRoom(obj, oneRoomDetials?._id, updateReservationData)
    );
    if (getRoom && getRoom.error == false) {
      await fetchRoomList(true);
      setLoading(false);
      setModalShowRoom(false);
      // changeTab("ROOMS");
    }
  };
  console.log("fhkahfafalfkajfhalfahfahlfafafafaf", housekeeperList);
  return (
    <>
      <Modal
        title={`${room_type.name} ${room_name}`}
        visible={modalShowRoomVisible}
        onCancel={() => setModalShowRoom(false)}
        okText={loading ? "Loading..." : "Update"}
        // width={500}
        onOk={form.submit}
        bodyStyle={{ padding: " 0px 24px 10px 24px" }}
      >
        <div style={{ background: "#fff", padding: "10px" }}>
          <Row gutter={25} justify="center">
            {oneRoomDetials && (
              <Form
                autoComplete="off"
                style={{ width: "100%" }}
                name="editProduct"
                form={form}
                onFinish={handleSubmit}
                className="comman-input"
              >
                {/* <Form.Item
                  name="name"
                  label="Room name"
                  initialValue={`${room_type.name} ${room_name}`}
                >
                  <Input
                    disabled={true}
                    style={{ marginBottom: 6 }}
                    placeholder="Room Name"
                  />
                </Form.Item> */}
                <Form.Item
                  name="status"
                  // label="Status"
                  initialValue={selectType}
                >
                  <Radio.Group
                    className="tick-radio"
                    onChange={(e) => setSelectType(e.target.value)}
                  >
                    <Radio.Button
                      value="available"
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {selectType === "available" ? (
                        <svg
                          width="13px"
                          style={{ marginRight: "2px" }}
                          viewBox="0 0 123 102"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                            fill="#BE3D5D"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      Clean
                    </Radio.Button>
                    <Radio.Button
                      value={"cleaning"}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                      disabled={
                        status == "checkin" || status == "assign" ? true : false
                      }
                    >
                      {selectType === "cleaning" ? (
                        <svg
                          width="13px"
                          style={{ marginRight: "2px" }}
                          viewBox="0 0 123 102"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                            fill="#BE3D5D"
                          />
                        </svg>
                      ) : (
                        ""
                      )}{" "}
                      Dirty
                    </Radio.Button>

                    <Radio.Button
                      value={"maintainance"}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                      disabled={
                        status == "checkin" || status == "assign" ? true : false
                      }
                    >
                      {selectType === "maintainance" ? (
                        <svg
                          width="13px"
                          style={{ marginRight: "2px" }}
                          viewBox="0 0 123 102"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                            fill="#BE3D5D"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      Block
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>

                {/* <Form.Item
                  name="reservation_status"
                  label="Reservation Status"
                  initialValue={
                    status == "assign"
                      ? "Assign"
                      : status == "checkin"
                      ? "Check in"
                      : status == "cleaning"
                      ? "Dirty"
                      : "Available"
                  }
                >
                  <Input style={{ marginBottom: 6 }} disabled={true} />
                </Form.Item> */}
                <Form.Item
                  name="housekeeper"
                  label="HouseKeeper"
                  initialValue={oneRoomDetials?.housekeeper?._id}
                >
                  <Select
                    style={{ marginBottom: 6 }}
                    placeholder="Select housekeeper"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {housekeeperList?.map((val) => {
                      return <Option value={val._id}>{val.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="remark"
                  label="Remarks"
                  initialValue={oneRoomDetials?.remark}
                >
                  <Input placeholder="Remarks" />
                </Form.Item>

                {/* <div
                  className="add-form-action"
                  style={{ float: "left", marginTop: 15 }}
                >
                  <Button
                    className="btn-cancel btn-custom"
                    size="medium"
                    style={{ marginRight: 10 }}
                    onClick={() => setModalShowRoom(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    className="btn-custom"
                    htmlType="submit"
                    type="primary"
                    raised
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 16,
                              color: "white",
                              margin: "0px 8px",
                            }}
                            spin
                          />
                        }
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div> */}
              </Form>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export { CurrentRoom };
