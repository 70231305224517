import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import withAdminLayout from "../../layout/withAdminLayout";
import AdminDashboard from "../../container/dashboard/Admin";
import Enquiry from "../../container/enquiryManagement/Enquiry";
import UserManagement from "../../container/userManagement/UserManagement";
import SubscriptionDetail from "../../container/userManagement/SubscriptionDetail";
import RegisterManagement from "../../container/userManagement/RegisterManagement";
const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path={`${path}`} component={AdminDashboard} />
        <Route exact path={`${path}/enquiry-management`} component={Enquiry} />
        <Route
          exact
          path={`${path}/user-management`}
          component={UserManagement}
        />{" "}
        <Route
          exact
          path={`${path}/register-management`}
          component={RegisterManagement}
        />
        <Route
          exact
          path={`${path}/user-management/subscription-detail`}
          component={SubscriptionDetail}
        />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
