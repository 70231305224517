import actions from "./actions";
import { getItem, setItem } from "../../utility/localStorageControl";
import axios from "axios";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const {
  RegisterList,
  RegisterListErr,
  changeRegisterSuccess,
  changeRegisterErr,
  registerId,
  registerIdErr,
  registerAdd,
  registerAddErr,
  registerDelete,
  registerDeleteErr,
} = actions;

const getAllHotelsList = (checkSell) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");
      if (checkSell == "sell" && allSetupcache?.hotelList) {
        return allSetupcache?.hotelList;
      }
      const allBingages = await DataService.get(API.hotel.getAllhotels);
      console.log("lplldedsfssssssddsde", allBingages);
      if (allBingages?.data?.data) {
        allSetupcache.hotelList = allBingages.data.data;
        setItem("setupCache", allSetupcache);
        return allBingages.data.data;
      }
    } catch (err) {
      let allSetupcache = getItem("setupCache");
      if (allSetupcache != null && allSetupcache.hotelList) {
        return allSetupcache?.hotelList;
      } else {
        return dispatch(RegisterListErr(err));
      }
    }
  };
};

const getRegisterById = (id) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");
      let registerDetails;
      if (allSetupcache != null && allSetupcache.register) {
        registerDetails = allSetupcache.register.find((val) => val._id == id);
      }
      if (registerDetails) {
        return dispatch(registerId(registerDetails));
      } else {
        const registerByIdData = await DataService.get(
          `${API.register.getRegisterById}/${id}`
        );
        if (!registerByIdData.data.error) {
          return dispatch(registerId(registerByIdData.data.data));
        } else {
          return dispatch(registerIdErr(registerByIdData.data));
        }
      }
    } catch (err) {
      dispatch(registerIdErr(err));
    }
  };
};

const addOrUpdateHotel = (formData, register_id, check) => {
  return async (dispatch) => {
    try {
      let apidata = await axios({
        method: "GET",
        url: `https://api-controller.streetgooser.io/application/posease/v1?propertyUuid=${formData.hotelId}`,
        headers: {
          api_key: `${formData.hotelKey}`,
        },
      });
      console.log("apiosdasdadasda", apidata);
      let response = apidata?.data;
      // let response = {
      //   propertyUuid: "6cc94b8b-c44d-4f86-b955-f14a0f9b92d2",
      //   rooms: [
      //     {
      //       uuid: "5ddcbaeb-2ec6-4b85-95b1-070db8d027ff",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "102",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "9cbd50da-afa3-4fd0-9e78-5ff3d0a47c28",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "103",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "29263dd0-f62c-4f5c-9646-e1f6685a2211",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "104",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "fc3e92d6-9c6a-4bd1-9677-729d72dbda4b",
      //       propertyRoomTypeName: "Super Deluxe Room",
      //       number: "101",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "d1051da0-ca79-43f7-acf9-8bf4ca311fe2",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "107",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "e14c4c28-60ae-4e71-a8c2-d8809854a469",
      //       propertyRoomTypeName: "Super Deluxe Room",
      //       number: "106",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "96617a10-b4e1-483d-a33d-96597f971bc7",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "105",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "24406b7c-018a-41d7-afd8-06a0530cc930",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "108",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "4f55919f-fd44-4494-b3cc-4dd2226ba2b3",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "203",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "7164dd0a-f722-440a-bbb6-628719215ee3",
      //       propertyRoomTypeName: "Deluxe Room",
      //       number: "202",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "fbb11a0d-9d2b-4ce7-b464-7c7a11ee1233",
      //       propertyRoomTypeName: "Super Deluxe Room",
      //       number: "201",
      //       isActive: true,
      //     },
      //     {
      //       uuid: "fbb11a0d-9d2b-4ce7-b464-7c7a11ee12330",
      //       propertyRoomTypeName: "Super Deluxe Room",
      //       number: "501",
      //       isActive: true,
      //     },
      //   ],
      // };
      if (response && response.rooms) {
        let allSetupcache = getItem("setupCache");
        let shopId = "";
        let main_register_id = "";
        if (allSetupcache && allSetupcache.shopDetails) {
          shopId = allSetupcache.shopDetails._id;
          let registerData = allSetupcache.register.find((val) => val.is_main);
          if (registerData && registerData._id) {
            main_register_id = registerData._id;
          }
        }
        formData.rooms = response.rooms.map((val) => ({
          ...val,
          roomType: val.propertyRoomTypeName,
          register_id: formData.registerId,
          table_name: `${val.propertyRoomTypeName} ${val.number}`,
          table_type: val.propertyRoomTypeName,
          hotel_room: true,
          hotelID: response.propertyUuid,
          main_register_id: main_register_id,
          shop_id: shopId,
        }));
      }
      if (response) {
        if (register_id !== "") {
          const getbingageData = await DataService.put(
            API.hotel.updatehotel + "/" + register_id,
            formData
          );

          if (check == "reload") {
            await dispatch(getAllHotelsList());
          }
          return getbingageData;
        } else {
          console.log("asdhagasdssdsdsaas", formData);
          const getbingageData = await DataService.post(
            API.hotel.addhotel,
            formData
          );

          return getbingageData;
        }
      }
    } catch (err) {
      if (err && err?.response?.data) {
        return err.response.data;
      }
    }
  };
};

const SwitchRegister = (payload) => {
  return async (dispatch) => {
    try {
      let getSwitchRegister = {};
      getSwitchRegister = await DataService.post(
        API.register.SwitchCurrentRegister,
        payload
      );
      if (!getSwitchRegister.data.error) {
        setItem("access_token", getSwitchRegister.data.data.token);
        return dispatch(changeRegisterSuccess(getSwitchRegister.data.data));
      } else {
        return dispatch(changeRegisterErr(getSwitchRegister.data.error));
      }
    } catch (err) {
      dispatch(changeRegisterErr(err));
    }
  };
};

const deleteHotels = (RegisterIds) => {
  return async (dispatch) => {
    try {
      const getDeletedRegister = await DataService.post(
        API.hotel.deleteAllhotels,
        RegisterIds
      );

      if (!getDeletedRegister.data.error) {
        return getDeletedRegister.data;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
};
export {
  getAllHotelsList,
  SwitchRegister,
  getRegisterById,
  deleteHotels,
  addOrUpdateHotel,
};
