import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  Space,
  Button,
  Form,
  Spin,
  message,
} from "antd";
import { SearchOutlined, ImportOutlined } from "@ant-design/icons";
import { UserTableStyleWrapper } from "../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../Customer/Style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "../../components/popup/popup";
import {
  getAllroomTypeList,
  deleteRoomType,
} from "../../redux/roomType/actionCreator";
import commonFunction from "../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../Customer/customer.css";
import { CaretDownOutlined } from "@ant-design/icons";
import { getItem, setItem } from "../../utility/localStorageControl";

const Roomtype = () => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [roomTypeListData, setRoomTypeList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectionType] = useState("checkbox");
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [loading, setLoading] = useState(false);
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");
  const [selectedItems, setSelecteditems] = useState([]);
  let [search, setsearch] = useState("");
  async function fetchRoomTypeList() {
    setLoader(true);
    const getRoomTypeList = await dispatch(
      getAllroomTypeList(currentRegisterData?._id, "sell")
    );
    if (getRoomTypeList && !getRoomTypeList.error) {
      setLoader(false);
      setRoomTypeList(getRoomTypeList.data);
    } else {
      message.error({
        content: getRoomTypeList.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  }
  useEffect(() => {
    if (currentRegisterData?._id) {
      fetchRoomTypeList();
    }
  }, [currentRegisterData?._id]);

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  const deleteSelectedRoomType = async () => {
    setLoading(true);
    let allTaxesIdsForDelete = [];
    selectedItems.map((item) => {
      allTaxesIdsForDelete.push(item._id);
    });
    const getDeletedTaxes = await dispatch(
      deleteRoomType({ ids: allTaxesIdsForDelete }, currentRegisterData?._id)
    );
    if (getDeletedTaxes && getDeletedTaxes.error == false) {
      fetchRoomTypeList();
      setLoading(false);
      setModelDeleteVisible(false);
    } else {
      message.error({
        content: getDeletedTaxes.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
      setLoading(false);
      setModelDeleteVisible(false);
    }
  };
  const handleCancel = (e) => {
    setModelDeleteVisible(false);
  };
  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelDeleteVisible(true);
          setLoading(false);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Delete Selected item</span>
      </NavLink>
    </>
  );
  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      width: "2%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: true,
      align: "left",
      render(text, record) {
        return {
          children: <div style={{ color: "#008cba" }}>{text}</div>,
        };
      },
    },
    {
      title: "Total Rooms",
      dataIndex: "numberOfRooms",
      key: "numberOfRooms",
    },
    // {
    //   title: "Active rooms",
    //   dataIndex: "active_rooms",
    //   key: "active_rooms",
    //   align: "left",
    // },
    // {
    //   title: "inactive rooms",
    //   dataIndex: "inactive_rooms",
    //   key: "inactive_rooms",
    //   align: "left",
    // },
    {
      title: "Adults",
      dataIndex: "default_adult",
      key: "default_adult",
      align: "left",
    },
    {
      title: "Childrens",
      dataIndex: "default_children",
      key: "default_children",
      align: "left",
    },
    {
      title: "Infants",
      dataIndex: "default_infant",
      key: "default_infant",
      align: "left",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelecteditems(selectedRows);
    },
  };
  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div className="table_titles">
                  <h2>Room type</h2>
                  <span className="title-counter">
                    {roomTypeListData.length} Room type
                  </span>
                </div>
                <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Input
                    suffix={<SearchOutlined />}
                    autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                    placeholder="Search by Name"
                    style={{
                      borderRadius: "30px",
                      width: "250px",
                    }}
                    onChange={(e) => setsearch(e.target.value)}
                    value={search}
                  />
                </div>
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions custom_action">
                <NavLink
                  to={`${path}/add`}
                  className="ant-btn ant-btn-primary ant-btn-md addprdpls"
                >
                  <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                  Add Room type
                </NavLink>
              </div>,
            ]}
          />
        </CardToolbox>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="_id"
                        size="small"
                        className="products_lsttable"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                        pagination={false}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        className="products_lsttable"
                        rowSelection={{
                          type: selectionType,
                          ...rowSelection,
                        }}
                        onRow={(row) => ({
                          onClick: () =>
                            history.push(`${path}/add`, {
                              roomTypeDetils: row,
                            }),
                        })}
                        size="small"
                        dataSource={roomTypeListData.filter((value) =>
                          value.name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
        <Modal
          title="Confirm Delete"
          okText={
            loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                    spin
                  />
                }
              />
            ) : (
              "Delete"
            )
          }
          visible={modalDeleteVisible}
          onOk={deleteSelectedRoomType}
          onCancel={handleCancel}
          width={600}
        >
          <p>Are you sure you want to delete selected room type ?</p>
        </Modal>
      </Main>
    </>
  );
};

export default Roomtype;
