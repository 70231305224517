import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { VendorItemList } from "./Items/VendorItemList";
import { VendorItemCategoryList } from "./Category/VendorItemCategoryList";
import { setItem } from "../../utility/localStorageControl";
import { PosItems } from "./Items/PosItems";
import PosProducts from "../profile/settings/overview/Inventory/ManageItems/PosProducts";
const VendorsItemsBuild = (props) => {
  const { TabPane } = Tabs;
  const search = new URLSearchParams(props.location.search);
  let type = search.get("type");
  const [vendorItems, setVendorItems] = useState([]);
  const [activeTab, changeTab] = useState(
    type == "category" ? "VENDOR_ITEMS_CATEGORY" : "VENDOR_ITEMS"
  );
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  console.log("adshkjadhajkdahdjkadhasdadasdadasdadadada", vendorItems);
  return (
    <>
      <Main>
        <PageHeader
          className="comman-custom-pageheader"
          size="small"
          ghost
          title={
            <>
              <Tabs
                type="card"
                activeKey={activeTab}
                size="small"
                onChange={changeTab}
              >
                <TabPane tab="Items" key="VENDOR_ITEMS"></TabPane>
                <TabPane tab="POS Products" key="POS_ITEMS"></TabPane>
                {/* <TabPane tab="Categories" key="VENDOR_ITEMS_CATEGORY"></TabPane> */}
                {/* <TabPane
                  tab="Inventory POS Products"
                  key="INVENTORY_PRODUCTS"
                ></TabPane> */}
              </Tabs>
            </>
          }
        />

        {activeTab === "VENDOR_ITEMS" ? (
          <VendorItemList setVendorItems={setVendorItems} />
        ) : (
          ""
        )}
        {activeTab === "VENDOR_ITEMS_CATEGORY" ? (
          <VendorItemCategoryList />
        ) : (
          ""
        )}
        {activeTab == "POS_ITEMS" ? <PosItems /> : ""}
        {/* {activeTab == "INVENTORY_PRODUCTS" ? (
          <PosProducts vendorItems={vendorItems} />
        ) : (
          ""
        )} */}
      </Main>
    </>
  );
};

export default VendorsItemsBuild;
