import React, { useState, useEffect, useRef } from "react";
import {
  Spin,
  Col,
  Row,
  Divider,
  Tooltip,
  Menu,
  Dropdown,
  Select,
  Calendar,
} from "antd";
import FeatherIcon from "feather-icons-react";
import { NavLink, Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CardBarChart, Pstates } from "../../style";
import { Cards } from "../../../../components/cards/frame/cards-frame";
import Heading from "../../../../components/heading/heading";
import { ChartjsBarChartTransparent } from "../../../../components/charts/chartjs";
import { ExclamationCircleOutlined, DownOutlined } from "@ant-design/icons";
import {
  cashFlowGetData,
  cashFlowFilterData,
} from "../../../../redux/chartContent/actionCreator";
import {
  getExpensesTotal,
  getPurchaseTotal,
  getSaleSummaryDatwiseChangeData,
} from "../../../../redux/dashboard/actionCreator";
import moment from "moment";

import { getItem, setItem } from "../../../../utility/localStorageControl";
import { getRevenueDashboard } from "../../../../redux/reservation/actionCreator";
const CashFlow = (props) => {
  const [topProducts, setTopProducts] = useState([]);
  const userDetails = getItem("userDetails");
  let { dashBoardData } = props;
  const [cash, setCash] = useState(0);
  const [revenueData, setRevenueData] = useState();
  const [card, setCard] = useState(0);
  const [other, setOther] = useState(0);
  const [custumPayment, setCustumPaymnet] = useState(0);
  const [currentDate, setCurrentDate] = useState("today");
  const [allCustomPaymnetList, setAllCustomPaymnetList] = useState([]);
  const [pattyCashDetails, setPattyCashDetails] = useState();
  const [dashboardDetails, setDashBoardDetails] = useState();
  const [totalBooking, setTotalBooking] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const { registerList } = useSelector(
    (state) => ({
      registerList: state.register.RegisterList,
    }),
    shallowEqual
  );

  let regiterId = false;
  let registerName = false;
  let pmsRegisterId = false;
  let pmsRegisterName = false;
  let pmsRegisterList = [];

  if (userDetails.role == "cashier" && registerList.length > 0) {
    if (registerList.find((val) => val.active)) {
      if (
        userDetails.business_type == "hotel_management" &&
        userDetails.role == "cashier" &&
        !userDetails.allow_other_register &&
        userDetails.casier_business_type == "hotel_management"
      ) {
        let activeRegisterName = registerList.find(
          (val) => val._id == userDetails.hotel_register_assigned_to
        );
        if (activeRegisterName) {
          regiterId = activeRegisterName._id;
          registerName = activeRegisterName.register_name;
        }
      } else {
        if (
          userDetails.business_type == "hotel_management" &&
          userDetails.role == "cashier" &&
          userDetails.allow_other_register
        ) {
          if (userDetails.hotel_register_assigned_to) {
            let activeRegisterName = registerList.find(
              (val) => val._id == userDetails.hotel_register_assigned_to
            );
            if (activeRegisterName) {
              pmsRegisterList.push(activeRegisterName);
              pmsRegisterId = activeRegisterName._id;
              pmsRegisterName = activeRegisterName.register_name;
            }
          }
          if (userDetails.register_assigned_to) {
            let activeRegisterName = registerList.find(
              (val) => val._id == userDetails.register_assigned_to
            );
            if (activeRegisterName) {
              pmsRegisterList.push(activeRegisterName);
              regiterId = activeRegisterName._id;
              registerName = activeRegisterName.register_name;
            }
          }
        } else {
          let activeRegisterName = registerList.find((val) => val.active);
          if (activeRegisterName) {
            regiterId = activeRegisterName._id;
            registerName = activeRegisterName.register_name;
          }
        }
      }
    }
  }
  const [dropDownValue, setDropDownValue] = useState(
    registerName ? registerName : "All Registers"
  );

  const [pmsregisterId, setPMSRegisterId] = useState(pmsRegisterId);
  const [pmsCasierRegisterId, setPmsCaiserRegisterId] = useState(
    pmsRegisterId ? "allRegister" : false
  );
  const [pmsDropDownValue, setPMSDropDownValue] = useState("All Registers");
  const [id, setId] = useState(regiterId ? regiterId : "allRegister");
  let [cashCardOther, setCashCardOther] = useState([]);
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );

  async function fetchTopProduct(inDashBoardData) {
    let startDate;
    let endDate;

    const getDashboardData = inDashBoardData
      ? { saleSummaruydashboardDateWiseDetails: inDashBoardData }
      : await dispatch(
          getSaleSummaryDatwiseChangeData(
            startDate,
            endDate,
            currentDate,
            id,
            dashBoardData?.type,
            pmsregisterId,
            pmsCasierRegisterId
          )
        );

    if (
      userDetails &&
      userDetails.business_type &&
      userDetails.business_type == "hotel_management" &&
      inDashBoardData == false
    ) {
      let response = await dispatch(
        getRevenueDashboard(
          currentDate,
          pmsregisterId ? pmsregisterId : id,
          pmsCasierRegisterId
        )
      );

      if (
        response &&
        response.data &&
        response.data.totalRevenue &&
        getDashboardData.saleSummaruydashboardDateWiseDetails
      ) {
        getDashboardData.saleSummaruydashboardDateWiseDetails.total_sales =
          getDashboardData.saleSummaruydashboardDateWiseDetails.total_sales +
          response.data.totalRevenue;
      }
    }

    if (getDashboardData) {
      setDashBoardDetails({
        ...getDashboardData.saleSummaruydashboardDateWiseDetails,
      });

      setPattyCashDetails(
        getDashboardData.saleSummaruydashboardDateWiseDetails.pettyCash
      );

      dashboardDetails?.total_booking[0]?.count > 0 &&
        setTotalBooking(dashboardDetails.total_booking[0]?.count);
      let threePaymnetType = [];

      if (
        getDashboardData?.saleSummaruydashboardDateWiseDetails?.payment_summary
      ) {
        let customPaymnetList = [];
        let custompaymnetSum = 0;

        getDashboardData.saleSummaruydashboardDateWiseDetails.payment_summary.map(
          (val) => {
            if (val._id == "cash") {
              setCash(val.sum);
              threePaymnetType.push(val);
            } else if (val._id == "card") {
              setCard(val.sum);
              threePaymnetType.push(val);
            } else if (val._id == "other") {
              setOther(val.sum);
              threePaymnetType.push(val);
            } else if (val._id == "pending") {
              let pending = val.sum;
            } else {
              custompaymnetSum += Number(val.sum);
              customPaymnetList.push(val);
            }
          }
        );

        setCustumPaymnet(custompaymnetSum);
        setAllCustomPaymnetList(customPaymnetList);
      } else {
        setCash(0);
        setCard(0);
        setOther(0);
      }
      setCashCardOther(threePaymnetType);
    }
  }

  const didMount = useRef(false);
  const fetchExpenseTotal = async () => {
    let response = await dispatch(getExpensesTotal(currentDate, id));
    if (response && response.data != undefined) {
      setTotalExpense(response.data);
    }
  };
  const fetchPurchaseTotal = async () => {
    let response = await dispatch(getPurchaseTotal(currentDate, id));
    if (response && response.data != undefined) {
      setTotalPurchase(response.data);
    }
  };
  useEffect(() => {
    if (didMount.current) {
      fetchTopProduct(false);
    } else if (dashBoardData) {
      fetchTopProduct(dashBoardData);
      didMount.current = true;
    }
  }, [
    currentDate,
    dropDownValue,
    id,
    dashBoardData,
    pmsregisterId,
    pmsCasierRegisterId,
  ]);

  useEffect(() => {
    fetchExpenseTotal();
    fetchPurchaseTotal();
  }, [currentDate, dropDownValue, id]);
  const dispatch = useDispatch();

  const { cashFlowState, cfIsLoading } = useSelector((state) => {
    return {
      cashFlowState: state.chartContent.cashFlowData,
      cfIsLoading: state.chartContent.cfLoading,
    };
  });

  // useEffect(() => {
  //   if (cashFlowGetData) {
  //     dispatch(cashFlowGetData());
  //   }
  // }, [dispatch]);

  let labels = [];
  let data = [];

  function compareFunction(a, b) {
    return a._id - b._id;
  }

  if (dashboardDetails !== undefined) {
    dashboardDetails.hourly_selling &&
      dashboardDetails.hourly_selling.sort(compareFunction);
  }

  dashboardDetails &&
    dashboardDetails.hourly_selling &&
    dashboardDetails.hourly_selling.map((i) => {
      let b = i._id.toString();
      if (b.length > 1) {
        labels.push(`${i._id}:00`);
      } else {
        labels.push(`0${i._id}:00`);
      }
      data.push(i.total);
    });

  let totalBookings;

  dashboardDetails &&
    (totalBookings =
      dashboardDetails.total_booking[0] &&
      dashboardDetails.total_booking[0].count);

  let newLabels = [];
  let newtotalls = [];

  labels.map((i, idx) => {
    let c = labels[idx + 1];

    for (let j = i; j < c; j++) {
      if (labels.includes(j)) {
        newLabels.push(j);
        newtotalls.push(data[labels.indexOf(j)]);
      } else {
        newLabels.push(j);
        newtotalls.push(0);
      }
    }
  });

  newLabels.push(labels[labels.length - 1]);

  newtotalls.push(data[data.length - 1]);

  let scale;
  let stepSize;

  if (currentDate == "today" || currentDate == "yesterday") {
    let max = 0;
    newtotalls.map((i) => {
      if (i > max) {
        max = i;
      }
    });

    if (max > 0 && max <= 109) {
      stepSize = 50;
    } else if (max > 99 && max <= 1001) {
      stepSize = 200;
    } else if (max > 999 && max <= 5000) {
      stepSize = 2000;
    } else if (max > 5000 && max <= 10000) {
      stepSize = 2000;
    } else if (max > 10000 && max <= 100000) {
      stepSize = 20000;
    } else if (max > 100000 && max <= 500000) {
      stepSize = 50000;
    } else if (max > 500000 && max <= 1000000) {
      stepSize = 200000;
    }

    scale = {
      yAxes: [
        {
          display: true,
          ticks: {
            stepSize: stepSize,
            suggestedMin: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 10,
            callback: function(val, index) {
              return val;
            },
          },
        },
      ],
    };
  }

  if (currentDate == "this_month") {
    newLabels = [];
    newtotalls = [];

    const monthLabel = [];
    const monthsArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const presentMonth = new Date().getMonth();
    const currentDates = new Date().getDate();

    if (dashboardDetails !== "undefined") {
      if (dashboardDetails?.hourly_selling?.length > 0) {
        for (let i = 1; i <= currentDates; i++) {
          monthLabel.push(`${monthsArr[presentMonth]} ${i}`);
        }
      }
    }

    newLabels = monthLabel;

    if (
      dashboardDetails &&
      dashboardDetails.hourly_selling &&
      dashboardDetails.hourly_selling.length > 0
    ) {
      newLabels.map((i, idx) => {
        dashboardDetails.hourly_selling.map((j) => {
          if (j._id == idx + 1) {
            newtotalls[idx] = j.total;
          }
        });
      });
    }

    let max = 0;
    let stepSize;

    newtotalls.map((i) => {
      if (i > max) {
        max = i;
      }
    });

    if (max > 0 && max <= 100) {
      stepSize = 50;
    } else if (max > 100 && max <= 1000) {
      stepSize = 100;
    } else if (max > 1000 && max <= 5000) {
      stepSize = 1000;
    } else if (max > 5000 && max <= 10000) {
      stepSize = 2000;
    } else if (max > 10000 && max <= 100000) {
      stepSize = 20000;
    } else if (max > 100000 && max <= 500000) {
      stepSize = 50000;
    } else if (max > 500000 && max <= 1000000) {
      stepSize = 200000;
    }

    scale = {
      yAxes: [
        {
          display: true,
          ticks: {
            stepSize: stepSize,
            suggestedMin: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 10,
            callback: function(val, index) {
              return index % 2 === 0 ? val : "";
            },
          },
        },
      ],
    };
  }

  if (currentDate == "last_month") {
    let lastMonth = dashboardDetails?.hourly_selling;

    newLabels = [];
    newtotalls = [];

    let today = new Date();
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    let month = lastDayOfMonth.getMonth();

    const monthLabel = [];
    const monthsArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let maxDate = 1;
    lastMonth.map((i) => {
      if (i._id > maxDate) {
        maxDate = i._id;
      }
    });

    for (let i = 1; i < maxDate + 1; i++) {
      monthLabel.push(`${monthsArr[month]} ${i}`);
    }

    newLabels = monthLabel;

    if (lastMonth.length > 0) {
      newLabels.map((i, idx) => {
        lastMonth.map((j) => {
          if (j._id == idx + 1) {
            newtotalls[idx] = j.total;
          }
        });
      });
    }

    let max = 0;
    let stepSize;

    newtotalls.map((i) => {
      if (i > max) {
        max = i;
      }
    });

    if (max > 0 && max <= 100) {
      stepSize = 50;
    } else if (max > 99 && max <= 1000) {
      stepSize = 100;
    } else if (max > 999 && max <= 5000) {
      stepSize = 1000;
    } else if (max > 5000 && max <= 10000) {
      stepSize = 2000;
    } else if (max > 10000 && max <= 100000) {
      stepSize = 20000;
    } else if (max > 100000 && max <= 500000) {
      stepSize = 50000;
    } else if (max > 500000 && max <= 1000000) {
      stepSize = 200000;
    }

    if (lastMonth.length == 0) {
      newLabels = [];
      newtotalls = [];
    }

    scale = {
      yAxes: [
        {
          display: true,
          ticks: {
            stepSize: stepSize,
            suggestedMin: 0,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback: function(val, index) {
              return index % 2 === 0 ? val : "";
            },
          },
        },
      ],
    };
  }

  const chartData = {
    labels: newLabels[0] == undefined ? [0] : newLabels,
    datasets: [
      {
        data: newtotalls,
        backgroundColor: "#bd025d",
        maxBarThickness: 5,
        barThickness: 5,
      },
    ],
  };

  scale.yAxes[0].ticks["callback"] = function(value) {
    var ranges = [
      { divider: 1e6, suffix: "M" },
      { divider: 1e3, suffix: "k" },
    ];
    function formatNumber(n) {
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          return (n / ranges[i].divider).toString() + ranges[i].suffix;
        }
      }
      return n;
    }
    return formatNumber(value);
  };

  const handleDropdownClick = (data, check) => {
    const { _id, register_name } = data;
    check
      ? setPMSDropDownValue(register_name)
      : setDropDownValue(register_name);
    _id != undefined
      ? check
        ? setPmsCaiserRegisterId(data._id)
        : setId(data._id)
      : check
      ? setPmsCaiserRegisterId("allRegister")
      : setId("allRegister");
  };

  const menu = (
    <Menu className="Dash_dropList">
      <Menu.Item>
        <Link
          to="#"
          onClick={() =>
            handleDropdownClick({ register_name: "All Registers" })
          }
        >
          All Registers
        </Link>
      </Menu.Item>

      {registerList.map((data, idx) => {
        return (
          <Menu.Item key={idx}>
            <Link
              to="#"
              key={data._id}
              onClick={() => handleDropdownClick(data)}
            >
              {data.register_name}
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const pmsMenu = (
    <Menu className="Dash_dropList">
      <Menu.Item>
        <Link
          to="#"
          onClick={() =>
            handleDropdownClick({ register_name: "All Registers" }, "pms")
          }
        >
          All Registers
        </Link>
      </Menu.Item>

      {pmsRegisterList.map((data, idx) => {
        return (
          <Menu.Item key={idx}>
            <Link
              to="#"
              key={data._id}
              onClick={() => handleDropdownClick(data, "pms")}
            >
              {data.register_name}
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const POSRegister = () => {
    return (
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        disabled={userDetails.role == "cashier" ? true : false}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {dropDownValue} <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  const PMSRegisterShow = () => {
    return (
      <Dropdown overlay={pmsMenu} trigger={["click"]}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {pmsDropDownValue} <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  return (
    <div className="cashflowCard">
      <Cards
        title="Summary Details"
        isbutton={
          <div className="card-nav dashcard-nav">
            <ul>
              <li>
                <p style={{ display: "none" }}>{id}</p>
                {userDetails.business_type == "hotel_management" ? (
                  userDetails.role == "cashier" ? (
                    userDetails.allow_other_register == true ? (
                      <PMSRegisterShow />
                    ) : (
                      <POSRegister />
                    )
                  ) : (
                    <POSRegister />
                  )
                ) : (
                  <POSRegister />
                )}
              </li>
              {/* {pmsDropDownValue && (
                <li>
                  <p style={{ display: "none" }}>{pmsRegisterId}</p>
                  <PMSRegisterShow />
                </li>
              )} */}
              <li className={currentDate === "today" ? "active" : "regular"}>
                <Link onClick={() => setCurrentDate("today")} to="#">
                  Today
                </Link>
              </li>
              <li
                className={currentDate === "yesterday" ? "active" : "regular"}
              >
                <Link onClick={() => setCurrentDate("yesterday")} to="#">
                  Yesterday
                </Link>
              </li>
              <li
                className={currentDate === "this_month" ? "active" : "regular"}
              >
                <Link onClick={() => setCurrentDate("this_month")} to="#">
                  This Month
                </Link>
              </li>
              <li
                className={currentDate === "last_month" ? "active" : "regular"}
              >
                <Link onClick={() => setCurrentDate("last_month")} to="#">
                  Last Month
                </Link>
              </li>
            </ul>
          </div>
        }
      >
        <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} className="bod_botm">
          <Col xs={12} xl={8}>
            <div className="growth-upward borderdas_rght mobile-frbr">
              <p>Sales</p>
              <h3>
                {rsSymbol}
                {dashboardDetails
                  ? Number(dashboardDetails?.total_sales).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  : Number(0).toFixed(2)}
              </h3>
            </div>
          </Col>

          <Col xs={12} xl={8}>
            <div className="growth-upward borderdas_rght mobileamrgt">
              <p>Expense </p>
              <h3>
                {" "}
                {rsSymbol}
                {Number(totalExpense).toFixed(2)}
              </h3>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="growth-upward mobile-frbr">
              <p>Purchase</p>
              <h3>
                {" "}
                {rsSymbol}
                {Number(totalPurchase).toFixed(2)}
              </h3>
            </div>
          </Col>
        </Row>
        <div>
          <p
            style={{
              color: "rgba(0, 0, 0, 0.85)",
              fontWeight: "500",
              fontSize: "16px",
              marginBottom: 10,
            }}
          >
            Payment Summary
          </p>
        </div>
        <div
          style={{ borderBottom: "1px solid #f0f0f0", marginBottom: 10 }}
        ></div>
        <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} className="bod_botm">
          <Col xs={12} xl={8}>
            <div className="growth-upward borderdas_rght mobile-frbr">
              <p>
                Cash{" "}
                {Number(
                  cashCardOther.length > 0 &&
                    cashCardOther.find((val) => val._id == "cash") &&
                    cashCardOther.find((val) => val._id == "cash").out
                    ? cashCardOther.find((val) => val._id == "cash").out
                    : 0
                ) > 0 && (
                  <Tooltip
                    title={
                      <div>
                        <div>
                          In : {rsSymbol}
                          {Number(
                            cashCardOther.length > 0 &&
                              cashCardOther.find((val) => val._id == "cash") &&
                              cashCardOther.find((val) => val._id == "cash").in
                              ? cashCardOther.find((val) => val._id == "cash")
                                  .in
                              : 0
                          ).toFixed(2)}
                        </div>
                        {
                          <div>
                            Refund: {rsSymbol}
                            {Number(
                              cashCardOther.find((val) => val._id == "cash").out
                            ).toFixed(2)}
                          </div>
                        }
                      </div>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
              </p>
              <h3>
                {" "}
                {rsSymbol}
                {Number(
                  cashCardOther.length > 0 &&
                    cashCardOther.find((val) => val._id == "cash") &&
                    cashCardOther.find((val) => val._id == "cash").sum
                    ? cashCardOther.find((val) => val._id == "cash").sum
                    : 0
                ).toFixed(2)}
              </h3>
            </div>
          </Col>

          <Col xs={12} xl={8}>
            <div className="growth-upward borderdas_rght mobileamrgt">
              <p>
                Card{" "}
                {Number(
                  cashCardOther.length > 0 &&
                    cashCardOther.find((val) => val._id == "card") &&
                    cashCardOther.find((val) => val._id == "card").out
                    ? cashCardOther.find((val) => val._id == "card").out
                    : 0
                ) > 0 && (
                  <Tooltip
                    title={
                      <div>
                        <div>
                          In : {rsSymbol}
                          {Number(
                            cashCardOther.length > 0 &&
                              cashCardOther.find((val) => val._id == "card") &&
                              cashCardOther.find((val) => val._id == "card").in
                              ? cashCardOther.find((val) => val._id == "card")
                                  .in
                              : 0
                          ).toFixed(2)}
                        </div>
                        {
                          <div>
                            Refund: {rsSymbol}
                            {Number(
                              cashCardOther.find((val) => val._id == "card").out
                            ).toFixed(2)}
                          </div>
                        }
                      </div>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
              </p>
              <h3>
                {" "}
                {rsSymbol}
                {Number(
                  cashCardOther.length > 0 &&
                    cashCardOther.find((val) => val._id == "card") &&
                    cashCardOther.find((val) => val._id == "card").sum
                    ? cashCardOther.find((val) => val._id == "card").sum
                    : 0
                ).toFixed(2)}
              </h3>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="growth-upward mobile-frbr">
              <p>
                Other{" "}
                {Number(
                  cashCardOther.length > 0 &&
                    cashCardOther.find((val) => val._id == "other") &&
                    cashCardOther.find((val) => val._id == "other").out
                    ? cashCardOther.find((val) => val._id == "other").out
                    : 0
                ) > 0 && (
                  <Tooltip
                    title={
                      <div>
                        <div>
                          In : {rsSymbol}
                          {Number(
                            cashCardOther.length > 0 &&
                              cashCardOther.find((val) => val._id == "other") &&
                              cashCardOther.find((val) => val._id == "other").in
                              ? cashCardOther.find((val) => val._id == "other")
                                  .in
                              : 0
                          ).toFixed(2)}
                        </div>
                        {Number(
                          cashCardOther.length > 0 &&
                            cashCardOther.find((val) => val._id == "other") &&
                            cashCardOther.find((val) => val._id == "other").out
                            ? cashCardOther.find((val) => val._id == "other")
                                .out
                            : 0
                        ) > 0 && (
                          <div>
                            Refund: {rsSymbol}
                            {Number(
                              cashCardOther.find((val) => val._id == "other")
                                .out
                            ).toFixed(2)}
                          </div>
                        )}
                      </div>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
              </p>
              <h3>
                {" "}
                {rsSymbol}
                {Number(
                  cashCardOther.length > 0 &&
                    cashCardOther.find((val) => val._id == "other") &&
                    cashCardOther.find((val) => val._id == "other").sum
                    ? cashCardOther.find((val) => val._id == "other").sum
                    : 0
                ).toFixed(2)}
              </h3>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            <div className="growth-upward borderdas_rght mobileamrgt">
              <p>
                Custom{" "}
                {allCustomPaymnetList.length > 0 && (
                  <Tooltip
                    title={
                      <div>
                        {allCustomPaymnetList.map((val) => {
                          if (
                            val._id &&
                            val._id != "false" &&
                            val._id != "pending"
                          ) {
                            return (
                              <div>
                                <div>
                                  {val._id} : {rsSymbol}
                                  {Number(val.sum).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                                {Number(val.out) > 0 && (
                                  <span>
                                    In:
                                    {Number(val.in).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                    {Number(val.out) > 0 && (
                                      <span>
                                        ,Refund:
                                        {Number(val.out).toLocaleString(
                                          "en-US",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </span>
                                    )}
                                  </span>
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    }
                    // cursor={{fill:"#bd025d"}}
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                        // backgroundColor:"#bd025d"
                      }}
                    />
                  </Tooltip>
                )}
              </p>

              <h3>
                {" "}
                {rsSymbol}
                {Number(custumPayment).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h3>
            </div>
          </Col>
          <Col xs={12} xl={8}>
            {pattyCashDetails ? (
              <div className="growth-upward  borderdas_rght mobile-frbr">
                <p>
                  Petty Cash{" "}
                  {typeof pattyCashDetails.cashOut == "number" &&
                    pattyCashDetails.cashOut > 0 && (
                      <Tooltip
                        title={
                          <div>
                            <div>
                              Cash In : + <span>{rsSymbol}</span>{" "}
                              {Number(pattyCashDetails.cashIn).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </div>
                            <div>
                              Cash Out: - <span>{rsSymbol}</span>{" "}
                              {Number(pattyCashDetails.cashOut).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </div>
                          </div>
                        }
                      >
                        <ExclamationCircleOutlined
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                </p>
                <h3>
                  {" "}
                  {rsSymbol}
                  {pattyCashDetails
                    ? Number(pattyCashDetails?.sum).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : Number(0).toFixed(2)}
                </h3>
              </div>
            ) : (
              <div className="growth-upward  borderdas_rght mobile-frbr">
                <p>
                  Petty Cash{" "}
                  {/* <Tooltip
                    title={
                      <div>
                        <div>
                          Cash In : + <span>{rsSymbol}</span>{" "}
                          {Number(0).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                        <div>
                          Cash Out: - <span>{rsSymbol}</span>{" "}
                          {Number(0).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip> */}
                </p>
                <h3>
                  {" "}
                  {rsSymbol}
                  {pattyCashDetails
                    ? Number(pattyCashDetails?.sum).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : Number(0).toFixed(2)}
                </h3>
              </div>
            )}
          </Col>
          <Col xs={12} xl={8}>
            <div className="growth-upward mobileamrgt">
              <p>Today Bookings </p>
              <h3>{totalBookings}</h3>
            </div>
          </Col>
        </Row>

        {cfIsLoading ? (
          <div className="sd-spin">
            <Spin />
          </div>
        ) : (
          <CardBarChart>
            <ChartjsBarChartTransparent
              chartData={chartData}
              displayLegend={false}
              scale={scale}
              height={"30%"}
            />
          </CardBarChart>
        )}
      </Cards>
    </div>
  );
};

export default CashFlow;
