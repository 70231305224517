import React from 'react'
import { Row, Col, Skeleton, Empty, Divider, Calendar } from "antd";
import { Cards } from "../../components/cards/frame/cards-frame";

function AvailabilityCalendar() {
    return (
        <>
            <Cards headless>
                <div className='Availability_header'>
                    <p className='Availability_headerText'>Availability</p>
                    <p>Total number of rooms available to sell. Click on a date to see the availability for each room type.</p>
                </div>

                <div className='calendarBox'>
                    <Calendar
                        className='dashboardCalendar'
                        fullscreen={false}
                    />
                </div>
            </Cards>
        </>
    )
}

export default AvailabilityCalendar