import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import withAdminLayout from "../../layout/withAdminLayout";
import { getItem } from "../../utility/localStorageControl";
import Ecommerce from "../../container/dashboard/Ecommerce";
import Receipts from "../../container/Receipts/Receipts";
import Invoice from "../../container/Receipts/Invoice";
import Products from "../../container/Product/Products";
import AddProduct from "../../container/Product/AddProduct";
import ImportProduct from "../../container/Product/Importform";
import Pricebook from "../../container/Product/PriceBook/Pricebook-list";
import AddPriceBook from "../../container/Product/PriceBook/AddPriceBook";
import EditProduct from "../../container/Product/EditProduct";
import PriceBookItemBuilder from "../../container/Product/PriceBook/PriceBookItemBuilder";
import CategoryBuilder from "../../container/ProductCategory/CategoryBuilder";
import ImportPriceBook from "../../container/Product/PriceBook/Importform";
import ReservationBuilder from "../../container/Reservation/ResurvationBuilder";
/*----- Customer ----------*/

import Customer from "../../container/Customer/Customers";
import EditCustomer from "../../container/Customer/EditCustomer";
import AddCustomer from "../../container/Customer/AddCustomer";
import ImportCustomer from "../../container/Customer/Importform";

/*---- Product Category -------------*/

import AddProductCategory from "../../container/ProductCategory/Add/AddProductCategory";
import AddOrderTicketGroup from "../../container/ProductCategory/Add/AddOrderTicketGroup";

/*---- Product Options -----*/

import ProductOptionBuild from "../../container/ProductOption/ProductOptionBuild";
import ImportVariant from "../../container/ProductOption/Variant/Importform";
import AddVariant from "../../container/ProductOption/Variant/AddVariant";
import EditVariant from "../../container/ProductOption/Variant/EditVariant";

//--- Variat group -----//

import AddVariantGroup from "../../container/ProductOption/VariantGroup/AddVariantGroup";
import EditVariantGroup from "../../container/ProductOption/VariantGroup/EditVariantGroup";
import ImportVariantGroup from "../../container/ProductOption/VariantGroup/Importform";

//---- Addon-----------//

import ImportAddon from "../../container/ProductOption/Addons/Importform";
import AddAddon from "../../container/ProductOption/Addons/AddAddons";
import EditAddon from "../../container/ProductOption/Addons/EditAddons";

// Addon group ---------//
import AddAddonGroup from "../../container/ProductOption/AddonsGroup/AddAddonGroup";
import EditAddonGroup from "../../container/ProductOption/AddonsGroup/EditAddonGroup";
import ImportAddonGroup from "../../container/ProductOption/AddonsGroup/Importform";

//----------- Item group ----------//

import AddItemGroup from "../../container/ProductOption/ItemGroup/AddItemGroup";
import EditItemGroup from "../../container/ProductOption/ItemGroup/EditItemGroup";
import ImportItemGroup from "../../container/ProductOption/ItemGroup/Importform";

//------- Dummy  --------//
import SellBuilder from "../../container/Sell/SellBuilder";

import Settings from "../../container/profile/settings/Settings";
import PricingTable from "../../container/Subscription/PricingTable";
import Application from "../../container/Application/Application";
import Patty from "../../container/PattyCash/Patty";

// --Inventory --//
import { InventoryBuilder } from "../../container/profile/settings/overview/InventoryBuilder";
import { addInventory } from "../../container/profile/settings/overview/Inventory/addInventory";
import { AdjustmentInventory } from "../../container/profile/settings/overview/Inventory/adjustmentInventory";
import { ManageProductsBuilder } from "../../container/profile/settings/overview/Inventory/ManageProductsBuilder";
import { InventoryItemsList } from "../../container/profile/settings/overview/Inventory/InventoryItemsList";
import { Recipe } from "../../container/profile/settings/overview/Inventory/Recipe";
import { InwardInventory } from "../../container/profile/settings/overview/Inventory/InwardInventory";
import { addInventoryRecipe } from "../../container/profile/settings/overview/Inventory/addInventoryRecipe";
import { Addproductrecipe } from "../../container/profile/settings/overview/Inventory/Addproductrecipe";
import { AddRecipe } from "../../container/profile/settings/overview/Inventory/AddRecipe";
import { EditRecipe } from "../../container/profile/settings/overview/Inventory/EditRecipe";

import Billing from "../../container/Billing/Billing";
//Purchase and expanses//
import VendorsBuild from "../../container/Vendors/VendorsBuild";
import AddVendor from "../../container/Vendors/Vendor/AddVendor";
import AddVendorTax from "../../container/Vendors/VendorTax/AddVendorTax";
import VendorsItemsBuild from "../../container/VendorItems/VendorsItemsBuild";
import AddVendorItemCategory from "../../container/VendorItems/Category/AddVendorItemCategory";

import Additems from "../../container/VendorItems/Items/Additems";
import EditItem from "../../container/VendorItems/Items/EditItem";
import AddVendorBill from "../../container/VendorsBill/VendorBill/AddVendorBill";
import VendorsBillBuild from "../../container/VendorsBill/VendorsBillBuild";
import ExpenseBillList from "../../container/ExpenseBill/ExpenseBillList";
import ExpenseCategoryList from "../../container/ExpenseBill/ExpenseCategoryList";
import AddExpenseCategory from "../../container/ExpenseBill/addExpenseCategory";
import AddExpense from "../../container/ExpenseBill/AddExpense";
import { viewAdjustmentsItems } from "../../container/profile/settings/overview/Inventory/viewAdjustmentsItems";
import { VendorItemCategoryList } from "../../container/VendorItems/Category/VendorItemCategoryList";
import ShowRoomReceipt from "../../container/Receipts/ShowRoomReceipt";
import { UserLogin } from "../../container/profile/authentication/overview/UserLogin";
const Cashier = () => {
  const { path } = useRouteMatch();
  const userDetails =
    getItem("userDetails") != null ? getItem("userDetails") : false;
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/user/login/:id" component={UserLogin} />
        <Route exact path={`${path}`} component={SellBuilder} />
        <Route exact path={`${path}receipts`} component={Receipts} />
        <Route exact path={`${path}petty`} component={Patty} />
        <Route exact path={`${path}sell`} component={SellBuilder} />

        {userDetails && userDetails.has_manager_permission && (
          <>
            <Route exact path={`${path}dashboard`} component={Ecommerce} />
            <Route exact path={`${path}customers`} component={Customer} />
            <Route
              exact
              path={`${path}customers/import`}
              component={ImportCustomer}
            />
            <Route
              exact
              path={`${path}customers/edit/:id`}
              component={EditCustomer}
            />
            <Route
              exact
              path={`${path}customers/add`}
              component={AddCustomer}
            />
            <Route
              exact
              path={`${path}inventory`}
              component={InventoryBuilder}
            />
            <Route
              exact
              path={`${path}inventory/add`}
              component={addInventory}
            />
            <Route
              exact
              path={`${path}inventory/POSitems`}
              component={ManageProductsBuilder}
            />
            <Route
              exact
              path={`${path}inventory/itemList`}
              component={InventoryItemsList}
            />
            <Route exact path={`${path}inventory/recipe`} component={Recipe} />
            <Route
              exact
              path={`${path}inventory/inward`}
              component={InwardInventory}
            />
            <Route
              exact
              path={`${path}inventory/add-inventory-recipe`}
              component={addInventoryRecipe}
            />
            <Route
              exact
              path={`${path}inventory/add-product-receipe`}
              component={Addproductrecipe}
            />
            <Route
              exact
              path={`${path}inventory/add-recipe`}
              component={AddRecipe}
            />
            <Route
              exact
              path={`${path}inventory/edit-recipe`}
              component={EditRecipe}
            />{" "}
            <Route
              exact
              path={`${path}vendor/item/categories`}
              component={VendorItemCategoryList}
            />
            <Route path={`${path}vendors`} component={VendorsBuild} />
            <Route path={`${path}vendor/add`} component={AddVendor} />
            <Route path={`${path}vendor/tax/add`} component={AddVendorTax} />
            <Route path={`${path}vendor/items`} component={VendorsItemsBuild} />
            <Route
              exact
              path={`${path}inventory/adjustment`}
              component={AdjustmentInventory}
            />
            <Route
              path={`${path}vendor/itemCategory/add`}
              component={AddVendorItemCategory}
            />
            <Route path={`${path}vendor/item/add`} component={Additems} />
            <Route
              exact
              path={`${path}vendor/item/edit`}
              component={EditItem}
            />
            <Route path={`${path}vendor/bills`} component={VendorsBillBuild} />
            <Route path={`${path}vendor/bill/add`} component={AddVendorBill} />
            <Route
              path={`${path}vendor/expenses`}
              component={ExpenseBillList}
            />
            .{" "}
            <Route
              path={`${path}vendor/expense/addOne`}
              component={AddExpense}
            />
            <Route
              path={`${path}vendor/expense/category`}
              component={ExpenseCategoryList}
            />
            <Route
              path={`${path}vendor/expense/add/category`}
              component={AddExpenseCategory}
            />
            <Route
              exact
              path={`${path}inventory/view-adjustmet-items`}
              component={viewAdjustmentsItems}
            />
          </>
        )}
        <Route
          exact
          path={`${path}room-receipts/:id`}
          component={ShowRoomReceipt}
        />
        <Route path={`${path}settings`} component={Settings} />
        <Route exact path={`${path}receipts/:id`} component={Invoice} />
        <Route path={`${path}appstore`} component={Application} />
        <Route path={`${path}billing`} component={Billing} />
        <Route
          exact
          path={`${path}reservation`}
          component={ReservationBuilder}
        />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Cashier);
