import React, { useState, useRef, useEffect } from "react";
import { Checkbox, Row, Col, Input, Form, Select, Tooltip } from "antd";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import Heading from "../../../../../components/heading/heading";
import { Button } from "../../../../../components/buttons/buttons";
import "../../setting.css";
import TextArea from "antd/lib/input/TextArea";
import { InfoCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { setItem } from "../../../../../utility/localStorageControl";
import { getAllRegisterList } from "../../../../../redux/register/actionCreator";
import {
  addOrUpdateHotel,
  getAllHotelsList,
} from "../../../../../redux/hotels/actionCreator";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const AddHotelIntegration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let isMounted = useRef(true);
  const [form] = Form.useForm();
  const { Option } = Select;
  const [checkedprint, setprint] = useState(false);
  const [checkedlogo, setlogo] = useState(false);
  const [HotelErr, setHotelErr] = useState(false);
  const [HotelAPIKeyErr, setHotelAPIKeyErr] = useState(false);
  const [RegisterList, setRegisterList] = useState([]);
  const [HotelList, setHotelList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [apiData, setApiData] = useState();
  console.log("🚀 ~ file: AddHotelIntegration.js:35 ~ AddHotelIntegration ~ apiData:", apiData)
  useEffect(() => {
    fetchHotelsList();
    async function fetchRegisterList() {
      const getRegisterList = await dispatch(getAllRegisterList("sell"));

      if (isMounted.current && getRegisterList && getRegisterList.RegisterList)
        setRegisterList(getRegisterList.RegisterList);
      if (location && location.state && location.state.data) {
        let FIlteredRegisterID = getRegisterList.RegisterList.find(
          (val) => val?.register_name == location?.state?.data?.register_name
        );
        // setDisabledSave(true);
        // console.log("location?.state?.data", location?.state?.data);
        setApiData({
          hotelKey: location?.state?.data?.receipt_number_prefix,
          registerId: FIlteredRegisterID?._id,
          hotelId: location?.state?.data?.hotel_id,
        });
        form.setFieldsValue({
          hotelKey: location?.state?.data?.receipt_number_prefix,
          registerId: FIlteredRegisterID?._id,
          hotelId: location?.state?.data?.hotel_id,
        });
      }
    }

    if (isMounted.current) {
      fetchRegisterList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchHotelsList = async () => {
    setHotelList([]);
    // let allSetupcache = getItem("Setupcache");
    // if (allSetupcache.hotels) {
    //   console.log("hhahdaidasd", allSetupcache.hotels);
    //   setHotelList(allSetupcache.hotels);
    // }
  };

  const handleSubmit = async (formData) => {
    setLoading(true);
    let getHotelResponse = "";
    if (location && location.state && location.state.data) {
      let FIlteredRegisterData = HotelList.find(
        (val) => val?.registerId?._id == formData?.registerId
      );
      if (
        (location &&
          location.state &&
          location.state.data &&
          location.state.data.register_name ==
          FIlteredRegisterData?.registerId?.register_name) ||
        formData?.registerId !== FIlteredRegisterData?.registerId?._id
      ) {
        getHotelResponse = await dispatch(
          addOrUpdateHotel(formData, location?.state?.data?.id)
        );
      } else {
        setLoading(false);
        setHotelAPIKeyErr(
          "Integration already exists for the selected register."
        );
      }
    } else {
      getHotelResponse = await dispatch(addOrUpdateHotel(formData, ""));
    }
    if (
      getHotelResponse &&
      getHotelResponse.status == 400
    ) {
      setLoading(false);
      setHotelErr(
        "Hotel Id is invalid, make sure you've entered the right id."
      );
      return 1;
    } else if (
      getHotelResponse &&
      getHotelResponse.status == 406
    ) {
      setLoading(false);
      setHotelErr(
        "Hotel Key is invalid, make sure you've entered the right Key."
      );
      return 1;
    } else if (getHotelResponse) {
      console.log("pfsfsfsfsdfslpsfhotelList", getHotelResponse)
      const hotelList = await dispatch(getAllHotelsList());
      console.log("ssaassahotelList", hotelList)
      const getRegisterList = await dispatch(getAllRegisterList());
      console.log("safasfafasgetRegisterList", getRegisterList)
      if (!getRegisterList.error) {
        setLoading(false);
        history.push(`/settings/Hotels`);
      }
    }
  };

  const handleFormChange = (item, allFileds) => {
    // setLoading(false);
    // if (apiData) {
    //   let currentFormData = {};
    //   _.each(apiData, (val, key) => {
    //     let findData = allFileds.find((k) => k.name[0] == key);
    //     if (findData) {
    //       currentFormData[findData.name[0]] =
    //         findData.name[0] == "tax_percentage"
    //           ? Number(findData.value)
    //           : findData.value;
    //     }
    //   });
    //   if (_.isEqual(apiData, currentFormData)) {
    //     setDisabledSave(true);
    //   } else {
    //     setDisabledSave(false);
    //   }
    //   return true;
    // }
  };
  return (
    <>
      <Cards
        title={
          <div className="setting-card-title">
            <Heading as="h4">Add Integration</Heading>
            <span>Contact posease support team for integration.</span>
          </div>
        }
      >
        <Row gutter={25} justify="center">
          <Col xxl={12} md={14} sm={18} xs={24}>
            <Form
              autoComplete="off"
              style={{ width: "100%" }}
              form={form}
              name="add Register"
              onFinish={handleSubmit}
              onChange={() => setHotelErr(false)}
              onFieldsChange={(val, allFileds) =>
                handleFormChange(val, allFileds)
              }
            >
              <Form.Item
                name="hotelKey"
                label="Hotel API key"
                rules={[
                  {
                    min: 3,
                    message:
                      "Hotel API Token must be at least 3 characters long",
                  },

                  { required: true, message: "Hotel API key is required" },
                ]}
              >
                <Input
                  style={{ marginBottom: 10 }}
                  placeholder="Hotel API Token"
                />
              </Form.Item>
              <Form.Item
                name="hotelId"
                label="Hotel Id"
                rules={[
                  {
                    min: 3,
                    message: "Hotel id must be at least 3 characters long",
                  },

                  { required: true, message: "Hotel id is required" },
                ]}
              >
                <Input
                  style={{ marginBottom: 10 }}
                  placeholder="Hotel API Id"
                />
              </Form.Item>
              <Form.Item
                name="registerId"
                label="Linked Register"
                rules={[
                  { required: true, message: "Linked Register is required" },
                ]}
              >
                <Select
                  style={{ width: "100%", marginBottom: 10 }}
                  placeholder="Select a Register"
                  disabled={apiData && apiData?.registerId !== undefined && apiData?.registerId !== null ? true : false}
                >
                  {RegisterList.map((val) => {
                    return <Option value={val._id}>{val.register_name}</Option>;
                  })}
                </Select>
              </Form.Item>
              {HotelAPIKeyErr && (
                <p style={{ color: "red" }}>{HotelAPIKeyErr}</p>
              )}
              {HotelErr && <p style={{ color: "red" }}>{HotelErr}</p>}
              <Form.Item style={{ float: "right" }}>
                <Button
                  onClick={() => history.push("/settings/Hotels")}
                  className="go-back-button"
                  size="medium"
                  type="white"
                  style={{ marginRight: "10px" }}
                >
                  Go Back
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disabledSave}
                >
                  {loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            color: "white",
                            margin: "0px 8px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Cards>
    </>
  );
};

export { AddHotelIntegration };
