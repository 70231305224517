import React, { useState, useRef, useEffect } from "react";
import CurrentReservation from "./CurrentReservation";
import AssignRoom from "./AssignRoom";
const CurrentReservationBuilder = ({
  newReservation,
  tabChangeToCurrentFunction,
  reservationDetails,
  currentRegisterData,
}) => {
  return (
    <>
      {newReservation ? (
        <CurrentReservation
          tabChangeToCurrentFunction={tabChangeToCurrentFunction}
          roomDetails={reservationDetails}
          currentRegisterData={currentRegisterData}
        />
      ) : (
        <AssignRoom
          tabChangeToCurrentFunction={tabChangeToCurrentFunction}
          reservationDetails={reservationDetails}
          currentRegisterData={currentRegisterData}
        />
      )}
    </>
  );
};

export default CurrentReservationBuilder;
