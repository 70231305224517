let OTA = [
  {
    value: "Agoda.com",
    label: "Agoda.com",
  },
  {
    value: "AirAsia",
    label: "AirAsia",
  },
  {
    value: "Airbnb",
    label: "Airbnb",
  },
  {
    value: "Booking.com",
    label: "Booking.com",
  },
  {
    value: "Cleartrip",
    label: "Cleartrip",
  },
  {
    value: "Ctrip.com",
    label: "Ctrip.com",
  },
  {
    value: "EaseMyTrip",
    label: "EaseMyTrip",
  },
  {
    value: "Expedia",
    label: "Expedia",
  },
  {
    value: "Go MMT",
    label: "Go MMT",
  },
  {
    value: "Goibibo",
    label: "Goibibo",
  },
  {
    value: "Goomo",
    label: "Goomo",
  },
  {
    value: "Hotels.com",
    label: "Hotels.com",
  },
  {
    value: "Hotwire.com",
    label: "Hotwire.com",
  },
  {
    value: "Klook",
    label: "Klook",
  },
  {
    value: "MakeMytrip",
    label: "MakeMytrip",
  },
  {
    value: "Orbitz",
    label: "Orbitz",
  },
  {
    value: "PayTM",
    label: "PayTM",
  },
  {
    value: "Tiket.com",
    label: "Tiket.com",
  },
  {
    value: "Travelguru",
    label: "Travelguru",
  },
  {
    value: "Traveloka",
    label: "Traveloka",
  },
  {
    value: "Trivago.com",
    label: "Trivago.com",
  },
  {
    value: "Venere.com",
    label: "Venere.com",
  },
  {
    value: "Wotip.com",
    label: "Wotip.com",
  },
  {
    value: "Via.com",
    label: "Via.com",
  },
  {
    value: "Yatra",
    label: "Yatra",
  },
];

export default OTA;
