import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Button, Select } from "antd";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import Heading from "../../components/heading/heading";
import { addOrUpdateRoom } from "../../redux/room/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { message } from "antd";

const EditRoom = () => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [roomDetails, setRoomDetails] = useState();
  let [roomTypeList, setRoomTypeList] = useState([]);
  const [roomLocationList, setRoomLocationList] = useState([]);
  useEffect(() => {
    if (location?.state?.roomTypeList) {
      setRoomTypeList(location.state.roomTypeList);
    }
    if (location?.state?.roomLocationList) {
      setRoomLocationList(location.state.roomLocationList);
    }
    if (location && location.state && location.state.roomDetails) {
      setRoomDetails(location.state.roomDetails);
      form.setFieldsValue({
        room_name: location.state.roomDetails.room_name,
        location: location.state.roomDetails.location._id,
        room_type: location.state.roomDetails.room_type._id,
      });
    }
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    let add;
    if (roomDetails && roomDetails._id) {
      values["register_id"] = currentRegisterData._id;
      add = await dispatch(addOrUpdateRoom(values, roomDetails._id));
    }
    if (add && add.error == false) {
      history.push("/rooms");
    } else {
      message.error({
        content: add.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  };

  return (
    <>
      <Main>
        <PageHeader ghost className="comman-custom-pageheader" />
        <br></br>
        <Cards
          title={
            <div className="setting-card-title">
              <Heading as="h4">Edit Room Details</Heading>
            </div>
          }
        >
          <Row gutter={25} justify="center">
            <Col xxl={12} md={14} sm={18} xs={24}>
              <div className="formDiv">
                <div className="mainFormdiv">
                  {" "}
                  <Form
                    className="comman-input"
                    autoComplete="off"
                    style={{ width: "100%" }}
                    form={form}
                    name="editProduct"
                    onFinish={handleSubmit}
                  >
                    <Form.Item
                      name="room_name"
                      label="Room Name"
                      rules={[
                        { required: true, message: "Room name is required" },
                        {
                          max: 50,
                          message:
                            "Room name cannot be more than 50 characters long.",
                        },
                      ]}
                    >
                      <Input
                        style={{ marginBottom: 6 }}
                        placeholder="Room Name"
                      />
                    </Form.Item>

                    <Form.Item name="room_type" label="Room type">
                      <Select
                        placeholder="Select room type"
                        showSearch
                        style={{ marginBottom: 6 }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {roomTypeList.map((data) => (
                          <option key={data._id} value={data._id}>
                            {data.name}
                          </option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="custom-input-text"
                      label="Location"
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Location is Required.",
                        },
                      ]}
                    >
                      <Select
                        className="high_addpd"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Select room location"
                      >
                        {roomLocationList &&
                          roomLocationList.map((data) => (
                            <option key={data._id} value={data._id}>
                              {data?.name}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                    <div
                      className="add-form-action"
                      style={{ float: "right", marginTop: 15 }}
                    >
                      <Form.Item>
                        <NavLink to="/rooms/" style={{ marginRight: 10 }}>
                          <Button
                            className="btn-cancel btn-custom"
                            size="medium"
                          >
                            Go Back
                          </Button>
                        </NavLink>
                        <Button
                          size="medium"
                          className="btn-custom"
                          htmlType="submit"
                          type="primary"
                          raised
                        >
                          {loading ? (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: 16,
                                    color: "white",
                                    margin: "0px 8px",
                                  }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default EditRoom;
