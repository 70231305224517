import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { VendorBillList } from "./VendorBill/VendorBillList";
import { VendorByPurchase } from "./VendorByPurchase/VendorByPurchase";
import { setItem, getItem } from "../../utility/localStorageControl";
const VendorsBuild = (props) => {
  const { TabPane } = Tabs;
  const search = new URLSearchParams(props.location.search);
  let type = search.get("type");
  const [activeTab, changeTab] = useState(
    type == "purchase" ? "VENDOR_BY_PURCHASE" : "BILL"
  );
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  // console.log("windowWidth:::---product option-builder---->>>>>>>>>>::", getItem("windowWidth"))

  return (
    <>
      <Main>
        <PageHeader
          className="comman-custom-pageheader"
          size="small"
          ghost
          // title={
          //   <>
          //     <Tabs
          //       type="card"
          //       activeKey={activeTab}
          //       size="small"
          //       onChange={changeTab}
          //     >
          //       <TabPane tab="Bill" key="BILL"></TabPane>
          //       <TabPane
          //         tab="Vendor by purchase"
          //         key="VENDOR_BY_PURCHASE"
          //       ></TabPane>
          //     </Tabs>
          //   </>
          // }
        />
        <VendorBillList />
        {/* {activeTab === "BILL" ? <VendorBillList /> : ""}
        {activeTab === "VENDOR_BY_PURCHASE" ? <VendorByPurchase /> : ""} */}
      </Main>
    </>
  );
};

export default VendorsBuild;
